export const messages = {
  sessionTitle: {
    id: "section-title.session-title",
    defaultMessage: "{{planTitle}} Volume {{volumeIndex}}, {{sessionIndex}}",
  },
  bibleVersions: {
    id: "section-title.bible-versions",
    defaultMessage: "Bible versions",
  },
};
