import { colors, fontSizes, lineHeights, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { pxToNumber } from "~/utils/theme";

export const markdownStyles = {
  body: {
    fontSize: pxToNumber(isWeb ? fontSizes.fs4 : fontSizes.fs3),
    marginHorizontal: pxToNumber(isWeb ? spacers.ss8 : spacers.ss7),
    lineHeight: pxToNumber(isWeb ? lineHeights.lh5 : lineHeights.lh3),
  },
  link: {
    color: colors.primaryBlue,
    textDecorationLine: "none",
  },
  heading3: {
    fontSize: pxToNumber(fontSizes.fs6),
    lineHeight: pxToNumber(lineHeights.lh6),
    paddingTop: pxToNumber(spacers.ss4),
  },
  heading4: {
    fontSize: pxToNumber(fontSizes.fs5),
    lineHeight: pxToNumber(lineHeights.lh5),
    paddingTop: pxToNumber(spacers.ss4),
  },
  image: {
    width: "100%",
    height: undefined,
  },
  superscript: {
    fontSize: pxToNumber(fontSizes.fs0),
    lineHeight: pxToNumber(lineHeights.lh1),
    bottom: pxToNumber(fontSizes.fs0) / 2,
  },
  code_inline: {
    color: colors.amber700,
    backgroundColor: colors.white,
    borderColor: "transparent",
    padding: 0,
  },
};
