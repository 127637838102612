import * as React from "react";

import { ListItem } from "~/components/list-item";

import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";

interface Props {
  title: MessageDescriptorValues;
  count: number;
  onPress: () => void;
  isLoading?: boolean;
  isActive?: boolean;
  isLast?: boolean;
}

export const Note = ({
  title,
  count,
  onPress,
  isLoading,
  isActive,
  isLast,
}: Props) => {
  const message = isLoading
    ? messages.loading
    : { ...messages.summary, values: { count } };

  return (
    <ListItem
      description={message}
      title={formatMessage(title)}
      onPress={onPress}
      isActive={isActive}
      isLast={isLast}
    />
  );
};
