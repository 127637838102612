export const messages = {
  session: {
    id: "bible-header.session",
    defaultMessage: "Session",
  },
  back: {
    id: "bible-header.back",
    defaultMessage: "Back",
  },
  selected: {
    id: "bible-header.selected",
    defaultMessage: "Selected:",
  },
};
