export const messages = {
  needHelp: {
    id: "pif.footer.need-help",
    defaultMessage: "Need Help?",
  },
  view: {
    id: "pif.footer.view",
    defaultMessage: "View",
  },
  helpTopics: {
    id: "pif.footer.help-topics",
    defaultMessage: "Help Topics",
  },
  call: {
    id: "pif.footer.call",
    defaultMessage: "Call",
  },
  orEmail: {
    id: "pif.footer.or-email",
    defaultMessage: "or email",
  },
};
