import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { SessionNoteViewProps } from "~/navigation/notes-stack/types";

import { NoteViewEdit, SavePayload } from "~/components/note-view-edit";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getSessionNotesById } from "~/state/notes/selectors";
import { NoteQuestion } from "~/components/note-question";
import { asyncLogEvent, events } from "~/utils/analytics";
import { MessageType } from "~/state/chat/types";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";
import { handleError } from "~/utils/logger";

import { messages } from "./intl";
import {
  editSessionNote,
  deleteSessionNote,
} from "~/state/session-notes/actions";

interface Props {
  noteId: string;
  sessionId: string;
  planId: string;
  title: string;
  sessionTitle: string;
  handleDelete: () => void;
}

export const SessionNoteViewComponent = ({
  noteId,
  sessionId,
  planId,
  title,
  sessionTitle,
  handleDelete,
}: Props) => {
  const note = useAppSelector((state) => getSessionNotesById(state, noteId));

  const dispatch = useAppDispatch();

  const { pushAlert } = useAlerts();

  const question = note?.question || "";
  const text = note?.note || "";
  const lastUpdated = note?.lastUpdated || 0;
  const key = note?.id || "";

  const backButtonText = { ...messages.backButton, values: { sessionTitle } };

  const onDelete = React.useCallback(() => {
    handleDelete();

    dispatch(
      deleteSessionNote({
        noteId,
        onSuccess: () => {
          asyncLogEvent(events.SESSION_NOTE_DELETE);
          pushAlert({
            message: genericMessages.noteDeleted,
            color: colors.emerald600,
          });
        },
        onError: (e) => {
          pushAlert({
            message: genericMessages.error,
          });
          handleError(e);
        },
      })
    );
  }, [dispatch, pushAlert, noteId, handleDelete]);

  const onSave = React.useCallback(
    ({ value }: SavePayload) => {
      if (value === "") {
        onDelete();
        return;
      }

      dispatch(
        editSessionNote({
          noteId,
          note: value,
          onSuccess: () => {
            asyncLogEvent(events.BIBLE_NOTE_EDIT);
            pushAlert({
              message: genericMessages.saveSuccess,
              color: colors.emerald600,
            });
          },
          onError: (e) => {
            pushAlert({
              message: genericMessages.error,
            });
            handleError(e);
          },
        })
      );
    },
    [noteId, dispatch, pushAlert, onDelete]
  );

  return (
    <NoteViewEdit
      screenName="session-note-view"
      title={title}
      text={text}
      type={MessageType.NoteSession}
      lastUpdated={lastUpdated}
      backButtonText={backButtonText}
      onSave={onSave}
      onDelete={onDelete}
      data={{
        title,
        sessionId,
        planId,
        question,
      }}
    >
      <NoteQuestion question={question} />
    </NoteViewEdit>
  );
};

export const SessionNoteView = () => {
  const {
    params: { noteId, sessionId, planId, title, sessionTitle, isSingleNote },
  } = useRoute<SessionNoteViewProps["route"]>();
  const navigation = useNavigation();

  const handleDelete = () => {
    if (isSingleNote) {
      navigation.navigate(routes.sessionNotes);
    } else {
      navigation.goBack();
    }
  };

  return (
    <SessionNoteViewComponent
      noteId={noteId}
      sessionId={sessionId}
      planId={planId}
      title={title}
      sessionTitle={sessionTitle}
      handleDelete={handleDelete}
    />
  );
};
