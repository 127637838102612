import { httpsCallable } from "firebase/functions";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

import { auth, functions } from "<config>/firebase";

import { ChangePasswordPayload, Environment } from "./types";
import {
  getUserDeleteFunction,
  getUpdateUserFunction,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";

export async function executeChangePassword({
  currentPassword,
  newPassword,
  onSuccess,
  onError,
}: ChangePasswordPayload) {
  const user = auth.currentUser;

  const credential = user?.email
    ? EmailAuthProvider.credential(user.email, currentPassword)
    : undefined;

  if (auth.currentUser && credential) {
    try {
      const reauthenticatedResult = await reauthenticateWithCredential(
        auth.currentUser,
        credential
      );

      if (reauthenticatedResult.user) {
        try {
          await updatePassword(reauthenticatedResult.user, newPassword);

          onSuccess();
        } catch (error) {
          if (error instanceof Error) {
            onError(error.message);
          }
          handleError(error);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      }
      handleError(error);
    }
  }
}

interface UpdateUserResponse {
  data?: {
    uid?: string;
    email?: string;
    error?: string;
  };
}

export async function deleteUser(
  env: Environment,
  deleteOnlyData: boolean = false
): Promise<UpdateUserResponse> {
  const userDeleteFunction = getUserDeleteFunction(env);
  const deleteAccount = httpsCallable(functions, userDeleteFunction);

  const result = await deleteAccount({ deleteOnlyData });

  return result as UpdateUserResponse;
}

export async function updateEmail(
  email: string,
  userId: string
): Promise<UpdateUserResponse> {
  const updateUserFunction = getUpdateUserFunction();
  const update = httpsCallable(functions, updateUserFunction);

  const result = await update({ email, userId });

  return result as UpdateUserResponse;
}
