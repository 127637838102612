import React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SettingsStackParamList } from "~/navigation/settings-stack";
import {
  openBrowserAsync,
  WebBrowserPresentationStyle,
} from "expo-web-browser";
import { ModalHeader } from "~/components/modal-header";
import {
  SaveButton,
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemDivider,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { APP_VERSION } from "~/constants";
import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { makeExternalLinks } from "~/utils/external-links";
import { messages } from "./intl";
import { ItemDescription, SubContainer } from "./styles";
import { isWeb } from "~/utils/platform";

type SettingsProps = NativeStackScreenProps<SettingsStackParamList, "settings">;

export const About = () => {
  const navigation = useNavigation<SettingsProps["navigation"]>();

  const onDonePress = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  const onPressTermsAndConditions = React.useCallback(async () => {
    await openBrowserAsync(makeExternalLinks().TERMS_AND_CONDITIONS, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  const onPressPrivacyPolicy = React.useCallback(async () => {
    await openBrowserAsync(makeExternalLinks().PRIVACY_POLICY, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  const onPressMarketingLink = React.useCallback(async () => {
    await openBrowserAsync(makeExternalLinks().MARKETING, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  const onPressLegal = React.useCallback(
    () => navigation.navigate(routes.legal),
    [navigation]
  );

  return (
    <SettingsScreen screenName="about">
      {!isWeb ? (
        <ModalHeader
          onLeftPress={onDonePress}
          onRightPress={onDonePress}
          title={messages.title}
        />
      ) : null}

      <SubContainer>
        <SettingsListItemContainer>
          <SettingsListItemLabel label={formatMessage(messages.appInfo)} />

          {!isWeb ? (
            <>
              <SettingsListItem
                title={formatMessage(messages.appVersion, {
                  version: APP_VERSION,
                })}
                isFirstItem
              />
              <SettingsListItemDivider />
            </>
          ) : null}

          <SettingsListItem
            title={formatMessage(messages.termsAndConditions)}
            type="navigate"
            onPress={onPressTermsAndConditions}
            isFirstItem={isWeb}
          />

          <SettingsListItemDivider />

          <SettingsListItem
            title={formatMessage(messages.privacyPolicy)}
            type="navigate"
            onPress={onPressPrivacyPolicy}
          />
          <SettingsListItemDivider />

          <SettingsListItem
            title={formatMessage(messages.legal)}
            type="navigate"
            isLastItem
            onPress={onPressLegal}
          />
        </SettingsListItemContainer>

        <SettingsListItemContainer>
          <SettingsListItemLabel
            label={formatMessage(messages.bibleEngagementProject)}
          />

          <ItemDescription>
            {formatMessage(messages.bibleEngagementProjectDescription)}
          </ItemDescription>

          <ItemDescription onPress={onPressMarketingLink}>
            {formatMessage(messages.marketing)}
          </ItemDescription>
        </SettingsListItemContainer>
      </SubContainer>

      <SaveButton onSave={onDonePress} />
    </SettingsScreen>
  );
};
