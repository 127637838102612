export const messages = {
  cancel: {
    id: "modal-header.cancel",
    defaultMessage: "Cancel",
  },
  done: {
    id: "bible-header.done",
    defaultMessage: "Done",
  },
};
