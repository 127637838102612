export const messages = {
  signIn: {
    id: "intro.log-in",
    defaultMessage: "Log In",
  },
  getStarted: {
    id: "intro.get-started",
    defaultMessage: "Create free account",
  },
};
