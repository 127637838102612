import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import { Plan } from "~/components/plan";
import { NoPlansFound } from "~/components/no-plans-found";
import { FeaturedCarousel } from "~/components/featured-carousel";
import { AudiencesContent, getFilteredAudiences } from "~/state/flamelink";
import { resetFilters } from "~/state/ui";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { LibraryProps } from "../..";
import {
  AudienceContainer,
  AudiencesContainer,
  AudienceTitle,
  PlansContainer,
  InnerContainer,
} from "./styles";
import { messages } from "./intl";
import { LibraryProgress } from "../library-progress";
import { TutorialStep } from "~/components/tutorial-step";
import { LibraryHeader } from "../library-header";

export function Audiences() {
  const navigation = useNavigation<LibraryProps["navigation"]>();
  const dispatch = useAppDispatch();
  const audiences = useAppSelector(getFilteredAudiences);
  const { bottom } = useSafeAreaInsets();

  const onPlanPress = React.useCallback(
    (planId: string) => navigation.navigate(routes.plan, { planId }),
    [navigation]
  );

  const onResetPress = React.useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  );

  if (!audiences || !audiences.length) {
    return (
      <NoPlansFound
        resetText={formatMessage(messages.resetFilters)}
        onResetPress={onResetPress}
      />
    );
  }

  const renderSection = (audience: AudiencesContent, index: number) => (
    <AudienceContainer key={`audience-container-${index}`}>
      <AudienceTitle>{audience.carouselTitle ?? audience.title}</AudienceTitle>

      <PlansContainer>
        {audience.plans.map((plan) => (
          <Plan
            key={`${plan}`}
            planId={plan}
            onPress={() => onPlanPress(plan)}
          />
        ))}
      </PlansContainer>
    </AudienceContainer>
  );

  return (
    <AudiencesContainer extraMargin={bottom} hasFixedHeader>
      <InnerContainer>
        <LibraryHeader />

        <FeaturedCarousel />
        {audiences.map((audience, i) => {
          if (i === 0) {
            return (
              <TutorialStep id="LIBRARY.AUDIENCE" key="tutorial-step">
                {renderSection(audience, i)}
              </TutorialStep>
            );
          }
          return renderSection(audience, i);
        })}

        <LibraryProgress />
      </InnerContainer>
    </AudiencesContainer>
  );
}
