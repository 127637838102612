import * as React from "react";
import { FlatList } from "react-native";

import { ListItem } from "~/components/list-item";
import { useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";

import { ListContainer, EmptyListText, containerStyle } from "../styles";
import { messages } from "../intl";

interface Props {
  onPress: (groupId: string) => void;
  currentGroupId: string;
}

export const GroupsList = ({ onPress, currentGroupId }: Props) => {
  const data = useAppSelector(getGroupsOrderedByTime);

  const hasData = data.length;

  return hasData ? (
    <ListContainer>
      <FlatList
        data={data}
        contentContainerStyle={containerStyle}
        renderItem={({ item, index }) => {
          const { id, name, imageUri, members } = item;
          const description =
            members.length === 1
              ? messages.oneMember
              : { ...messages.members, values: { count: members.length } };
          const isLast = index === data.length - 1;
          const isActive = currentGroupId === id;

          return (
            <ListItem
              title={name}
              description={description}
              uri={imageUri}
              onPress={() => onPress(id)}
              hasAvatar
              isLast={isLast}
              isActive={isActive}
            />
          );
        }}
      />
    </ListContainer>
  ) : (
    <EmptyListText>{messages.empty}</EmptyListText>
  );
};
