import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { configDefaults } from "~/constants/remote-config";

import { ConfigState } from "./types";

const initialState: ConfigState = {
  remote: configDefaults,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setRemoteConfig: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.remote = action.payload;
    },
  },
});

export const { setRemoteConfig } = configSlice.actions;

export default configSlice.reducer;
