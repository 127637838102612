import * as React from "react";
import { View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

import { genericMessages } from "~/constants/intl";
import { AuthScreen } from "~/components/auth-screen";
import { LoginBox } from "~/components/auth-screen/components/login-box";
import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { TextInput } from "~/components/text-input";
import { useAppDispatch } from "~/state/hooks";
import { signup } from "~/state/user/actions";
import { isValidEmail, isValidPassword } from "~/utils/strings";
import { isWeb } from "~/utils/platform";
import { saveParams } from "~/utils/params";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { messages } from "./intl";

export const SignUp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState<MessageDescriptor>();
  const [passwordError, setPasswordError] = React.useState<MessageDescriptor>();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();
  const navigation = useNavigation();
  const route = useRoute();

  const isValid = isValidEmail(email) && isValidPassword(password);

  const resetEmailError = React.useCallback(() => setEmailError(undefined), []);
  const resetPasswordError = React.useCallback(
    () => setPasswordError(undefined),
    []
  );

  const onChangeEmail = React.useCallback(
    (value: string) => {
      resetEmailError();
      setEmail(value);
    },
    [resetEmailError]
  );

  const onChangePassword = React.useCallback(
    (value: string) => {
      resetPasswordError();
      setPassword(value);
    },
    [resetPasswordError]
  );

  const handleError = React.useCallback(
    (error: string = "") => {
      if (error.includes("auth/invalid-email")) {
        setEmailError(messages.errorEmail);
        return;
      }
      if (error.includes("auth/email-already-in-use")) {
        setEmailError(messages.errorEmailInUse);
        return;
      }
      if (error.includes("auth/wrong-password")) {
        setPasswordError(messages.errorPassword);
        return;
      }
      if (error.includes("auth/weak-password")) {
        setPasswordError(messages.weakPassword);
        return;
      }

      pushAlert({ message: genericMessages.error, color: colors.black });
    },
    [pushAlert]
  );

  const handleSignUp = React.useCallback(() => {
    setEmailError(undefined);
    setPasswordError(undefined);
    setIsLoading(true);

    dispatch(
      signup({
        email: email.toLowerCase(),
        password,
        onSuccess: () => {
          setIsLoading(false);
        },
        onError: (error) => {
          handleError(error);
          setIsLoading(false);
        },
      })
    );
  }, [dispatch, email, password, handleError]);

  React.useEffect(() => {
    const params = route?.params as Record<string, string>;
    if (isWeb && params) {
      saveParams(params);
    }
  }, [route]);

  return (
    <AuthScreen
      screenName="sign-up"
      title={messages.header}
      description={messages.title}
      hasLinks
      hasBackButton
      hasDisclaimer
      id="signup"
    >
      <InputBox>
        <TextInput
          label={messages.email}
          value={email}
          onChangeText={onChangeEmail}
          onFocus={resetEmailError}
          errorMessage={emailError}
          autoCorrect={false}
          autoCapitalize="none"
          autoComplete="email"
          keyboardType="email-address"
          returnKeyType="done"
          key="email"
          numberOfLines={1}
        />
        <TextInput
          label={messages.password}
          value={password}
          onChangeText={onChangePassword}
          onFocus={resetPasswordError}
          errorMessage={passwordError}
          hintMessage={messages.hintPassword}
          returnKeyType="done"
          secureTextEntry
          isSecure
          key="password"
          numberOfLines={1}
        />
      </InputBox>

      <View>
        <Button
          text={messages.buttonCreate}
          onPress={handleSignUp}
          type={ButtonTypes.Primary}
          isDisabled={!isValid}
          isLoading={isLoading}
          isUppercase
        />
        <LoginBox />
      </View>
    </AuthScreen>
  );
};
