import * as React from "react";
import {
  format,
  isToday,
  isYesterday,
  differenceInCalendarDays,
} from "date-fns";

import { handleError } from "~/utils/logger";

import { Container, Text } from "./styles";
import { messages } from "./intl";

interface Props {
  timestamp: number;
}

const getTimeFormat = (timestamp: number) => {
  try {
    if (isToday(timestamp)) {
      return messages.today;
    }
    if (isYesterday(timestamp)) {
      return messages.yesterday;
    }
    if (differenceInCalendarDays(new Date(), timestamp) < 7) {
      return format(timestamp, "EEEE");
    }
    return format(timestamp, "EEE d LLL");
  } catch (e) {
    handleError(e);
    return "";
  }
};

export const DayLabel = React.memo<Props>(({ timestamp }) => {
  const text = getTimeFormat(timestamp);
  return (
    <Container>
      <Text>{text}</Text>
    </Container>
  );
});
