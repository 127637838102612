import React from "react";

import { LibraryContext } from "./context";

type Props = {
  children: React.ReactNode;
};

export const LibraryProvider = ({ children }: Props) => {
  const [planProgressId, setPlanProgressId] = React.useState("");

  const state = {
    planProgressId,
    setPlanProgressId,
  };

  return (
    <LibraryContext.Provider value={state}>{children}</LibraryContext.Provider>
  );
};
