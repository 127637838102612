export const messages = {
  verifyText: {
    id: "auth.verify",
    defaultMessage: "Verifying email address...",
  },
  wait: {
    id: "auth.wait",
    defaultMessage: "Please wait...",
  },
};
