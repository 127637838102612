import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import { SectionTitle } from "../../../common";
import { RichText } from "../../../rich-text";
import { Media } from "../../../media";
import { ContentIcon } from "../../../content-icon";

interface BibleLessonRecapProps {
  bibleLessonRecap: LessonsContent["bibleLessonRecap"];
}

export const BibleLessonRecap: React.FC<BibleLessonRecapProps> = ({
  bibleLessonRecap,
}) => {
  const hasContent = React.useMemo(
    () =>
      bibleLessonRecap?.introduction ||
      bibleLessonRecap?.youWillNeed ||
      bibleLessonRecap?.gettingReady ||
      bibleLessonRecap?.description ||
      bibleLessonRecap?.media?.length,
    [bibleLessonRecap]
  );

  return hasContent ? (
    <>
      {bibleLessonRecap?.sectionTitle && (
        <SectionTitle>{bibleLessonRecap.sectionTitle}</SectionTitle>
      )}

      {bibleLessonRecap?.introduction && (
        <RichText>{bibleLessonRecap.introduction}</RichText>
      )}

      {bibleLessonRecap?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={bibleLessonRecap.youWillNeed}
          checklistId="bibleLessonRecap-youWillNeed"
        />
      )}

      {bibleLessonRecap?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={bibleLessonRecap.gettingReady}
          checklistId="bibleLessonRecap-gettingReady"
        />
      )}

      {bibleLessonRecap?.description && (
        <RichText>{bibleLessonRecap.description}</RichText>
      )}

      {Array.isArray(bibleLessonRecap?.media) &&
        bibleLessonRecap?.media.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {bibleLessonRecap?.content && (
        <RichText>{bibleLessonRecap.content}</RichText>
      )}

      <ContentIcon
        content={[
          bibleLessonRecap?.introduction || "",
          bibleLessonRecap?.description || "",
          bibleLessonRecap?.content || "",
        ]}
      />
    </>
  ) : null;
};
