import { css } from "styled-components/native";
import { isWeb } from "~/utils/platform";

export enum MaxWidthBreakpoints {
  Mobile = 640,
  Desktop = 1024,
}

export const getWebContainerStyle = (
  maxWidth: number = MaxWidthBreakpoints.Mobile,
  withMarginAuto: boolean = true,
  withAlignSelfCenter: boolean = false
) =>
  isWeb
    ? css`
        width: 100%;
        max-width: ${maxWidth}px;
        ${withMarginAuto ? "margin: 0px auto;" : ""}
        ${withAlignSelfCenter ? "align-self: center;" : ""}
      `
    : "";

export const webModalStyles = isWeb
  ? {
      maxHeight: 600,
      width: 600,
    }
  : {};
