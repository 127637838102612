import { createSelector } from "@reduxjs/toolkit";

import { getUsers } from "~/state/users/selectors";

import { RootState } from "../store";
import { Message, RawMessage } from "./types";

const getState = (state: RootState) => state.chat;

export const getChatMessages: (state: RootState) => RawMessage[] =
  createSelector(getState, (state) => state.messages || []);

export const getSortedChatMessages: (state: RootState) => RawMessage[] =
  createSelector(getChatMessages, (messages) =>
    [...messages].sort((a, b) => a.timestamp - b.timestamp)
  );

const emptyUser = {
  id: "",
  imageUri: "",
  firstName: "",
  lastName: "",
};

export const getUserChatMessages: (state: RootState) => Message[] =
  createSelector([getSortedChatMessages, getUsers], (messages, users) => {
    return messages.map((message) => {
      const sender = users.find(({ id }) => id === message.userId) || emptyUser;
      return { ...message, sender };
    });
  });
