import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text1Center, Text2Center } from "../text";

interface OfflineBannerContainerProps {
  withTopMargin: boolean;
}

export const OfflineBannerContainer = styled.View<OfflineBannerContainerProps>`
  background-color: ${colors.gray600};
  padding: ${spacers.ss4};
  margin: ${({ withTopMargin }) => (withTopMargin ? spacers.ss4 : 0)} 0;
`;

export const OfflineBannerTitle = styled(Text2Center)`
  color: ${colors.white};
  font-family: SFProDisplayMedium;
`;

export const OfflineBannerDescription = styled(Text1Center)`
  color: ${colors.white};
  font-family: SFProDisplayMedium;
  margin: ${spacers.ss2} 0 0;
`;
