import styled, { css } from "styled-components/native";

import { Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Wrapper = styled.View`
  margin-bottom: ${spacers.ss3};
`;

export const Text = styled(Text3)`
  color: ${colors.gray500};
  letter-spacing: -0.2px;
  padding: ${spacers.ss5};
`;
