import * as React from "react";

import { ModalState } from "./types";

export const initialState: ModalState = {
  isVisible: false,
  showModal: () => {},
  hideModal: () => {},
};

export const ModalContext = React.createContext(initialState);
