import * as React from "react";

import { AuthScreen } from "~/components/auth-screen";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { saveProfile, preLogout } from "~/state/user/actions";
import { getIsEmailVerified } from "~/state/user/selectors";
import { useAlert } from "~/components/alert";
import { formatMessage } from "~/utils/translation";
import { messages as authMessages } from "~/components/auth-screen/intl";

import { BasicInputs } from "./components/basic-inputs";
import { ChurchInputs } from "./components/church-inputs";
import { ChurchNameInputs } from "./components/church-name-inputs";
import { UsageInputs } from "./components/usage-inputs";
import { EmailVerification } from "./components/email-verification";
import {
  ProfileData,
  ChurchData,
  UsageData,
  ChurchNameData,
  BasicData,
} from "./types";

export const CreateProfile = () => {
  const [data, setData] = React.useState({} as ProfileData);
  const [step, setStep] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const isEmailVerified = useAppSelector(getIsEmailVerified);
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();

  const progressStep = React.useCallback((index: number) => {
    setStep(index);
  }, []);

  const handleCreateProfile = React.useCallback(
    (allData: ProfileData) => {
      setIsLoading(true);

      dispatch(
        saveProfile({
          ...allData,
          onSuccess: () => setIsLoading(false),
          onError: () => setIsLoading(false),
        })
      );
    },
    [dispatch]
  );

  const handleStep0 = React.useCallback(
    (formData: BasicData) => {
      setData({ ...data, ...formData });
      progressStep(1);
    },
    [data, progressStep]
  );

  const handleStep1 = React.useCallback(
    (formData: ChurchData) => {
      setData({ ...data, ...formData });
      progressStep(2);
    },
    [data, progressStep]
  );

  const handleStep2 = React.useCallback(
    (formData: ChurchNameData) => {
      setData({ ...data, ...formData });
      progressStep(3);
    },
    [data, progressStep]
  );

  const handleStep3 = React.useCallback(
    (formData: UsageData) => {
      const newData = { ...data, ...formData };
      setData(newData);
      handleCreateProfile(newData);
    },
    [data, handleCreateProfile]
  );

  const handleLogout = React.useCallback(() => {
    showAlert(
      formatMessage(authMessages.logOutTitle),
      formatMessage(authMessages.logOutText),
      [
        {
          text: formatMessage(authMessages.logOutCancel),
          style: "cancel",
        },
        {
          text: formatMessage(authMessages.logOutOk),
          onPress: () => dispatch(preLogout()),
        },
      ]
    );
  }, [dispatch, showAlert]);

  const onBackPress = React.useCallback(() => {
    if (step === 0) {
      handleLogout();
    } else {
      setStep(step - 1);
    }
  }, [step, setStep, handleLogout]);

  const goToStep0 = React.useCallback(() => progressStep(0), [progressStep]);
  const goToStep1 = React.useCallback(() => progressStep(1), [progressStep]);
  const goToStep2 = React.useCallback(() => progressStep(2), [progressStep]);

  if (!isEmailVerified) {
    return <EmailVerification />;
  }

  return (
    <AuthScreen
      hideSocialButtons
      id="create"
      screenName="create-profile"
      hasBackButton
      onBack={onBackPress}
      step={step}
    >
      {step === 0 ? (
        <BasicInputs
          onPress={handleStep0}
          progressStep={progressStep}
          {...data}
        />
      ) : null}

      {step === 1 ? (
        <ChurchInputs
          onPress={handleStep1}
          onBackPress={goToStep0}
          progressStep={progressStep}
          {...data}
        />
      ) : null}

      {step === 2 ? (
        <ChurchNameInputs
          onPress={handleStep2}
          onBackPress={goToStep1}
          progressStep={progressStep}
          {...data}
        />
      ) : null}

      {step === 3 ? (
        <UsageInputs
          onPress={handleStep3}
          onBackPress={goToStep2}
          isLoading={isLoading}
          progressStep={progressStep}
          {...data}
        />
      ) : null}
    </AuthScreen>
  );
};
