import styled from "styled-components/native";
import { Text3 } from "~/components/text";
import { lineHeights, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

import { horizontalMargin } from "../../styles";

export const VolumeOverviewHeading = styled(Text3)`
  font-family: SFProDisplayBold;
  padding: 0 ${horizontalMargin};
  line-height: ${lineHeights.lh3};
  font-weight: 700;
  padding-top: ${isWeb ? spacers.ss5 : 0};
`;
