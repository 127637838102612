import * as React from "react";
import { View } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";

import { Button } from "~/components/button";
import { InputCode } from "~/components/input-code";
import { GroupAvatar } from "~/components/group-avatar";
import { useModal } from "~/state/modal/hook.web";
import { asyncLogEvent, events } from "~/utils/analytics";
import { useAppDispatch } from "~/state/hooks";
import { editGroup } from "~/state/groups/actions";
import { useAlerts } from "~/state/alerts";
import { messages as editMessages } from "../create-edit/intl";

import { ChangeImage } from "../change-image";
import { Members } from "../members";
import { DeleteButtonComponent } from "../delete-button";

import { SectionBox } from "../../styles";
import {
  Title,
  EditButton,
  EditText,
  SectionTitle,
  CodeBox,
  RefreshButton,
  MemberBox,
  WebBox,
  WebBoxContent,
} from "./styles";
import { messages } from "./intl";
import { GroupModalComponent } from "../..";
import { colors } from "~/styles/theme";

interface Props {
  canEdit: boolean;
  onCodeRefresh: () => void;
  shareLink: () => void;
  groupId: string;
  groupName?: string;
  inviteCode?: string;
  imageUri?: string;
}

export const GroupOverviewContent = React.memo<Props>(
  ({
    canEdit,
    groupName = "",
    imageUri,
    onCodeRefresh,
    inviteCode = "",
    shareLink,
    groupId,
  }) => {
    const [localUri, setLocalUri] = React.useState(imageUri);
    const { showModal, hideModal } = useModal();
    const { pushAlert } = useAlerts();
    const dispatch = useAppDispatch();

    const onChange = React.useCallback(
      (uri: string) => {
        asyncLogEvent(events.GROUP_EDITED);
        dispatch(
          editGroup({
            groupId,
            imageUri: uri,
            onSuccess: () => {
              pushAlert({
                message: editMessages.editSuccess,
                color: colors.emerald600,
              });
            },
            onError: () => {
              pushAlert({
                message: editMessages.saveError,
                color: colors.red600,
              });
            },
          })
        );
      },
      [dispatch, groupId, pushAlert]
    );

    const onEdit = React.useCallback(() => {
      showModal(
        <GroupModalComponent
          groupId={groupId}
          isEditMode
          onGoBack={hideModal}
        />
      );
    }, [showModal, hideModal, groupId]);

    return (
      <View style={{ flex: 1 }}>
        <WebBox>
          <ChangeImage
            groupName={groupName}
            imageUri={localUri}
            setImageUri={setLocalUri}
            isSmall
            isDisabled={!canEdit}
            onChange={onChange}
          />

          <WebBoxContent>
            <Title>{groupName}</Title>
          </WebBoxContent>

          {canEdit ? (
            <EditButton onPress={onEdit}>
              <EditText>{messages.editButton}</EditText>
            </EditButton>
          ) : null}
        </WebBox>

        {canEdit ? (
          <SectionBox>
            <CodeBox>
              <SectionTitle>{messages.inviteSection}</SectionTitle>
              <RefreshButton onPress={onCodeRefresh}>
                <Icon name="refresh" size={16} />
              </RefreshButton>
            </CodeBox>

            <InputCode value={inviteCode} isLoading={true} />
          </SectionBox>
        ) : null}

        {canEdit ? (
          <SectionBox>
            <Button
              text={messages.button}
              onPress={shareLink}
              iconType="send"
            />
          </SectionBox>
        ) : null}

        <MemberBox>
          <SectionTitle>{messages.membersSection}</SectionTitle>
          <Members groupId={groupId} />
        </MemberBox>

        {canEdit ? (
          <SectionBox>
            <DeleteButtonComponent groupId={groupId} onSuccess={hideModal} />
          </SectionBox>
        ) : null}
      </View>
    );
  }
);
