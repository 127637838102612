export function chunkArray(array: any[], chunkSize: number): string[][] {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunkedArray;
}

/**
 * Shallow comparison for arrays of primitives
 */
export function isShallowPrimitiveArrayEqual(
  arr1: string[] | number[],
  arr2: string[] | number[]
) {
  return [...arr1].sort().join() === [...arr2].sort().join();
}

export const removeUndefinedKeys = <T>(obj: T): T => {
  const newObj: T = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};
