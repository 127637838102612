import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { colors, fontSizes, spacers } from "~/styles/theme";
import { Text1, Text3, Text5 } from "~/components/text";

export const Container = styled.View`
  margin-horizontal: ${spacers.ss8};
`;

export const Title = styled(Text5)`
  font-family: SFProDisplayMedium;
  margin-bottom: ${spacers.ss3};
`;

export const TitleBox = styled.View`
  align-items: center;
  margin-vertical: ${spacers.ss8};
`;

export const TopBox = styled.View``;

export const Row = styled.View`
  flex-direction: row;
`;

export const DisclaimerBox = styled.View`
  margin-top: ${spacers.ss6};
  flex-direction: row;
`;

export const DividerBox = styled.View`
  align-items: center;
  margin-vertical: ${spacers.ss8};
`;

export const Divider = styled.View`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray400};
`;

export const Section = styled.View<{
  isCenter?: boolean;
  hasPadding?: boolean;
}>`
  margin-bottom: ${spacers.ss8};
  ${({ isCenter }) => (isCenter ? "align-items: center;" : "")};
  ${({ hasPadding }) =>
    hasPadding ? `padding: ${spacers.ss6} ${spacers.ss8}` : ""};
`;

export const InputBox = styled.View<{ isFirst?: boolean }>`
  flex: 1;
  align-tems: stretch;
  ${({ isFirst }) => (isFirst ? `margin-right: ${spacers.ss5}` : "")};
`;

export const ConsiderTitle = styled(Text5)`
  font-family: SFProDisplayMedium;
  margin-top: ${spacers.ss6};
  margin-bottom: ${spacers.ss6};
`;

export const ConsiderText = styled(Text3)`
  letter-spacing: 0.5px;
  line-height: 22px;
  color: ${colors.gray600};
  text-align: center;
`;

export const Text = styled(Text1)`
  letter-spacing: 0.5px;
  font-size: 13px;
  line-height: 17px;
  color: ${colors.gray500};
  margin-left: ${spacers.ss4};
  margin-right: ${spacers.ss6};
`;

export const EditButton = styled.TouchableOpacity``;

export const EditText = styled(Text3)`
  letter-spacing: 0.5px;
  font-family: SFProDisplayMedium;
  text-transform: uppercase;
  color: ${colors.primaryBlue};
`;

export const SwitchBox = styled.View`
  background-color: ${colors.gray100};
  align-self: stretch;
  padding: ${spacers.ss4};
  border-radius: ${spacers.ss4};
  margin-top: ${spacers.ss6};
  flex-direction: row;
  align-items: center;
`;

export const SwitchText = styled(Text3)`
  letter-spacing: 0.5px;
  font-family: SFProDisplayMedium;
  padding-left: ${spacers.ss4};
`;

export const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.gray100,
  },
  cardContainer: {
    height: 48,
    marginVertical: 4,
    marginHorizontal: 4,
  },
});

export const CloseButton = styled.TouchableOpacity`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Badge = styled.View`
  border-color: ${colors.amber600};
  border-width: 1px;
  border-radius: 4px;
  padding: 2px 3px;
  margin-top: ${spacers.ss6};
`;

export const BadgeText = styled(Text1)`
  color: ${colors.amber600};
  text-align: center;
  font-size: 11px;
`;

export const InputLabel = styled(Text3)`
  letter-spacing: 0.5px;
  line-height: 22px;
  color: ${colors.gray600};
`;

export const WebInput = styled.TextInput`
  letter-spacing: 0.5px;
  line-height: 22px;
  color: ${colors.gray600};
  background-color: ${colors.white};
  padding: ${spacers.ss5};
  margin-bottom: ${spacers.ss6};
  border: 1px solid #e6e6e6;
  border-radius: ${spacers.ss3};
  font-size: ${fontSizes.fs3};
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;

  &:focus {
    outline: red;
    border-color: blue;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
      yellow;
  }
`;
