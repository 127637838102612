import NetInfo from "@react-native-community/netinfo";
import { recordError, log } from "~/utils/crashlytics";

// We can use this function to log errors to a service like Sentry/Crashlytics
export function logError(error: any, message?: string) {
  if (message) {
    log(message);
  }
  recordError(error instanceof Error ? error : new Error(error));
  const errorMessage = error?.message;

  if (typeof errorMessage === "string" && errorMessage.includes("offline")) {
    NetInfo.fetch().then((data) => {
      log(
        JSON.stringify({
          type: data?.type,
          isConnected: data?.isConnected,
          isInternetReachable: data?.isInternetReachable,
          details: data?.details,
        })
      );
    });
  }

  if (__DEV__) {
    console.log({ error });
  }
}

// TODO: handle errors more gracefully (dispatch corresponding actions depending on the error)
export function handleError(error: any) {
  logError(error);
}
