import { createSelector } from "@reduxjs/toolkit";

import { SortEnum } from "~/state/notes/types";
import { getSearchConditions } from "~/state/notes/utils";

import { RootState } from "../store";
import { PersonalNote } from "./types";

const getState = (state: RootState) => state.personalNotes;

export const getPersonalNotes: (state: RootState) => PersonalNote[] =
  createSelector(getState, (state) => state?.notes || []);

export const getPersonalNotesCount: (state: RootState) => number =
  createSelector(getPersonalNotes, (notes) => notes.length);

export const getPersonalNoteById: (
  state: RootState,
  noteId: string
) => PersonalNote | undefined = createSelector(
  [getPersonalNotes, (_, props) => props],
  (notes, noteId) => notes.find((note) => note.id === noteId)
);

const hasSearchTerm = ({ title, text }: PersonalNote, searchTerm: string) =>
  [title, text].join(" ").toLowerCase().includes(searchTerm.toLowerCase());

const sortData = (data: PersonalNote[], sortMethod: string) => {
  return [...data].sort((a, b) =>
    sortMethod === SortEnum.ASC
      ? a.lastUpdated - b.lastUpdated
      : b.lastUpdated - a.lastUpdated
  );
};

export const getPersonalNotesFiltered: (
  state: RootState,
  searchConditions: string
) => PersonalNote[] = createSelector(
  [getPersonalNotes, (_, props) => props],
  (data, searchConditions) => {
    const { sortMethod, searchTerm } = getSearchConditions(searchConditions);
    const sortedData = sortData(data, sortMethod);
    return sortedData.filter((item) => hasSearchTerm(item, searchTerm));
  }
);
