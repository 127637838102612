import React from "react";

import {
  SessionProgress,
  getHomeResourcesProgress,
} from "~/state/content-progress";
import { useAppNetInfo, useAppSelector } from "~/state/hooks";
import { ResourceOffline } from "./components/resource-offline";
import { ResourcesContent } from "./components/content";
import { ResourcesContainer, EmptyResourcesContainer } from "./styles";

export function Resources() {
  const { data: planProgressesData } = useAppSelector(getHomeResourcesProgress);
  const [progressData, setProgressData] = React.useState<
    SessionProgress | undefined
  >();
  const [activeResourceIndex, setActiveResourceIndex] = React.useState(0);
  const { shouldRenderBubbles } = useAppSelector((state) => state.startup);

  const onActiveResource = React.useCallback(
    (index: number) => {
      const planProgress = planProgressesData?.[index];
      const currentSessionProgress = planProgress?.activeSession;

      if (currentSessionProgress && currentSessionProgress.sessionProgressId) {
        setProgressData(currentSessionProgress);
      }

      setActiveResourceIndex(index);
    },
    [planProgressesData]
  );

  const resourcesSwipingEnabled = React.useMemo(
    () => (planProgressesData ?? [])?.length > 1,
    [planProgressesData]
  );

  // Sets the initial session id after the progress data is loaded
  React.useEffect(() => {
    if (planProgressesData?.length && planProgressesData[0].activeSession) {
      setProgressData(planProgressesData[0].activeSession);
    }
  }, [planProgressesData]);

  // reset the progress data if the user has no progress – otherwise the stay visible
  React.useEffect(() => {
    if (!planProgressesData?.length) {
      setProgressData(undefined);
    }
  }, [planProgressesData]);

  const { isConnected } = useAppNetInfo();

  const showLoading = !!planProgressesData?.length && !progressData;

  if (!isConnected) {
    return (
      <ResourcesContainer>
        <EmptyResourcesContainer>
          <ResourceOffline />
        </EmptyResourcesContainer>
      </ResourcesContainer>
    );
  }

  return (
    <ResourcesContent
      showLoading={showLoading}
      progressData={progressData}
      planProgressesData={planProgressesData}
      resourcesSwipingEnabled={resourcesSwipingEnabled}
      onActiveResource={onActiveResource}
      shouldRenderBubbles={shouldRenderBubbles}
      activeResourceIndex={activeResourceIndex}
    />
  );
}
