import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { ContentIcon } from "../../../content-icon";

interface RulesReviewProps {
  rulesReview: LessonsContent["rulesReview"];
}

export const RulesReview: React.FC<RulesReviewProps> = ({ rulesReview }) => {
  const hasContent = React.useMemo(
    () =>
      rulesReview?.intro ||
      rulesReview?.rulesVideoMedia?.length ||
      rulesReview?.conclusion,
    [rulesReview]
  );

  return hasContent ? (
    <>
      {rulesReview?.sectionTitle && (
        <SectionTitle>{rulesReview.sectionTitle}</SectionTitle>
      )}

      {rulesReview?.intro && <RichText>{rulesReview.intro}</RichText>}

      {Array.isArray(rulesReview?.rulesVideoMedia) &&
        rulesReview?.rulesVideoMedia.map((fileId, index) => (
          <Media key={`rulesVideoMedia-${index}`} fileId={fileId} />
        ))}

      {rulesReview?.conclusion && <RichText>{rulesReview.conclusion}</RichText>}

      {Array.isArray(rulesReview?.conclusionMedia) &&
        rulesReview?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon content={[rulesReview?.intro || ""]} />
    </>
  ) : null;
};
