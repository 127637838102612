import AsyncStorage from "@react-native-async-storage/async-storage";

import { handleError } from "./logger";

const STORE_KEY = "@hasCookiesBeenAccepted";

export const getIsCookiePolicyAccepted = async () => {
  try {
    const hasBeenAccepted = await AsyncStorage.getItem(STORE_KEY);
    return hasBeenAccepted === "true";
  } catch (e) {
    handleError(e);
    return false;
  }
};

export const acceptCookiesPolicy = async () => {
  try {
    await AsyncStorage.setItem(STORE_KEY, "true");
  } catch (e) {
    handleError(e);
    return false;
  }
};
