const defaultData = {
  totalMemory: Infinity,
  usedMemory: 0,
  freeMemory: Infinity,
  isMemoryLow: false,
};

export const getMemoryData = async () => defaultData;

export const useRAMInfo = () => defaultData;
