import React from "react";
import { useNavigation } from "@react-navigation/native";
import { ModalHeader } from "~/components/modal-header";
import {
  SettingsListItemContainer,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { formatMessage } from "~/utils/translation";
import { messages } from "./intl";
import { ItemDescription, SubContainer } from "./styles";

export const Legal = () => {
  const navigation = useNavigation();

  const onDonePress = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  return (
    <SettingsScreen screenName="legal">
      <ModalHeader
        onLeftPress={onDonePress}
        onRightPress={onDonePress}
        title={messages.title}
      />

      <SubContainer>
        <SettingsListItemContainer>
          <ItemDescription>
            {formatMessage(messages.copyright)}
          </ItemDescription>

          <SettingsListItemLabel
            label={formatMessage(messages.acknowledgements)}
          />

          <ItemDescription>
            {formatMessage(messages.licenses)}
          </ItemDescription>
        </SettingsListItemContainer>
      </SubContainer>
    </SettingsScreen>
  );
};
