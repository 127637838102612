import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Icon from "@expo/vector-icons/Ionicons";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { ProgressBar } from "~/components/progress-bar";
import { useAlert } from "~/components/alert";
import {
  getPlanById,
  getVolumeById,
  getPlanDaysCount,
  getPlanThumbnail,
} from "~/state/flamelink/selectors";
import { TabParamList } from "~/navigation/post-auth";
import * as routes from "~/constants/routes";
import { colors } from "~/styles/theme";
import { removeResource } from "~/state/groups/actions";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { formatMessage } from "~/utils/translation";
import { getIsGroupLeaderByGroupId } from "~/state/groups/selectors";
import { getNormalizedProgressByPlanId } from "~/state/content-progress";

import {
  Container,
  ImageContainer,
  ResourceImage,
  EmptyImage,
  Content,
  TextBox,
  Category,
  Title,
  ProgressBox,
  Progress,
  CloseButton,
} from "./styles";
import { messages } from "./intl";
import { navigateTo } from "~/utils/navigation";
import { isWeb } from "~/utils/platform";

type GroupTabProps = NativeStackScreenProps<TabParamList, "groups.tab">;

interface Props {
  planId: string;
  groupId: string;
}

interface BasicProps {
  onPress: () => void;
}

export const ResourceItem = React.memo<Props>(({ groupId, planId }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigation = useNavigation<GroupTabProps["navigation"]>();
  const progressData = useAppSelector((state) =>
    getNormalizedProgressByPlanId(state, planId)
  );

  const volumeId = progressData?.planProgress[0]?.volumeId ?? "";

  const planData = useAppSelector((state) => getPlanById(state, planId));
  const volumeData = useAppSelector((state) => getVolumeById(state, volumeId));
  const total = useAppSelector((state) => getPlanDaysCount(state, planId));
  const image = useAppSelector((state) => getPlanThumbnail(state, planId));
  const isGroupLeader = useAppSelector((state) =>
    getIsGroupLeaderByGroupId(state, groupId)
  );

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();
  const { showAlert } = useAlert();

  const daysCompleted = progressData?.numberOfDaysCompleted || 0;
  const progress = total ? Math.round((daysCompleted / total) * 100) : 0;

  const onPress = React.useCallback(() => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: { planId },
    });
  }, [navigation, planId]);

  const onRemoveResource = React.useCallback(() => {
    if (isLoading && !isWeb) {
      return;
    }
    setIsLoading(true);

    showAlert(
      formatMessage(messages.removeTitle),
      formatMessage(messages.removeDescription),
      [
        {
          text: formatMessage(genericMessages.cancel),
          style: "cancel",
          onPress: () => setIsLoading(false),
        },
        {
          text: formatMessage(genericMessages.remove),
          style: "destructive",
          onPress: () =>
            dispatch(
              removeResource({
                groupId,
                planId,
                onSuccess: () => {
                  setIsLoading(false);
                  pushAlert({
                    message: messages.resourceRemovedSuccess,
                    color: colors.emerald600,
                  });
                },
                onError: () => setIsLoading(false),
              })
            ),
        },
      ]
    );
  }, [dispatch, pushAlert, groupId, planId, isLoading]);

  return (
    <Container>
      <ImageContainer onPress={onPress}>
        {image ? <ResourceImage uri={image} /> : <EmptyImage />}
      </ImageContainer>

      <Content>
        <TextBox>
          {planData?.title ? <Category>{planData?.title}</Category> : null}
          {volumeData?.title ? <Title>{volumeData?.title}</Title> : null}
        </TextBox>

        <ProgressBox>
          <ProgressBar progress={progress} />
          <Progress>{{ ...messages.progress, values: { progress } }}</Progress>
        </ProgressBox>
      </Content>

      {isGroupLeader ? (
        <CloseButton
          onPress={onRemoveResource}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Icon name="close" size={20} color={colors.gray800} />
        </CloseButton>
      ) : null}
    </Container>
  );
});

// This component exists for demo purpose only
// It can be removed when we have real data
export const KidsResourceItem = React.memo<BasicProps>(({ onPress }) => {
  const image = require("<assets>/resources/en/learn/kids.jpg");
  const category = "Learn: Kids";
  const resourceTitle = "The Bible Is My Weapon";

  const progress = 75;

  return (
    <Container onPress={onPress}>
      {image ? <ResourceImage source={image} /> : <EmptyImage />}
      <Content>
        <TextBox>
          <Category>{category}</Category>
          <Title>{resourceTitle}</Title>
        </TextBox>

        <ProgressBox>
          <ProgressBar progress={progress} />
          <Progress>{{ ...messages.progress, values: { progress } }}</Progress>
        </ProgressBox>
      </Content>
    </Container>
  );
});
