export const messages: {
  [key: string]: { id: string; defaultMessage: string };
} = {
  GEN: { id: "books.gen", defaultMessage: "Genesis" },
  EXO: { id: "books.exo", defaultMessage: "Exodus" },
  LEV: { id: "books.lev", defaultMessage: "Leviticus" },
  NUM: { id: "books.num", defaultMessage: "Numbers" },
  DEU: { id: "books.deu", defaultMessage: "Deuteronomy" },
  JOS: { id: "books.jos", defaultMessage: "Joshua" },
  JDG: { id: "books.jdg", defaultMessage: "Judges" },
  RUT: { id: "books.rut", defaultMessage: "Ruth" },
  "1SA": { id: "books.1sa", defaultMessage: "1 Samuel" },
  "2SA": { id: "books.2sa", defaultMessage: "2 Samuel" },
  "1KI": { id: "books.1ki", defaultMessage: "1 Kings" },
  "2KI": { id: "books.2ki", defaultMessage: "2 Kings" },
  "1CH": { id: "books.1ch", defaultMessage: "1 Chronicles" },
  "2CH": { id: "books.2ch", defaultMessage: "2 Chronicles" },
  EZR: { id: "books.ezr", defaultMessage: "Ezra" },
  NEH: { id: "books.neh", defaultMessage: "Nehemiah" },
  EST: { id: "books.est", defaultMessage: "Esther" },
  JOB: { id: "books.job", defaultMessage: "Job" },
  PSA: { id: "books.psa", defaultMessage: "Psalms" },
  PRO: { id: "books.pro", defaultMessage: "Proverbs" },
  ECC: { id: "books.ecc", defaultMessage: "Ecclesiastes" },
  SNG: { id: "books.sng", defaultMessage: "Songs of Solomon" },
  ISA: { id: "books.isa", defaultMessage: "Isaiah" },
  JER: { id: "books.jer", defaultMessage: "Jeremiah" },
  LAM: { id: "books.lam", defaultMessage: "Lamentations" },
  EZK: { id: "books.ezk", defaultMessage: "Ezekiel" },
  DAN: { id: "books.dan", defaultMessage: "Daniel" },
  HOS: { id: "books.hos", defaultMessage: "Hosea" },
  JOL: { id: "books.jol", defaultMessage: "Joel" },
  AMO: { id: "books.amo", defaultMessage: "Amos" },
  OBA: { id: "books.oba", defaultMessage: "Obadiah" },
  JON: { id: "books.jon", defaultMessage: "Jonah" },
  MIC: { id: "books.mic", defaultMessage: "Micah" },
  NAM: { id: "books.nah", defaultMessage: "Nahum" },
  HAB: { id: "books.hab", defaultMessage: "Habakkuk" },
  ZEP: { id: "books.zep", defaultMessage: "Zephaniah" },
  HAG: { id: "books.hag", defaultMessage: "Haggai" },
  ZEC: { id: "books.zec", defaultMessage: "Zechariah" },
  MAL: { id: "books.mal", defaultMessage: "Malachi" },
  MAT: { id: "books.mat", defaultMessage: "Matthew" },
  MRK: { id: "books.mrk", defaultMessage: "Mark" },
  LUK: { id: "books.luk", defaultMessage: "Luke" },
  JHN: { id: "books.jhn", defaultMessage: "John" },
  ACT: { id: "books.act", defaultMessage: "Acts" },
  ROM: { id: "books.rom", defaultMessage: "Romans" },
  "1CO": { id: "books.1co", defaultMessage: "1 Corinthians" },
  "2CO": { id: "books.2co", defaultMessage: "2 Corinthians" },
  GAL: { id: "books.gal", defaultMessage: "Galatians" },
  EPH: { id: "books.eph", defaultMessage: "Ephesians" },
  PHP: { id: "books.php", defaultMessage: "Philippians" },
  COL: { id: "books.col", defaultMessage: "Colossians" },
  "1TH": { id: "books.1th", defaultMessage: "1 Thessalonians" },
  "2TH": { id: "books.2th", defaultMessage: "2 Thessalonians" },
  "1TI": { id: "books.1ti", defaultMessage: "1 Timothy" },
  "2TI": { id: "books.2ti", defaultMessage: "2 Timothy" },
  TIT: { id: "books.tit", defaultMessage: "Titus" },
  PHM: { id: "books.phm", defaultMessage: "Philemon" },
  HEB: { id: "books.heb", defaultMessage: "Hebrews" },
  JAS: { id: "books.jas", defaultMessage: "James" },
  "1PE": { id: "books.1pe", defaultMessage: "1 Peter" },
  "2PE": { id: "books.2pe", defaultMessage: "2 Peter" },
  "1JN": { id: "books.1jn", defaultMessage: "1 John" },
  "2JN": { id: "books.2jn", defaultMessage: "2 John" },
  "3JN": { id: "books.3jn", defaultMessage: "3 John" },
  JUD: { id: "books.jud", defaultMessage: "Jude" },
  REV: { id: "books.rev", defaultMessage: "Revelation" },
};
