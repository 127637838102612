import * as React from "react";
import { Text } from "react-native";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { MessageData } from "~/state/chat/types";

import { SectionHeader } from "./section-header";
import {
  Container,
  NoteText,
  HighlightedBox,
  VerseNumber,
  VerseText,
} from "./styles";
import { Verse } from "~/state/bible/types";
import { navigateTo } from "~/utils/navigation";

interface BibleNoteProps {
  data?: MessageData;
  text: string;
}

export const NoteBible = React.memo<BibleNoteProps>(({ text, data }) => {
  const scripture = data?.title || "";
  const verses = (data?.verses || []) as Verse[];

  const navigation = useNavigation();

  const onPress = React.useCallback(() => {
    // @ts-ignore
    navigateTo(navigation, routes.bibleTab, {
      screen: routes.bible,
      params: { scripture, showBackButton: true },
    });
  }, [navigation, scripture]);

  return (
    <Container>
      <SectionHeader text={scripture} onPress={onPress} />

      <HighlightedBox>
        <Text numberOfLines={3}>
          {verses.map(({ verseId, content }, index) => {
            const id: string = verseId.split(".")[1];
            const numberText = index === 0 ? `${id} ` : ` ${id} `;
            return [
              <VerseNumber key={id}>{numberText}</VerseNumber>,
              <VerseText key={`verse-${id}`}>{content}</VerseText>,
            ];
          })}
        </Text>
      </HighlightedBox>

      <NoteText>{text}</NoteText>
    </Container>
  );
});
