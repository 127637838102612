// Crashlytics is not yet available on web
const crashlytics = () => ({
  log: (name: string) => {},
  recordError: (error: Error) => {},
  setUserId: (id: string) => {},
  setCrashlyticsCollectionEnabled: (isEnabled: boolean) => {},
  setAttribute: (key: string, value: string) => {},
  setAttributes: (attr: { [key: string]: string }) => {},
});

export const addTrace = (name: string) => crashlytics().log(name);

export const recordError = (error: Error) => crashlytics().recordError(error);

export const setUserId = (id: string) => crashlytics().setUserId(id);

export const log = (message: string) => crashlytics().log(message);

export const setCrashlyticsCollectionEnabled = (isEnabled: boolean) =>
  crashlytics().setCrashlyticsCollectionEnabled(isEnabled);

export const setAttribute = (key: string, value: string) =>
  crashlytics().setAttribute(key, String(value));

export const setAttributes = (attr: { [key: string]: string }) =>
  crashlytics().setAttributes(attr);
