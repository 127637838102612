import * as React from "react";
import { Card, Grid, Icon, IconButton, styled } from "@mui/material";
import { H3, Paragraph } from "~/dashboard/components/typography";

import { getCurrentStats } from "~/state/stats/selectors";
import { useAppSelector } from "~/state/hooks";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

const StyledCard = styled(Card)(() => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}));

export const StatCards = () => {
  const data = useAppSelector(getCurrentStats);

  const statList = [
    {
      icon: "church",
      amount: data?.totalChurches,
      title: formatMessage(messages.churches),
    },
    {
      icon: "church",
      amount: data?.usChurches,
      title: formatMessage(messages.churchesUs),
    },
    {
      icon: "apple",
      amount: "TBD",
      title: formatMessage(messages.iosDownloads),
    },
    {
      icon: "android",
      amount: "TBD",
      title: formatMessage(messages.androidDownloads),
    },
  ];

  return (
    <Grid container spacing={3}>
      {statList.map((item) => (
        <Grid key={item.title} item md={6} xs={12}>
          <StyledCard elevation={3}>
            <IconButton
              sx={{ padding: "12px", background: "rgba(0, 0, 0, 0.01)" }}
            >
              <Icon sx={{ color: "text.secondary" }}>{item.icon}</Icon>
            </IconButton>

            <H3 sx={{ mt: "4px", fontSize: 32 }}>
              {item.amount?.toLocaleString()}
            </H3>
            <Paragraph sx={{ m: 0, color: "text.secondary" }}>
              {item.title}
            </Paragraph>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};
