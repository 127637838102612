import * as React from "react";
import { Keyboard } from "react-native";
import { Modal, Portal } from "react-native-paper";
import { useRoute } from "@react-navigation/native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { BottomSheetModal } from "~/components/bottom-sheet";
import { IconSizes } from "~/components/icon";
import { messages } from "~/screens/session/intl";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import { Question as QuestionType } from "~/state/flamelink";
import { isWeb } from "~/utils/platform";
import { asyncLogEvent, events } from "~/utils/analytics";
import { useAppSelector } from "~/state/hooks";
import { getSessionNoteByKey } from "~/state/notes/selectors";
import type { SessionRouteProp } from "~/screens/session/types";
import { getFontSize } from "~/state/settings";
import { isAndroid } from "~/utils/platform";
import {
  AnswerActionContainer,
  AnswerActionText,
  QuestionContainer,
  QuestionGradientSeparator,
  QuestionTextWrapper,
  QuestionTitle,
  webModalContainerStyles,
  QuestionWebWrapper,
  getQuestionTextStyles,
} from "./styles";
import { Answer } from "../answer";
import { RichText } from "../rich-text";
import { useModal } from "~/state/modal/hook.web";

interface QuestionProps {
  question: QuestionType;
}

export const Question: React.FC<QuestionProps> = ({ question }) => {
  const {
    params: { sessionId },
  } = useRoute<SessionRouteProp>();
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);
  const snapPoints = React.useMemo(() => [isAndroid ? "88%" : "92%"], []);

  const { showModal, hideModal } = useModal();

  const data = useAppSelector((state) =>
    getSessionNoteByKey(state, question.uniqueKey)
  );
  const fontSize = useAppSelector(getFontSize);

  const handleCloseModal = React.useCallback(() => {
    isWeb ? hideModal() : bottomSheetModalRef.current?.dismiss();

    Keyboard.dismiss();
  }, [hideModal]);

  const handlePresentModalPress = React.useCallback(() => {
    asyncLogEvent(events.OPEN_QUESTION_SHEET);
    isWeb
      ? showModal(
          <Answer
            question={question}
            onClose={handleCloseModal}
            sessionId={sessionId}
            answer={data?.note}
            noteId={data?.id}
            lastUpdated={data?.lastUpdated}
          />
        )
      : bottomSheetModalRef.current?.present();
  }, [
    data?.id,
    data?.lastUpdated,
    data?.note,
    handleCloseModal,
    question,
    sessionId,
    showModal,
  ]);

  const RichTextWrapper = isWeb ? QuestionWebWrapper : React.Fragment;

  return (
    <>
      <QuestionContainer>
        <QuestionTitle>{formatMessage(messages.question)}</QuestionTitle>
        <QuestionTextWrapper>
          <QuestionGradientSeparator />

          <RichTextWrapper>
            <RichText extraMarkdownStyles={getQuestionTextStyles(fontSize)}>
              {question.question}
            </RichText>
          </RichTextWrapper>
        </QuestionTextWrapper>

        {question.disableAnswer ? null : (
          <AnswerActionContainer onPress={handlePresentModalPress}>
            <Icon
              name={data ? "remove-red-eye" : "edit"}
              color={colors.primaryBlue}
              size={IconSizes.SMedium}
            />

            <AnswerActionText>
              {data
                ? formatMessage(messages.viewAnswer)
                : formatMessage(messages.answerQuestion)}
            </AnswerActionText>
          </AnswerActionContainer>
        )}
      </QuestionContainer>

      {isWeb ? null : (
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={0}
          snapPoints={snapPoints}
        >
          <Answer
            question={question}
            onClose={handleCloseModal}
            sessionId={sessionId}
            answer={data?.note}
            noteId={data?.id}
            lastUpdated={data?.lastUpdated}
          />
        </BottomSheetModal>
      )}
    </>
  );
};
