import * as React from "react";

import { MessageType } from "~/state/chat/types";

import { Container, Text } from "./styles";
import { messages } from "./intl";

interface Props {
  username: string;
  type: MessageType;
}

export const ActivityMessage = React.memo<Props>(({ username, type }) => {
  const message =
    type === MessageType.NewMember
      ? messages.newMember
      : messages.memberRemoved;

  return (
    <Container>
      <Text>{{ ...message, values: { username } }}</Text>
    </Container>
  );
});
