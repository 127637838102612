import { LinearGradient } from "expo-linear-gradient";
import * as React from "react";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from "react-native-reanimated";

export const ProgressBar = Animated.createAnimatedComponent(LinearGradient);

const HIDE_PROGRESS_BAR_DELAY = 1000;

interface DownloadProgressBarProps {
  downloadProgress: number;
}

export const DownloadProgressBar = React.memo<DownloadProgressBarProps>(
  ({ downloadProgress }) => {
    const progressBarHeight = useSharedValue(0);
    const progressBarWidth = useSharedValue(0);

    const progressBarAnimatedStyles = useAnimatedStyle(() => {
      return {
        height: progressBarHeight.value,
        width: `${progressBarWidth.value}%`,
      };
    });

    React.useEffect(() => {
      progressBarWidth.value = withSpring(downloadProgress * 100, {
        stiffness: 20,
      });

      const isDownloading = downloadProgress > 0 && downloadProgress < 1;

      if (isDownloading) {
        progressBarHeight.value = withSpring(2);
      } else {
        progressBarHeight.value = withDelay(
          HIDE_PROGRESS_BAR_DELAY,
          withTiming(0, { duration: HIDE_PROGRESS_BAR_DELAY / 2 })
        );
      }
    }, [downloadProgress, progressBarHeight, progressBarWidth]);

    return (
      <ProgressBar
        colors={["#FEFFD7", "#22C55E"]}
        start={{ x: 0, y: 30 }}
        style={[progressBarAnimatedStyles]}
      />
    );
  }
);
