import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { useNetInfo } from "@react-native-community/netinfo";

import type { RootState, AppDispatch } from "./store";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppNetInfo = () => {
  const netInfo = useNetInfo();

  return {
    ...netInfo,
    isConnected: netInfo.isConnected !== false,
  };
};
