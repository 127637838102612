import * as React from "react";
import { StyleSheet, Linking } from "react-native";
import Markdown, {
  MarkdownIt,
  MarkdownProps,
} from "react-native-markdown-display";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import * as routes from "~/constants/routes";
import { TabParamList } from "~/navigation/post-auth";
import { asyncLogEvent, events } from "~/utils/analytics";
import { navigateTo } from "~/utils/navigation";
import { isWeb } from "~/utils/platform";
import { useAppSelector } from "~/state/hooks";
import { getFontSize } from "~/state/settings";

import { markdownRules } from "./rules";
import { transformStyles } from "./utils";

const markdownIt = MarkdownIt({ typographer: true }).use(
  require("markdown-it-sup")
);

type RichTextNavigationProp = NativeStackScreenProps<
  TabParamList,
  "library.tab"
>;

interface RichTextProps extends MarkdownProps {
  children: string;
  extraMarkdownStyles?: StyleSheet.NamedStyles<any>;
}

export const RichText: React.FC<RichTextProps> = ({
  children: text = "",
  extraMarkdownStyles = {},
  rules,
  ...rest
}) => {
  const navigation = useNavigation<RichTextNavigationProp["navigation"]>();
  const fontSize = useAppSelector(getFontSize);

  const onLinkPress = React.useCallback(
    (url: string) => {
      const isWebUrl = url?.startsWith("https");

      if (isWebUrl) {
        Linking.openURL(url);
      } else {
        asyncLogEvent(events.SCRIPTURE_NAVIGATION, { url });

        navigateTo(navigation, routes.bibleTab, {
          screen: routes.bible,
          params: { scripture: url, showBackButton: true, isSession: true },
        });
      }

      return !isWeb;
    },
    [navigation]
  );

  const markdownProps = {
    style: { ...transformStyles(fontSize), ...extraMarkdownStyles },
    onLinkPress,
    rules: { ...rules, ...markdownRules },
    markdownit: markdownIt,
    ...rest,
  };

  return <Markdown {...markdownProps}>{text}</Markdown>;
};
