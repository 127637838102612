import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SessionNote } from "~/state/session-notes";

import { NotesState, EditSessionNote, SessionNotesMeta } from "./types";

const initialState: NotesState = {
  session: [],
  bible: [],
  personal: [],
  bibleCount: 0,
  personalCount: 0,
  sessionMeta: [],
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    addSessionNotes: (state, action: PayloadAction<SessionNote[]>) => {
      state.session = action.payload;
    },
    addSessionNotesMeta: (state, action: PayloadAction<SessionNotesMeta[]>) => {
      state.sessionMeta = action.payload;
    },
    addNote: (state, action: PayloadAction<SessionNote>) => {
      state.session = [...state.session, action.payload];
      state.sessionMeta = [...state.sessionMeta, action.payload];
    },
    editNote: (state, action: PayloadAction<EditSessionNote>) => {
      state.session = state.session.map((note) => {
        if (note.id !== action.payload.id) {
          return note;
        }
        return {
          ...note,
          note: action.payload.note,
          lastUpdated: Date.now(),
        };
      });
    },
    deleteNote: (state, action: PayloadAction<string>) => {
      state.session = state.session.filter(({ id }) => id !== action.payload);
      state.sessionMeta = state.sessionMeta.filter(
        ({ id }) => id !== action.payload
      );
    },
    editBibleCount: (state, action: PayloadAction<number>) => {
      state.bibleCount = action.payload;
    },
    editPersonalCount: (state, action: PayloadAction<number>) => {
      state.personalCount = action.payload;
    },
  },
});

export const {
  addSessionNotes,
  deleteNote,
  addNote,
  editNote,
  editBibleCount,
  editPersonalCount,
  addSessionNotesMeta,
} = notesSlice.actions;

export default notesSlice.reducer;
