import styled, { css } from "styled-components/native";
import { SafeScrollView } from "~/components/safe-scroll-view";
import { Text4 } from "~/components/text";
import { spacers } from "~/styles/theme";
import { getWebContainerStyle, MaxWidthBreakpoints } from "~/utils/web";
import { isPad, isWeb } from "~/utils/platform";
import { pxToNumber } from "~/utils/theme";

export const planSize = (() => {
  const BASE_SIZE = 160;

  if (isPad) return `${BASE_SIZE * 2}px`;

  return `${BASE_SIZE}px`;
})();

export const AudiencesContainer = styled(SafeScrollView).attrs<{
  extraMargin: number;
}>(({ extraMargin }) => ({
  showsVerticalScrollIndicator: false,
  extraMargin,
}))``;

export const InnerContainer = styled.View`
  ${getWebContainerStyle(MaxWidthBreakpoints.Desktop, false, true)}
`;

export const AudienceContainer = styled.View`
  margin-top: -${spacers.ss6};
  ${isWeb &&
  css`
    align-items: flex-start;
    overflow-x: scroll;
  `}
`;

export const AudienceTitle = styled(Text4)`
  text-transform: capitalize;
  font-family: SFProDisplayMedium;
  margin: ${spacers.ss6} ${spacers.ss6} 0;
  ${isWeb ? "text-align: center" : ""}
`;

export const PlansContainer = styled.ScrollView.attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    padding: pxToNumber(spacers.ss6),
    paddingRight: 0,
  },
})`
  flex-direction: row;
`;
