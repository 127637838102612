export const messages = {
  title: {
    id: "legal.title",
    defaultMessage: "Legal",
  },
  copyright: {
    id: "legal.copyright",
    defaultMessage: "Copyright© 2023 by The General Council of the Assemblies of God, 1445 N. Boonville Ave., Springfield, Missouri 65802. All rights reserved.\n"
  },
  acknowledgements: {
    id: "legal.acknowledgements",
    defaultMessage: "Acknowledgements",
  },
  licenses: {
    id: "legal.licenses",
    defaultMessage:
    "PUBLIC DOMAIN except in the United Kingdom, where a Crown Copyright applies to printing the KJV.\n\nScripture quotations marked (NLT) are taken from the Holy Bible, New Living Translation, copyright©1996, 2004, 2015 by Tyndale House Foundation. Used by permission of Tyndale House Publishers, Carol Stream, Illinois 60188. All rights reserved.\n\nThe Holy Bible, New International Version® NIV® Copyright © 1973, 1978, 1984, 2011 by Biblica, Inc.® Used by Permission of Biblica, Inc.® All rights reserved worldwide.\n\nNEW AMERICAN STANDARD BIBLE® NASB® Copyright © 1960, 1971, 1977,1995, 2020 by The Lockman Foundation A Corporation Not for Profit La Habra, CA All Rights Reserved www.lockman.org\n\nThe Holy Bible, English Standard Version® (ESV®) Copyright © 2001 by Crossway, a publishing ministry of Good News Publishers. All rights reserved. ESV Text Edition: 2016 The Holy Bible, English Standard Version (ESV) is adapted from the Revised Standard Version of the Bible, copyright Division of Christian Education of the National Council of the Churches of Christ in the U.S.A. All rights reserved.\n\nReina-Valera 1909 - Dominio público. Fue realizada por Sociedades Bíblicas Unidas y publicada por primera vez en 1909.\n\nSanta Biblia, Nueva Versión Internacional® NVI® © 1999, 2015, 2022 por Biblica, Inc. Usado con permiso. Reservados todos los derechos en todo el mundo.\n\nSanta Biblia, Nueva Traducción Viviente, copyright © 2010 by Tyndale House Foundation. Used by permission of Tyndale House Publishers, a Division of Tyndale House Ministries, Carol Stream, Illinois 60188. All rights reserved.",
  }
};
