import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { RichText } from "../../../rich-text";
import { Media } from "../../../media";
import { ContentIcon } from "../../../content-icon";

interface FaithFact {
  faithFact: LessonsContent["faithFactContent"];
}

export const FaithFact: React.FC<FaithFact> = ({ faithFact }) => {
  const hasContent = React.useMemo(
    () =>
      faithFact?.faithFactIntro ||
      faithFact?.faithFactMedia?.length ||
      faithFact?.faithFactConclusion ||
      faithFact?.faithFactVerse ||
      faithFact?.conclusionMedia?.length,
    [faithFact]
  );

  return hasContent ? (
    <>
      {faithFact?.faithFactIntro && (
        <RichText>{faithFact?.faithFactIntro}</RichText>
      )}

      {Array.isArray(faithFact?.faithFactMedia) &&
        faithFact?.faithFactMedia.map((fileId, index) => (
          <Media key={`faithFactMedia-${index}`} fileId={fileId} />
        ))}

      {faithFact?.faithFactConclusion && (
        <RichText>{faithFact?.faithFactConclusion}</RichText>
      )}

      {Array.isArray(faithFact?.conclusionMedia) &&
        faithFact?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {faithFact?.faithFactVerse && (
        <RichText>{faithFact?.faithFactVerse}</RichText>
      )}

      <ContentIcon
        content={[
          faithFact?.faithFactIntro || "",
          faithFact?.faithFactConclusion || "",
          faithFact?.faithFactVerse || "",
        ]}
      />
    </>
  ) : null;
};
