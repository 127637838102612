export const messages = {
  accountDeleted: {
    id: "delete-account.account-deleted",
    defaultMessage: "Your account has been deleted.",
  },
  dataDeleted: {
    id: "delete-account.data-deleted",
    defaultMessage: "Your data has been wiped out.",
  },
  error: {
    id: "delete-account.error",
    defaultMessage: "An error occurred. Please try again later.",
  },
  infoButtonText: {
    id: "delete-account.info-button-text",
    defaultMessage: "OK",
  },
  deletingAccount: {
    id: "delete-account.deleting-account",
    defaultMessage: "Deleting your account...",
  },
  deletingData: {
    id: "delete-account.deleting-data",
    defaultMessage: "Wiping out your data...",
  },
};
