import * as React from "react";

import { WEB_APP_URL } from "~/constants";
import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import { getIsGroupLeaderByGroupId } from "~/state/groups/selectors";
import { formatMessage } from "~/utils/translation";
import { refreshInviteCode } from "~/state/groups";
import { isAndroid } from "~/utils/platform";
import { useAlerts } from "~/state/alerts";
import { useAlert } from "~/components/alert";
import { colors } from "~/styles/theme";
import { genericMessages } from "~/constants/intl";

import { GroupOverviewContent } from "./content";
import { messages } from "./intl";
import { useShare } from "~/components/share";

interface Props {
  onEdit: () => void;
  groupId: string;
  groupName?: string;
  inviteCode?: string;
  imageUri?: string;
}

export const GroupOverview = React.memo<Props>(
  ({ onEdit, groupName = "", inviteCode = "", imageUri, groupId }) => {
    const url = `${WEB_APP_URL}/i/${inviteCode}`;

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const dispatch = useAppDispatch();
    const { isConnected } = useAppNetInfo();
    const { pushAlert } = useAlerts();
    const { showAlert } = useAlert();
    const { share } = useShare();

    const canEdit = useAppSelector((state) =>
      getIsGroupLeaderByGroupId(state, groupId)
    );

    const messageUrl = isAndroid ? `\n\n${url}` : "";

    const shareLink = async () => {
      await share(
        {
          message: formatMessage(messages.inviteMessage, {
            inviteCode,
            messageUrl,
          }),
          url,
          hasLink: true,
        },
        {
          subject: "",
        }
      );
    };

    const onCodeRefresh = React.useCallback(() => {
      if (!isConnected) {
        pushAlert({
          message: genericMessages.featureUnavailableOffline,
          color: colors.gray600,
        });

        return;
      }

      if (isRefreshing) {
        return;
      }
      setIsRefreshing(true);

      showAlert(
        formatMessage(messages.refreshCode),
        formatMessage(messages.refreshAdvice),
        [
          {
            text: formatMessage(genericMessages.yes),
            onPress: () => {
              dispatch(
                refreshInviteCode({
                  groupId,
                  onSuccess: () => setIsRefreshing(false),
                  onError: () => setIsRefreshing(false),
                })
              );
            },
          },
          {
            text: formatMessage(genericMessages.no),
            onPress: () => setIsRefreshing(false),
            style: "cancel",
          },
        ]
      );
    }, [dispatch, groupId, isConnected, isRefreshing, pushAlert, showAlert]);

    return (
      <GroupOverviewContent
        canEdit={canEdit}
        groupName={groupName}
        imageUri={imageUri}
        onEdit={onEdit}
        onCodeRefresh={onCodeRefresh}
        inviteCode={inviteCode}
        shareLink={shareLink}
        groupId={groupId}
      />
    );
  }
);
