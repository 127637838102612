import { Dimensions } from "react-native";
import styled from "styled-components/native";

import { spacers, colors } from "~/styles/theme";
import { IconSizes } from "~/components/icon";
import { Text1Center, Text2Center, Text3 } from "~/components/text";

export const HEADER_BAR_HEIGHT = 72;

type ContainerProps = {
  isSticky?: boolean;
  hasShadow?: boolean;
};

export const Container = styled.View<ContainerProps>`
  flex-direction: row;
  height: ${HEADER_BAR_HEIGHT}px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding-horizontal: ${spacers.ss8};
  background-color: ${colors.white};
  position: ${({ isSticky }) => (isSticky ? "fixed" : "relative")};
  right: 0px;
  left: 0px;
  ${({ isSticky }) => (isSticky ? "top: 0px; z-index: 9999;" : "")}
  ${({ hasShadow }) =>
    hasShadow ? "box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);" : ""}
  ${({ hasShadow }) =>
    !hasShadow ? `border-bottom: 1px solid ${colors.gray300};` : ""}
`;

export const MenuBox = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const MenuButton = styled.TouchableOpacity<{ isActive?: boolean }>`
  cursor: pointer;
  padding-horizontal: ${spacers.ss6};
  padding-vertical: 26px;
  border-bottom: 2px solid
    ${({ isActive }) => (!isActive ? "transparent" : colors.primaryBlue)};
`;

export const MenuText = styled(Text3)<{ isActive?: boolean }>`
  ${({ isActive }) => (isActive ? "font-family: SFProDisplayMedium" : "")}
`;

export const HeaderIconsContainer = styled.View`
  width: 130px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${spacers.ss5};
`;

export const HeaderIconWrapper = styled.View<{ isFirst?: boolean }>`
  margin-right: ${({ isFirst }) => (isFirst ? spacers.ss8 : spacers.ss7)};
  height: ${IconSizes.Medium}px;
`;

export const HeaderAvatar = styled.Pressable`
  height: 32px;
  width: 32px;
`;

export const Logo = styled.Image`
  width: 144px;
  height: 40px;
`;

export const HeaderBarTitle = styled(Text2Center)`
  font-family: SFProDisplayMedium;
`;

export const Badge = styled.View<{ color: string }>`
  border-color: ${({ color }) => color};
  margin-right: ${spacers.ss7};
  border-width: 1px;
  border-radius: 4px;
  padding: 2px 3px;
`;

export const BadgeText = styled(Text1Center)<{ color: any }>`
  color: ${({ color }) => color};
  font-size: 11px;
`;
