import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import {
  SectionTitle,
  SubsectionTitle,
  SubsectionSubtitle,
} from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { Prayer } from "../../../prayer";
import { ContentIcon } from "../../../content-icon";

interface CreativeTimeProps {
  creativeTime: LessonsContent["creativeTime"];
}

export const CreativeTime: React.FC<CreativeTimeProps> = ({ creativeTime }) => {
  const hasContent = React.useMemo(
    () =>
      creativeTime?.youWillNeed ||
      creativeTime?.gettingReady ||
      creativeTime?.introduction ||
      creativeTime?.media?.length ||
      creativeTime?.content ||
      creativeTime?.faithFactSoundMedia?.length ||
      creativeTime?.faithFactContent ||
      creativeTime?.faithFactSlideMedia?.length ||
      creativeTime?.conclusion,
    [creativeTime]
  );

  return hasContent ? (
    <>
      {creativeTime?.sectionTitle && (
        <SectionTitle>{creativeTime.sectionTitle}</SectionTitle>
      )}

      {creativeTime?.subheading && (
        <SubsectionTitle>{creativeTime.subheading}</SubsectionTitle>
      )}

      {creativeTime?.subtitle && (
        <SubsectionSubtitle>{creativeTime.subtitle}</SubsectionSubtitle>
      )}

      {creativeTime?.introduction && (
        <RichText>{creativeTime.introduction}</RichText>
      )}

      {creativeTime?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={creativeTime.youWillNeed}
          checklistId="creativeTime-youWillNeed"
        />
      )}

      {creativeTime?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={creativeTime.gettingReady}
          checklistId="creativeTime-gettingReady"
        />
      )}

      {Array.isArray(creativeTime?.gettingReadyMedia) &&
        creativeTime?.gettingReadyMedia.map((fileId, index) => (
          <Media key={`gettingReadyMedia-${index}`} fileId={fileId} />
        ))}

      {Array.isArray(creativeTime?.media) &&
        creativeTime?.media.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {creativeTime?.content && <RichText>{creativeTime.content}</RichText>}

      {Array.isArray(creativeTime?.faithFactSoundMedia) &&
        creativeTime?.faithFactSoundMedia.map((fileId, index) => (
          <Media key={`faithFactSoundMedia-${index}`} fileId={fileId} />
        ))}

      {creativeTime?.faithFactContent && (
        <RichText>{creativeTime.faithFactContent}</RichText>
      )}

      {Array.isArray(creativeTime?.faithFactSlideMedia) &&
        creativeTime?.faithFactSlideMedia.map((fileId, index) => (
          <Media key={`faithFactSlideMedia-${index}`} fileId={fileId} />
        ))}

      {creativeTime?.conclusion && (
        <RichText>{creativeTime.conclusion}</RichText>
      )}

      {creativeTime?.prayer && <Prayer prayer={creativeTime?.prayer} />}

      <ContentIcon
        content={[
          creativeTime?.introduction || "",
          creativeTime?.content || "",
          creativeTime?.faithFactContent || "",
          creativeTime?.conclusion || "",
        ]}
      />
    </>
  ) : null;
};
