import styled from "styled-components/native";

import { spacers } from "~/styles/theme";
import { Text3, Text5 } from "~/components/text";

export const Container = styled.View`
  margin: ${spacers.ss8};
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.View`
  margin-bottom: ${spacers.ss8};
`;

export const TextContent = styled.Text`
  line-height: ${spacers.ss7};
  max-width: 360px;
`;

export const Title = styled(Text5)`
  text-align: center;
  font-family: SFProDisplayMedium;
  margin-bottom: ${spacers.ss5};
`;

export const TextRegular = styled(Text3)`
  text-align: center;
  letter-spacing: 1px;
`;

export const TextBold = styled(Text3)`
  font-family: SFProDisplayBold;
`;
