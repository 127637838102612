import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { getPlans } from "~/state/flamelink";
import { RootState } from "../store";
import { initialState } from "./slice";
import {
  Stats,
  StatsData,
  DateRange,
  AppSessionData,
  ProgressStats,
} from "./types";
import {
  transformDataRelative,
  getMonthString,
  sortStatsData,
  formatRelative,
  getTimestamp,
  formatSeconds,
  getRecentDataWithLabels,
} from "./utils";

const getState = (state: RootState) => state.stats;

export const getDateRange: (state: RootState) => DateRange = createSelector(
  getState,
  (state) => state?.dateRange || initialState.dateRange
);

export const getStatsHistory: (state: RootState) => Stats[] = createSelector(
  [getState, getDateRange],
  (state, dateRange) => {
    const history = state?.statsHistory || initialState.statsHistory;
    return history.filter((item) => {
      return (
        dayjs(getTimestamp(item.date)).isBefore(dateRange.to) &&
        dayjs(getTimestamp(item.date)).isAfter(dateRange.from)
      );
    });
  }
);

export const getSortedStats: (state: RootState) => Stats[] = createSelector(
  [getStatsHistory],
  (data) => data.sort((a, b) => getTimestamp(b?.date) - getTimestamp(a?.date))
);

export const getCurrentStats: (state: RootState) => Stats = createSelector(
  [getSortedStats],
  (data) => {
    const currentData = data[0];
    const impact = Math.round(
      currentData?.totalChurches * 0.606 * 34.2 + currentData?.totalUsers
    );
    return {
      ...data[0],
      activeUsers: currentData?.activeUsers
        ? Number(currentData.activeUsers)
        : 0,
      impact,
    };
  }
);

export const getMonthlyHistory: (state: RootState) => Stats[] = createSelector(
  [getSortedStats],
  (data) => {
    return data.filter((item) => item.type === "MONTH_SUMMARY");
  }
);

export const getDailyHistory: (state: RootState) => Stats[] = createSelector(
  [getSortedStats],
  (data) => data.filter((item) => item.type === "DAY_SUMMARY")
);

export const getiOSDownloadsHistory: (state: RootState) => StatsData[] =
  createSelector(getMonthlyHistory, (data) => {
    return sortStatsData(data)
      .map((item) => {
        return {
          label: getMonthString(new Date((item.date?.seconds ?? 0) * 1000)),
          value: item.iosDownloads,
        };
      })
      .filter((item) => !!item.value);
  });

export const getAndroidDownloadsHistory: (state: RootState) => StatsData[] =
  createSelector(getMonthlyHistory, (data) =>
    sortStatsData(data)
      .map((item) => ({
        label: getMonthString(new Date((item.date?.seconds ?? 0) * 1000)),
        value: item.androidDownloads,
      }))
      .filter((item) => !!item.value)
  );

export const getStatsRelative: (state: RootState) => {
  categories: string[];
  series: Array<{ name: string; data: number[] }>;
} = createSelector(getMonthlyHistory, (data) => transformDataRelative(data));

export const getCurrentStatsRelativeTime: (state: RootState) => string =
  createSelector(getCurrentStats, (data) =>
    formatRelative(getTimestamp(data?.date))
  );

export const getAppSessionsData: (state: RootState) => AppSessionData =
  createSelector(getDailyHistory, (data) => {
    const allSessions = data.reduce((acc, item) => {
      if (!Number.isInteger(item.sessions)) {
        return acc;
      }
      return acc + item.sessions;
    }, 0);
    const allDuration = data.reduce((acc, item) => {
      if (!Number.isInteger(item.userEngagementDuration)) {
        return acc;
      }
      return acc + item.userEngagementDuration;
    }, 0);
    const allActiveUsers = data.reduce((acc, item) => {
      if (!Number.isInteger(item.activeUsers)) {
        return acc;
      }
      return acc + item.activeUsers;
    }, 0);
    const engagedPerUser =
      Math.round((allSessions * 100) / allActiveUsers) / 100;
    const avgEngagementTime = formatSeconds(allDuration / allActiveUsers);
    return {
      engagedPerUser,
      avgEngagementTime,
      dailyEngagementTime: getRecentDataWithLabels(
        data
          .filter((item) => item.userEngagementDuration)
          .map((item) => ({
            date: item.date,
            value: item.userEngagementDuration / item.activeUsers,
          }))
      ),
    };
  });

export const getMostRecentMonthlyData: (state: RootState) => Stats | undefined =
  createSelector(getMonthlyHistory, (data) => data[0]);

export const getProgressStats: (
  state: RootState
) => ProgressStats[] | undefined = createSelector(
  [getMostRecentMonthlyData, getPlans],
  (data, plans) => {
    return data?.progress
      ?.map((item) => {
        const title = plans.find((plan) => plan.id === item?.planId)?.title;
        return {
          ...item,
          title,
        };
      })
      .filter((item) => !!item.title);
  }
);
