import AsyncStorage from "@react-native-async-storage/async-storage";
import { handleError } from "./logger";

export const saveParams = async (params: Record<string, string>) => {
  try {
    const promises = Object.entries(params).map(([key, value]) =>
      AsyncStorage.setItem(key, value)
    );
    await Promise.all(promises);
    console.log("All items saved successfully");
  } catch (error) {
    handleError(error);
  }
};

export const getParam = async (key: string) => {
  try {
    return AsyncStorage.getItem(key);
  } catch (error) {
    handleError(error);
  }
};
