import React from "react";
import { Greetings } from "~/components/greetings";
import { HeaderBar } from "~/components/header-bar";
import { HeaderBackground } from "./background";

export function HomeHeader() {
  return (
    <>
      <HeaderBar withBackButton={false} isSticky />

      <HeaderBackground />

      <Greetings />
    </>
  );
}
