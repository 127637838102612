export const messages = {
  title: {
    id: "help-and-feedback.title",
    defaultMessage: "Help & Feedback",
  },
  faqs: {
    id: "help-and-feedback.faqs",
    defaultMessage: "FAQs",
  },
  contactUs: {
    id: "help-and-feedback.contact-us",
    defaultMessage: "Contact Us",
  },
  helpEmail: {
    id: "help-and-feedback.email",
    defaultMessage: "BEP@MyHealthyChurch.com",
  },
  helpPhone: {
    id: "help-and-feedback.phone",
    defaultMessage: "+1-800-545-2760",
  },
  helpPhoneIntl: {
    id: "help-and-feedback.phone-intl",
    defaultMessage: "+1-417-567-4910",
  },
};
