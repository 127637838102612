export const messages = {
  title: {
    id: "giving.method.title",
    defaultMessage: "Payment methods",
  },
  expiryDate: {
    id: "giving.method.expiry-date",
    defaultMessage: "Expiry date: {{date}}",
  },
  saved: {
    id: "giving.method.saved",
    defaultMessage: "Payment method successfully updated",
  },
  addNewCard: {
    id: "giving.method.add-new-card",
    defaultMessage: "+ Add new card",
  },
};
