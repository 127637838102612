import * as React from "react";

import { useAppSelector } from "~/state/hooks";
import { getPersonalDevotionData } from "~/state/flamelink";
import { Loader } from "~/components/loader";

import { Disclaimer, SectionTitle, SubsectionTitle } from "../common";
import { Media } from "../media";
import { Question } from "../question";
import { RichText } from "../rich-text";
import { Scripture } from "../scripture";
import { IntroBlock } from "../intro-block";
import { ContentIcon } from "../content-icon";

interface DayProps {
  dayId: string;
}

export const Day: React.FC<DayProps> = ({ dayId }) => {
  const day = useAppSelector((state) =>
    getPersonalDevotionData(state, dayId || "")
  );

  if (!day) return <Loader fullScreen />;

  return (
    <>
      {day?.introBlocks ? <IntroBlock data={day?.introBlocks} /> : null}

      {day?.heading && <SectionTitle>{day?.heading}</SectionTitle>}

      {day?.disclaimer && <Disclaimer>{day?.disclaimer}</Disclaimer>}

      {Array.isArray(day?.mediaRepeater) &&
        day?.mediaRepeater.map(({ media, content }, i) => (
          <React.Fragment key={`mediaRepeater-${i}`}>
            {Array.isArray(media) &&
              media.map((fileId, index) => (
                <Media key={`mediaRepeater-media-${index}`} fileId={fileId} />
              ))}

            {content && <RichText>{content}</RichText>}
          </React.Fragment>
        ))}

      {day?.scriptureHeading && (
        <SubsectionTitle>{day?.scriptureHeading}</SubsectionTitle>
      )}

      {day?.scriptureDisclaimer && (
        <Disclaimer>{day?.scriptureDisclaimer}</Disclaimer>
      )}

      <Scripture scripture={day?.scripture || ""} />

      <RichText>{day?.content || ""}</RichText>

      {Array.isArray(day?.questions) &&
        day?.questions.map(({ question, uniqueKey, content }, index) => (
          <React.Fragment key={`content-repeater-${index}`}>
            <Question question={{ question, uniqueKey }} />

            <RichText>{content}</RichText>
          </React.Fragment>
        ))}

      <RichText>{day?.conclusion || ""}</RichText>

      <ContentIcon content={[day?.content]} />
    </>
  );
};
