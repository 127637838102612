export const messages = {
  buttonBack: {
    id: "auth-screen.button-back",
    defaultMessage: "Back",
  },
  buttonAppleSignIn: {
    id: "auth-screen.button-apple-sign-in",
    defaultMessage: "Sign in with Apple",
  },
  buttonAppleSignUp: {
    id: "auth-screen.button-apple",
    defaultMessage: "Sign up with Apple",
  },
  buttonGoogleSignIn: {
    id: "auth-screen.button-google-sign-in",
    defaultMessage: "Sign in with Google",
  },
  buttonGoogleSignUp: {
    id: "auth-screen.button-google",
    defaultMessage: "Sign up with Google",
  },
  logOutTitle: {
    id: "auth-screen.log-out-title",
    defaultMessage: "Log out",
  },
  logOutText: {
    id: "auth-screen.log-out-text",
    defaultMessage: "Are you sure you want to log out?",
  },
  logOutOk: {
    id: "auth-screen.log-out-ok",
    defaultMessage: "OK",
  },
  logOutCancel: {
    id: "auth-screen.log-out-cancel",
    defaultMessage: "Cancel",
  },
  privacyPolicy: {
    id: "auth-screen.privacy-policy",
    defaultMessage: "Privacy Policy",
  },
  terms: {
    id: "auth-screen.terms",
    defaultMessage: "Terms of Use",
  },
  disclaimer: {
    id: "auth-screen.disclaimer",
    defaultMessage:
      "By signing up for Bible Engagement Project, you agree to receive promotional emails. You can unsubscribe anytime through the link in the email footer or from the Settings menu in the app",
  },
};
