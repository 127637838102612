export const messages = {
  title: {
    id: "change-name.title",
    defaultMessage: "Change Name",
  },
  firstName: {
    id: "change-name.first-name",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "change-name.last-name",
    defaultMessage: "Last Name",
  },
  save: {
    id: "change-name.save",
    defaultMessage: "Save",
  },
  profileSaved: {
    id: "change-name.profile-saved",
    defaultMessage: "Profile saved successfully!",
  },
  error: {
    id: "change-name.error",
    defaultMessage: "An error occurred. Please try again later.",
  },
};
