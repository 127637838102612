import * as React from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { Footnote } from "~/state/flamelink/types";

import { RichText } from "../rich-text";

import { Container, Title, Button, Content } from "./styles";
import { messages } from "./intl";
import { formatFootnote } from "./utils";

interface Props {
  data?: Footnote[];
}

export const Footnotes = React.memo<Props>(({ data = [] }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleContent = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const iconName = isOpen ? "chevron-up" : "chevron-down";

  if (!data.length) {
    return null;
  }
  return (
    <Container>
      <Button onPress={toggleContent}>
        <Title>{messages.title}</Title>
        <Icon name={iconName} />
      </Button>

      {isOpen ? (
        <Content>
          {data.map(({ footnote }, index) => (
            <RichText
              key={index}
              extraMarkdownStyles={{ body: { fontSize: 13 } }}
            >
              {formatFootnote(footnote, index)}
            </RichText>
          ))}
        </Content>
      ) : null}
    </Container>
  );
});
