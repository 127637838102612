export const messages = {
  today: {
    id: "day-label.today",
    defaultMessage: "Today",
  },
  yesterday: {
    id: "day-label.yesterday",
    defaultMessage: "Yesterday",
  },
};
