export const messages = {
  backButton: {
    id: "personal-notes-view.back",
    defaultMessage: "All Personal Notes",
  },
  date: {
    id: "personal-notes-view.list-date",
    defaultMessage: "Last Edited {{date}}",
  },
};
