import { format } from "date-fns";

import { messages } from "./intl";
import { SortEnum } from "~/state/notes/types";

export const getLastUpdated = (lastUpdated?: number) => ({
  ...messages.date,
  values: { date: lastUpdated ? format(lastUpdated, "MM/dd/yy") : "-" },
});

export const getOptionText =
  (sortMethod: SortEnum) => (text: string, index: number) => {
    if (
      (index === 0 && sortMethod === SortEnum.DESC) ||
      (index === 1 && sortMethod === SortEnum.ASC)
    ) {
      return `${text} ✓`;
    }
    return text;
  };
