import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { messages as sessionMessages } from "~/screens/session/intl";
import { Checklist } from "../../../checklist";
import { SectionTitle } from "../../../common";
import { RichText } from "../../../rich-text";
import { Thought } from "../../../thought";
import { Media } from "../../../media";
import { ContentIcon } from "../../../content-icon";

interface FaithVerseReviewProps {
  faithVerseReview: LessonsContent["faithVerseReview"];
}

export const FaithVerseReview: React.FC<FaithVerseReviewProps> = ({
  faithVerseReview,
}) => {
  const hasContent = React.useMemo(
    () =>
      faithVerseReview?.youWillNeed ||
      faithVerseReview?.gettingReady ||
      faithVerseReview?.introduction ||
      faithVerseReview?.faithVerseVideoMedia?.length ||
      faithVerseReview?.faithVerseFun,
    [faithVerseReview]
  );

  return hasContent ? (
    <>
      {faithVerseReview?.sectionTitle && (
        <SectionTitle>{faithVerseReview.sectionTitle}</SectionTitle>
      )}

      {faithVerseReview?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={faithVerseReview.youWillNeed}
          checklistId="faithVerseReview-youWillNeed"
        />
      )}

      {faithVerseReview?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={faithVerseReview.gettingReady}
          checklistId="faithVerseReview-gettingReady"
        />
      )}

      {Array.isArray(faithVerseReview?.gettingReadyMedia) &&
        faithVerseReview?.gettingReadyMedia.map((fileId, index) => (
          <Media key={`gettingReadyMedia-${index}`} fileId={fileId} />
        ))}

      {faithVerseReview?.introduction && (
        <RichText>{faithVerseReview.introduction}</RichText>
      )}

      {Array.isArray(faithVerseReview?.faithVerseVideoMedia) &&
        faithVerseReview?.faithVerseVideoMedia.map((fileId, index) => (
          <Media key={`faithVerseVideoMedia-${index}`} fileId={fileId} />
        ))}

      {faithVerseReview?.content && (
        <RichText>{faithVerseReview.content}</RichText>
      )}

      {faithVerseReview?.faithVerseFun && (
        <RichText>{faithVerseReview.faithVerseFun}</RichText>
      )}

      {Array.isArray(faithVerseReview?.faithVerseFunMedia) &&
        faithVerseReview?.faithVerseFunMedia.map((fileId, index) => (
          <Media key={`faithVerseFunMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon
        content={[
          faithVerseReview?.introduction || "",
          faithVerseReview?.content || "",
          faithVerseReview?.faithVerseFun || "",
        ]}
      />
    </>
  ) : null;
};
