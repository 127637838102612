import Animated from "react-native-reanimated";
import styled from "styled-components/native";
import { Text4Center } from "~/components/text";
import { colors, fontSizes, lineHeights, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const SessionHomeContent = styled(Animated.View)`
  position: absolute;
  width: 100%;
  ${!isWeb ? "height: 100%;" : ""}
  align-items: center;
`;

export const SessionLogo = styled(Animated.Image)`
  min-width: 220px;
  resize-mode: contain;
  tint-color: ${colors.white};
`;

export const SessionSeparator = styled(Animated.View)`
  width: 157px;
  opacity: 0.5;
  border: 1px solid ${colors.white};
  margin: ${spacers.ss4};
`;

export const PlanTitle = styled(Text4Center)`
  color: ${colors.white};
  font-family: SFPro;
  line-height: ${lineHeights.lh2};
`;

export const SessionIndex = styled(Text4Center)`
  color: ${colors.white};
  font-family: SFPro;
`;

export const SessionDay = styled(Animated.Text)`
  color: ${colors.white};
  font-size: ${fontSizes.fs11};
  font-family: MontserratLight;
  margin-top: -${spacers.ss3};
  text-align: center;
`;
