import { LinearGradient } from "expo-linear-gradient";
import styled from "styled-components/native";
import { Text2, Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const PrayerContainer = styled.View`
  margin: ${spacers.ss5} 0;
  padding: ${spacers.ss5};
  background-color: ${colors.gray100};
`;

export const PrayerTitle = styled(Text2)`
  font-family: SFProDisplayBold;
  color: ${colors.gray800};
  letter-spacing: ${pxToNumber(spacers.ss1) / 2}px;
  text-transform: uppercase;
`;

export const PrayerTextWrapper = styled.View`
  flex-direction: row;
  padding: ${spacers.ss5} 0;
`;

export const PrayerGradientSeparator = styled(LinearGradient).attrs({
  colors: ["#2563EB", "#EB9025"],
  start: { x: 0.1, y: 0.2 },
})`
  width: 2px;
`;

export const PrayerText = styled(Text3)`
  font-family: SFPro;
  color: ${colors.gray800};
  letter-spacing: -${pxToNumber(spacers.ss1) / 2}px;
  padding: ${spacers.ss4} ${spacers.ss5};
`;
