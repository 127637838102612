import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import {
  SectionTitle,
  SubsectionTitle,
  SubsectionSubtitle,
} from "../../../common";
import { Footnotes } from "../../../footnotes";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { Scripture } from "../../../scripture";
import { ContentIcon } from "../../../content-icon";

interface GameProps {
  game: LessonsContent["game"];
}

export const Game: React.FC<GameProps> = ({ game }) => {
  const hasContent = React.useMemo(
    () =>
      game?.name ||
      game?.youWillNeed ||
      game?.gettingReady ||
      game?.faithVerseFun ||
      game?.gameSlideADescription ||
      game?.gameSlideAConclusion ||
      game?.gameSlideBDescription ||
      game?.gameSlideBConclusion,
    [game]
  );

  return hasContent ? (
    <>
      {game?.sectionTitle && <SectionTitle>{game.sectionTitle}</SectionTitle>}

      {game?.subheading && <SubsectionTitle>{game.subheading}</SubsectionTitle>}

      {game?.name && <SubsectionSubtitle>{game.name}</SubsectionSubtitle>}

      {game?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={game.youWillNeed}
          checklistId="game-youWillNeed"
        />
      )}

      {game?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={game.gettingReady}
          checklistId="game-gettingReady"
        />
      )}

      <FaithVerseFun game={game} />

      {game?.gameSlideADescription && (
        <RichText>{game.gameSlideADescription}</RichText>
      )}

      {Array.isArray(game?.gameSlideAMedia) &&
        game?.gameSlideAMedia.map((fileId, index) => (
          <Media key={`gameSlideA-${index}`} fileId={fileId} />
        ))}

      {game?.gameSlideAConclusion && (
        <RichText>{game.gameSlideAConclusion}</RichText>
      )}

      {game?.gameSlideBDescription && (
        <RichText>{game.gameSlideBDescription}</RichText>
      )}

      {Array.isArray(game?.gameSlideBMedia) &&
        game?.gameSlideBMedia.map((fileId, index) => (
          <Media key={`gameSlideBMedia-${index}`} fileId={fileId} />
        ))}

      {game?.gameSlideBConclusion && (
        <RichText>{game.gameSlideBConclusion}</RichText>
      )}

      <Footnotes data={game?.footnotes} />

      <ContentIcon
        content={[
          game?.gameSlideADescription || "",
          game?.gameSlideAConclusion || "",
          game?.gameSlideBDescription || "",
          game?.gameSlideBConclusion || "",
        ]}
      />
    </>
  ) : null;
};

const FaithVerseFun: React.FC<GameProps> = ({ game }) => {
  if (!game?.faithVerseFun?.introduction) return null;

  return (
    <>
      <SubsectionSubtitle>
        {formatMessage(sessionMessages.faithVerseFun)}
      </SubsectionSubtitle>

      {game?.faithVerseFun?.introduction && (
        <RichText>{game.faithVerseFun.introduction}</RichText>
      )}

      {game?.faithVerseFun?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={game.faithVerseFun.youWillNeed}
          checklistId="faithversefun-youWillNeed"
        />
      )}

      {game?.faithVerseFun?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={game.faithVerseFun.gettingReady}
          checklistId="faithversefun-gettingReady"
        />
      )}

      {game?.faithVerseFun?.thought && (
        <RichText>{game.faithVerseFun.thought}</RichText>
      )}

      {Array.isArray(game?.faithVerseFun?.media) &&
        game?.faithVerseFun?.media.map((fileId, index) => (
          <Media key={`faithVerseFun-media-${index}`} fileId={fileId} />
        ))}

      {game?.faithVerseFun?.content && (
        <RichText>{game.faithVerseFun.content}</RichText>
      )}

      {game?.faithVerseFun?.scripture && (
        <Scripture scripture={game.faithVerseFun.scripture} />
      )}

      {game?.faithVerseFun?.conclusion && (
        <RichText>{game.faithVerseFun.conclusion}</RichText>
      )}

      <ContentIcon
        content={[
          game?.faithVerseFun?.introduction || "",
          game?.faithVerseFun?.thought || "",
          game?.faithVerseFun?.content || "",
          game?.faithVerseFun?.conclusion || "",
        ]}
      />
    </>
  );
};
