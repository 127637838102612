import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text2, Text4 } from "~/components/text";

export const Container = styled.View`
  height: 164px;
  padding-vertical: ${spacers.ss4};
  padding-horizontal: ${spacers.ss6};
  background-color: ${colors.white};
`;

export const Content = styled.View<{ bottom: number }>``;

export const InputBox = styled.View`
  background-color: ${colors.gray75};
  padding: ${spacers.ss6} ${spacers.ss5};
  border-radius: 10px;
  margin-bottom: ${spacers.ss6};
`;

export const TextInput = styled.TextInput``;

export const Button = styled.TouchableOpacity<{ isDisabled: boolean }>`
  height: 36px;
  padding-horizontal: ${spacers.ss5};
  background-color: ${({ isDisabled }) =>
    isDisabled ? colors.gray300 : colors.zinc700};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Text4)`
  font-family: SFProDisplayMedium;
  letter-spacing: 1px;
  text-align: center;
  margin-top: ${spacers.ss3};
  margin-bottom: ${spacers.ss6};
`;

export const ButtonText = styled(Text2)`
  color: ${colors.white};
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
  letter-spacing: 1px;
`;
