import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import { BibleNotesViewProps } from "~/navigation/notes-stack/types";

import { SavePayload } from "~/components/note-view-edit";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { asyncLogEvent, events } from "~/utils/analytics";
import { deleteBibleNote, editBibleNote } from "~/state/bible-notes";
import { getBibleNoteById } from "~/state/bible-notes/selectors";
import { genericMessages } from "~/constants/intl";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { BibleNotesView, BibleNoteAddComponent } from "./content";

export const BibleNoteAdd = () => {
  const {
    params: { bookId, chapterId, selectedVerses = [] },
  } = useRoute<BibleNotesViewProps["route"]>();

  const navigation = useNavigation();

  return (
    <BibleNoteAddComponent
      bookId={bookId}
      chapterId={chapterId}
      selectedVerses={selectedVerses}
      onSuccess={navigation.goBack}
    />
  );
};

interface ViewEditProps {
  noteId: string;
  handleDelete: () => void;
  hideActions?: boolean;
}

export const BibleNoteViewEditComponent = ({
  noteId,
  handleDelete,
  hideActions,
}: ViewEditProps) => {
  const note = useAppSelector((state) => getBibleNoteById(state, noteId));

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const onSave = ({ value: text }: SavePayload) => {
    if (!text) {
      onDelete();
      return;
    }

    dispatch(
      editBibleNote({
        noteId,
        text,
        onSuccess: () => {
          asyncLogEvent(events.BIBLE_NOTE_EDIT);
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
        },
        onError: () => {
          pushAlert({
            message: genericMessages.error,
          });
        },
      })
    );
  };

  const onDelete = () => {
    handleDelete();
    dispatch(
      deleteBibleNote({
        noteId,
        onSuccess: () => {
          asyncLogEvent(events.BIBLE_NOTE_DELETE);
          pushAlert({
            message: genericMessages.noteDeleted,
            color: colors.emerald600,
          });
        },
        onError: () => {
          pushAlert({
            message: genericMessages.error,
          });
        },
      })
    );
  };

  const bookId = note?.bookId || "";
  const chapterId = note?.chapterId || "";
  const lastUpdated = note?.lastUpdated;
  const text = note?.text;
  const selectedVerses = note?.verses || [];

  return (
    <BibleNotesView
      bookId={bookId}
      chapterId={chapterId}
      selectedVerses={selectedVerses}
      lastUpdated={lastUpdated}
      text={text}
      onSave={onSave}
      onDelete={onDelete}
      noteId={noteId}
      hideActions={hideActions}
    />
  );
};

export const BibleNoteViewEdit = () => {
  const {
    params: { noteId },
  } = useRoute<BibleNotesViewProps["route"]>();
  const navigation = useNavigation();

  const handleDelete = () => {
    navigation.goBack();
  };

  return (
    <BibleNoteViewEditComponent noteId={noteId} handleDelete={handleDelete} />
  );
};
