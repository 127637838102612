import { SessionNote } from "~/state/session-notes";

type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

export type SessionNotesMeta = {
  id: string;
  sessionId: string;
  lastUpdated: number;
};

export type SessionNotesMetaData = {
  planId: string;
  planTitle: string;
  volume: string;
  volumeNumber: number;
  sessionId: string;
  session: string;
  lastUpdated: number;
};

export type NotesState = {
  session: SessionNote[];
  bible: string[];
  personal: string[];
  bibleCount: number;
  personalCount: number;
  sessionMeta: SessionNotesMeta[];
};

export type SessionNotesData = SessionNotesMetaData & {
  notes: SessionNote[];
};

export type SessionNoteData = {
  title: string;
  notes: SessionNotesData[];
};

export type PlanData = {
  id: string;
  title: string;
};

export type EditSessionNote = {
  id: string;
  note: string;
};

export enum SortEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum NoteTypes {
  Session = "session",
  Bible = "bible",
  Personal = "personal",
}

type Callbacks = {
  onSuccess?: () => void;
  onError?: (e?: Error) => void;
};

export type GetNotesCountPayload = {
  type: NoteTypes;
} & Callbacks;

export type GetNotesCountAction = Action<GetNotesCountPayload>;
