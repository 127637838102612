import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text2 } from "../text";

export const Container = styled.View`
  position: relative;
  z-index: 9;
`;

export const Options = styled.View`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  background-color: ${colors.white};
  z-index: 99;
  padding-vertical: ${spacers.ss4};
`;

export const Option = styled.TouchableOpacity`
  padding: ${spacers.ss3} ${spacers.ss5};
`;

export const OptionText = styled(Text2)`
  color: ${colors.black};
  font-family: SFPro;
  font-size: 16.5px;
`;
