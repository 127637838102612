import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  query,
  collection,
  getDocs,
  DocumentSnapshot,
} from "firebase/firestore";
import { database } from "<config>/firebase";

import { getWalkthroughCollection } from "~/constants/collections";
import { getEnvironment } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";

import { getWalkthrough } from "./actions";
import { WalkthroughContent } from "./types";
import { setContent } from "./slice";

export function* getWalkthroughFn(): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const walkthroughCollection = getWalkthroughCollection(env);

    const snapshots = yield call(
      // @ts-ignore
      getDocs,
      query(collection(database, walkthroughCollection))
    );

    const data = snapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as WalkthroughContent[];

    if (Array.isArray(data)) {
      const steps = data.reduce((acc, item) => {
        Object.assign(acc, item);
        return acc;
      }, {});
      yield put(setContent(steps));
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export const getWalkthroughSaga = function* () {
  yield takeLatest(getWalkthrough.type, getWalkthroughFn);
};
