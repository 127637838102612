export const messages = {
  title: {
    id: "bible-notes.title",
    defaultMessage: "Bible Notes",
  },
  search: {
    id: "bible-notes.search",
    defaultMessage: "Search Bible notes",
  },
  empty: {
    id: "bible-notes.empty",
    defaultMessage: "You have not created any note yet",
  },
  emptySearch: {
    id: "bible-notes.empty-search",
    defaultMessage: "There are no notes matching your search criteria",
  },
};
