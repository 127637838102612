import NetInfo from "@react-native-community/netinfo";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import * as FileSystem from "~/utils/file-system";
import * as Sharing from "expo-sharing";
import {
  openBrowserAsync,
  WebBrowserPresentationStyle,
} from "expo-web-browser";

import { makeDownloadUrl } from "./utils";
import { isWeb } from "~/utils/platform";
import { Environment } from "~/state/user/types";
import { LibraryFiltersOptions } from "~/state/ui/types";
import {
  getCmsContentCollection,
  getFiltersCollection,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { FILES_DIRECTORY } from "~/constants";

import type {
  DownloadFilePayload,
  FlamelinkMeta,
  FlamelinkMediaFile,
  OpenFilePayload,
  LessonsContent,
  PersonalDevotionsContent,
  Session,
  FlamelinkFileMeta,
} from "./types";

const filesCollection = "fl_files";

export async function getFlamelinkMedia(
  chunkedFilesIds: string[][]
): Promise<FlamelinkMediaFile[]> {
  const filesRefs = chunkedFilesIds.map((coverIds) =>
    query(
      collection(database, filesCollection),
      where(documentId(), "in", coverIds)
    )
  );

  const filesQueries = filesRefs.map((ref) => getDocs(ref));

  const filesResult = await Promise.all(filesQueries).then((results) =>
    results.map((result) => result.docs.map((data) => data.data()))
  );

  const files = [...filesResult.flat()].map(({ id, file, contentType }) => ({
    id,
    file,
    contentType,
  }));

  return files;
}

export async function getSingleFlamelinkMedia(
  fileId: string
): Promise<FlamelinkMediaFile | undefined> {
  const fileRef = doc(database, filesCollection, fileId);

  const file = await getDoc(fileRef).then((result) => result.data());

  return {
    id: file?.id,
    file: file?.file,
    contentType: file?.contentType,
  };
}

const combineBatches = async (firestoreCollection: string, key: string) => {
  let retryCount = 0;
  const maxRetries = 3;
  let documents: FlamelinkFileMeta[] = [];

  while (retryCount < maxRetries) {
    try {
      const querySnapshot = await getDocs(
        collection(database, firestoreCollection, "data", key)
      );

      documents = [];
      querySnapshot.forEach((document) => {
        documents.push(document.data().data);
      });

      return documents.flat();
    } catch (error) {
      handleError(`Error fetching ${key} documents`);
      retryCount++;

      // Wait for a short time before retrying
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }

  // If max retries exceeded, throw an error or handle it accordingly
  throw new Error("Max retry count exceeded. Unable to fetch documents.");
};

export async function fetchFlamelinkData(env: Environment): Promise<
  | FlamelinkMeta
  | {
      error: string;
    }
> {
  try {
    const cmsContentCollection = getCmsContentCollection(env);

    const audiencesMeta = doc(database, cmsContentCollection, "audiences");
    const getFilesMeta = combineBatches(cmsContentCollection, "files");

    const lessonsMeta = doc(database, cmsContentCollection, "lessons");
    const personalDevotionMeta = doc(
      database,
      cmsContentCollection,
      "personalDevotion"
    );
    const plansMeta = doc(database, cmsContentCollection, "plans");
    const questionsMeta = doc(database, cmsContentCollection, "questions");
    const sessionsMeta = doc(database, cmsContentCollection, "sessions");
    const volumesMeta = doc(database, cmsContentCollection, "volumes");

    // Apparently using Promise.all instead of separate await get(...) counts a single query
    // instead of several queries in terms of the Firebase billing
    const [
      files,
      audiences,
      lessons,
      personalDevotion,
      plans,
      questions,
      sessions,
      volumes,
    ] = await Promise.all([
      getFilesMeta,
      getDoc(audiencesMeta),
      getDoc(lessonsMeta),
      getDoc(personalDevotionMeta),
      getDoc(plansMeta),
      getDoc(questionsMeta),
      getDoc(sessionsMeta),
      getDoc(volumesMeta),
    ]);

    const data = {
      files,
      audiences: audiences.exists() ? audiences?.data().data ?? [] : [],
      lessons: lessons.exists() ? lessons?.data().data ?? [] : [],
      personalDevotion: personalDevotion.exists()
        ? personalDevotion?.data().data ?? []
        : [],
      plans: plans.exists() ? plans?.data().data ?? [] : [],
      questions: questions.exists() ? questions?.data().data ?? [] : [],
      sessions: sessions.exists() ? sessions?.data().data ?? [] : [],
      volumes: volumes.exists() ? volumes?.data().data ?? [] : [],
    };

    return data;
  } catch (error) {
    handleError(error);
    return { error } as { error: string };
  }
}

export async function executeDownloadFile({
  fileName,
  onUpdate,
}: Pick<DownloadFilePayload, "fileName" | "onUpdate">) {
  const outputDir = encodeURI(`${FILES_DIRECTORY}/${fileName}`);
  const directoryInfo = await FileSystem.getInfoAsync(FILES_DIRECTORY);

  if (!directoryInfo.exists) {
    await FileSystem.makeDirectoryAsync(FILES_DIRECTORY, {
      intermediates: true,
    });
  }

  const downloadResumable = FileSystem.createDownloadResumable(
    makeDownloadUrl(fileName),
    outputDir,
    {},
    onUpdate
  );

  const download = await downloadResumable.downloadAsync();

  return download;
}

export const executeWebDownload = async (
  fileName: string,
  onUpdate: (progress: FileSystem.DownloadProgressData) => void
) => {
  const downloadURL = makeDownloadUrl(fileName);

  onUpdate({ totalBytesWritten: 0.5, totalBytesExpectedToWrite: 1 });
  const res = await fetch(downloadURL);
  const blob = await res.blob();
  onUpdate({ totalBytesWritten: 1, totalBytesExpectedToWrite: 1 });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};

export async function deleteDownloadFromFileSystem(filePath: string) {
  try {
    await FileSystem.deleteAsync(filePath, { idempotent: true });

    return true;
  } catch (err) {
    handleError(err);
    return false;
  }
}

export async function openPresentationOrPDF({
  fileName,
  downloadedResourceLocation,
}: Pick<OpenFilePayload, "fileName" | "downloadedResourceLocation">) {
  const url = makeDownloadUrl(fileName);

  if (isWeb) {
    window.open(url, "_blank");

    return;
  }

  const { isConnected } = await NetInfo.fetch();

  if (isConnected || !downloadedResourceLocation) {
    await openBrowserAsync(url, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });

    return;
  }

  // offline handling as we can't open the browser to view the pdf
  const contentUri = await FileSystem.getContentUriAsync(
    downloadedResourceLocation
  );

  Sharing.shareAsync(contentUri);
}

export async function fetchFiltersOptions(env: Environment) {
  const filtersCollection = getFiltersCollection(env);
  const docRef = doc(database, filtersCollection, "library");

  const querySnapshot = await getDoc(docRef);

  if (!querySnapshot.exists()) return undefined;

  return querySnapshot.data() as LibraryFiltersOptions;
}

export async function fetchSession(env: Environment, sessionId: string) {
  const cmsContentCollection = getCmsContentCollection(env);

  const docRef = doc(
    database,
    cmsContentCollection,
    "data",
    "sessions",
    sessionId
  );

  const querySnapshot = await getDoc(docRef);

  return querySnapshot.data()?.data as Session;
}

export async function fetchPersonalDevotion(
  env: Environment,
  personalDevotionId: string
) {
  const cmsContentCollection = getCmsContentCollection(env);

  const docRef = doc(
    database,
    cmsContentCollection,
    "data",
    "personalDevotions",
    personalDevotionId
  );

  const querySnapshot = await getDoc(docRef);

  return querySnapshot.data()?.data as PersonalDevotionsContent;
}

export async function fetchLesson(env: Environment, lessonId: string) {
  const cmsContentCollection = getCmsContentCollection(env);

  const docRef = doc(
    database,
    cmsContentCollection,
    "data",
    "lessons",
    lessonId
  );

  const querySnapshot = await getDoc(docRef);

  return querySnapshot.data()?.data as LessonsContent;
}
