import styled from "styled-components/native";

import { spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

export const Content = styled.View`
  padding-horizontal: ${spacers.ss8};
  padding-bottom: 20px;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 0px;
  right: 0px;
`;

export const ButtonBox = styled.View`
  ${getWebContainerStyle(340)}
`;
