import { formatMessage } from "~/utils/translation";
import {
  LanguageKey,
  LocalisedText,
} from "~/state/in-app-notifications-setup/types";

import { messages } from "./intl";

export const languageOptions: Array<{ key: LanguageKey; value: string }> = [
  { key: "en", value: formatMessage(messages.languageEn) },
  { key: "es", value: formatMessage(messages.languageEs) },
];

export const defaultString: LocalisedText = {
  en: "",
  es: "",
};
