import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.View`
  margin-horizontal: ${spacers.ss8};
  margin-bottom: ${spacers.ss4};
  flex: 1;
`;

export const Row = styled.View`
  flex-direction: row;
  margin-bottom: ${spacers.ss5};
`;

export const BottomSection = styled.View`
  margin-top: ${spacers.ss8};
  flex-direction: column;
`;

export const Text = styled(Text3)`
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: ${spacers.ss6};
`;
