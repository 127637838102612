export const messages = {
  title: {
    id: "pif.content.title",
    defaultMessage:
      "Help bring discipleship resources to more people around the world",
  },
  description: {
    id: "pif.content.description",
    defaultMessage:
      "Every dollar helps us equip churches and individuals with discipleship resources.",
  },
  customAmount: {
    id: "pif.content.custom-amount",
    defaultMessage: "Custom amount",
  },
};
