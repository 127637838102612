import * as React from "react";
import { TouchableOpacity } from "react-native";

import { colors } from "~/styles/theme";

import type { ChipProps } from "./types";
import { ChipsContainer, ChipLabel, ChipCheck } from "./styles";

export const Chip = React.memo<ChipProps>(
  ({ label, value, onPress, selected }) => {
    const handleOnPress = React.useCallback(() => {
      onPress(value);
    }, [onPress, value]);

    return (
      <TouchableOpacity onPress={handleOnPress}>
        <ChipsContainer
          selected={selected}
          colors={
            selected ? ["#FEFFD7", "#FFE792"] : [colors.white, colors.white]
          }
        >
          {selected && <ChipCheck />}

          <ChipLabel selected={selected}>{label}</ChipLabel>
        </ChipsContainer>
      </TouchableOpacity>
    );
  }
);
