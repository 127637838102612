import * as React from "react";

import {
  Checkbox,
  TableCell,
  TableRow,
  TableSortLabel,
  SortDirection,
} from "@mui/material";
import MuiTableHead from "@mui/material/TableHead";

interface Props {
  order: SortDirection;
  orderBy: string;
  onRequestSort: (event: Event, property: string) => void;
  headCells: {
    id: string;
    align: "center" | "left" | "right" | "justify" | "inherit" | undefined;
    disablePadding: boolean;
    label: string;
  }[];
}

export const TableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
}: Props) => {
  const createSortHandler = (property: string) => (event: Event) =>
    onRequestSort(event, property);

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: headCell.align === "center" ? 5 : 2 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)}
              // @ts-ignore
              direction={orderBy === headCell.id ? order : "asc"}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
