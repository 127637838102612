import { FlamelinkLocales } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { messages } from "./intl";

export function mapFlamelinkLocaleToLabel(locale: string) {
  switch (locale) {
    case FlamelinkLocales.en:
      return formatMessage(messages.english);
    case FlamelinkLocales.es:
      return formatMessage(messages.spanish);
    default:
      return "";
  }
}

export function mapAppLocaleToLabel(locale: string) {
  switch (locale) {
    case "en":
      return formatMessage(messages.english);
    case "es":
      return formatMessage(messages.spanish);
    default:
      return "";
  }
}
