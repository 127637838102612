import { EXTERNAL_LINKS } from "~/constants";
import i18next from "~/utils/translation";

export function makeExternalLinks() {
  const locale = i18next.language as keyof typeof EXTERNAL_LINKS;

  if (locale in EXTERNAL_LINKS) {
    return EXTERNAL_LINKS[locale];
  }

  return EXTERNAL_LINKS.en;
}
