import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Localization from "expo-localization";
import { ModuleType } from "i18next";

import { handleError } from "./logger";

const STORE_LANGUAGE_KEY = "@language";

export const languageDetector = {
  type: "languageDetector" as ModuleType,
  async: true,
  init: () => {},
  detect: async function (callback: (lang: string) => void) {
    try {
      await AsyncStorage.getItem(STORE_LANGUAGE_KEY).then((storedLang) => {
        if (storedLang) return callback(storedLang);

        const deviceLocale = Localization.locale.split("-")[0];

        return callback(deviceLocale);
      });
    } catch (error) {
      handleError({ message: "Error reading language", error });
    }
  },
  cacheUserLanguage: async function (lang: string) {
    try {
      await AsyncStorage.setItem(STORE_LANGUAGE_KEY, lang);
    } catch (error) {
      handleError({ message: "Error saving language", error });
    }
  },
};
