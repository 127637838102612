import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text1, Text2, Text3 } from "~/components/text";
import { Icon, IconTypes } from "~/components/icon";

interface Props {
  isLeft?: boolean;
  isRight?: boolean;
  isDisabled?: boolean;
}

interface ButtonProps {
  isActive?: boolean;
  isLoading?: boolean;
  isLong?: boolean;
  flex?: number;
}

export const Container = styled.View`
  margin-horizontal: ${spacers.ss8};
  margin-bottom: ${spacers.ss4};
`;

export const Row = styled.View`
  flex-direction: row;
  margin-bottom: ${spacers.ss5};
`;

export const PayButton = styled.Pressable<Props>`
  height: 52px;
  border-radius: 26px;
  background-color: ${colors.zinc700};
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: ${({ isLeft }) => (isLeft ? spacers.ss3 : 0)};
  margin-left: ${({ isRight }) => (isRight ? spacers.ss3 : 0)};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const TextRegular = styled(Text1)``;

export const TextBold = styled(Text1)`
  font-family: SFProDisplayBold;
`;

export const TextLink = styled(Text1)`
  color: ${colors.primaryBlue};
`;

export const TextLinkBold = styled(Text1)`
  font-family: SFProDisplayMedium;
  color: ${colors.primaryBlue};
`;

export const AppleIcon = styled(Icon).attrs({
  type: IconTypes.ApplePay,
})``;

export const GoogleIcon = styled(Icon).attrs({
  type: IconTypes.GooglePay,
})``;

export const ButtonContainer = styled.Pressable<ButtonProps>`
  height: 52px;
  border-radius: 26px;
  background-color: ${({ isActive }) =>
    isActive ? colors.primaryBlue : colors.gray300};
  align-items: center;
  justify-content: center;
  margin: ${spacers.ss2};
  ${({ flex }) => (flex ? `flex: ${flex};` : "")};
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
`;

export const ButtonText = styled(Text2)<{ isActive: boolean | undefined }>`
  color: ${({ isActive }) =>
    isActive ? colors.white : colors.black};
  font-family: SFProDisplayBold;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const DividerTextBox = styled.View`
  background-color: ${colors.white}
  top: -10px;
  padding-horizontal: ${spacers.ss5}
`;

export const DividerText = styled(Text3)`
  text-transform: uppercase;
  color: ${colors.gray500};
`;

export const LoaderBox = styled.View`
  position: absolute;
  top: ${spacers.ss6};
  right: ${spacers.ss6};
`;
