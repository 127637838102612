import * as React from "react";
import type { WatchSection } from "~/state/flamelink/types";
import { RichText } from "../rich-text";
import { Question } from "../question";
import { SectionTitle } from "../common";
import { Media } from "../media";
import { Footnotes } from "../footnotes";
import { ContentIcon } from "../content-icon";

interface WatchProps {
  watch: WatchSection;
}

export const Watch: React.FC<WatchProps> = ({ watch }) => {
  return (
    <>
      <SectionTitle>{watch?.sectionTitle}</SectionTitle>

      <RichText>{watch?.intro}</RichText>

      {Array.isArray(watch?.introQuestions) &&
        watch?.introQuestions.map(({ question, uniqueKey, content }, i) => (
          <React.Fragment key={`introQuestions-repeater-${i}`}>
            <Question question={{ question, uniqueKey }} />

            <RichText>{content}</RichText>
          </React.Fragment>
        ))}

      {watch?.watchVideoMedia && <Media fileId={watch?.watchVideoMedia[0]} />}

      <RichText>{watch?.conclusion}</RichText>

      {Array.isArray(watch?.questions) &&
        watch?.questions.map(({ question, uniqueKey, content }, index) => (
          <React.Fragment key={`questions-repeater-${index}`}>
            <Question question={{ question, uniqueKey }} />

            <RichText>{content}</RichText>
          </React.Fragment>
        ))}

      <Footnotes data={watch?.footnotes} />

      <ContentIcon content={[watch?.intro, watch?.questions]} />
    </>
  );
};
