import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { RichText } from "../../../rich-text";
import { Media } from "../../../media";
import { ContentIcon } from "../../../content-icon";

interface BibleTimeProps {
  bibleTime: LessonsContent["bibleTime"];
}

export const BibleTime: React.FC<BibleTimeProps> = ({ bibleTime }) => {
  const hasContent = React.useMemo(
    () =>
      bibleTime?.introduction ||
      bibleTime?.bibleTimeIntroMedia?.length ||
      bibleTime?.content ||
      bibleTime?.bibleTimeMedia?.length ||
      bibleTime?.conclusion ||
      bibleTime?.conclusionMedia?.length ||
      bibleTime?.epilogue,
    [bibleTime]
  );

  return hasContent ? (
    <>
      {bibleTime?.sectionTitle && (
        <SectionTitle>{bibleTime.sectionTitle}</SectionTitle>
      )}

      {bibleTime?.introduction && <RichText>{bibleTime.introduction}</RichText>}

      {Array.isArray(bibleTime?.bibleTimeIntroMedia) &&
        bibleTime?.bibleTimeIntroMedia.map((fileId, index) => (
          <Media key={`bibleTimeIntroMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTime?.content && <RichText>{bibleTime.content}</RichText>}

      {Array.isArray(bibleTime?.bibleTimeMedia) &&
        bibleTime?.bibleTimeMedia.map((fileId, index) => (
          <Media key={`bibleTimeMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTime?.conclusion && <RichText>{bibleTime.conclusion}</RichText>}

      {Array.isArray(bibleTime?.conclusionMedia) &&
        bibleTime?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTime?.epilogue && <RichText>{bibleTime.epilogue}</RichText>}

      <ContentIcon
        content={[
          bibleTime?.introduction || "",
          bibleTime?.content || "",
          bibleTime?.conclusion || "",
          bibleTime?.epilogue || "",
        ]}
      />
    </>
  ) : null;
};
