import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgBible = (props) => (
  <Svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.475 5.23-.333-.248c-1.595-1.186-3.76-1.862-5.92-2.22a27.82 27.82 0 0 0-5.614-.32c-.571.026-1.007.491-1.006 1.142.004 4.014.016 14.377.016 15.916v.009c-.002.128.111.378.438.617.318.233.654.315.842.283l.009-.002h.008c3.626-.519 7.699.254 10.984 2.618.083.041.245.083.453.082.205 0 .366-.04.449-.08a15.23 15.23 0 0 1 11.42-2.78l.006.002c.431.074.864-.291.857-.733V3.758c0-.717-.525-1.274-1.18-1.298-3.691-.135-8.121.645-11.08 2.544l-.349.224Zm-.857 15.737-.88-.462C11.918 19.02 8.902 18.6 5.684 18.6h-.6V4.1l.566-.032c3.387-.195 6.83.603 9.699 2.498l.269.178v14.223Zm12-2.313h-.6c-3.218 0-6.233.42-9.054 1.904l-.88.462V6.744l.27-.178c2.868-1.895 6.311-2.693 9.698-2.498l.566.032v14.553Zm.858 5.524a16.868 16.868 0 0 0-2.793-.245 16.653 16.653 0 0 0-9.008 2.639l-.324.208-.324-.208a16.653 16.653 0 0 0-9.008-2.639 16.87 16.87 0 0 0-2.793.245l-.008.001a.733.733 0 0 0-.61.835.733.733 0 0 0 .857.57l.01-.002a15.135 15.135 0 0 1 11.452 2.65.733.733 0 0 0 .848 0 15.134 15.134 0 0 1 11.452-2.65l.01.002a.733.733 0 0 0 .857-.57.733.733 0 0 0-.61-.835h-.008Z"
      fill="currentColor"
    />
  </Svg>
);

export default SvgBible;
