import React from "react";
import Constants from "expo-constants";
import * as NativeSplashScreen from "expo-splash-screen";
import { setCrashlyticsCollectionEnabled } from "~/utils/crashlytics";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { ThemeProvider } from "styled-components/native";
import { Provider as StoreProvider } from "react-redux";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider as PaperProvider } from "react-native-paper";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";

import { Navigation } from "~/navigation";
import { AlertsProvider } from "~/state/alerts";
import { ModalProvider } from "~/state/modal";
import { MenuProvider } from "~/state/menu";
import { UiProvider } from "~/state/ui";
import { Alerts } from "~/components/alerts";
import { store, persistor } from "~/state/store";
import { theme, paperTheme } from "~/styles/theme";
import { SplashScreen } from "~/components/splash-screen";
import { ErrorBoundary } from "~/components/error-boundary";
import { addTrace, recordError } from "~/utils/crashlytics";
import { migrateAuth } from "~/utils/storage";
import { isWeb } from "~/utils/platform";
import { initialiseAnalytics } from "~/utils/analytics";
import { enableInAppMessages } from "~/utils/in-app-messages";
import { useScript, appleAuthHelpers } from "~/utils/apple-sign-in-auth";
import { init } from "~/utils/one-signal";

// This is added here because of the issue with the Firebase auth "session"
// being lost after the upgrade to Expo v49. Eventually it can be removed after most of the users
// get the newest build
migrateAuth();

NativeSplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});

export function App() {
  React.useEffect(() => {
    addTrace("App mounted");
    enableInAppMessages(true);
    setCrashlyticsCollectionEnabled(true);

    if (isWeb) {
      initialiseAnalytics();
    }
  }, []);

  // We need to preload the Apple auth script for web
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const errorHandler = (error: Error, isFatal?: boolean) => {
    if (isFatal) {
      recordError(error);
    } else {
      console.log(error);
    }
  };

  if (!isWeb) {
    ErrorUtils.setGlobalHandler(errorHandler);
  }

  React.useEffect(() => {
    const appId = Constants?.expoConfig?.extra?.oneSignalAppId;
    if (!isWeb || !appId) {
      return;
    }
    init({
      appId,
    });
  }, []);

  return (
    <StoreProvider store={store}>
      <ErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <SplashScreen>
            <SafeAreaProvider>
              <StatusBar style="dark" />
              <ThemeProvider theme={theme}>
                <PaperProvider theme={paperTheme}>
                  <GestureHandlerRootView style={{ flex: 1 }}>
                    <AlertsProvider>
                      <ModalProvider>
                        <UiProvider>
                          <ActionSheetProvider>
                            <BottomSheetModalProvider>
                              <MenuProvider>
                                <Navigation />
                              </MenuProvider>
                            </BottomSheetModalProvider>
                          </ActionSheetProvider>
                          <Alerts />
                        </UiProvider>
                      </ModalProvider>
                    </AlertsProvider>
                  </GestureHandlerRootView>
                </PaperProvider>
              </ThemeProvider>
            </SafeAreaProvider>
          </SplashScreen>
        </PersistGate>
      </ErrorBoundary>
    </StoreProvider>
  );
}
