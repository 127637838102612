import * as React from "react";
import { Animated } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";
import { TutorialStep } from "~/components/tutorial-step";
import { pxToNumber } from "~/utils/theme";
import { spacers } from "~/styles/theme";
import { Container, Button, shadowStyle } from "./styles";

interface Props {
  onPress: () => void;
  isLeft?: boolean;
  opacity: Animated.Value;
  showTutorial?: boolean;
}

type IconProps = {
  onPress: () => void;
  iconType: "chevron-left" | "chevron-right";
};

const ButtonComponent = ({ onPress, iconType }: IconProps) => (
  <Button onPress={onPress} style={shadowStyle}>
    <Icon name={iconType} size={30} />
  </Button>
);

const TutorialContainer = ({ onPress, iconType }: IconProps) => (
  <TutorialStep id="BIBLE.CONTENT.ARROWS">
    <ButtonComponent onPress={onPress} iconType={iconType} />
  </TutorialStep>
);

export const Arrow = React.memo<Props>(
  ({ opacity, onPress, isLeft, showTutorial }) => {
    const iconType = isLeft ? "chevron-left" : "chevron-right";
    const { bottom } = useSafeAreaInsets();

    const containerStyle = React.useMemo(
      () => ({
        bottom: bottom + TAB_BAR_HEIGHT + pxToNumber(spacers.ss5),
      }),
      [bottom]
    );

    const Content = showTutorial ? TutorialContainer : ButtonComponent;

    return (
      <Container style={{ ...containerStyle, opacity }} isLeft={!!isLeft}>
        <Content onPress={onPress} iconType={iconType} />
      </Container>
    );
  }
);
