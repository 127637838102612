export const messages = {
  title: {
    id: "font-size.title",
    defaultMessage: "Font Scaling",
  },
  small: {
    id: "font-size.small",
    defaultMessage: "Small",
  },
  medium: {
    id: "font-size.medium",
    defaultMessage: "Medium",
  },
  large: {
    id: "font-size.large",
    defaultMessage: "Large",
  },
  cancel: {
    id: "font-size.cancel",
    defaultMessage: "Cancel",
  },
};
