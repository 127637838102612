import styled from "styled-components/native";
import { Image as ExpoImage } from "expo-image";

import { spacers } from "~/styles/theme";
import { Text2, Text5 } from "~/components/text";

export const Container = styled.View`
  margin-top: ${spacers.ss8};
  margin-horizontal: ${spacers.ss8};
  align-items: center;
`;

export const Header = styled(Text2)`
  margin-bottom: ${spacers.ss8};
  text-align: center;
  text-transform: uppercase;
`;

export const Title = styled(Text5)`
  margin-bottom: ${spacers.ss8};
  font-family: SFProDisplayMedium;
  text-align: center;
  letter-spacing: 1px;
`;

export const Description = styled(Text5)`
  margin-bottom: ${spacers.ss10};
  font-family: SFProDisplayMedium;
  text-align: center;
  letter-spacing: 1px;
`;

export const Image = styled(ExpoImage)`
  margin-bottom: ${spacers.ss8};
  height: 300px;
  width: 100%;
`;

export const ButtonBox = styled.View`
  width: 100%;
`;
