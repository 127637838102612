export const messages = {
  viewPrayerRequests: {
    id: "prayer-requests.view",
    defaultMessage: "View Prayer Requests",
  },
  addPrayerRequests: {
    id: "prayer-requests.add",
    defaultMessage: "Add Prayer Requests",
  },
};
