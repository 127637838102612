import * as FileSystem from "~/utils/file-system";
import { BIBLE_DIRECTORY, SUPPORTED_BIBLE_VERSIONS } from "~/constants";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

export function bytesToMegabytes(bytes: number): string {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
}

export const getDownloadedBibles = async () => {
  const downloadedBibles = [];

  for (const abbreviation of SUPPORTED_BIBLE_VERSIONS) {
    const booksUri = `${BIBLE_DIRECTORY}/${abbreviation}/books.json`;
    const data = await FileSystem.getInfoAsync(booksUri);
    if (data?.exists) {
      downloadedBibles.push({
        id: abbreviation,
        title: formatMessage(messages.bibleVersion, { abbreviation }),
        size: data?.size || 0,
        resourceLocation: data?.uri,
      });
    }
  }

  return downloadedBibles;
};
