import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  updateDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import uuid from "react-native-uuid";

import { database } from "<config>/firebase";
import { deleteNote, addNote, editNote } from "~/state/notes";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getSessionNotesCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";

import type { AddSessionNotesParams, SessionNote } from "./types";
import type { RootState } from "../store";

const SESSION_NOTES_TAG = "SessionNotes";

export const sessionNotesApi = createApi({
  reducerPath: "sessionNotesApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: [SESSION_NOTES_TAG],
  endpoints: (builder) => ({
    addSessionNotes: builder.mutation<string, AddSessionNotesParams>({
      async queryFn(
        { sessionId, note, noteId, questionId, question },
        { getState, dispatch }
      ) {
        try {
          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env = (getState() as RootState).user.environment ?? "";

          const sessionNotesCollection = getSessionNotesCollection(env);

          if (noteId) {
            // Delete if the note value is empty
            if (!note) {
              const q = query(
                collection(database, sessionNotesCollection),
                where("id", "==", noteId)
              );

              await deleteDoc(doc(database, sessionNotesCollection, noteId));

              dispatch(deleteSessionNote(noteId));
              return {
                data: "Session note deleted successfully",
              };
            }

            await updateDoc(doc(database, sessionNotesCollection, noteId), {
              lastUpdated: Date.now(),
              note,
            });
            asyncLogEvent(events.SESSION_NOTE_EDIT);

            dispatch(editNote({ id: noteId, note }));

            return {
              data: "Session notes updated successfully",
            };
          }

          if (!note) {
            return { data: "Sesson note requires a value" };
          }

          const id = uuid.v4() as string;
          const sessionNoteToStore: SessionNote = {
            id,
            userId,
            sessionId,
            lastUpdated: Date.now(),
            note,
            questionId,
            question,
          };

          await setDoc(
            doc(database, sessionNotesCollection, id),
            sessionNoteToStore
          );
          asyncLogEvent(events.SESSION_NOTE_ADD);

          dispatch(addNote(sessionNoteToStore));

          return {
            data: "Session notes created successfully",
          };
        } catch (error: any) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_NOTES_TAG],
    }),

    getSessionNoteByKey: builder.query<
      SessionNote,
      { sessionId: string; noteKey: string }
    >({
      async queryFn({ sessionId, noteKey }, { getState }) {
        try {
          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env = (getState() as RootState).user.environment ?? "";
          const sessionNotesCollection = getSessionNotesCollection(env);

          const q = query(
            collection(database, sessionNotesCollection),
            where("userId", "==", userId),
            where("sessionId", "==", sessionId),
            where("questionId", "==", noteKey)
          );

          const querySnapshot = await getDocs(q);
          const note = querySnapshot.docs.map((snapshotDoc) =>
            snapshotDoc.data()
          )[0] as SessionNote;

          if (!note) {
            return { error: `No session notes found for the key: ${noteKey}` };
          }

          return { data: note };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      providesTags: [SESSION_NOTES_TAG],
    }),

    deleteSessionNote: builder.mutation<string, { noteId: string }>({
      async queryFn({ noteId }, { getState, dispatch }) {
        try {
          const env = (getState() as RootState).user.environment ?? "";
          const sessionNotesCollection = getSessionNotesCollection(env);

          const q = query(
            collection(database, sessionNotesCollection),
            where("id", "==", noteId)
          );

          await deleteDoc(doc(database, sessionNotesCollection, noteId));

          dispatch(deleteNote(noteId));

          return { data: `Note deleted: ${noteId}` };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
    }),
  }),
});

export const {
  useAddSessionNotesMutation,
  useDeleteSessionNoteMutation,
  useGetSessionNoteByKeyQuery,
} = sessionNotesApi;
