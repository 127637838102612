import * as React from "react";
import { TouchableWithoutFeedback, TextInput } from "react-native";

import {
  Container,
  CodeContainer,
  CodeBox,
  Code,
  InputBox,
  Input,
} from "./styles";

interface Props {
  value: string;
  codeLength?: number;
  isLoading: boolean;
  onChange?: (arg0: string) => void;
}

export const InputCode = React.memo<Props>(
  ({ value, codeLength = 6, isLoading, onChange }) => {
    const refInput = React.useRef<TextInput>();

    const onPress = () => {
      refInput?.current?.focus();
    };

    return (
      <Container>
        <TouchableWithoutFeedback onPress={onPress}>
          <CodeContainer>
            {new Array(codeLength).fill(0).map((_, index) => (
              <CodeBox
                key={index}
                isLoading={isLoading}
                isActive={index === value.length}
              >
                <Code>{value.charAt(index)}</Code>
              </CodeBox>
            ))}
          </CodeContainer>
        </TouchableWithoutFeedback>
        <InputBox>
          <Input
            value={value}
            onChangeText={onChange}
            ref={refInput}
            {...(!isLoading && {
              autoFocus: true,
              textContentType: "oneTimeCode",
            })}
            disabled={isLoading}
            autoComplete="off"
          />
        </InputBox>
      </Container>
    );
  }
);
