import AsyncStorage from "@react-native-async-storage/async-storage";

import { APP_VERSION } from "~/constants";

import { handleError } from "./logger";

const STORE_KEY = "@recentAppVersion";

export const getRecentAppVersion = async () => {
  try {
    const recentAppVersion = await AsyncStorage.getItem(STORE_KEY);
    if (!recentAppVersion) {
      await AsyncStorage.setItem(STORE_KEY, APP_VERSION);
      return "";
    }
    return recentAppVersion;
  } catch (e) {
    handleError(e);
    return "";
  }
};
