export const messages = {
  inviteSection: {
    id: "group-modal.overview.invite",
    defaultMessage: "Group code",
  },
  membersSection: {
    id: "group-modal.overview.members",
    defaultMessage: "Members",
  },
  button: {
    id: "group-modal.overview.button",
    defaultMessage: "Invite members",
  },
  editButton: {
    id: "group-modal.overview.edit-button",
    defaultMessage: "Edit",
  },
  inviteMessage: {
    id: "group-modal.overview.invite-message",
    defaultMessage:
      "Hey! I am very excited about the new discipleship resources we will be using for our group. It's all delivered in an app called Bible Engagement Project.\n\nClick the link to download the app or use invite code {{inviteCode}}.{{messageUrl}}",
  },
  refreshCode: {
    id: "group-modal.overview.refresh-code",
    defaultMessage: "Reset invite code",
  },
  refreshAdvice: {
    id: "group-modal.overview.refresh-advice",
    defaultMessage:
      "You are about to reset your group invite code. All current invitations will become invalid. You will need to resend invitations to individuals who are not members of your group.\n\nAre you sure?",
  },
};
