import Constants from "expo-constants";

import { SagaIterator } from "redux-saga";
import { takeLatest, call } from "redux-saga/effects";
import { getToken } from "firebase/messaging";

import { doc, updateDoc, arrayUnion } from "firebase/firestore";

import { messaging } from "<config>/firebase.web";
import { database } from "<config>/firebase";
import { getUserData } from "~/state/push-notifications";

import { GetWebTokenAction } from "./types";
import { getUsersCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { setIsUserReady } from "../user/actions";

export const getWebTokenFn = function* ({
  payload: { userId },
}: GetWebTokenAction): SagaIterator {
  if (!userId) {
    return;
  }
  try {
    const usersCollection = getUsersCollection();

    const permission = yield call(Notification.requestPermission);

    if (permission !== "granted") {
      return;
    }

    const webToken = yield call(getToken, messaging, {
      vapidKey: Constants?.expoConfig?.extra?.vapidKey,
    });

    const userData = yield call(getUserData, userId);

    // Token already exists in the profile
    if (userData?.webTokens?.includes(webToken)) {
      return;
    }
    const data = Array.isArray(userData?.webTokens)
      ? {
          webTokens: arrayUnion(webToken),
        }
      : { webTokens: [webToken] };

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, usersCollection, userId),
      data
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

export const getWebTokenSaga = function* () {
  yield takeLatest(setIsUserReady.type, getWebTokenFn);
};
