import * as React from "react";

import * as routes from "~/constants/routes";
import { useAppSelector } from "~/state/hooks";
import { getSessionNotesCount } from "~/state/notes/selectors";
import { getBibleNotesCount } from "~/state/bible-notes/selectors";
import { getPersonalNotesCount } from "~/state/personal-notes/selectors";

import { SessionNotesContent } from "~/screens/notes-session";
import { BibleNotesContent } from "~/screens/notes-bible";
import { PersonalNotesContent } from "~/screens/notes-personal";
import {
  SessionNoteOptions,
  SessionNotesViewComponent,
} from "~/screens/notes-session-view";
import { SessionNoteViewComponent } from "~/screens/notes-session-single-view";
import { WebListContainer } from "~/styles/web";

import { messages } from "../../intl";
import { Note } from "../note";

import { WebContainer, NavigationContainer, ContentContainer } from "./styles";
import { BibleNoteViewEditComponent } from "~/screens/notes-bible-view";
import {
  PersonalNoteAddComponent,
  PersonalNoteViewEditComponent,
} from "~/screens/notes-personal-view";

interface Props {
  isLoadingSession: boolean;
  isLoadingBible: boolean;
  isLoadingPersonal: boolean;
}

export const NotesContent = ({
  isLoadingSession,
  isLoadingBible,
  isLoadingPersonal,
}: Props) => {
  const [screen, setScreen] = React.useState(routes.sessionNotes);
  const [sessionId, setSessionId] = React.useState("");
  const [planId, setPlanId] = React.useState("");
  const [noteId, setNoteId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [sessionTitle, setSessionTitle] = React.useState("");
  const [activeSection, setActiveSection] = React.useState("session");

  const sessionNotesCount = useAppSelector(getSessionNotesCount);
  const bibleNotesCount = useAppSelector(getBibleNotesCount);
  const personalNotesCount = useAppSelector(getPersonalNotesCount);

  const onSessionNotes = React.useCallback(() => {
    setActiveSection("session");
    setScreen(routes.sessionNotes);
  }, [setScreen]);

  const onBibleNotes = React.useCallback(() => {
    setActiveSection("bible");
    setScreen(routes.bibleNotes);
  }, [setScreen]);

  const onPersonalNotes = React.useCallback(() => {
    setActiveSection("personal");
    setScreen(routes.personalNotes);
  }, [setScreen]);

  const renderContent = () => {
    switch (screen) {
      case routes.sessionNotes: {
        return (
          <SessionNotesContent
            onPress={(noteSessionId: string, notePlanId: string) => {
              setScreen(routes.sessionNotesView);
              setSessionId(noteSessionId);
              setPlanId(notePlanId);
            }}
          />
        );
      }
      case routes.bibleNotes: {
        return (
          <BibleNotesContent
            onPress={(itemNoteId) => {
              setScreen(routes.bibleNoteViewEdit);
              setNoteId(itemNoteId);
            }}
          />
        );
      }
      case routes.personalNotes: {
        return (
          <PersonalNotesContent
            onPress={(itemNoteId) => {
              setScreen(routes.personalNotesViewEdit);
              setNoteId(itemNoteId);
            }}
            onPressAdd={() => setScreen(routes.personalNotesAdd)}
          />
        );
      }
      case routes.sessionNotesView: {
        return (
          <SessionNotesViewComponent
            onPress={(options: SessionNoteOptions) => {
              setScreen(routes.sessionNoteView);
              setNoteId(options.noteId);
              setTitle(options.title);
              setSessionTitle(options.sessionTitle);
            }}
            sessionId={sessionId}
            planId={planId}
          />
        );
      }
      case routes.sessionNoteView: {
        return (
          <SessionNoteViewComponent
            handleDelete={() => setScreen(routes.sessionNotes)}
            noteId={noteId}
            sessionId={sessionId}
            planId={planId}
            title={title}
            sessionTitle={sessionTitle}
          />
        );
      }
      case routes.bibleNoteViewEdit: {
        return (
          <BibleNoteViewEditComponent
            handleDelete={() => setScreen(routes.bibleNotes)}
            noteId={noteId}
          />
        );
      }
      case routes.personalNotesViewEdit: {
        return (
          <PersonalNoteViewEditComponent
            handleDelete={() => setScreen(routes.personalNotes)}
            noteId={noteId}
          />
        );
      }
      case routes.personalNotesAdd: {
        return (
          <PersonalNoteAddComponent
            onDone={() => setScreen(routes.personalNotes)}
          />
        );
      }
    }
    return null;
  };

  return (
    <WebContainer>
      <NavigationContainer>
        <WebListContainer>
          <Note
            title={messages.sessionNotes}
            count={sessionNotesCount}
            onPress={onSessionNotes}
            isLoading={isLoadingSession}
            isActive={activeSection === "session"}
          />

          <Note
            title={messages.bibleNotes}
            count={bibleNotesCount}
            onPress={onBibleNotes}
            isLoading={isLoadingBible}
            isActive={activeSection === "bible"}
          />

          <Note
            title={messages.personalNotes}
            count={personalNotesCount}
            onPress={onPersonalNotes}
            isLoading={isLoadingPersonal}
            isActive={activeSection === "personal"}
            isLast
          />
        </WebListContainer>
      </NavigationContainer>

      <ContentContainer>{renderContent()}</ContentContainer>
    </WebContainer>
  );
};
