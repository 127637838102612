export const messages = {
  curriculumProgress: {
    id: "library-progress.progress-row.curriculum-progress",
    defaultMessage: "{{progress}}% Curriculum Progress",
  },
  completionDate: {
    id: "library-progress.progress-row.completion-date",
    defaultMessage: "Completed on {{date}}",
  },
};
