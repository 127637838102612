export const messages = {
  title: {
    id: "video-quality.title",
    defaultMessage: "Video Quality",
  },
  ultraHigh: {
    id: "video-quality.ultra-high",
    defaultMessage: "Ultra High (4K)",
  },
  high: {
    id: "video-quality.high",
    defaultMessage: "High (1080p)",
  },
  normal: {
    id: "video-quality.normal",
    defaultMessage: "Normal (720p)",
  },
  auto: {
    id: "video-quality.auto",
    defaultMessage: "Auto",
  },
  cancel: {
    id: "video-quality.cancel",
    defaultMessage: "Cancel",
  },
};
