import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  collection,
  query,
  where,
  documentId,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import { database } from "<config>/firebase";
import { Profile } from "~/state/user/types";
import { updateGroups } from "~/state/groups/slice";
import { getGroupsMembers } from "~/state/groups/selectors";

import { updateUsers } from "./slice";
import { getUserIds } from "./selectors";
import { User } from "./types";
import { getUsersCollection } from "~/constants/collections";

function* fetchFirestoreDataInBatches(
  usersCollection: string,
  missingUserIds: string[]
): SagaIterator {
  const batchSize = 10;
  let allUserSnapshots: QueryDocumentSnapshot[] = [];

  for (let i = 0; i < missingUserIds.length; i += batchSize) {
    const batchUserIds = missingUserIds.slice(i, i + batchSize);

    const q = query(
      collection(database, usersCollection),
      where(documentId(), "in", batchUserIds)
    );

    // @ts-ignore
    const userSnapshots = yield call(getDocs, q);
    allUserSnapshots = allUserSnapshots.concat(userSnapshots.docs);
  }

  return allUserSnapshots;
}

// Each time the group is being updated we are checking whether there are any new members.
// If yes then we fetch their data from the Firebase and store them in the Redux state.
export function* updateUsersFn(): SagaIterator {
  const usersCollection = getUsersCollection();

  const members = yield select(getGroupsMembers);
  const userIds = yield select(getUserIds);
  const missingUserIds = members.filter((id: string) => !userIds.includes(id));

  if (!missingUserIds.length) {
    return;
  }

  const usersSnapshots = yield call(
    fetchFirestoreDataInBatches,
    usersCollection,
    missingUserIds
  );

  const users: User[] = [];
  usersSnapshots.forEach((user: QueryDocumentSnapshot<Profile>) => {
    if (user.exists()) {
      const { firstName, lastName, image } = user.data() || {};
      users.push({ id: user.id, firstName, lastName, imageUri: image });
    }
  });
  yield put(updateUsers(users));
}

export const updateUsersSaga = function* () {
  yield takeLatest(updateGroups.type, updateUsersFn);
};
