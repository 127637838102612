import { Animated } from "react-native";
import styled from "styled-components/native";
import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";

export const FooterBox = styled(Animated.View)<{ bottom: number }>`
  position: absolute;
  bottom: ${({ bottom = 0 }) => TAB_BAR_HEIGHT + bottom}px;
  left: 0px;
  right: 0px;
  z-index: 9;
`;

export const BackButtonWrapper = styled.View`
  position: absolute;
  top: ${TAB_BAR_HEIGHT + 16}px;
  left: 0px;
  z-index: 9;
`;
