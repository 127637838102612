import styled, { css } from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const HeaderWrapper = styled.View`
  margin-bottom: ${spacers.ss3};
`;

export const SessionTitleWrapper = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const SessionTitleSubWrapper = styled.View<{
  isChild?: boolean;
}>`
  flex-direction: column;
  justify-content: center;

  ${({ isChild }) =>
    isWeb &&
    !isChild &&
    css`
      margin: ${spacers.ss4} 0 0;
    `}

  ${({ isChild }) =>
    isChild &&
    css`
      margin-left: ${spacers.ss4};
    `}
`;

export const SessionTitle = styled(Text2)<{ isExpanded?: boolean }>`
  color: ${colors.black};
  margin-left: ${spacers.ss3};
  ${({ isExpanded }) => (isExpanded ? "font-family: SFProDisplayMedium" : "")}
`;

export const ListWrapper = styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray200};
`;

export const DownloadedIconContainer = styled.View`
  width: ${spacers.ss8};
`;
