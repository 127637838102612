import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { InlineButton } from "~/components/button";

import { LoginButtonBox, LoginText } from "./styles";
import { messages } from "./intl";

export const LoginBox = () => {
  const navigation = useNavigation();

  const navigateToLogIn = React.useCallback(() => {
    navigation.navigate(routes.login);
  }, [navigation]);

  return (
    <LoginButtonBox>
      <LoginText>{messages.alreadyAccount}</LoginText>
      <InlineButton
        text={messages.logIn}
        onPress={navigateToLogIn}
        isUppercase
      />
    </LoginButtonBox>
  );
};
