export const messages = {
  staging: {
    id: "use-change-environment.staging",
    defaultMessage: "Staging",
  },
  production: {
    id: "use-change-environment.production",
    defaultMessage: "Production",
  },
  cancel: {
    id: "use-change-environment.cancel",
    defaultMessage: "Cancel",
  },
};
