import * as React from "react";

import { useAppSelector } from "~/state/hooks";
import { getSessionNotesByPlanId } from "~/state/notes/selectors";
import { ListItem } from "~/components/list-item";
import { getLastUpdated } from "~/screens/notes/utils";
import { formatMessage } from "~/utils/translation";

import { messages } from "../intl";

interface Props {
  planId: string;
  searchTerm: string;
  sortMethod: string;
  onPress: (sessionId: string, planId: string) => void;
}

export const SectionList = ({
  planId,
  searchTerm,
  sortMethod,
  onPress,
}: Props) => {
  const planNotes = useAppSelector((state) =>
    getSessionNotesByPlanId(state, { planId, searchTerm, sortMethod })
  );

  return (
    <>
      {planNotes.map(
        ({ session, sessionId, volumeNumber, lastUpdated }, index) => {
          const sessionNumber = session.split(":")[0];
          const title = formatMessage(messages.noteTitleShort, {
            volumeNumber,
            sessionNumber,
          });
          const description = getLastUpdated(lastUpdated);

          return (
            <ListItem
              title={title}
              description={description}
              onPress={() => onPress(sessionId, planId)}
              isLast={index === planNotes.length - 1}
              key={title}
              numberOfLines={1}
            />
          );
        }
      )}
    </>
  );
};
