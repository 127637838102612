import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { initializeApp, getApps, getApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

import { firebaseConfig } from "./config";

const isAppInitialised = !!getApps().length;
export const app = isAppInitialised ? getApp() : initializeApp(firebaseConfig);

export const database = getFirestore();
export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});
export const storage = getStorage();
export const functions = getFunctions();
export const messaging = getMessaging(app);

// Use it in the root component if in the future we want to show the foreground notifications
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
