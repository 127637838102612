import React, { Fragment } from "react";
import { CommonActions, useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { ModalHeader } from "~/components/modal-header";
import { SettingsScreen } from "~/components/settings";
import { Alerts } from "~/components/alerts";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getUserEmail, getIsSocialProvider } from "~/state/user/selectors";
import { changeEmail, preLogout } from "~/state/user/actions";
import { colors } from "~/styles/theme";
import { useAlerts } from "~/state/alerts";

import { EditInput, Container } from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

interface Props {
  callback: () => void;
}

export const ChangeEmailComponent = ({ callback }: Props) => {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);
  const userEmail = useAppSelector(getUserEmail);
  const isSocialProvider = useAppSelector(getIsSocialProvider);
  const { pushAlert } = useAlerts();

  const [email, setEmail] = React.useState(userEmail);

  const hasDataChanged = email !== userEmail;

  const resetStack = React.useCallback(
    (level = 0) => {
      for (let i = level; i >= 0; i--) {
        callback();
      }
    },
    [callback]
  );

  const logoutUser = React.useCallback(() => {
    if (!isWeb) {
      resetStack(3);
    }

    setTimeout(() => {
      dispatch(preLogout());
    }, 500);
  }, [dispatch, resetStack]);

  const onDonePress = React.useCallback(() => {
    if (hasChanged) {
      logoutUser();
      return;
    }
    if (isSocialProvider) {
      return;
    }
    if (hasDataChanged) {
      setIsSaving(true);

      dispatch(
        changeEmail({
          email,
          onSuccess: () => {
            pushAlert({
              message: messages.emailSaved,
              color: colors.emerald600,
            });

            setIsSaving(false);
            setHasChanged(true);

            if (isWeb) {
              callback();
            }
          },
          onError: (error?: string) => {
            pushAlert({
              message: error || messages.error,
              color: colors.red600,
            });

            setIsSaving(false);
          },
        })
      );

      return;
    }
  }, [
    dispatch,
    email,
    hasDataChanged,
    pushAlert,
    isSocialProvider,
    hasChanged,
    logoutUser,
    callback,
  ]);

  const onCancel = React.useCallback(() => {
    if (hasChanged) {
      logoutUser();
      return;
    }
    callback();
  }, [logoutUser, hasChanged, callback]);

  const Wrapper = isWeb ? Container : Fragment;

  return (
    <Wrapper>
      <ModalHeader
        onLeftPress={onCancel}
        onRightPress={onDonePress}
        title={messages.title}
        rightButtonText={hasDataChanged ? messages.save : undefined}
        isSaving={isSaving}
      />

      <EditInput label={messages.email} value={email} onChangeText={setEmail} />

      <Alerts />
    </Wrapper>
  );
};

export const ChangeEmail = () => {
  const navigation = useNavigation();

  return (
    <SettingsScreen screenName="change-email">
      <ChangeEmailComponent callback={navigation.goBack} />
    </SettingsScreen>
  );
};
