export const messages = {
  placeholder: {
    id: "group-modal.placeholder",
    defaultMessage: "Group Name",
  },
  buttonCreate: {
    id: "group-modal.button-create",
    defaultMessage: "Create Group",
  },
  buttonSave: {
    id: "group-modal.button-save",
    defaultMessage: "Save changes",
  },
  saveSuccess: {
    id: "group-modal.save-success",
    defaultMessage: "The group has been created successfully",
  },
  editSuccess: {
    id: "group-modal.edit-success",
    defaultMessage: "The group has been edited successfully",
  },
  saveError: {
    id: "group-modal.save-error",
    defaultMessage: "There has been an error. Please try again later.",
  },
};
