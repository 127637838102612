import * as React from "react";
import { LessonsContent, LessonSectionOrder } from "~/state/flamelink";

import { LessonOverview } from "./components/lesson-overview";
import { SessionPreparation } from "./components/session-preparation";
import { Game } from "./components/game";
import { BibleLesson } from "./components/bible-lesson";
import { TeachingTime } from "./components/teaching-time";
import { FaithVerseReview } from "./components/faith-verse-review";
import { ActivityPage } from "./components/activity-page";
import { Dismissal } from "./components/dismissal";
import { FaithFact } from "./components/faith-fact";
import { PrayerAndResponse } from "./components/prayer-and-response";
import { GroupConnection } from "./components/group-connection";
import { BibleLessonRecap } from "./components/bible-lesson-recap";
import { GroupDiscussion } from "./components/group-discussion";
import { LiveIt } from "./components/live-it";
import { RulesReview } from "./components/rules-review";
import { PreserviceActivities } from "./components/preservice-activities";
import { Learning } from "./components/learning";
import { Worship } from "./components/worship";
import { BibleTime, BibleTimeContinued } from "./components/bible-time";
import { Application } from "./components/application";
import { CreativeTime } from "./components/creative-time";

interface LessonProps {
  lesson: LessonsContent;
  lessonSectionToRender?: string;
}

export const Lesson: React.FC<LessonProps> = ({
  lesson,
  lessonSectionToRender,
}) => {
  if (!lesson || !lessonSectionToRender) return null;

  switch (lessonSectionToRender) {
    case LessonSectionOrder.LessonOverview:
      return <LessonOverview lesson={lesson} />;
    case LessonSectionOrder.BibleLesson:
      return <BibleLesson bibleLesson={lesson.bibleLesson} />;
    case LessonSectionOrder.FaithFact:
      return <FaithFact faithFact={lesson.faithFactContent} />;
    case LessonSectionOrder.SessionPreparation:
      return (
        <SessionPreparation
          preparingForTheSession={lesson.preparingForTheSession}
        />
      );
    case LessonSectionOrder.PreserviceActivities:
      return (
        <PreserviceActivities
          preserviceActivities={lesson.preserviceActivities}
        />
      );
    case LessonSectionOrder.RulesReview:
      return <RulesReview rulesReview={lesson.rulesReview} />;
    case LessonSectionOrder.Game:
      return <Game game={lesson.game} />;
    case LessonSectionOrder.Worship:
      return <Worship worship={lesson.worship} />;
    case LessonSectionOrder.TeachingTime:
      return <TeachingTime teachingTime={lesson.teachingTime} />;
    case LessonSectionOrder.PrayerResponse:
      return <PrayerAndResponse prayerAndResponse={lesson.prayerAndResponse} />;
    case LessonSectionOrder.GroupConnection:
      return <GroupConnection groupConnection={lesson.groupConnection} />;
    case LessonSectionOrder.BibleLessonRecap:
      return <BibleLessonRecap bibleLessonRecap={lesson.bibleLessonRecap} />;
    case LessonSectionOrder.GroupDiscussion:
      return <GroupDiscussion groupDiscussion={lesson.groupDiscussion} />;
    case LessonSectionOrder.LiveIt:
      return <LiveIt liveIt={lesson.liveIt} />;
    case LessonSectionOrder.FaithVerseReview:
      return <FaithVerseReview faithVerseReview={lesson.faithVerseReview} />;
    case LessonSectionOrder.ActivityPage:
      return <ActivityPage activityPage={lesson.activityPage} />;
    case LessonSectionOrder.Learning:
      return <Learning learning={lesson.learning} />;
    case LessonSectionOrder.BibleTime:
      return <BibleTime bibleTime={lesson.bibleTime} />;
    case LessonSectionOrder.BibleTimeContinued:
      return (
        <BibleTimeContinued bibleTimeContinued={lesson.bibleTimeContinued} />
      );
    case LessonSectionOrder.Dismissal:
      return <Dismissal dismissal={lesson.dismissal} />;
    case LessonSectionOrder.Application:
      return <Application application={lesson.application} />;
    case LessonSectionOrder.CreativeTime:
      return <CreativeTime creativeTime={lesson.creativeTime} />;
    default:
      return null;
  }
};
