import { useActionSheet } from "@expo/react-native-action-sheet";
import { formatMessage } from "~/utils/translation";
import { useAppDispatch } from "~/state/hooks";
import { messages } from "./intl";
import { setEnvironment } from "~/state/user";
import { Environment } from "~/state/user/types";

const options = [
  formatMessage(messages.staging),
  formatMessage(messages.production),
  formatMessage(messages.cancel),
];

export const useChangeEnvironment = () => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch = useAppDispatch();

  const changeEnvironment = () => {
    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      { options, cancelButtonIndex },
      (selectedIndex?: number) => {
        if (selectedIndex === undefined || selectedIndex === cancelButtonIndex)
          return;

        const environment =
          selectedIndex === 0 ? Environment.Staging : Environment.Production;
        dispatch(setEnvironment(environment));
      }
    );
  };

  return { changeEnvironment };
};
