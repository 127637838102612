import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { SectionTitle } from "../../../common";
import { Checklist } from "../../../checklist";
import { Media } from "../../../media";
import { Question } from "../../../question";
import { Prayer } from "../../../prayer";
import { RichText } from "../../../rich-text";
import { ContentIcon } from "../../../content-icon";

interface GroupDiscussionProps {
  groupDiscussion: LessonsContent["groupDiscussion"];
}

export const GroupDiscussion: React.FC<GroupDiscussionProps> = ({
  groupDiscussion,
}) => {
  const hasContent = React.useMemo(
    () =>
      groupDiscussion?.introduction ||
      groupDiscussion?.youWillNeed ||
      groupDiscussion?.gettingReady ||
      groupDiscussion?.questions?.length ||
      groupDiscussion?.content ||
      groupDiscussion?.questionRepeater?.length ||
      groupDiscussion?.conclusion ||
      groupDiscussion?.conclusionMedia?.length ||
      groupDiscussion?.prayerRequests?.length ||
      groupDiscussion?.prayer,
    [groupDiscussion]
  );

  return hasContent ? (
    <>
      {groupDiscussion?.sectionTitle && (
        <SectionTitle>{groupDiscussion.sectionTitle}</SectionTitle>
      )}

      {groupDiscussion?.introduction && (
        <RichText>{groupDiscussion.introduction}</RichText>
      )}

      {groupDiscussion?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={groupDiscussion.youWillNeed}
          checklistId="groupDiscussion-youWillNeed"
        />
      )}

      {groupDiscussion?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={groupDiscussion.gettingReady}
          checklistId="groupDiscussion-gettingReady"
        />
      )}

      {groupDiscussion?.questions?.map((question, index) => (
        <Question key={index} question={question} />
      ))}

      {groupDiscussion?.content && (
        <RichText>{groupDiscussion.content}</RichText>
      )}

      {Array.isArray(groupDiscussion?.questionRepeater) &&
        groupDiscussion?.questionRepeater.map(
          ({ question, uniqueKey, content }, i) => (
            <React.Fragment key={`questionRepeater-${i}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>
            </React.Fragment>
          )
        )}

      {groupDiscussion?.conclusion && (
        <RichText>{groupDiscussion.conclusion}</RichText>
      )}

      {Array.isArray(groupDiscussion?.conclusionMedia) &&
        groupDiscussion?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {groupDiscussion?.prayerRequests && (
        <RichText>{groupDiscussion.prayerRequests}</RichText>
      )}

      {groupDiscussion?.prayer && <Prayer prayer={groupDiscussion.prayer} />}

      <ContentIcon
        content={[
          groupDiscussion?.introduction || "",
          groupDiscussion?.questionRepeater || "",
          groupDiscussion?.conclusion || "",
          groupDiscussion?.prayerRequests || "",
        ]}
      />
    </>
  ) : null;
};
