import * as React from "react";
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { StackNavigationProp } from "@react-navigation/stack";

import * as routes from "~/constants/routes";
import { Login } from "~/screens/login";
import { SignUp } from "~/screens/sign-up";
import { Intro } from "~/screens/intro";
import { ForgotPassword } from "~/screens/forgot-password";
import { ResetPassword } from "~/screens/reset-password";
import { InviteCode } from "~/screens/invite-code";
import { CreateProfile } from "~/screens/create-profile";
import { LocationModal } from "~/screens/location-modal";
import { PrefixModal } from "~/screens/prefix-modal";
import { Auth } from "~/screens/auth";
import { CookiePolicy } from "~/screens/cookie-policy";
import { PayItForwardScreen } from "~/screens/pay-it-forward";

import { useAppSelector } from "~/state/hooks";
import { getUser } from "~/state/user/selectors";

export type PreAuthStackParamList = {
  [routes.locationModal]: {
    onPress: (value: string) => void;
    value: string;
    type: string;
  };
  [routes.prefixModal]: { onPress: (value: string) => void; value: string };
  [routes.auth]: { path: string };
  [routes.intro]: undefined;
  [routes.resetPassword]: { code: string };
};

export type PreAuthNavigationProps = StackNavigationProp<PreAuthStackParamList>;

export type PreAuthViewProps = NativeStackScreenProps<
  PreAuthStackParamList,
  typeof routes.auth
>;

const Stack = createNativeStackNavigator();

export const PreAuthNavigator = React.memo(() => {
  const user = useAppSelector(getUser);
  const initialRoute = user ? routes.createProfile : routes.intro;

  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenOptions={{
        headerShown: false,
      }}
    >
      {!user ? (
        <Stack.Group>
          <Stack.Screen name={routes.login} component={Login} />
          <Stack.Screen name={routes.signUp} component={SignUp} />
          <Stack.Screen name={routes.intro} component={Intro} />
          <Stack.Screen
            name={routes.forgotPassword}
            component={ForgotPassword}
          />
          <Stack.Screen name={routes.resetPassword} component={ResetPassword} />
          <Stack.Screen name={routes.inviteCode} component={InviteCode} />
          <Stack.Screen name={routes.auth} component={Auth} />
          <Stack.Screen name={routes.giving} component={PayItForwardScreen} />
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen name={routes.createProfile} component={CreateProfile} />
          <Stack.Screen name={routes.auth} component={Auth} />
        </Stack.Group>
      )}

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name={routes.locationModal} component={LocationModal} />
        <Stack.Screen name={routes.prefixModal} component={PrefixModal} />
        <Stack.Screen name={routes.cookiePolicy} component={CookiePolicy} />
      </Stack.Group>
    </Stack.Navigator>
  );
});
