export const messages = {
  title: {
    id: "giving.intro.title",
    defaultMessage: "My Giving",
  },
  description: {
    id: "giving.intro.description",
    defaultMessage:
      "Thank you for your generous donation. Your support is invaluable and makes a significant impact on the local church. We are truly grateful for your partnership as we work to get discipleship materials to every church.",
  },
  info: {
    id: "giving.intro.info",
    defaultMessage: "Here's your giving information",
  },
  help: {
    id: "giving.intro.help",
    defaultMessage: "Help",
  },
};
