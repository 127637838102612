import styled from "styled-components/native";

import { Text2Center } from "~/components/text";

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Button = styled.TouchableOpacity``;

export const Text = styled(Text2Center)``;
