import {
  SessionProgress,
  ProgressState,
  SessionProgressCheckConditions,
} from "~/state/content-progress";
import { getSessionConditions } from "~/state/flamelink";
import type { SessionsContent, VolumesContent } from "~/state/flamelink/types";

/**
 * Returns the hierarchy index to be used for the table of contents.
 * Determines which day to open/resume and its indexes.
 */
export function getHierarchyIndexes(
  volumes: VolumesContent[],
  { volumeId, sessionId, days }: SessionProgress,
  {
    shouldOmitFirstDayCheck,
    shouldOmitLastDayCheck,
  }: SessionProgressCheckConditions
) {
  const volumeIndex = volumes.findIndex((volume) => volume.id === volumeId);
  const { sessions } = volumes[volumeIndex];

  const getFirstDayByState = (progressState: ProgressState) => {
    const startIndex = shouldOmitFirstDayCheck ? 1 : 0;
    const endIndex = shouldOmitLastDayCheck ? days.length - 1 : days.length;

    const inProgressIndex = days
      .slice(startIndex, endIndex)
      .reduceRight((acc, { state }, index) => {
        if (acc !== -1) return acc;
        if (state === progressState) return index;
        return acc;
      }, -1);

    return inProgressIndex !== -1 ? inProgressIndex + startIndex : -1;
  };

  const firstDayInProgress = getFirstDayByState(ProgressState.InProgress);
  const firstDayNotStarted = getFirstDayByState(ProgressState.NotStarted);

  return {
    volumeIndex,
    sessionIndex: sessions.findIndex((id) => id === sessionId) ?? 0,
    // If there is a day in progress, use that as the default index
    // Otherwise, use the first day that is not started
    dayIndex:
      firstDayInProgress !== -1 ? firstDayInProgress : firstDayNotStarted,
  };
}

export const getSessionById = (
  sessions: SessionsContent[],
  sessionId: string
) => {
  return sessions.find(({ id }) => id === sessionId);
};

export const getSessionProgressCheckConditions = (
  sessions: SessionsContent[],
  sessionId: string
) => {
  return getSessionConditions(getSessionById(sessions, sessionId));
};
