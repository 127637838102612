import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { messages as sessionMessages } from "~/screens/session/intl";
import { Checklist } from "../../../checklist";
import { SectionTitle, SubsectionSubtitle } from "../../../common";
import { Footnotes } from "../../../footnotes";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { messages } from "./intl";
import { PreparationTime } from "./styles";
import { ContentIcon } from "../../../content-icon";

interface SessionPreparationProps {
  preparingForTheSession: LessonsContent["preparingForTheSession"];
}

export const SessionPreparation: React.FC<SessionPreparationProps> = ({
  preparingForTheSession,
}) => {
  const hasContent = React.useMemo(
    () =>
      preparingForTheSession?.youWillNeed?.largeGroup ||
      preparingForTheSession?.youWillNeed?.smallGroup ||
      preparingForTheSession?.youWillNeed?.smallGroupActivities ||
      preparingForTheSession?.gettingReady?.largeGroup ||
      preparingForTheSession?.gettingReady?.smallGroup ||
      preparingForTheSession?.gettingReady?.smallGroupActivities ||
      preparingForTheSession?.conclusion,
    [preparingForTheSession]
  );

  return hasContent ? (
    <>
      {preparingForTheSession?.sectionTitle && (
        <SectionTitle>{preparingForTheSession.sectionTitle}</SectionTitle>
      )}

      <PreparationTime>
        {preparingForTheSession?.preparationTime || ""}
      </PreparationTime>

      <>
        <SubsectionSubtitle>
          {formatMessage(sessionMessages.youWillNeed)}
        </SubsectionSubtitle>

        {preparingForTheSession?.youWillNeed?.preserviceActivities && (
          <Checklist
            title={formatMessage(messages.preserviceActivities)}
            content={preparingForTheSession.youWillNeed.preserviceActivities}
            checklistId="preparingForTheSession-youWillNeed-preserviceActivities"
          />
        )}

        {preparingForTheSession?.youWillNeed?.largeGroup && (
          <Checklist
            title={formatMessage(messages.largeGroup)}
            content={preparingForTheSession.youWillNeed.largeGroup}
            checklistId="preparingForTheSession-youWillNeed-largeGroup"
          />
        )}

        {preparingForTheSession?.youWillNeed?.smallGroupActivities && (
          <Checklist
            title={formatMessage(messages.smallGroupActivities)}
            content={preparingForTheSession.youWillNeed.smallGroupActivities}
            checklistId="preparingForTheSession-youWillNeed-smallGroupActivities"
          />
        )}

        {preparingForTheSession?.youWillNeed?.smallGroup && (
          <Checklist
            title={formatMessage(messages.smallGroup)}
            content={preparingForTheSession.youWillNeed.smallGroup}
            checklistId="preparingForTheSession-youWillNeed-smallGroup"
          />
        )}

        <Footnotes data={preparingForTheSession?.footnotes} />
      </>

      <>
        <SubsectionSubtitle>
          {formatMessage(sessionMessages.gettingReady)}
        </SubsectionSubtitle>

        {preparingForTheSession?.gettingReady?.preserviceActivities && (
          <Checklist
            title={formatMessage(messages.preserviceActivities)}
            content={preparingForTheSession.gettingReady.preserviceActivities}
            checklistId="preparingForTheSession-gettingReady-preserviceActivities"
          />
        )}

        {preparingForTheSession?.gettingReady?.largeGroup && (
          <Checklist
            title={formatMessage(messages.largeGroup)}
            content={preparingForTheSession.gettingReady.largeGroup}
            checklistId="preparingForTheSession-gettingReady-largeGroup"
          />
        )}

        {preparingForTheSession?.gettingReady?.smallGroupActivities && (
          <Checklist
            title={formatMessage(messages.smallGroupActivities)}
            content={preparingForTheSession.gettingReady.smallGroupActivities}
            checklistId="preparingForTheSession-gettingReady-smallGroupActivities"
          />
        )}

        {preparingForTheSession?.gettingReady?.smallGroup && (
          <Checklist
            title={formatMessage(messages.smallGroup)}
            content={preparingForTheSession.gettingReady.smallGroup}
            checklistId="preparingForTheSession-gettingReady-smallGroup"
          />
        )}
      </>

      {preparingForTheSession?.conclusion && (
        <RichText>{preparingForTheSession?.conclusion}</RichText>
      )}

      {Array.isArray(preparingForTheSession?.facilitatorGuideMedia) &&
        preparingForTheSession?.facilitatorGuideMedia.map((fileId, index) => (
          <Media key={`facilitatorGuideMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon content={[preparingForTheSession?.conclusion || ""]} />
    </>
  ) : null;
};
