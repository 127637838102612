import * as React from "react";

import { useModal } from "~/state/modal/hook.web";
import { Modal } from "~/components/modal/index.web";
import { Option } from "~/components/modal/types";

export const useAlert = () => {
  const { showModal, hideModal } = useModal();

  const showAlert = (
    title: string,
    description: string,
    options?: Option[]
  ) => {
    const node = (
      <Modal
        title={title}
        description={description}
        options={options}
        hideModal={hideModal}
      />
    );
    showModal(node);
  };

  return {
    showAlert,
  };
};

export const Alert = {
  alert: (title: string, description: string, options: Option[]) => {
    const result = window.confirm(
      [title, description].filter(Boolean).join("\n")
    );

    if (result) {
      const confirmOption = options.find(({ style }) => style !== "cancel");
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({ style }) => style === "cancel");
      cancelOption && cancelOption.onPress();
    }
  },
};
