export const messages = {
  deleteGroup: {
    id: "delete-group.delete-group",
    defaultMessage: "Delete group",
  },
  confirmation: {
    id: "delete-group.confirmation",
    defaultMessage: "Are you sure you want to delete this group?",
  },
  cancel: {
    id: "delete-group.cancel",
    defaultMessage: "Cancel",
  },
  delete: {
    id: "delete-group.delete",
    defaultMessage: "Delete",
  },
  success: {
    id: "delete-group.success",
    defaultMessage: "The group has been successfully deleted",
  },
  error: {
    id: "delete-group.error",
    defaultMessage: "There was an error deleting the group",
  },
};
