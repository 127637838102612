import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import { Text2 } from "~/components/text";
import { IconSizes } from "~/components/icon";
import { colors, spacers } from "~/styles/theme";

import type { ChipProps } from "./types";

type ChipStyledTypes = Pick<ChipProps, "selected">;

export const ChipsContainer = styled(LinearGradient).attrs<ChipStyledTypes>(
  ({ selected }) => ({
    start: selected ? { x: 0.2, y: 0.3 } : undefined,
  })
)<ChipStyledTypes>`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: ${spacers.ss4} ${spacers.ss5};
  margin: ${spacers.ss3};
  border-radius: ${spacers.ss6};
  border-color: ${({ selected }) =>
    selected ? colors.transparent : colors.amber600};
  border-width: ${StyleSheet.hairlineWidth}px;
`;

export const ChipLabel = styled(Text2)<ChipStyledTypes>`
  color: ${({ selected }) => (selected ? colors.gray800 : colors.gray800)};
  font-family: Montserrat;
`;

export const ChipCheck = styled(MaterialCommunityIcons).attrs({
  name: "check",
  size: IconSizes.SMedium,
  color: colors.gray500,
})`
  margin: 0 ${spacers.ss3} 0 0;
`;
