import * as React from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { LoginBox } from "~/components/auth-screen/components/login-box";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { SplashContent } from "~/components/splash-screen";

import { Content, ButtonBox } from "./styles";
import { messages } from "./intl";

export const Intro = () => {
  const navigation = useNavigation();

  const navigateToSignUp = React.useCallback(
    // @ts-ignore
    () => navigation.navigate(routes.signUp),
    [navigation]
  );

  return (
    <View style={{ flex: 1 }}>
      <SplashContent isAnimated={false}>
        <Content>
          <ButtonBox>
            <Button
              text={messages.getStarted}
              onPress={navigateToSignUp}
              type={ButtonTypes.Primary}
              isUppercase
            />
            <LoginBox />
          </ButtonBox>
        </Content>
      </SplashContent>
    </View>
  );
};
