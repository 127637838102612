import styled from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";

export const PdfContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  margin: ${spacers.ss5} ${spacers.ss5};
  padding: ${spacers.ss6};
  background-color: ${colors.gray100};
  border-radius: ${spacers.ss6};
  border-color: ${colors.gray500};
  border-width: 0.5px;
`;

export const Preview = styled.Image`
  height: 96px;
  width: 74px;
`;

export const PdfTextContainer = styled.View`
  flex: 1;
  margin: 0 ${spacers.ss5};
`;

export const PdfTitle = styled(Text2)`
  font-family: MontserratBold;
  letter-spacing: 1px;
  margin: ${spacers.ss3} ${spacers.ss3};
  line-height: ${lineHeights.lh2};
  text-transform: uppercase;
  color: ${colors.gray600};
`;

export const PdfSubtitle = styled(Text2)`
  font-family: SFCompact;
  margin: ${spacers.ss3} ${spacers.ss3};
`;
