import { createAction } from "@reduxjs/toolkit";

import {
  CreateGroupPayload,
  EditGroupPayload,
  AddResourcePayload,
  RemoveResourcePayload,
  JoinGroupPayload,
  DeleteGroupPayload,
  RemoveMemberPayload,
  ChangeRolePayload,
  SetLastActivityPayload,
  RefreshInvitecodePayload,
} from "./types";

export const fetchUserGroups = createAction("fetchUserGroups");

export const createGroup = createAction<CreateGroupPayload>("createGroup");

export const editGroup = createAction<EditGroupPayload>("editGroup");

export const addResource = createAction<AddResourcePayload>("addResource");

export const removeResource =
  createAction<RemoveResourcePayload>("removeResource");

export const joinGroup = createAction<JoinGroupPayload>("joinGroup");

export const deleteGroup = createAction<DeleteGroupPayload>("deleteGroup");

export const removeMember = createAction<RemoveMemberPayload>("removeMember");

export const changeRole = createAction<ChangeRolePayload>("changeRole");

export const setLastActivity =
  createAction<SetLastActivityPayload>("setLastActivity");

export const refreshInviteCode =
  createAction<RefreshInvitecodePayload>("refreshInviteCode");
