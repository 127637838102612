import styled from "styled-components/native";

import { Text2, Text3, Text4 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View``;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TotalBox = styled.View`
  padding-vertical: ${spacers.ss5};
  margin-bottom: ${spacers.ss7};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray500};
`;

export const Total = styled(Text4)``;

export const SectionHeaderBox = styled.TouchableOpacity`
  flex-direction: row;
  padding-bottom: ${spacers.ss6};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray200};
  align-items: center;
`;

export const ContentBox = styled.ScrollView`
  flex: 1;
`;

export const LoaderBox = styled.View`
  align-items: center;
  padding-vertical: ${spacers.ss9};
`;

export const SectionHeader = styled(Text4)`
  margin-right: ${spacers.ss4};
`;

export const DataBox = styled.View`
  flex-direction: row;
  padding-vertical: ${spacers.ss6};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray200};
  flex: 1;
`;

export const Date = styled(Text3)`
  width: 70px;
`;

export const Amount = styled(Text3)`
  font-family: SFProDisplayBold;
`;

export const Disclaimer = styled(Text2)`
  color: ${colors.gray400};
  padding-top: ${spacers.ss8};
  padding-bottom: ${spacers.ss6};
  text-align: center;
`;

export const EmptyText = styled(Text3)`
  padding-vertical: ${spacers.ss10};
  text-align: center;
`;
