import * as React from "react";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { colors } from "~/styles/theme";

import {
  Container,
  WebInput,
  WebActionButton,
  TextInput,
  Section,
  TextWraper,
  MetaText,
  MetaTextBold,
} from "./styles";
import { useAppNetInfo } from "~/state/hooks";

import { messages } from "./intl";

interface Props {
  onContentSizeChange: () => void;
  onSubmit: (text: string) => void;
}

export const InputBox = React.memo<Props>(
  ({ onContentSizeChange, onSubmit }) => {
    const [text, setText] = React.useState("");
    const { isConnected } = useAppNetInfo();

    const handleSubmit = React.useCallback(() => {
      onSubmit(text.trim());
      setText("");
    }, [onSubmit, setText, text]);

    const handleKeyDown = (e: any) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
      } else if (e.key === "Enter" && e.shiftKey) {
        setText((prevValue) => prevValue);
      }
    };

    const iconColor =
      !!text && isConnected ? colors.primaryBlue : colors.gray300;

    return (
      <Container>
        <Section>
          <WebInput>
            <TextInput
              value={text}
              onChangeText={setText}
              blurOnSubmit={false}
              onKeyPress={handleKeyDown}
              multiline
              onContentSizeChange={onContentSizeChange}
            />
          </WebInput>
          <TextWraper>
            <MetaTextBold>{messages.shift}</MetaTextBold>
            <MetaText>{messages.newLine}</MetaText>
          </TextWraper>
        </Section>

        <WebActionButton
          onPress={handleSubmit}
          {...((!text?.length || !isConnected) && { disabled: true })}
        >
          <Icon name="send-circle" size={32} color={iconColor} />
        </WebActionButton>
      </Container>
    );
  }
);
