import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";

export const ListContainer = styled.View`
  background-color: ${colors.blue50};
`;

export const WebListContainer = styled.View`
  background-color: ${colors.white};
  border-radius: ${spacers.ss5};
  max-height: 500px;
`;
