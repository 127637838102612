import { Button } from "react-native-paper";
import styled, { css } from "styled-components/native";
import { IconSizes } from "~/components/icon";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { pxToNumber } from "~/utils/theme";

export const Footer = styled.View<{
  hasExtraSpace?: boolean;
}>`
  width: 100%;
  padding-bottom: ${({ hasExtraSpace }) =>
    hasExtraSpace ? spacers.ss4 : spacers.ss1};
  padding-top: ${spacers.ss5};
  background-color: white;
`;

export const NavigationButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NavigationButton = styled(Button)`
  border-radius: 42px;
  min-width: 160px;
`;

const labelStyle = {
  fontSize: IconSizes.Medium,
  color: colors.white,
  marginTop: isWeb ? pxToNumber(spacers.ss3) : undefined,
  paddingVertical: pxToNumber(spacers.ss3),
};

interface BackButtonProps {
  isFirstDay?: boolean;
}

export const BackButton = styled(NavigationButton).attrs<BackButtonProps>(
  (props) => ({
    mode: "outlined",
    icon: "chevron-left",
    disabled: props?.isFirstDay,
    labelStyle: {
      ...labelStyle,
      color: colors.gray700,
    },
  })
)<BackButtonProps>`
  border-color: ${colors.gray700};
  border-width: 1px;
  margin-right: ${spacers.ss4};
  ${(props) =>
    props?.isFirstDay &&
    css`
      opacity: 0.5;
    `}
`;

export const ContinueButton = styled(NavigationButton).attrs({
  icon: "chevron-right",
  mode: "contained",
  color: colors.gray700,
  labelStyle,
  contentStyle: { flexDirection: "row-reverse" },
})`
  margin-left: ${spacers.ss4};
`;

type CompleteButtonProps = {
  isFinishButton: boolean;
};

export const CompleteButton = styled(NavigationButton).attrs({
  icon: "check",
  mode: "contained",
  color: colors.green600,
  labelStyle,
  contentStyle: { flexDirection: "row-reverse" },
})<CompleteButtonProps>`
  margin-left: ${({ isFinishButton }) => (isFinishButton ? spacers.ss4 : 0)};
`;
