import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { Prayer } from "../../../prayer";
import { RichText } from "../../../rich-text";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface DismissalProps {
  dismissal: LessonsContent["dismissal"];
}

export const Dismissal: React.FC<DismissalProps> = ({ dismissal }) => {
  const hasContent = React.useMemo(
    () =>
      dismissal?.introduction ||
      dismissal?.prayer ||
      dismissal?.faithFactMedia?.length ||
      dismissal?.faithFactThought ||
      dismissal?.faithFactRecap,
    [dismissal]
  );

  return hasContent ? (
    <>
      {dismissal?.sectionTitle && (
        <SectionTitle>{dismissal.sectionTitle}</SectionTitle>
      )}

      {dismissal?.introduction && <RichText>{dismissal.introduction}</RichText>}

      {dismissal?.prayer && <Prayer prayer={dismissal.prayer} />}

      {Array.isArray(dismissal?.faithFactMedia) &&
        dismissal?.faithFactMedia.map((fileId, index) => (
          <Media key={`faithFactMedia-${index}`} fileId={fileId} />
        ))}

      {dismissal?.faithFactThought && (
        <Thought thought={dismissal.faithFactThought} />
      )}

      {Array.isArray(dismissal?.faithFactSlideMedia) &&
        dismissal?.faithFactSlideMedia.map((fileId, index) => (
          <Media key={`faithFactSlideMedia-${index}`} fileId={fileId} />
        ))}

      {dismissal?.faithFactRecap && (
        <RichText>{dismissal.faithFactRecap}</RichText>
      )}

      <ContentIcon
        content={[
          dismissal?.introduction || "",
          dismissal?.faithFactRecap || "",
        ]}
      />
    </>
  ) : null;
};
