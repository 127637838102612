import * as React from "react";

import { MenuContext } from "./context.web";

export const useMenu = () => {
  const { showMenu } = React.useContext(MenuContext);

  return {
    showMenu,
  };
};
