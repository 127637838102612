export const messages = {
  title: {
    id: "messages.title",
    defaultMessage: "Messages",
  },
  text: {
    id: "messages.text",
    defaultMessage: "{{senderName}}: {{text}}",
  },
  userText: {
    id: "messages.user-text",
    defaultMessage: "You: {{text}}",
  },
  yesterday: {
    id: "messages.yesterday",
    defaultMessage: "Yesterday",
  },
  noMessages: {
    id: "messages.no-messages",
    defaultMessage: "No messages yet...",
  },
  youDeleted: {
    id: "messages.you-deleted",
    defaultMessage: "You have deleted the message",
  },
  userDeleted: {
    id: "messages.user-deleted",
    defaultMessage: "{{senderName}} has deleted the message",
  },
  youSessionNote: {
    id: "messages.you-session-note",
    defaultMessage: "You have shared a session note",
  },
  userSessionNote: {
    id: "messages.user-session-note",
    defaultMessage: "{{senderName}} has shared a session note",
  },
  youBibleNote: {
    id: "messages.you-bible-note",
    defaultMessage: "You have shared a Bible note",
  },
  userBibleNote: {
    id: "messages.user-bible-note",
    defaultMessage: "{{senderName}} has shared a Bible note",
  },
  youPersonalNote: {
    id: "messages.you-personal-note",
    defaultMessage: "You have shared a personal note",
  },
  userPersonalNote: {
    id: "messages.user-personal-note",
    defaultMessage: "{{senderName}} has shared a personal note",
  },
};
