import React, { useState } from "react";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import "./styles.css";

import { MenuContext } from "./context.web";

interface Props {
  children: React.ReactNode;
}

const MENU_ID = "context-menu";

export const MenuProvider = ({ children }: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [style, setStyle] = useState({});
  const [callbackFn, setCallbackFn] = useState<(selectedIndex: number) => void>(
    () => {}
  );

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const showMenu = (
    opts: string[],
    fn: (selectedIndex: number) => void,
    event: React.MouseEvent,
    position: DOMRect
  ) => {
    const positionProps = position
      ? { position: { x: position.left, y: position.bottom + 8 } }
      : {};

    if (position?.width) {
      setStyle({ width: position.width - 20 });
    }

    setCallbackFn(() => fn);
    setOptions(opts);
    show({
      event,
      ...positionProps,
    });
  };

  const state = {
    showMenu,
  };

  return (
    <MenuContext.Provider value={state}>
      {children}
      <Menu id={MENU_ID}>
        {options.map((option, index) => (
          <Item key={index} onClick={() => callbackFn(index)} style={style}>
            {option}
          </Item>
        ))}
      </Menu>
    </MenuContext.Provider>
  );
};
