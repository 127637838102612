import styled from "styled-components/native";
import { SafeAreaView } from "react-native-safe-area-context";

import { Colors } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";
import { isWeb } from "~/utils/platform";

interface Props {
  backgroundColor: Colors;
  paddingBottom: number;
  paddingTop?: number;
  hasOverflow?: boolean;
}

export const SafeAreaContainer = styled(SafeAreaView)<Props>`
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
  height: 100%;
  width: 100%;
  ${({ hasOverflow }) => (!hasOverflow && isWeb ? "overflow: hidden;" : "")}
`;

export const ViewContainer = styled.View<Props>`
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  ${({ hasOverflow }) => (!hasOverflow && isWeb ? "overflow: hidden;" : "")}
`;

export const ScrollViewContainer = styled.ScrollView<Props>`
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ paddingTop }) =>
    isWeb && paddingTop ? `padding-top: ${paddingTop}px` : ""}
`;

export const KeyboardContainer = styled.KeyboardAvoidingView<Props>`
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
