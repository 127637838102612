import React from "react";
import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import {
  openBrowserAsync,
  WebBrowserPresentationStyle,
} from "expo-web-browser";
import { ModalHeader } from "~/components/modal-header";
import {
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemDivider,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { formatMessage } from "~/utils/translation";
import { makeExternalLinks } from "~/utils/external-links";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

export const HelpAndFeedback = () => {
  const navigation = useNavigation();

  const onDonePress = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  const onPressFAQs = React.useCallback(async () => {
    await openBrowserAsync(makeExternalLinks().FAQS, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  return (
    <SettingsScreen screenName="help-and-feedback">
      {!isWeb ? (
        <ModalHeader
          onLeftPress={onDonePress}
          onRightPress={onDonePress}
          title={messages.title}
        />
      ) : null}

      <SettingsListItemContainer>
        <SettingsListItem
          title={formatMessage(messages.faqs)}
          type="navigate"
          isFirstItem
          isLastItem
          onPress={onPressFAQs}
        />
      </SettingsListItemContainer>

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.contactUs)} />

        <SettingsListItem
          title={formatMessage(messages.helpEmail)}
          isFirstItem
          onPress={() =>
            Linking.openURL(`mailto:${messages.helpEmail.defaultMessage}`)
          }
        />

        <SettingsListItemDivider />

        <SettingsListItem
          title={formatMessage(messages.helpPhone)}
          onPress={() =>
            Linking.openURL(
              `tel:${messages.helpPhone.defaultMessage.replace(/-/g, "")}`
            )
          }
        />

        <SettingsListItemDivider />

        <SettingsListItem
          title={formatMessage(messages.helpPhoneIntl)}
          isLastItem
          onPress={() =>
            Linking.openURL(
              `tel:${messages.helpPhoneIntl.defaultMessage.replace(/-/g, "")}`
            )
          }
        />
      </SettingsListItemContainer>
    </SettingsScreen>
  );
};
