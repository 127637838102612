import { HEADER_BAR_HEIGHT, HEADER_BAR_TOP_OFFSET } from "~/constants";
import { windowHeight, isAndroid } from "~/utils/platform";

export const OPEN_BOTTOM_SHEET_TIMEOUT = 4000;
export const OPEN_BOTTOM_SHEET_TIMEOUT_WEB = 2000;
export const ANIMATION_TIMEOUT_OFFSET = 500;
export const HEADER_TEXT_OVERLAP_OFFSET = 20;
const LAST_SNAP_POINT =
  windowHeight -
  (HEADER_BAR_HEIGHT + HEADER_BAR_TOP_OFFSET + HEADER_TEXT_OVERLAP_OFFSET);

const ANIMATED_LANDING_CONTENT_HEIGHT = isAndroid ? 124 : 118;

export const BOTTOM_SHEET_SNAP_POINTS = [
  LAST_SNAP_POINT - ANIMATED_LANDING_CONTENT_HEIGHT,
  LAST_SNAP_POINT,
];
