import * as React from "react";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  FlamelinkFile,
  getSessionFileIds,
  getSessionIndexBySessionId,
  loadFlamelinkFiles,
} from "~/state/flamelink";

import { Item as DownloadItem } from "~/components/download-item";
import { formatMessage } from "~/utils/translation";
import { downloadFilesAsZip } from "~/utils/zip";

import { messages } from "../intl";

interface SessionDownloadsProps {
  sessionId: string;
}

export const DownloadAllSession = ({ sessionId }: SessionDownloadsProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const sessionFiles = useAppSelector((state) =>
    getSessionFileIds(state, sessionId)
  );
  const sessionIndex = useAppSelector((state) =>
    getSessionIndexBySessionId(state, sessionId)
  );
  const index = sessionIndex + 1;

  const dispatch = useAppDispatch();

  const fileName = formatMessage(messages.downloadSession, {
    index,
  });

  const onPress = React.useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dispatch(
      loadFlamelinkFiles({
        fileIds: sessionFiles,
        onSuccess: (data: FlamelinkFile[]) => {
          downloadFilesAsZip(data, fileName, () => setIsLoading(false));
        },
        onError: () => setIsLoading(false),
      })
    );
  }, [fileName, isLoading, dispatch, sessionFiles]);

  return (
    <DownloadItem
      onPress={onPress}
      leftIcon="file-tray-stacked"
      rightIcon="download"
      isLoading={isLoading}
      title={formatMessage({
        ...messages.downloadSession,
        values: { index },
      })}
      isBold
    />
  );
};
