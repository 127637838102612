import * as React from "react";

import { AlertsState } from "./types";

export const initialState: AlertsState = {
  alerts: [],
  pushAlert: () => {},
  removeAlert: (alertId: string) => {},
};

export const AlertsContext = React.createContext(initialState);
