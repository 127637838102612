import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Description = styled(Text3)`
  line-height: 22px;
  margin-bottom: ${spacers.ss9};
  letter-spacing: 1px;
`;
