import * as React from "react";

import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { TutorialStep } from "~/components/tutorial-step";

import { EmptyGroupImage } from "./group-image";
import { Container, Content, Title, Description, ButtonBox } from "./styles";
import { messages } from "./intl";

interface Props {
  handleCreate: () => void;
  handleJoin: () => void;
}

export const EmptyGroupComponent = React.memo(
  ({ handleCreate, handleJoin }: Props) => {
    return (
      <Container>
        <Content>
          <EmptyGroupImage />

          <Title>{messages.title}</Title>

          <Description>{messages.description}</Description>
        </Content>

        <TutorialStep id="GROUPS.ACTION.CREATE">
          <ButtonBox>
            <Button
              text={messages.buttonCreate}
              onPress={handleCreate}
              iconType="add"
            />
          </ButtonBox>
        </TutorialStep>

        <ButtonBox>
          <Button
            text={messages.buttonJoin}
            onPress={handleJoin}
            iconType="add"
            type={ButtonTypes.Secondary}
          />
        </ButtonBox>
      </Container>
    );
  }
);
