import { StyleSheet } from "react-native";
import { SCALING_FACTORS } from "~/components/text/constants";
import { FontSize } from "~/state/settings";
import { markdownStyles } from "./styles";

export function transformStyles(
  fontScale?: FontSize,
  defaultStyles = markdownStyles
): StyleSheet.NamedStyles<any> {
  const scalingFactor = SCALING_FACTORS[fontScale || FontSize.Medium];

  const pxScaled = (px: number) => px * scalingFactor;

  return {
    ...(defaultStyles as StyleSheet.NamedStyles<any>),
    body: {
      ...defaultStyles.body,
      fontSize: pxScaled(defaultStyles.body.fontSize),
      lineHeight: pxScaled(defaultStyles.body.lineHeight),
    },
    heading3: {
      ...defaultStyles.heading3,
      fontSize: pxScaled(defaultStyles.heading3.fontSize),
      lineHeight: pxScaled(defaultStyles.heading3.lineHeight),
    },
    heading4: {
      ...defaultStyles.heading4,
      fontSize: pxScaled(defaultStyles.heading4.fontSize),
      lineHeight: pxScaled(defaultStyles.heading4.lineHeight),
    },
    superscript: {
      ...defaultStyles.superscript,
      fontSize: pxScaled(defaultStyles.superscript.fontSize),
      lineHeight: pxScaled(defaultStyles.superscript.lineHeight),
    },
  };
}
