import React from "react";
import { GestureResponderEvent } from "react-native";

import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { FontSize, setFontSize, getFontSize } from "~/state/settings";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getOptionText } from "~/utils/options";
import { useActionSheet } from "~/components/action-sheet";

import { messages } from "./intl";

export const useFontSize = () => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch = useAppDispatch();
  const fontSize = useAppSelector(getFontSize);

  const fontOptions = [
    { message: formatMessage(messages.small), type: FontSize.Small },
    { message: formatMessage(messages.medium), type: FontSize.Medium },
    { message: formatMessage(messages.large), type: FontSize.Large },
  ].map(getOptionText<FontSize>(fontSize));

  const selectFontSize = (
    event?: GestureResponderEvent,
    position?: DOMRect
  ) => {
    showActionSheetWithOptions(
      {
        title: formatMessage(messages.title),
        options: [...fontOptions, formatMessage(messages.cancel)],
        cancelButtonIndex: 3,
      },
      (selectedOption?: number) => {
        switch (selectedOption) {
          case 0:
            dispatch(setFontSize(FontSize.Small));
            asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Small });

            break;
          case 1:
            dispatch(setFontSize(FontSize.Medium));
            asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Medium });

            break;
          case 2:
            dispatch(setFontSize(FontSize.Large));
            asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Large });

            break;
          case 3:
            dispatch(setFontSize(undefined));
            asyncLogEvent(events.FONT_SIZE, { fontSize: undefined });

            break;
        }
      },
      // @ts-ignore
      event,
      position
    );
  };

  const selectedFontSize = React.useMemo(() => {
    if (fontSize) {
      const translationKey = `${fontSize}` as keyof typeof messages;

      return formatMessage(messages[translationKey]);
    }

    return formatMessage(messages.medium);
  }, [fontSize]);

  const selectFontSizeIncrementally = React.useCallback(() => {
    if (fontSize === FontSize.Small) {
      dispatch(setFontSize(FontSize.Medium));
      asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Medium });
    } else if (fontSize === FontSize.Medium) {
      dispatch(setFontSize(FontSize.Large));
      asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Large });
    } else {
      dispatch(setFontSize(FontSize.Small));
      asyncLogEvent(events.FONT_SIZE, { fontSize: FontSize.Small });
    }
  }, [dispatch, fontSize]);

  return { selectFontSize, selectFontSizeIncrementally, selectedFontSize };
};
