import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle, SubsectionSubtitle } from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { ContentIcon } from "../../../content-icon";

interface PreserviceActivitiesProps {
  preserviceActivities: LessonsContent["preserviceActivities"];
}

export const PreserviceActivities: React.FC<PreserviceActivitiesProps> = ({
  preserviceActivities,
}) => {
  const hasContent = React.useMemo(
    () =>
      preserviceActivities?.warmUp ||
      preserviceActivities?.snackTime ||
      preserviceActivities?.freePlay ||
      preserviceActivities?.transition,
    [preserviceActivities]
  );

  return hasContent ? (
    <>
      {preserviceActivities?.sectionTitle && (
        <SectionTitle>{preserviceActivities.sectionTitle}</SectionTitle>
      )}

      {preserviceActivities?.warmUp && (
        <RichText>{preserviceActivities.warmUp}</RichText>
      )}

      {Array.isArray(preserviceActivities?.warmUpMedia) &&
        preserviceActivities?.warmUpMedia.map((fileId, index) => (
          <Media key={`warmUpMedia-${index}`} fileId={fileId} />
        ))}

      {preserviceActivities?.snackTime && (
        <SnackTime preserviceActivities={preserviceActivities} />
      )}

      {preserviceActivities?.freePlay && (
        <RichText>{preserviceActivities.freePlay}</RichText>
      )}

      {preserviceActivities?.transition && (
        <RichText>{preserviceActivities.transition}</RichText>
      )}

      <ContentIcon
        content={[
          preserviceActivities?.warmUp || "",
          preserviceActivities?.freePlay || "",
          preserviceActivities?.transition || "",
        ]}
      />
    </>
  ) : null;
};

const SnackTime: React.FC<PreserviceActivitiesProps> = ({
  preserviceActivities,
}) => {
  return (
    <>
      {preserviceActivities?.snackTime?.sectionTitle && (
        <SectionTitle>
          {preserviceActivities.snackTime?.sectionTitle}
        </SectionTitle>
      )}

      {preserviceActivities?.snackTime?.subtitle && (
        <SubsectionSubtitle>
          {preserviceActivities.snackTime?.subtitle}
        </SubsectionSubtitle>
      )}

      {Array.isArray(preserviceActivities?.snackTime?.snackIntroMedia) &&
        preserviceActivities?.snackTime?.snackIntroMedia.map(
          (fileId, index) => (
            <Media key={`snackTime-introMedia-${index}`} fileId={fileId} />
          )
        )}

      {preserviceActivities?.snackTime?.introduction && (
        <RichText>{preserviceActivities.snackTime.introduction}</RichText>
      )}

      {Array.isArray(preserviceActivities?.snackTime?.snackMedia) &&
        preserviceActivities?.snackTime?.snackMedia.map((fileId, index) => (
          <Media key={`snackTime-media-${index}`} fileId={fileId} />
        ))}

      {preserviceActivities?.snackTime?.conclusion && (
        <RichText>{preserviceActivities.snackTime.conclusion}</RichText>
      )}

      <ContentIcon
        content={[
          preserviceActivities?.snackTime?.introduction || "",
          preserviceActivities?.snackTime?.conclusion || "",
        ]}
      />
    </>
  );
};
