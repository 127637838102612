import styled from "styled-components/native";

import { Text1, Text2, Text5 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  padding-top: ${spacers.ss4};
  padding-horizontal: ${spacers.ss8};
  background-color: ${colors.white};
  flex: 1;
`;

export const ManageBox = styled.View`
  border-radius: 8px;
  border-width: 1px;
  border-color: ${colors.gray200};
  padding: ${spacers.ss6};
`;

export const CancelButton = styled.TouchableOpacity`
  align-items: center;
  padding: ${spacers.ss6} ${spacers.ss8};
`;

export const CancelText = styled(Text1)`
  color: ${colors.gray500};
`;

export const Amount = styled(Text5)`
  font-family: SFProDisplayMedium;
  text-align: center;
  margin-bottom: ${spacers.ss4};
  letter-spacing: 0.5px;
`;

export const EditButton = styled.TouchableOpacity`
  align-items: center;
  margin-bottom: ${spacers.ss8};
`;

export const EditText = styled(Text2)`
  color: ${colors.primaryBlue};
  font-family: SFProDisplayMedium;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const EmptyText = styled(Text2)`
  color: ${colors.gray500};
  padding: ${spacers.ss10} ${spacers.ss8};
  text-align: center;
`;
