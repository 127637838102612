import React from "react";
import { Swipeable } from "react-native-gesture-handler";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  DownloadedFile,
  deleteDownload,
  getSessionById,
  getVolumeBySessionId,
} from "~/state/flamelink";
import { useAlert } from "~/components/alert";
import { formatMessage } from "~/utils/translation";

import {
  SwipeableDeleteIconContainer,
  DeleteIcon,
} from "../download-item/styles";
import { DownloadedItem } from "../download-item";

import {
  Container,
  PanelWrapper,
  Panel,
  Title,
  AccordionListIcon,
} from "./styles";
import { messages } from "./intl";

interface Props {
  sessionId?: string;
  data?: DownloadedFile[];
  isActive: boolean;
  setActive: (id: string) => void;
}

export const SectionPanel = ({
  sessionId = "",
  data,
  isActive,
  setActive,
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const swipeableRef = React.useRef<Swipeable>(null);

  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();

  const volume = useAppSelector((state) =>
    getVolumeBySessionId(state, sessionId)
  );

  const session = useAppSelector((state) => getSessionById(state, sessionId));

  const toggleVisibility = React.useCallback(() => {
    setIsVisible(!isVisible);
    setActive(!isVisible ? sessionId : "");
  }, [isVisible, setIsVisible, setActive, sessionId]);

  const onDeletePress = React.useCallback(() => {
    swipeableRef.current?.close();

    showAlert(
      formatMessage(messages.delete),
      formatMessage(messages.deleteConfirmation),
      [
        {
          text: formatMessage(messages.cancel),
          style: "cancel",
        },
        {
          text: formatMessage(messages.delete),
          style: "destructive",
          onPress: () => {
            data?.forEach((item) => {
              dispatch(
                deleteDownload({
                  fileId: item.id,
                  filePath: item.resourceLocation,
                  sessionId: item?.sessionId,
                })
              );
            });
          },
        },
      ]
    );
  }, [data, dispatch, showAlert]);

  const volumeIndex = volume?.volumeNumber;

  const filteredData = data?.filter(
    (item) => !item.contentType?.includes("video")
  );

  const title = {
    ...messages.panelTitle,
    values: { volumeIndex, sessionTitle: session?.title },
  };

  return (
    <Swipeable
      ref={swipeableRef}
      renderRightActions={() => (
        <SwipeableDeleteIconContainer onPress={onDeletePress}>
          <DeleteIcon />
        </SwipeableDeleteIconContainer>
      )}
      overshootRight={false}
    >
      <Container>
        <PanelWrapper>
          <Panel onPress={toggleVisibility} isActive={isActive}>
            <Title>{title}</Title>
            <AccordionListIcon isExpanded={isVisible} />
          </Panel>

          {isVisible
            ? filteredData?.map((item) => (
                <DownloadedItem key={item.id} item={item} isLast isActive />
              ))
            : null}
        </PanelWrapper>
      </Container>
    </Swipeable>
  );
};
