import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LibraryStackParamList } from "~/navigation/library-stack";
import { ModalHeader } from "~/components/modal-header";
import { formatMessage, getCurrentLocale } from "~/utils/translation";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getLanguagesToDisplay, setLanguagesToDisplay } from "~/state/settings";
import { asyncLogEvent, events } from "~/utils/analytics";
import { Screen } from "~/components/screen";
import { isAndroid } from "~/utils/platform";
import {
  getAudienceLibraryFilter,
  getLibraryFiltersOptions,
  getTypeLibraryFilter,
  resetFilters,
  setAllAudienceFilter,
  setAllTypeFilter,
  setAudienceFilter,
  setTypeFilter,
} from "~/state/ui";
import { isShallowPrimitiveArrayEqual } from "~/utils/arrays";
import { Filter, FilterProps } from "./components/filter";
import { messages } from "./intl";
import {
  FiltersContainer,
  ResetFiltersButton,
  ResetFiltersButtonText,
} from "./styles";

export type LibraryFiltersProps = NativeStackScreenProps<
  LibraryStackParamList,
  "library.filters"
>;

interface ComponentProps {
  onGoBack: () => void;
}

export const LibraryFiltersComponent = ({ onGoBack }: ComponentProps) => {
  const dispatch = useAppDispatch();
  const languagesToDisplay = useAppSelector(getLanguagesToDisplay);
  const typeLibraryFilter = useAppSelector(getTypeLibraryFilter);
  const audienceLibraryFilter = useAppSelector(getAudienceLibraryFilter);
  const libraryFiltersOptions = useAppSelector(getLibraryFiltersOptions);
  const currentLocale = getCurrentLocale();

  const onResetFilters = React.useCallback(() => {
    asyncLogEvent(events.SELECT_LIBRARY_FILTER, {
      type: "reset-all",
    });
    dispatch(resetFilters());
  }, [dispatch]);

  const optionsType: FilterProps["options"] = React.useMemo(() => {
    const data = libraryFiltersOptions?.types[currentLocale] || [];
    const isAllChecked = !data.find(
      ({ value }) => !typeLibraryFilter.includes(value)
    );
    return data.map((type) => ({
      title: type.title,
      selected: typeLibraryFilter.includes(type.value),
      value: type.value,
      onPress: () => {
        const value = type.value;
        asyncLogEvent(events.SELECT_LIBRARY_FILTER, { type: "type", value });
        dispatch(setTypeFilter({ value, isAllChecked }));
      },
    }));
  }, [
    currentLocale,
    dispatch,
    libraryFiltersOptions?.types,
    typeLibraryFilter,
  ]);

  const optionsAudiences: FilterProps["options"] = React.useMemo(() => {
    const data = libraryFiltersOptions?.audiences[currentLocale] || [];
    const isAllChecked = !data.find(
      ({ value }) => !audienceLibraryFilter.includes(value)
    );
    return data.map((audience) => ({
      title: audience.title,
      selected: audienceLibraryFilter.includes(audience.value),
      value: audience.value,
      onPress: () => {
        const value = audience.value;
        asyncLogEvent(events.SELECT_LIBRARY_FILTER, {
          type: "audience",
          value,
        });
        dispatch(setAudienceFilter({ value, isAllChecked }));
      },
    }));
  }, [
    libraryFiltersOptions?.audiences,
    currentLocale,
    audienceLibraryFilter,
    dispatch,
  ]);

  const optionsLanguage: FilterProps["options"] = React.useMemo(
    () =>
      (libraryFiltersOptions?.languages[currentLocale] || []).map(
        (language) => ({
          title: language.title,
          selected: isShallowPrimitiveArrayEqual(
            languagesToDisplay,
            language.value
          ),
          value: language.value,
          onPress: () => {
            const value = language.value;
            asyncLogEvent(events.SELECT_LIBRARY_FILTER, {
              type: "language",
              value,
            });
            dispatch(setLanguagesToDisplay(value));
          },
        })
      ),
    [
      currentLocale,
      dispatch,
      languagesToDisplay,
      libraryFiltersOptions?.languages,
    ]
  );

  const resetTypeFilters = React.useCallback(() => {
    dispatch(
      setAllTypeFilter(optionsType.map((option) => option.value as string))
    );
  }, [dispatch, optionsType]);

  const resetAudienceFilters = React.useCallback(() => {
    dispatch(
      setAllAudienceFilter(
        optionsAudiences.map((option) => option.value as string)
      )
    );
  }, [dispatch, optionsAudiences]);

  return (
    <>
      <ModalHeader
        onLeftPress={onGoBack}
        onRightPress={onGoBack}
        title={messages.title}
        hasSeparator
      />

      <FiltersContainer>
        <Filter
          title={formatMessage(messages.language)}
          options={optionsLanguage}
        />

        <Filter
          title={formatMessage(messages.type)}
          options={optionsType}
          onReset={resetTypeFilters}
        />

        <Filter
          title={formatMessage(messages.audience)}
          options={optionsAudiences}
          onReset={resetAudienceFilters}
        />

        <ResetFiltersButton onPress={onResetFilters}>
          <ResetFiltersButtonText>
            {formatMessage(messages.resetFilters)}
          </ResetFiltersButtonText>
        </ResetFiltersButton>
      </FiltersContainer>
    </>
  );
};

export const LibraryFilters: React.FC<LibraryFiltersProps> = () => {
  const navigation = useNavigation<LibraryFiltersProps["navigation"]>();

  return (
    <Screen screenName="library-filters" isView={!isAndroid}>
      <LibraryFiltersComponent onGoBack={navigation.goBack} />
    </Screen>
  );
};
