import * as React from "react";
import { SkeletonContent } from "~/components/skeleton-content";

import {
  Container,
  Content,
  imageSkeletonLayout,
  skeletonStyles,
  contentSkeletonLayout,
  ResourceBox,
} from "./styles";

export const ResourceItemSkeleton = React.memo(() => {
  return (
    <Container>
      <ResourceBox>
        <SkeletonContent
          isLoading
          containerStyle={skeletonStyles.image}
          layout={imageSkeletonLayout}
        />
      </ResourceBox>

      <Content>
        <SkeletonContent
          isLoading
          containerStyle={skeletonStyles.content}
          layout={contentSkeletonLayout}
        />
      </Content>
    </Container>
  );
});
