import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  query,
  collection,
  getDocs,
  DocumentSnapshot,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import NetInfo from "@react-native-community/netinfo";

import { getStatsCollection } from "~/constants/collections";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";

import { getStatsHistory } from "./actions";
import { setStatsHistory } from "./slice";
import { GetStatsHistoryAction, Stats } from "./types";

export function* getStatsHistoryFn({
  payload: { onSuccess, onError },
}: GetStatsHistoryAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    if (onSuccess) yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const collectionName = getStatsCollection(env);
    const statsSnapshots = yield call(
      // @ts-ignore
      getDocs,
      query(collection(database, collectionName))
    );

    const data = statsSnapshots?.docs?.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as Stats[];

    if (data) {
      yield put(setStatsHistory(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const getStatsHistorySaga = function* () {
  yield takeLatest(getStatsHistory.type, getStatsHistoryFn);
};
