import * as React from "react";
import { EmptyGroupImage } from "~/components/empty-group/group-image";

import { NoItemsContainer, NoItemsTitle } from "./styles";

interface NoPlansFoundProps {
  title: string;
}

export const NoPlansFound: React.FC<NoPlansFoundProps> = ({ title }) => {
  return (
    <NoItemsContainer>
      <EmptyGroupImage />

      <NoItemsTitle>{title}</NoItemsTitle>
    </NoItemsContainer>
  );
};
