export const messages = {
  inProgress: {
    id: "library-progress.in-progress",
    defaultMessage: "In Progress",
  },
  completed: {
    id: "library-progress.completed",
    defaultMessage: "Completed",
  },
};
