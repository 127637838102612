import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgGooglePay = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 62 26"
    {...props}
  >
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M28.893 19.788V12.73h3.79c1.552 0 2.862-.5 3.93-1.486l.257-.25a4.944 4.944 0 0 0-.256-7.115c-1.04-1-2.465-1.543-3.932-1.515H26.6v17.425h2.293Zm0-9.199V4.503h3.847c.826 0 1.61.306 2.194.862a3.012 3.012 0 0 1 .071 4.321 3.038 3.038 0 0 1-2.265.903h-3.847Zm18.675-1.792c-.983-.876-2.322-1.32-4.017-1.32-2.18 0-3.818.778-4.9 2.32l2.022 1.237c.741-1.056 1.752-1.584 3.035-1.584.812 0 1.595.292 2.207.82.599.5.94 1.223.94 1.987v.514c-.883-.472-1.994-.723-3.361-.723-1.596 0-2.877.362-3.832 1.098-.954.737-1.439 1.71-1.439 2.946a3.648 3.648 0 0 0 1.354 2.918c.897.778 2.037 1.167 3.376 1.167 1.58 0 2.834-.68 3.789-2.042h.1v1.653h2.193v-7.35c0-1.543-.484-2.766-1.467-3.641Zm-6.225 8.796a1.79 1.79 0 0 1-.755-1.46c0-.652.314-1.194.926-1.625.627-.43 1.41-.653 2.337-.653 1.282-.014 2.28.264 2.992.82 0 .945-.385 1.764-1.14 2.46a3.691 3.691 0 0 1-2.579 1.041 2.848 2.848 0 0 1-1.78-.583Zm12.62 7.434 7.664-17.161h-2.493l-3.547 8.56h-.043l-3.632-8.56H49.42l5.029 11.158-2.85 6.003h2.365Z"
      clipRule="evenodd"
    />
    <Path
      fill="#4285F4"
      d="M20.975 11.2c0-.68-.057-1.36-.17-2.028h-9.673v3.85h5.542a4.628 4.628 0 0 1-2.052 3.042v2.502h3.305c1.937-1.737 3.048-4.308 3.048-7.365Z"
    />
    <Path
      fill="#34A853"
      d="M11.133 20.983c2.763 0 5.1-.889 6.795-2.417l-3.305-2.502c-.926.612-2.108.96-3.49.96-2.678 0-4.943-1.766-5.755-4.128H1.974v2.585c1.738 3.376 5.284 5.502 9.159 5.502Z"
    />
    <Path
      fill="#FBBC04"
      d="M5.378 12.896a5.896 5.896 0 0 1 0-3.835V6.49H1.973a9.702 9.702 0 0 0 0 8.976l3.405-2.57Z"
    />
    <Path
      fill="#EA4335"
      d="M11.133 4.934a5.596 5.596 0 0 1 3.931 1.5L18 3.573A10.04 10.04 0 0 0 11.133.974c-3.875 0-7.421 2.14-9.16 5.516l3.405 2.585c.812-2.376 3.077-4.141 5.755-4.141Z"
    />
  </Svg>
);
export default SvgGooglePay;
