import * as React from "react";

import { Loader } from "../loader";
import { Container, ButtonBox, ButtonText, TitleBox, Title } from "./styles";
import { messages } from "./intl";

interface Props {
  title?: TextType;
  onRightPress?: () => void;
  onLeftPress?: () => void;
  hasSeparator?: boolean;
  rightButtonText?: TextType;
  isSaving?: boolean;
}

export const ModalHeader = React.memo<Props>(
  ({
    title,
    onRightPress,
    onLeftPress,
    hasSeparator,
    rightButtonText = messages.done,
    isSaving,
  }) => {
    const renderRightAction = React.useCallback(() => {
      if (isSaving) return <Loader size={16}/>;

      return (
        onRightPress && <ButtonText isHighlighted>{rightButtonText}</ButtonText>
      );
    }, [isSaving, onRightPress, rightButtonText]);

    return (
      <Container hasSeparator={hasSeparator}>
        <ButtonBox onPress={onLeftPress}>
          {onLeftPress && <ButtonText>{messages.cancel}</ButtonText>}
        </ButtonBox>

        {title ? (
          <TitleBox>
            <Title>{title}</Title>
          </TitleBox>
        ) : null}

        <ButtonBox onPress={onRightPress}>{renderRightAction()}</ButtonBox>
      </Container>
    );
  }
);
