import * as React from "react";
import { formatMessage } from "~/utils/translation";
import { useAddPrayerRequestsMutation } from "~/state/prayer-requests";
import {
  PrayerRequestsInput,
  SavePrayerRequestsButtonText,
  SavePrayerRequestsCancelButton,
  SavePrayerRequestsDivider,
  SavePrayerRequestsDoneButton,
  SavePrayerRequestsHeaderContainer,
  SavePrayerRequestsTitle,
} from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

interface SavePrayerRequestsProps {
  onClose(): void;
  onSuccess(): void;
  sessionId: string;
  prayerRequest?: string;
}

export const SavePrayerRequests: React.FC<SavePrayerRequestsProps> = ({
  onClose,
  onSuccess,
  sessionId,
  prayerRequest,
}) => {
  const [value, onChangeText] = React.useState(prayerRequest ?? "");
  const [addPrayerRequests] = useAddPrayerRequestsMutation();

  const handleOnSubmitEditing = React.useCallback(() => {
    addPrayerRequests({
      sessionId,
      prayerRequests: value,
    });

    onClose();
  }, [addPrayerRequests, onClose, sessionId, value]);

  return (
    <>
      <SavePrayerRequestsHeaderContainer>
        <SavePrayerRequestsCancelButton onPress={onClose}>
          <SavePrayerRequestsButtonText>
            {formatMessage(messages.cancelButton)}
          </SavePrayerRequestsButtonText>
        </SavePrayerRequestsCancelButton>

        <SavePrayerRequestsTitle>
          {formatMessage(messages.title)}
        </SavePrayerRequestsTitle>

        <SavePrayerRequestsDoneButton onPress={handleOnSubmitEditing}>
          <SavePrayerRequestsButtonText>
            {formatMessage(messages.doneButton)}
          </SavePrayerRequestsButtonText>
        </SavePrayerRequestsDoneButton>
      </SavePrayerRequestsHeaderContainer>

      <SavePrayerRequestsDivider />

      <PrayerRequestsInput
        multiline={true}
        numberOfLines={10}
        placeholder={formatMessage(messages.inputPlaceholder)}
        autoFocus
        value={value}
        onChangeText={(text: string) => onChangeText(text)}
        textAlignVertical="top"
        // outline required to be passed inline to work for web
        {...(isWeb ? { style: { outline: "none" } } : {})}
      />
    </>
  );
};
