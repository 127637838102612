import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { messages as sessionMessages } from "~/screens/session/intl";
import { Checklist } from "../../../checklist";
import { RichText } from "../../../rich-text";
import { formatMessage } from "~/utils/translation";
import { SectionTitle } from "../../../common";
import { Prayer } from "../../../prayer";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface LiveItProps {
  liveIt: LessonsContent["liveIt"];
}

export const LiveIt: React.FC<LiveItProps> = ({ liveIt }) => {
  const hasContent = React.useMemo(
    () =>
      liveIt?.youWillNeed ||
      liveIt?.gettingReady ||
      liveIt?.description ||
      liveIt?.thought ||
      liveIt?.prayer,
    [liveIt]
  );

  return hasContent ? (
    <>
      {liveIt?.sectionTitle && (
        <SectionTitle>{liveIt.sectionTitle}</SectionTitle>
      )}

      {liveIt?.introduction && <RichText>{liveIt.introduction}</RichText>}

      {liveIt?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={liveIt.youWillNeed}
          checklistId="liveIt-youWillNeed"
        />
      )}

      {liveIt?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={liveIt.gettingReady}
          checklistId="liveIt-gettingReady"
        />
      )}

      {liveIt?.description && <RichText>{liveIt.description}</RichText>}

      {liveIt?.thought && <Thought thought={liveIt.thought} />}

      {liveIt?.prayer && <Prayer prayer={liveIt.prayer} />}

      <ContentIcon
        content={[liveIt?.introduction || "", liveIt?.description || ""]}
      />
    </>
  ) : null;
};
