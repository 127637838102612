import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { useModal } from "~/state/modal/hook.web";
import { BooksModalComponent } from "~/screens/books-modal";
import { VersionModalComponent } from "~/screens/version-modal";
import { BibleProps } from "~/navigation/bible-stack";

import { BibleHeaderComponent } from "./content";
import { Props } from "./types";

export const BibleHeader = React.memo<Props>(
  ({ book, version, scripture, isCompact, showBackButton, isSession }) => {
    const navigation = useNavigation<BibleProps["navigation"]>();
    const { showModal, hideModal } = useModal();

    const openBooksModal = React.useCallback(() => {
      showModal(<BooksModalComponent onClose={hideModal} />);
    }, [showModal, hideModal]);

    const openVersionModal = React.useCallback(() => {
      showModal(<VersionModalComponent onClose={hideModal} />);
    }, [showModal, hideModal]);

    const handleBackPress = React.useCallback(() => {
      navigation.goBack();
    }, [navigation]);

    return (
      <BibleHeaderComponent
        book={book}
        version={version}
        scripture={scripture}
        isCompact={isCompact}
        showBackButton={showBackButton}
        isSession={isSession}
        openBooksModal={openBooksModal}
        openVersionModal={openVersionModal}
        handleBackPress={handleBackPress}
      />
    );
  }
);
