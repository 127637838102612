import styled from "styled-components/native";
import { TextInput } from "~/components/text-input";
import { spacers } from "~/styles/theme";

export const Container = styled.View`
  padding-bottom: ${spacers.ss8};
`;

export const EditInput = styled(TextInput)`
  margin: ${spacers.ss6} ${spacers.ss6} 0;
`;
