import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FontSize, SettingsState, VideoQuality } from "./types";

export const initialState: SettingsState = {
  languagesToDisplay: [],
  videoQuality: undefined,
  fontSize: FontSize.Medium,
  appLanguage: undefined,
};

export const settingsSlice = createSlice({
  name: "flamelink",
  initialState,
  reducers: {
    setLanguagesToDisplay: (state, action: PayloadAction<string[]>) => {
      state.languagesToDisplay = action.payload;
    },
    setVideoQuality: (
      state,
      action: PayloadAction<VideoQuality | undefined>
    ) => {
      state.videoQuality = action.payload;
    },
    setFontSize: (state, action: PayloadAction<FontSize | undefined>) => {
      state.fontSize = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<string | undefined>) => {
      state.appLanguage = action.payload;
    },
  },
});

export const {
  setLanguagesToDisplay,
  setVideoQuality,
  setFontSize,
  setAppLanguage,
} = settingsSlice.actions;

export default settingsSlice.reducer;
