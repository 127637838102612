import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  flex-direction: row;
  padding-top: ${spacers.ss8};
  padding-bottom: ${spacers.ss6};
`;

export const Button = styled.TouchableOpacity<{ isActive?: boolean }>`
  border-bottom-width: 2px;
  border-bottom-color: ${({ isActive }) =>
    isActive ? colors.primaryBlue : colors.gray300};
  padding: ${spacers.ss4};
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled(Text2)<{ isActive?: boolean }>`
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
  color: ${({ isActive }) => (isActive ? colors.primaryBlue : colors.gray600)};
`;
