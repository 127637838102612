export interface SettingsState {
  languagesToDisplay: string[];
  videoQuality?: VideoQuality;
  fontSize?: FontSize;
  appLanguage?: string;
}

export enum VideoQuality {
  Normal = "normal",
  High = "high",
  UltraHigh = "ultraHigh",
}

export enum FontSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
