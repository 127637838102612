import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dismissAskForFeedback, openAskForFeedback } from "../app-feedback";
import { setIsUserReady } from "./actions";

import { UserState, User, Profile, UserData, Environment } from "./types";

export const initialState: UserState = {
  user: null,
  profile: null,
  emailVerified: false,
  environment: Environment.Production,
  firstLoginDate: null,
  hasUserBeenPromptedForFeedback: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.profile = null;
      state.emailVerified = false;
    },
    setEmailVerified: (state) => {
      state.emailVerified = true;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setProfile: (state, action: PayloadAction<Profile | null>) => {
      state.profile = action.payload;
    },
    setPartialProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      };
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.user = {
        ...state.user,
        email: action.payload,
      };
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      const { user, profile } = action.payload;
      if (user) {
        state.user = user;
      }
      if (profile) {
        state.profile = profile;
      }
      if (user?.emailVerified) {
        state.emailVerified = user.emailVerified;
      }
    },
    setEnvironment: (state, action: PayloadAction<Environment>) => {
      state.environment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setIsUserReady.type, (state) => {
      if (!state.firstLoginDate) {
        state.firstLoginDate = new Date();
      }
    });
    builder.addCase(openAskForFeedback.type, (state) => {
      state.hasUserBeenPromptedForFeedback = true;
    });
    builder.addCase(dismissAskForFeedback.type, (state) => {
      state.hasUserBeenPromptedForFeedback = false;
      // Reset the first login date so that the user will be prompted again
      state.firstLoginDate = new Date();
    });
    builder.addCase(logout.type, (state) => {
      state.hasUserBeenPromptedForFeedback = false;
      state.firstLoginDate = null;
    });
  },
});

export const {
  logout,
  setUser,
  setProfile,
  setPartialProfile,
  setUserData,
  setEmailVerified,
  setEnvironment,
  setUserEmail,
} = userSlice.actions;

export default userSlice.reducer;
