import * as React from "react";
import { ResizeMode, Video as ExpoVideo } from "expo-av";
import { isWeb } from "~/utils/platform";

interface Props {
  width: number;
  height: number;
  uri: string;
  onSize: (size: number) => void;
}

export const InAppVideo = React.memo<Props>(
  ({ width, height, uri, onSize }) => {
    const videoRef = React.useRef(null);

    if (isWeb) {
      return (
        <video
          style={{ width, height }}
          ref={videoRef}
          controls
          onLoadedMetadata={() => {
            const videoElement = videoRef.current;

            if (videoElement) {
              const { videoWidth, videoHeight } = videoElement;
              onSize((width * videoHeight) / videoWidth);
              console.log(`Video dimensions: ${videoWidth}x${videoHeight}`);
            }
          }}
        >
          <source src={uri} type="video/mp4" />
        </video>
      );
    }
    return (
      <ExpoVideo
        style={{ width, height }}
        source={{ uri }}
        resizeMode={ResizeMode.CONTAIN}
        shouldPlay={true}
        useNativeControls
        onError={() => onSize(0)}
        usePoster={false}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        isLooping
        onReadyForDisplay={({ naturalSize }) => {
          if (!naturalSize?.width || !naturalSize?.height) {
            return;
          }
          onSize((width * naturalSize.height) / naturalSize.width);
        }}
      />
    );
  }
);
