export const messages = {
  progress: {
    id: "group-resources.item.progress",
    defaultMessage: "{{progress}}% Curriculum Progress",
  },
  removeTitle: {
    id: "group-resources.item.remove-title",
    defaultMessage: "Remove resource",
  },
  removeDescription: {
    id: "group-resources.item.remove-description",
    defaultMessage:
      "Are you sure you want to remove this resource from the group?",
  },
  resourceRemovedSuccess: {
    id: "group-resources.item.resource-removed-success",
    defaultMessage: "The resource has been successfully removed",
  },
};
