import { SagaIterator } from "redux-saga";
import { call, select, takeLatest } from "redux-saga/effects";

import { handleError } from "~/utils/logger";
import {
  getEnvironment,
  getUserEmail,
  getUserId,
} from "~/state/user/selectors";

import { addAppFeedback } from "./actions";
import { sendAppFeedback } from "./side-effects";
import type { AddAppFeedbackAction } from "./types";

function* handleAddAppFeedback({
  payload,
}: AddAppFeedbackAction): SagaIterator {
  try {
    const userId = yield select(getUserId);
    const email = yield select(getUserEmail);
    const env: ReturnType<typeof getEnvironment> = yield select(getEnvironment);

    yield call(sendAppFeedback, { payload, env, userId, email });
    yield call(payload.onCompleted);
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* appFeedbackSaga() {
  yield takeLatest(addAppFeedback.type, handleAddAppFeedback);
}
