export const messages = {
  title: {
    id: "about.title",
    defaultMessage: "About",
  },
  appInfo: {
    id: "about.app-info",
    defaultMessage: "App Info",
  },
  appVersion: {
    id: "about.app-version",
    defaultMessage: "Version {{version}}",
  },
  termsAndConditions: {
    id: "about.terms-and-conditions",
    defaultMessage: "Terms and Conditions",
  },
  privacyPolicy: {
    id: "about.privacy-policy",
    defaultMessage: "Privacy Policy",
  },
  legal: {
    id: "about.legal",
    defaultMessage: "Legal",
  },
  marketing: {
    id: "about.marketing-link",
    defaultMessage: "For more information, visit BibleEngagementProject.com"
  },
  bibleEngagementProject: {
    id: "about.bible-engagement-project",
    defaultMessage: "About Bible Engagement Project",
  },
  bibleEngagementProjectDescription: {
    id: "about.bible-engagement-project-description",
    defaultMessage:
    "Bible Engagement Project exists to equip every church with access to quality discipleship resources regardless of size, location, or budget. We offer free digital curriculum for preschool, kids, youth, and adults that is age-aligned to help your whole church engage with the Bible.\n\nWith a curriculum library developed and created by My Healthy Church, publishing arm of the Assemblies of God, we are committed to seeing an increasingly Bible-engaged world.\n",
  }
};
