import * as React from "react";
import { Linking } from "react-native";
import { WebBrowserPresentationStyle, openBrowserAsync } from "expo-web-browser";

import { CONTRIBUTIONS_EMAIL, CONTRIBUTIONS_PHONE } from "~/constants";
import { makeExternalLinks } from "~/utils/external-links";

import {
  Container,
  TextRow,
  TextRegular,
  TextBold,
  TextLink,
  TextLinkBold,
} from "./styles";
import { messages } from "./intl";

export const Footer = React.memo(() => {
  const onCall = React.useCallback(() => {
    Linking.openURL(`tel://${CONTRIBUTIONS_PHONE}`);
  }, []);

  const onEmail = React.useCallback(() => {
    Linking.openURL(`mailto:${CONTRIBUTIONS_EMAIL}`);
  }, []);

  const onPressFAQs = React.useCallback(async () => {
    await openBrowserAsync(makeExternalLinks().FAQS, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  return (
    <Container>
      <TextRow>
        <TextBold>{messages.needHelp}</TextBold>{" "}
        <TextRegular>{messages.view}</TextRegular>{" "}
        <TextLinkBold onPress={onPressFAQs}>{messages.helpTopics}</TextLinkBold>
      </TextRow>
      <TextRow>
        <TextRegular>{messages.call}</TextRegular>{" "}
        <TextLinkBold onPress={onCall}>{CONTRIBUTIONS_PHONE}</TextLinkBold>,{" "}
        <TextRegular>{messages.orEmail}</TextRegular>{" "}
        <TextLink onPress={onEmail}>{CONTRIBUTIONS_EMAIL}</TextLink>
      </TextRow>
    </Container>
  );
});
