import styled from "styled-components/native";

import { Text5 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.View`
  padding-top: ${spacers.ss5};
  padding-bottom: ${spacers.ss9};
`;

export const Message = styled(Text5)``;
