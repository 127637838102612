export const messages = {
  messageTitle: {
    id: "content.message-title",
    defaultMessage: "Message Title",
  },
  messageBody: {
    id: "content.message-body",
    defaultMessage: "Message Body",
  },
  schedule: {
    id: "content.schedule",
    defaultMessage: "Send at",
  },
  emails: {
    id: "content.emails",
    defaultMessage: "E-mails",
  },
  disclaimer: {
    id: "content.disclaimer",
    defaultMessage:
      "Optional, comma-separated list of e-mails. If left empty the announcement will be sent to all the users",
  },
  enabled: {
    id: "content.enabled",
    defaultMessage: "Enabled?",
  },
  language: {
    id: "content.language",
    defaultMessage: "Language",
  },
};
