import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ChecklistState, ToggleChecklistItemPayload } from "./types";

export const initialState: ChecklistState = {
  checklists: {},
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    toggleChecklistItem(
      state,
      action: PayloadAction<ToggleChecklistItemPayload>
    ) {
      const { parentId, checklistId, itemId } = action.payload;
      const checklist = state.checklists?.[parentId]?.[checklistId];

      if (!state.checklists[parentId]) {
        state.checklists[parentId] = {};
      }

      if (!checklist) {
        state.checklists[parentId][checklistId] = {
          items: [{ id: itemId, checked: true }],
        };

        return;
      }

      const item = checklist.items.find((listItem) => listItem.id === itemId);

      if (!item) {
        checklist.items = [...checklist.items, { id: itemId, checked: true }];

        return;
      }

      checklist.items = [
        ...checklist.items.filter((listItem) => listItem.id !== itemId),
        { id: itemId, checked: !item.checked },
      ];
    },

    removeChecklistByParentId(state, action: PayloadAction<string>) {
      delete state.checklists[action.payload];
    },
  },
});

export const { toggleChecklistItem, removeChecklistByParentId } =
  checklistSlice.actions;
