import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";

import { IconSizes } from "~/components/icon";
import { Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const GeneralStatsWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${spacers.ss5} ${spacers.ss4} ${spacers.ss5};
`;

export const GeneralStatsText = styled(Text3)`
  font-family: SFCompact;
`;

export const MoreIcon = styled(MaterialIcons).attrs({
  name: "more-horiz",
  size: IconSizes.Medium,
  color: colors.black,
})``;

export const DownloadsList = styled.SectionList<{
  extraMarginBottom?: number;
}>`
  padding: 0 ${spacers.ss5};
  margin: 0 0 ${({ extraMarginBottom }) => extraMarginBottom ?? 0}px;
`;
