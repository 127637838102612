import * as React from "react";
import { Animated } from "react-native";

import { Container, Bar, AnimatedBar } from "./styles";

interface Props {
  progress: number;
  isCompleted?: boolean;
}

export const ProgressBar = React.memo<Props>(
  ({ progress = 0, isCompleted }) => {
    const width = isCompleted ? 100 : Math.min(100, Math.max(0, progress));
    return (
      <Container>
        <Bar width={width} isCompleted={isCompleted} />
      </Container>
    );
  }
);

export const AnimatedProgressBar = React.memo<Props>(({ progress = 0 }) => {
  const widthAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(widthAnim, {
      toValue: progress,
      useNativeDriver: false,
    }).start();
  }, [widthAnim, progress]);

  return (
    <Container>
      <AnimatedBar
        style={{
          width: widthAnim.interpolate({
            inputRange: [0, 100],
            outputRange: ["0%", "100%"],
          }),
        }}
      />
    </Container>
  );
});
