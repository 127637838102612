import React from "react";
import { ActivityIndicator } from "react-native-paper";
import { colors } from "~/styles/theme";
import { LoaderContainer, Text } from "./styles";

interface LoaderProps {
  fullScreen?: boolean;
  size?: number;
  text?: TextType;
}

export function Loader({ fullScreen, text, size = 32 }: LoaderProps) {
  if (!fullScreen) return <ActivityIndicator size={size} />;

  return (
    <LoaderContainer>
      <ActivityIndicator animating color={colors.primaryBlue} size={size} />
      {text ? <Text>{text}</Text> : null}
    </LoaderContainer>
  );
}
