export const messages = {
  preparingForTheSession: {
    id: "preparing-for-the-session.title",
    defaultMessage: "Preparing for the Session",
  },
  preserviceActivities: {
    id: "preparing-for-the-session.preservice-activities",
    defaultMessage: "Preservice Activities",
  },
  largeGroup: {
    id: "preparing-for-the-session.large-group",
    defaultMessage: "Large Group",
  },
  smallGroup: {
    id: "preparing-for-the-session.small-group",
    defaultMessage: "Small Group",
  },
  smallGroupActivities: {
    id: "preparing-for-the-session.small-group-activities",
    defaultMessage: "Small Group Activities",
  },
};
