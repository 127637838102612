import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { Text2, Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const SavePrayerRequestsActionContainer = styled.TouchableOpacity`
  margin: 0 ${spacers.ss5};
  flex-direction: row;
  align-items: center;
`;

export const SavePrayerRequestsActionText = styled(Text3)`
  font-family: SFPro;
  padding: ${spacers.ss5} ${spacers.ss4};
  color: ${colors.primaryBlue};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
`;

export const webModalContainerStyles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: pxToNumber(spacers.ss5),
  },
}).container;
