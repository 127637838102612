export const messages = {
  title: {
    id: "share-modal.title",
    defaultMessage: "Share with others",
  },
  linkMessage: {
    id: "share-modal.link-message",
    defaultMessage: "Link has been copied!",
  },
};
