import * as React from "react";

import { getScriptureText } from "~/utils/bible";

import { TextWrapper, VerseNumber, VerseText, VerseBox } from "./styles";
import { messages } from "./intl";
import { NoteViewEdit, SavePayload } from "~/components/note-view-edit";
import { MessageType } from "~/state/chat/types";
import { Verse } from "~/state/bible/types";
import { useAppDispatch } from "~/state/hooks";
import { asyncLogEvent, events } from "~/utils/analytics";
import { addBibleNote } from "~/state/bible-notes";
import { genericMessages } from "~/constants/intl";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { getVerseNumber, getVerseNumbers } from "./utils";

interface Props {
  bookId: string;
  chapterId: string;
  selectedVerses: Verse[];
  isCreateMode?: boolean;
  lastUpdated?: number;
  text?: string;
  onSave: (data: SavePayload) => void;
  noteId?: string;
  onDelete?: () => void;
  hideActions?: boolean;
}

interface AddProps {
  bookId: string;
  chapterId: string;
  selectedVerses: Verse[];
  onSuccess: () => void;
}

export const BibleNotesView = React.memo<Props>(
  ({
    bookId,
    chapterId,
    selectedVerses = [],
    isCreateMode,
    lastUpdated,
    text = "",
    onSave,
    onDelete,
    hideActions,
  }) => {
    const verseNumbers = getVerseNumbers(selectedVerses, bookId);
    const title = getScriptureText(bookId, chapterId, verseNumbers);
    const backButtonText = isCreateMode
      ? genericMessages.back
      : messages.backButton;

    return (
      <NoteViewEdit
        screenName="bible-note-view"
        title={title}
        text={text}
        isCreateMode={isCreateMode}
        lastUpdated={lastUpdated}
        backButtonText={backButtonText}
        type={MessageType.NoteBible}
        onSave={onSave}
        onDelete={onDelete}
        data={{
          title,
          verses: selectedVerses.slice(0, 3),
        }}
        hideActions={hideActions}
      >
        <VerseBox>
          <TextWrapper numberOfLines={6}>
            {selectedVerses.map(({ verseId, content }, index) => {
              const id = getVerseNumber(verseId, bookId);
              const numberText = index === 0 ? `${id} ` : ` ${id} `;
              return [
                <VerseNumber key={id}>{numberText}</VerseNumber>,
                <VerseText key={`verse-${id}`}>{content}</VerseText>,
              ];
            })}
          </TextWrapper>
        </VerseBox>
      </NoteViewEdit>
    );
  }
);

export const BibleNoteAddComponent = React.memo<AddProps>(
  ({ bookId, chapterId, selectedVerses = [], onSuccess }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const { pushAlert } = useAlerts();

    const onSave = React.useCallback(
      ({ value: text }: SavePayload) => {
        if (isLoading) {
          return;
        }

        if (!text) {
          onSuccess();
          return;
        }

        setIsLoading(true);
        dispatch(
          addBibleNote({
            bookId,
            chapterId,
            verses: selectedVerses,
            text,
            onSuccess: () => {
              asyncLogEvent(events.BIBLE_NOTE_ADD, { bookId, chapterId });
              pushAlert({
                message: genericMessages.saveSuccess,
                color: colors.emerald600,
              });
              setIsLoading(false);
              onSuccess();
            },
            onError: () => {
              pushAlert({ message: genericMessages.error });
              setIsLoading(false);
            },
          })
        );
      },
      [
        dispatch,
        pushAlert,
        bookId,
        chapterId,
        selectedVerses,
        isLoading,
        onSuccess,
      ]
    );

    return (
      <BibleNotesView
        bookId={bookId}
        chapterId={chapterId}
        selectedVerses={selectedVerses}
        onSave={onSave}
        isCreateMode
      />
    );
  }
);
