export const messages = {
  panelTitle: {
    id: "section-title.panel-title",
    defaultMessage: "Volume {{volumeIndex}}, {{sessionTitle}}",
  },
  delete: {
    id: "downloaded-items.delete",
    defaultMessage: "Delete",
  },
  cancel: {
    id: "downloaded-items.cancel",
    defaultMessage: "Cancel",
  },
  deleteConfirmation: {
    id: "downloaded-items.delete-confirmation",
    defaultMessage: "Are you sure you want to delete the session files?",
  },
};
