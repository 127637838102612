import React from "react";
import axios from "axios";
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
  loadStripe,
  Stripe,
} from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { store } from "~/state/store";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getPaymentMethodsAction } from "~/state/payments/actions";
import { getStripeProps, getApiHeaders } from "~/state/payments/utils";

import {
  getEnvironment,
  getUserEmail,
  getUserFullName,
} from "~/state/user/selectors";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { AccountHeader } from "../../../header";
import { Container, Title } from "../../styles";
import { Screens } from "../../../../types";

import { MethodBox } from "./styles";
import { messages } from "./intl";
import { WEB_APP_URL } from "~/constants";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

export const PaymentAddComponent = React.memo<Props>(({ setScreen }) => {
  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();
  const stripe = useStripe();
  const elements = useElements();

  const env = useAppSelector(getEnvironment);
  const name = useAppSelector(getUserFullName);
  const email = useAppSelector(getUserEmail);
  const [isValidCard, setIsValidCard] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleError = React.useCallback(() => {
    pushAlert({ message: messages.error, color: colors.red500 });
  }, [pushAlert]);

  const billing_details = React.useMemo(
    () => ({
      name,
      email,
    }),
    [name, email]
  );

  const onSave = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const data = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement) as StripeCardElement,
        billing_details,
      });

      const paymentMethodId = data?.paymentMethod?.id;
      if (!paymentMethodId) {
        handleError();
        setIsSaving(false);
        return;
      }

      const headers = await getApiHeaders();
      const response = await axios.post(
        `${WEB_APP_URL}/api/payments/setup-intent-${env}`,
        {
          methodId: paymentMethodId,
        },
        headers
      );

      const setupIntentSecret = response?.data?.data?.client_secret;

      if (!setupIntentSecret) {
        throw new Error("Client secret is missing");
      }

      const confirmIntent = await stripe?.confirmCardSetup(setupIntentSecret, {
        payment_method: {
          card: elements?.getElement(CardElement) as StripeCardElement,
          billing_details,
        },
      });

      if (confirmIntent?.setupIntent?.status === "succeeded") {
        dispatch(
          getPaymentMethodsAction({
            onSuccess: () => {
              pushAlert({
                message: messages.confirm,
                color: colors.emerald600,
              });
              setScreen(Screens.PaymentMethod);
              setIsSaving(false);
            },
            onError: () => setIsSaving(false),
          })
        );
      } else {
        handleError();
      }
    } catch (e) {
      handleError();
      setIsSaving(false);
    }
  }, [
    dispatch,
    setScreen,
    env,
    handleError,
    pushAlert,
    elements,
    stripe,
    billing_details,
  ]);

  const handleOnChange = React.useCallback(
    ({ complete }: StripeCardElementChangeEvent) => {
      setIsValidCard(complete);
    },
    []
  );

  return (
    <Container>
      <AccountHeader
        onBackPress={() => setScreen(Screens.PaymentMethod)}
        onSave={onSave}
        isSaveDisabled={!isValidCard}
        isLoading={isSaving}
      />

      <Title>{messages.title}</Title>

      <MethodBox>
        <CardElement onChange={handleOnChange} />
      </MethodBox>
    </Container>
  );
});

export const PaymentAdd = React.memo<Props>(({ onBackPress, setScreen }) => {
  let stripePromise = null;
  // Avoid ecreating the Stripe object on every render.
  if (!stripePromise) {
    stripePromise = loadStripe(getStripeProps(store.getState()).publishableKey);
  }

  if (!stripePromise) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <PaymentAddComponent onBackPress={onBackPress} setScreen={setScreen} />
    </Elements>
  );
});
