export const messages = {
  title: {
    id: "share-sheet.title",
    defaultMessage: "Share with a group",
  },
  button: {
    id: "share-sheet.button",
    defaultMessage: "Share note",
  },
  success: {
    id: "share-sheet.success",
    defaultMessage: "Note has been successfully shared with the group",
  },
  shareOther: {
    id: "share-sheet.button-other",
    defaultMessage: "Other sharing options",
  },
};
