import { IconButton } from "react-native-paper";
import styled from "styled-components/native";
import { IconSizes } from "~/components/icon";
import { Text2Center } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const LibraryHeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${spacers.ss6} ${spacers.ss3};
`;

export const SearchIcon = styled(IconButton).attrs({
  icon: "magnify",
  size: IconSizes.Large,
})`
  margin: 0;
`;

export const FilterContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const FilterCounterContainer = styled.TouchableOpacity`
  position: absolute;
  left: 50px;
  height: 24px;
  width: 24px;
  border-radius: ${spacers.ss3};
  background-color: ${colors.indigo600};
  padding: ${spacers.ss3};
`;

export const FilterCounter = styled(Text2Center)`
  color: ${colors.white};
`;

export const ModalBox = styled.View`
  min-width: 600px;
  max-height: 600px;
`;
