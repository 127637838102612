import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { RemoteConfig } from "./types";

const getState = (state: RootState) => state.config;

export const getRemoteConfig: (state: RootState) => RemoteConfig =
  createSelector(getState, (state) => state.remote || {});

export const getRemoteConfigValue: (state: RootState, key: string) => boolean =
  createSelector(
    [getRemoteConfig, (_, props) => props],
    (config, key) => config[key] || false
  );
