import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Swipeable } from "react-native-gesture-handler";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import {
  getIsSessionPublished,
  getSessionById,
} from "~/state/flamelink/selectors";
import {
  ProgressHierarchyIds,
  ProgressState,
  SessionProgress,
  useAddSessionProgressMutation,
  useResetSessionProgressMutation,
  useUpdateSessionProgressMutation,
} from "~/state/content-progress";
import {
  getShouldShowMoreButtonAnimation,
  incrementMoreButtonAnimationCount,
} from "~/state/ui";
import { useLibrary } from "~/state/library";
import { isWeb } from "~/utils/platform";
import * as routes from "~/constants/routes";
import {
  getIsSessionDownloaded,
  getIsVolumeDownloaded,
  getSessionItemsLength,
  getVolumeAdditionalFileIds,
} from "~/state/flamelink";
import { DownloadProgressBar } from "~/components/download-progress-bar";
import { SessionDownloads } from "../session-downloads";
import { colors } from "~/styles/theme";
import { asyncLogEvent, events } from "~/utils/analytics";

import {
  AccordionList,
  AccordionListIcon,
  AccordionListIconContainer,
  AccordionListItemIcon,
  ActionButtonsContainer,
} from "../../styles";
import {
  SessionTitle,
  SessionTitleWrapper,
  SessionTitleSubWrapper,
  DownloadedIconContainer,
  ListWrapper,
  HeaderWrapper,
} from "./styles";
import { ProgressIcon } from "../progress-icon";
import type { PlanProps } from "../..";
import { SessionItems } from "../session-items";
import { messages } from "./intl";
import { ContentMode } from "../../types";
import { DownloadAll } from "../session-downloads/download-all";

const BOUNCE_EFFECT_TIMEOUT = 500;

interface VolumeSessionsProps
  extends Pick<ProgressHierarchyIds, "planId" | "volumeId"> {
  audienceId: string;
  sessions: string[];
  volumeIndex: number;
  progressData: [] | SessionProgress[];
  isInitializingProgress: boolean;
  setIsInitializingProgress: React.Dispatch<React.SetStateAction<boolean>>;
  contentMode: ContentMode;
}

export const VolumeSessions: React.FC<VolumeSessionsProps> = React.memo(
  ({ sessions, volumeIndex, volumeId, contentMode, ...rest }) => {
    const additionalFiles = useAppSelector((state) =>
      getVolumeAdditionalFileIds(state, volumeId)
    );
    const isVolumeDownloaded = useAppSelector((state) =>
      getIsVolumeDownloaded(state, volumeId)
    );

    const showAdditionalFiles =
      contentMode === ContentMode.Downloads && additionalFiles.length;
    const showDownloadAll = !isVolumeDownloaded;

    if (!sessions.length) return null;

    return (
      <>
        {sessions.map((sessionId, sessionIndex) => (
          <VolumeSession
            sessionId={sessionId}
            sessionIndex={sessionIndex}
            volumeIndex={volumeIndex}
            volumeId={volumeId}
            contentMode={contentMode}
            key={`session-${volumeIndex}-${sessionIndex}`}
            {...rest}
          />
        ))}
        {showAdditionalFiles ? (
          <AdditionalFiles
            volumeIndex={volumeIndex}
            progressData={rest.progressData}
            volumeId={volumeId}
            audienceId={rest.audienceId}
          />
        ) : null}
        {showDownloadAll ? (
          <DownloadAll volumeIndex={volumeIndex} volumeId={volumeId} />
        ) : null}
      </>
    );
  }
);

interface VolumeSessionProps
  extends Pick<
    VolumeSessionsProps,
    | "planId"
    | "volumeId"
    | "audienceId"
    | "progressData"
    | "isInitializingProgress"
    | "setIsInitializingProgress"
  > {
  volumeIndex: number;
  sessionIndex: number;
  sessionId: string;
  contentMode: ContentMode;
}

const VolumeSession: React.FC<VolumeSessionProps> = React.memo(
  ({
    volumeIndex,
    sessionIndex,
    progressData,
    sessionId,
    volumeId,
    audienceId,
    planId,
    isInitializingProgress,
    setIsInitializingProgress,
    contentMode,
    ...rest
  }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isBlinking, setIsBlinking] = React.useState(false);
    const [downloadingProgress, setDownloadingProgress] =
      React.useState<Record<string, number>>();
    const swipeableRef = React.useRef<Swipeable>(null);

    const navigation = useNavigation<PlanProps["navigation"]>();
    const dispatch = useAppDispatch();
    const { planProgressId, setPlanProgressId } = useLibrary();
    const { isConnected } = useAppNetInfo();
    const [resetSessionProgress, { isLoading }] =
      useResetSessionProgressMutation();
    const [addSessionProgress] = useAddSessionProgressMutation();
    const [updateSessionProgress] = useUpdateSessionProgressMutation();

    const shouldShowMoreButtonAnimation = useAppSelector(
      getShouldShowMoreButtonAnimation
    );
    const isSessionDownloaded = useAppSelector((state) =>
      getIsSessionDownloaded(state, sessionId)
    );
    const sessionData = useAppSelector((state) =>
      getSessionById(state, sessionId)
    );
    const isSessionPublished = useAppSelector((state) =>
      getIsSessionPublished(state, sessionId, planId)
    );

    const sessionItemsLength = React.useMemo(
      () => getSessionItemsLength(sessionData),
      [sessionData]
    );

    const hasNoChildItems = React.useMemo(
      () =>
        sessionData?.days?.length === 0 &&
        sessionData?.lessons?.length === 0 &&
        sessionData?.sessions?.length === 0,
      [sessionData]
    );

    const sessionProgressData = progressData.find(
      (data) => sessionId === data.sessionId
    );

    const calculatedProgress = React.useMemo(() => {
      if (!downloadingProgress) return 0;

      const values = Object.values(downloadingProgress);
      const numberOfItems = values.length;
      const totalProgress = values.reduce((acc, item) => acc + item, 0);

      return totalProgress / numberOfItems;
    }, [downloadingProgress]);

    const isDownloadMode = contentMode === ContentMode.Downloads;

    const onExpandSession = React.useCallback(
      () => setIsExpanded((expanded) => !expanded),
      []
    );

    const openSessionDirectly = React.useCallback(async () => {
      // prevents creating multiple records in case of a user tapping multiple times
      if (isInitializingProgress) return;

      asyncLogEvent(events.LIBRARY_OPEN_SESSION, {
        planId,
        sessionId,
        audienceId,
        sessionIndex,
        volumeIndex,
        dayIndex: 0,
      });

      navigation.navigate(routes.session, {
        planId,
        sessionId,
        sessionIndex,
        volumeIndex,
        dayIndex: 0,
      });

      // we create a new record if there is none associated to the selected session
      if (!sessionProgressData?.sessionProgressId) {
        const result = await addSessionProgress({
          planId,
          volumeId,
          sessionId,
          dayKey: "day0",
          totalDays: 0,
          planProgressId,
        });

        if ("data" in result) {
          setPlanProgressId(result.data);
        }
      } else {
        await updateSessionProgress({
          dayKey: "day0",
          sessionProgressId: sessionProgressData?.sessionProgressId,
        });
      }
    }, [
      isInitializingProgress,
      navigation,
      planId,
      sessionId,
      audienceId,
      sessionIndex,
      volumeIndex,
      sessionProgressData?.sessionProgressId,
      addSessionProgress,
      volumeId,
      planProgressId,
      setPlanProgressId,
      updateSessionProgress,
    ]);

    const onPress = React.useCallback(() => {
      if (!isConnected && !isSessionDownloaded) return;

      if (hasNoChildItems && !isDownloadMode) {
        openSessionDirectly();

        return;
      }

      onExpandSession();
    }, [
      hasNoChildItems,
      isConnected,
      isSessionDownloaded,
      onExpandSession,
      openSessionDirectly,
      isDownloadMode,
    ]);

    const handleSessionUpdate = React.useCallback(
      async (newState: ProgressState) => {
        if (sessionProgressData?.sessionProgressId) {
          await resetSessionProgress({
            sessionProgressId: sessionProgressData.sessionProgressId,
            newState,
          });

          return;
        }

        // prevents creating multiple records in case of a user tapping multiple times
        if (isInitializingProgress) return;

        !planProgressId && setIsInitializingProgress(true);

        const result = await addSessionProgress({
          planId,
          volumeId,
          sessionId,
          initialState: newState,
          totalDays: sessionItemsLength,
          planProgressId,
        });

        if ("data" in result) {
          setPlanProgressId(result.data);
        }

        setIsInitializingProgress(false);
      },
      [
        sessionProgressData?.sessionProgressId,
        isInitializingProgress,
        setIsInitializingProgress,
        addSessionProgress,
        planId,
        volumeId,
        sessionId,
        sessionItemsLength,
        planProgressId,
        resetSessionProgress,
        setPlanProgressId,
      ]
    );

    const onProgressIconPress = React.useCallback(() => {
      const sessionProgressState =
        sessionProgressData?.state ?? ProgressState.NotStarted;

      switch (sessionProgressState) {
        case ProgressState.NotStarted:
          handleSessionUpdate(ProgressState.InProgress);
          break;
        case ProgressState.InProgress:
          handleSessionUpdate(ProgressState.Completed);
          break;
        case ProgressState.Completed:
          handleSessionUpdate(ProgressState.NotStarted);
          break;
      }
    }, [handleSessionUpdate, sessionProgressData?.state]);

    React.useEffect(() => {
      if (isWeb) return;

      const bounceSwipeableEffectTimeout = setTimeout(() => {
        if (
          shouldShowMoreButtonAnimation &&
          swipeableRef.current &&
          sessionIndex === 0 &&
          volumeIndex === 0
        ) {
          swipeableRef.current.openRight();
          setIsBlinking(true);

          setTimeout(() => {
            swipeableRef.current?.close();
            setIsBlinking(false);

            dispatch(incrementMoreButtonAnimationCount());
          }, BOUNCE_EFFECT_TIMEOUT * 2);
        }
      }, BOUNCE_EFFECT_TIMEOUT);

      return () => clearTimeout(bounceSwipeableEffectTimeout);
    }, [
      dispatch,
      sessionIndex,
      shouldShowMoreButtonAnimation,
      swipeableRef,
      volumeIndex,
    ]);

    if (!isSessionPublished) return null;

    return (
      <HeaderWrapper>
        <AccordionList isExpanded={isExpanded} isBlinking={isBlinking}>
          <SessionTitleWrapper onPress={onPress}>
            {!isDownloadMode ? (
              <TouchableOpacity
                onPress={onProgressIconPress}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
              >
                <ProgressIcon
                  state={sessionProgressData?.state}
                  isSession={false}
                  isLoading={isLoading}
                />
              </TouchableOpacity>
            ) : null}

            {isDownloadMode && !isWeb ? (
              <DownloadedIconContainer>
                {isSessionDownloaded ? (
                  <Icon name="check-underline" size={15} color={colors.black} />
                ) : null}
              </DownloadedIconContainer>
            ) : null}

            <SessionTitleSubWrapper>
              <SessionTitle isExpanded={isExpanded}>
                {sessionData?.title || ""}
              </SessionTitle>
            </SessionTitleSubWrapper>
          </SessionTitleWrapper>

          {isConnected || isSessionDownloaded ? (
            <ActionButtonsContainer>
              <AccordionListIconContainer onPress={onPress}>
                {hasNoChildItems && !isDownloadMode ? (
                  <AccordionListItemIcon />
                ) : (
                  <AccordionListIcon isExpanded={isExpanded} />
                )}
              </AccordionListIconContainer>
            </ActionButtonsContainer>
          ) : null}
        </AccordionList>

        <DownloadProgressBar downloadProgress={calculatedProgress} />

        {isExpanded && !isDownloadMode ? (
          <SessionItems
            sessionId={sessionId}
            sessionProgressData={sessionProgressData}
            planId={planId}
            volumeId={volumeId}
            sessionIndex={sessionIndex}
            volumeIndex={volumeIndex}
            {...rest}
          />
        ) : null}

        {isExpanded && isDownloadMode ? (
          <SessionDownloads
            sessionId={sessionId}
            audienceId={audienceId}
            volumeId={volumeId}
            volumeIndex={volumeIndex}
          />
        ) : null}
      </HeaderWrapper>
    );
  }
);

const AdditionalFiles: React.FC<any> = React.memo(
  ({ sessionId, volumeId, volumeIndex, audienceId }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [downloadingProgress, setDownloadingProgress] =
      React.useState<Record<string, number>>();

    const isSessionDownloaded = useAppSelector((state) =>
      getIsSessionDownloaded(state, sessionId)
    );

    const additionalFiles = useAppSelector((state) =>
      getVolumeAdditionalFileIds(state, volumeId)
    );

    const calculatedProgress = React.useMemo(() => {
      if (!downloadingProgress) return 0;

      const values = Object.values(downloadingProgress);
      const numberOfItems = values.length;
      const totalProgress = values.reduce((acc, item) => acc + item, 0);

      return totalProgress / numberOfItems;
    }, [downloadingProgress]);

    const onExpandSession = React.useCallback(
      () => setIsExpanded(!isExpanded),
      [isExpanded, setIsExpanded]
    );

    const onPress = React.useCallback(() => {
      onExpandSession();
    }, [onExpandSession]);

    return (
      <ListWrapper>
        <AccordionList isExpanded={isExpanded} isBlinking={false} isFirst>
          <SessionTitleWrapper onPress={onPress}>
            {!isWeb ? (
              <TouchableOpacity>
                <ProgressIcon
                  state={ProgressState.NotStarted}
                  isSession={false}
                />
              </TouchableOpacity>
            ) : null}

            <SessionTitleSubWrapper>
              <SessionTitle>
                {{
                  ...messages.additionalAssets,
                  values: { volumeIndex: volumeIndex + 1 },
                }}
              </SessionTitle>

              {isSessionDownloaded && !isWeb && (
                <DownloadedIconContainer>
                  <Icon name="check-underline" size={10} color={colors.black} />
                </DownloadedIconContainer>
              )}
            </SessionTitleSubWrapper>
          </SessionTitleWrapper>

          <ActionButtonsContainer>
            <AccordionListIconContainer onPress={onPress}>
              <AccordionListIcon isExpanded={isExpanded} />
            </AccordionListIconContainer>
          </ActionButtonsContainer>
        </AccordionList>

        <DownloadProgressBar downloadProgress={calculatedProgress} />

        {isExpanded ? (
          <SessionDownloads
            sessionId={sessionId}
            audienceId={audienceId}
            files={additionalFiles}
          />
        ) : null}
      </ListWrapper>
    );
  }
);
