export const messages = {
  title: {
    id: "change-password.title",
    defaultMessage: "Change Password",
  },
  currentPassword: {
    id: "change-password.current-password",
    defaultMessage: "Current Password",
  },
  newPassword: {
    id: "change-password.new-password",
    defaultMessage: "New Password",
  },
  newPasswordConfirm: {
    id: "change-password.new-password-confirm",
    defaultMessage: "Confirm New Password",
  },
  passwordUpdated: {
    id: "change-password.password-updated",
    defaultMessage: "Password updated successfully",
  },
  passwordsDoNotMatch: {
    id: "change-password.passwords-do-not-match",
    defaultMessage:
      "Your confirmation password does not match the new password.",
  },
  wrongPassword: {
    id: "change-password.wrong-password",
    defaultMessage: "Your current password is incorrect.",
  },
  weakPassword: {
    id: "change-password.weak-password",
    defaultMessage: "Your new password does not meet the criteria",
  },
  error: {
    id: "change-password.error",
    defaultMessage: "An error occurred. Please try again later.",
  },
  save: {
    id: "change-password.save",
    defaultMessage: "Save",
  },
  forgotPassword: {
    id: "change-password.forgot-password",
    defaultMessage: "Forgot Password?",
  },
  resetPasswordEmailSent: {
    id: "change-password.reset-password-email-sent",
    defaultMessage: "Reset password email sent",
  },
  resetPasswordConfirmation: {
    id: "change-password.reset-password-email-confirmation",
    defaultMessage:
      "A reset password email will be send, you will be log out. Do you want to continue?",
  },
  resetPasswordConfirmationYes: {
    id: "change-password.reset-password-email-confirmation-yes",
    defaultMessage: "Yes",
  },
  resetPasswordConfirmationCancel: {
    id: "change-password.reset-password-email-confirmation-cancel",
    defaultMessage: "Cancel",
  },
  resetPasswordEmailSentOk: {
    id: "change-password.reset-password-email-confirmation-ok",
    defaultMessage: "Ok",
  },
};
