import * as React from "react";
import { IconSizes } from "~/components/icon";
import { ProgressState } from "~/state/content-progress";
import { colors } from "~/styles/theme";
import {
  ListItemProgressIcon,
  ProgressSpinner,
  ProgressSpinnerContainer,
} from "./styles";

interface ProgressIconProps {
  state?: ProgressState;
  isSession: boolean;
  iconSize?: IconSizes;
  isLoading?: boolean;
}

export const ProgressIcon: React.FC<ProgressIconProps> = React.memo(
  ({ state, isSession, iconSize, isLoading }) => {
    if (isLoading)
      return (
        <ProgressSpinnerContainer>
          <ProgressSpinner
            iconSize={iconSize}
            isSession={isSession}
            isHidden={false}
          />
        </ProgressSpinnerContainer>
      );

    if (state === ProgressState.InProgress)
      return (
        <ListItemProgressIcon
          name="progress-check"
          color={colors.black}
          isSession={isSession}
          iconSize={iconSize}
        />
      );

    if (state === ProgressState.Completed)
      return (
        <ListItemProgressIcon
          name="check-circle"
          color={colors.green600}
          isSession={isSession}
          iconSize={iconSize}
        />
      );

    return (
      <ListItemProgressIcon
        name="check-circle"
        isHidden={true}
        iconSize={iconSize}
      />
    );
  }
);
