import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from "firebase/remote-config";

import { app } from "<config>/firebase.web";

const config = getRemoteConfig(app);

export const remoteConfig = () => ({
  setDefaults: (defaultConfig: Record<string, any>) =>
    new Promise((resolve) => {
      config.defaultConfig = defaultConfig;
      resolve(true);
    }),
  fetchAndActivate: () => {
    return fetchAndActivate(config);
  },
  getAll: () => getAll(config),
});
