export const messages = {
  volumeTitle: {
    id: "plan.volume.title",
    defaultMessage: "Vol. {{volumeIndex}} {{volumeTitle}}",
  },
  planProgress: {
    id: "plan.progress.text",
    defaultMessage: "{{progress}}% Progress",
  },
  totalSessions: {
    id: "plan.total-sessions",
    defaultMessage: "{{total}} Sessions",
  },
  resetPlanProgress: {
    id: "plan.progress.reset",
    defaultMessage: "Reset Plan Progress",
  },
  resetConfirm: {
    id: "plan.progress.reset.confirm",
    defaultMessage: "Are you sure you want to reset your progress?",
  },
  resetButton: {
    id: "plan.progress.reset.button",
    defaultMessage: "Reset",
  },
  cancel: {
    id: "plan.progress.cancel",
    defaultMessage: "Cancel",
  },
  restartConfirm: {
    id: "plan.progress.restart.confirm",
    defaultMessage: "Are you sure you want to restart your plan?",
  },
  restartButton: {
    id: "plan.progress.restart.button",
    defaultMessage: "Restart",
  },
  sessions: {
    id: "plan.progress.button.sessions",
    defaultMessage: "Sessions",
  },
  downloads: {
    id: "plan.progress.button.downloads",
    defaultMessage: "Session Downloads",
  },
};
