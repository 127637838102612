export const messages = {
  titleCreate: {
    id: "group-modal.title-create",
    defaultMessage: "Create a new group",
  },
  titleEdit: {
    id: "group-modal.title-edit",
    defaultMessage: "Group Edit",
  },
};
