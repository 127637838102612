export const messages = {
  imageChangeSuccess: {
    id: "settings.avatar.image-change-success",
    defaultMessage: "Your profile picture has been successfully changed!",
  },
  imageChangeError: {
    id: "settings.avatar.image-change-error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
};
