import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";

import { Camera } from "~/components/camera";
import { InputBox } from "~/components/auth-screen/styles";
import { TextInput } from "~/components/text-input";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { useAppSelector } from "~/state/hooks";
import {
  getProfileImage,
  getUserFirstName,
  getUserLastName,
} from "~/state/user/selectors";
import { useCamera, ImageProps } from "~/utils/hooks/use-camera";

import { messages as genericMessages } from "../intl";
import { BasicData } from "../types";
import { KeyboardAvoiding, Container, Content } from "../styles";
import {
  CameraBox,
  CenterBox,
  ImageBox,
  ImageIcon,
  ImagePreview,
  PhotoBox,
  Title,
} from "./styles";
import { messages } from "./intl";

interface Props {
  onPress: (data: BasicData) => void;
  firstName?: string;
  lastName?: string;
  image?: string;
}

export const BasicInputs = React.memo<Props>(
  ({
    onPress,
    firstName: defaultFirstName = "",
    lastName: defaultLastName = "",
    image,
  }) => {
    const stateFirstName = useAppSelector(getUserFirstName);
    const stateLastName = useAppSelector(getUserLastName);
    const [firstName, setFirstName] = React.useState(
      defaultFirstName || stateFirstName
    );
    const [lastName, setLastName] = React.useState(
      defaultLastName || stateLastName
    );
    const statePhotoUrl: string = useAppSelector(getProfileImage);
    const [localImage, setLocalImage] = React.useState(image || statePhotoUrl);

    const handleImage = React.useCallback(
      ({ uri }: ImageProps) => {
        setLocalImage(uri);
      },
      [onPress]
    );

    const { onShowOptions, isModalVisible, handleCloseModal } = useCamera({
      handleImage,
    });

    const isValid = firstName && lastName;

    // Sometimes state update takes time after the social auth
    React.useEffect(() => {
      if (stateFirstName) {
        setFirstName(stateFirstName);
      }
      if (stateLastName) {
        setLastName(stateLastName);
      }
    }, [stateFirstName, stateLastName]);

    const handleOnPress = React.useCallback(() => {
      if (isValid) {
        onPress({ firstName, lastName, image: localImage });
      }
    }, [isValid, firstName, lastName, onPress]);

    return (
      <>
        <KeyboardAvoiding>
          <Container>
            <Content>
              <Title>{messages.basicTitle}</Title>

              <CenterBox>
                <PhotoBox>
                  <ImageBox onPress={onShowOptions}>
                    {localImage ? (
                      <ImagePreview source={{ uri: localImage }} />
                    ) : (
                      <ImageIcon />
                    )}
                  </ImageBox>
                  <CameraBox onPress={onShowOptions}>
                    <Icon name="camera-alt" />
                  </CameraBox>
                </PhotoBox>
              </CenterBox>

              {isModalVisible ? (
                <Camera
                  handlePicture={handleImage}
                  onClose={handleCloseModal}
                />
              ) : null}

              <InputBox>
                <TextInput
                  label={messages.firstName}
                  value={firstName}
                  onChangeText={setFirstName}
                  autoCapitalize="words"
                  key="firstName"
                  returnKeyType="done"
                />
                <TextInput
                  label={messages.lastName}
                  value={lastName}
                  onChangeText={setLastName}
                  autoCapitalize="words"
                  key="lastName"
                  returnKeyType="done"
                />
              </InputBox>
            </Content>
          </Container>
        </KeyboardAvoiding>

        <Button
          text={genericMessages.buttonNext}
          onPress={handleOnPress}
          type={ButtonTypes.Primary}
          isDisabled={!isValid}
        />
      </>
    );
  }
);
