import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { genericMessages } from "~/constants/intl";
import { AuthScreen } from "~/components/auth-screen";
import { ButtonRightBox } from "~/components/auth-screen/styles";
import { Button, InlineButton } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { TextInput } from "~/components/text-input";
import { useAppDispatch } from "~/state/hooks";
import { login } from "~/state/user/actions";
import { isValidEmail, isValidString } from "~/utils/strings";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { messages } from "./intl";

export const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState<MessageDescriptor>();
  const [passwordError, setPasswordError] = React.useState<MessageDescriptor>();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { pushAlert } = useAlerts();

  const isValid = isValidEmail(email) && isValidString(password, 6);

  const resetEmailError = React.useCallback(() => setEmailError(undefined), []);
  const resetPasswordError = React.useCallback(
    () => setPasswordError(undefined),
    []
  );

  const handleError = React.useCallback((error: string = "") => {
    if (error.includes("auth/invalid-email")) {
      setEmailError(messages.errorEmail);
      return;
    }
    if (error.includes("auth/user-not-found")) {
      setEmailError(messages.userNotFound);
      return;
    }
    if (error.includes("auth/wrong-password")) {
      setPasswordError(messages.errorPassword);
      return;
    }

    pushAlert({ message: genericMessages.error, color: colors.black });
  }, []);

  const handleLogin = React.useCallback(() => {
    setEmailError(undefined);
    setPasswordError(undefined);
    setIsLoading(true);
    dispatch(
      login({
        email: email.toLowerCase(),
        password,
        onSuccess: () => setIsLoading(false),
        onError: (e) => {
          setIsLoading(false);
          handleError(e);
        },
      })
    );
  }, [dispatch, email, password, handleError]);

  const navigateToIntro = React.useCallback(
    () => navigation.navigate(routes.intro),
    [navigation]
  );

  const navigateToSignUp = React.useCallback(
    () => navigation.navigate(routes.signUp),
    [navigation]
  );

  const navigateToForgotPassword = React.useCallback(
    () => navigation.navigate(routes.forgotPassword),
    [navigation]
  );

  return (
    <AuthScreen
      screenName="login"
      title={messages.title}
      hasBackButton
      onBack={navigateToIntro}
      hasLinks
      hasFAQ
      isSignIn
      id="login"
    >
      <>
        <TextInput
          label={messages.email}
          value={email}
          onChangeText={setEmail}
          onFocus={resetEmailError}
          errorMessage={emailError}
          autoCorrect={false}
          autoCapitalize="none"
          autoComplete="email"
          keyboardType="email-address"
          returnKeyType="done"
          numberOfLines={1}
        />
        <TextInput
          label={messages.password}
          value={password}
          onChangeText={setPassword}
          onFocus={resetPasswordError}
          errorMessage={passwordError}
          returnKeyType="done"
          secureTextEntry
          isSecure
          numberOfLines={1}
        />
      </>

      <ButtonRightBox>
        <InlineButton
          text={messages.forgotPassword}
          onPress={navigateToForgotPassword}
          type={ButtonTypes.Secondary}
          isLast
        />
      </ButtonRightBox>

      <Button
        text={messages.signIn}
        onPress={handleLogin}
        type={ButtonTypes.Primary}
        isDisabled={!isValid}
        isLoading={isLoading}
        isUppercase
      />
    </AuthScreen>
  );
};
