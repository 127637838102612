import uuid from "react-native-uuid";
import { eventChannel, SagaIterator } from "redux-saga";
import {
  call,
  take,
  cancelled,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  limit,
  deleteDoc,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import NetInfo from "@react-native-community/netinfo";

import { database } from "<config>/firebase";
import { setIsUserReady } from "~/state/user/actions";
import { handleUploadImage } from "~/state/user/sagas";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { MessageType, RawMessage } from "~/state/chat/types";
import { FUNCTIONS_ENDPOINT } from "~/constants";
import {
  getGroupsCollection,
  getGroupsImageFolder,
  getInviteCodeEndpoint,
  getMessagesCollection,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { asyncLogEvent, events } from "~/utils/analytics";

import { updateGroups } from "./slice";
import {
  createGroup,
  editGroup,
  addResource,
  joinGroup,
  deleteGroup,
  removeMember,
  changeRole,
  setLastActivity,
  removeResource,
  refreshInviteCode,
  fetchUserGroups,
} from "./actions";
import { messages } from "./intl";
import {
  Group,
  CreateGroupAction,
  EditGroupAction,
  JoinGroupAction,
  AddResourceAction,
  RemoveMemberAction,
  ChangeRoleAction,
  SetLastActivityAction,
  DeleteGroupAction,
  RefreshInvitecodeAction,
  Roles,
  Member,
} from "./types";

function subscribeToGroups(userId: string, groupsCollection: string) {
  return eventChannel((emmiter: any) => {
    const groupsRef = query(
      collection(database, groupsCollection),
      where("subscribers", "array-contains", userId)
    );

    const unsubscribe = onSnapshot(groupsRef, (snap) => {
      const data: Group[] = [];

      snap.forEach((document) => {
        data.push(document.data() as Group);
      });

      emmiter(data);
    });

    return () => unsubscribe();
  });
}

export function* fetchGroups(data: any): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) return;

  const defaultUserId = data?.payload?.userId;
  const savedUserId = yield select(getUserId);

  const userId = savedUserId || defaultUserId;

  if (!userId) {
    return;
  }
  const env = yield select(getEnvironment);
  const groupsCollection = getGroupsCollection(env);
  const channel = yield call(subscribeToGroups, userId, groupsCollection);
  try {
    while (true) {
      const groupData = yield take(channel);

      if (groupData) {
        yield put(updateGroups(groupData));
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export const fetchGroupsSaga = function* () {
  yield takeLatest([setIsUserReady.type, fetchUserGroups.type], fetchGroups);
};

export function* createGroupFn({
  payload: { name = "", imageUri, planId, onSuccess, onError },
}: CreateGroupAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const groupsImageFolder = getGroupsImageFolder(env);
    const inviteCodeEndpoint = getInviteCodeEndpoint(env);
    const groupsCollection = getGroupsCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const createdAt = new Date().getTime();
    let url = "";

    if (imageUri) {
      const path = `${groupsImageFolder}/${id}.jpg`;
      url = yield call(handleUploadImage, imageUri, path);
    }

    const rawResponse = yield call(
      fetch,
      `${FUNCTIONS_ENDPOINT}/${inviteCodeEndpoint}`
    );
    const response = yield call(() => rawResponse.json());
    const inviteCode = response?.result;

    const imageData = url ? { imageUri: url } : {};
    const inviteData = inviteCode ? { inviteCode } : {};
    const plans = planId ? [planId] : [];
    const newMember = {
      id: userId,
      role: Roles.Leader,
    };
    const data = {
      id,
      name,
      subscribers: [userId],
      members: [newMember],
      plans,
      createdAt,
      ...imageData,
      ...inviteData,
    };

    // @ts-ignore
    yield call(setDoc, doc(database, groupsCollection, id), data, {
      merge: true,
    });

    yield call(onSuccess, id);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const createGroupSaga = function* () {
  yield takeLatest(createGroup.type, createGroupFn);
};

export function* editGroupFn({
  payload: { groupId, name = "", imageUri, onSuccess, onError },
}: EditGroupAction): SagaIterator {
  try {
    if (!name && !imageUri) {
      return;
    }
    const env = yield select(getEnvironment);
    const groupsImageFolder = getGroupsImageFolder(env);
    const groupsCollection = getGroupsCollection(env);

    let url = "";
    if (imageUri) {
      const path = `${groupsImageFolder}/${groupId}.jpg`;
      url = yield call(handleUploadImage, imageUri, path);
    }

    const imageData = url ? { imageUri: url } : {};
    const nameData = name ? { name } : {};
    const data = {
      ...imageData,
      ...nameData,
    };

    // @ts-ignore
    yield call(setDoc, doc(database, groupsCollection, groupId), data, {
      merge: true,
    });

    yield call(onSuccess, groupId);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const editGroupSaga = function* () {
  yield takeLatest(editGroup.type, editGroupFn);
};

function* getGroupData(groupId: string, onError: () => void): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    // Get the group data from Firebase
    const groupRef = doc(database, groupsCollection, groupId);
    // @ts-ignore
    const groupSnapshot = yield call(getDoc, groupRef);
    // Group does not exist
    if (!groupSnapshot.exists()) {
      yield call(onError);
    }
    return groupSnapshot.data();
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addResourceFn({
  payload: { groupId, planId, onSuccess = () => {}, onError = () => {} },
}: AddResourceAction): SagaIterator {
  try {
    if (!groupId || !planId) {
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    const groupData = yield call(getGroupData, groupId, onError);

    // Resource already exists in the group
    if (groupData?.plans.includes(planId)) {
      yield call(onSuccess);
      return;
    }

    // Otherwise, add the resource to the group
    const data = {
      plans: arrayUnion(planId),
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const addGroupResourceSaga = function* () {
  yield takeLatest(addResource.type, addResourceFn);
};

export function* removeResourceFn({
  payload: { groupId, planId, onSuccess = () => {}, onError = () => {} },
}: AddResourceAction): SagaIterator {
  try {
    if (!groupId || !planId) {
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    const groupData = yield call(getGroupData, groupId, onError);

    // Resource does not exist in the group
    if (!groupData?.plans.includes(planId)) {
      yield call(onSuccess);
      return;
    }

    // Otherwise, add the resource to the group
    const data = {
      plans: arrayRemove(planId),
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const removeGroupResourceSaga = function* () {
  yield takeLatest(removeResource.type, removeResourceFn);
};

export function* joinGroupFn({
  payload: { inviteCode, userId, onSuccess = () => {}, onError = () => {} },
}: JoinGroupAction): SagaIterator {
  try {
    if (!inviteCode || !userId) {
      yield call(onError, messages.errorGeneric);
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);
    const messagesCollection = getMessagesCollection(env);

    const q = query(
      collection(database, groupsCollection),
      where("inviteCode", "==", inviteCode),
      limit(1)
    );

    const groupResponse = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const groups: Group[] = [];
    groupResponse.forEach((group: QueryDocumentSnapshot<Group>) => {
      groups.push(group.data());
    });

    // No group with a given invite code
    if (!groups.length) {
      yield call(onError, messages.errorInvalid);
      return;
    }

    const groupData = groups[0];
    const groupId = groupData?.id;
    const members = groupData?.members || [];
    const subscribers = groupData?.subscribers || [];

    // User already exists in the group
    const userExists = !!members.find(({ id }) => id === userId);
    if (userExists) {
      yield call(onSuccess, groupId);
      return;
    }

    const currentReadMessages = groupData?.readMessages || {};
    const currentTotalMessages = groupData?.totalMessages || 0;

    // Otherwise, add the user to the group
    const data = {
      subscribers: [...subscribers, userId],
      members: [
        ...members,
        {
          id: userId,
          role: Roles.Member,
        },
      ],
      readMessages: {
        ...currentReadMessages,
        [userId]: currentTotalMessages,
      },
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);

    // Add the corresponding message to the collection
    const id = uuid.v4() as string;
    const timestamp = new Date().getTime();
    const message: RawMessage = {
      id,
      timestamp,
      type: MessageType.NewMember,
      userId,
    };
    yield call(
      // @ts-ignore
      setDoc,
      doc(database, messagesCollection, groupId, "messages", id),
      message
    );
    yield call(onSuccess, groupId);
  } catch (e) {
    yield call(onError, messages.errorGeneric);
    yield call(handleError, e);
  }
}

export const joinGroupSaga = function* () {
  yield takeEvery(joinGroup.type, joinGroupFn);
};

export function* getInviteCode(): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const inviteCodeEndpoint = getInviteCodeEndpoint(env);

    const rawResponse = yield call(
      fetch,
      `${FUNCTIONS_ENDPOINT}/${inviteCodeEndpoint}`
    );
    const response = yield call(() => rawResponse.json());
    return response?.result;
  } catch (e) {
    return "";
  }
}

export function* refreshInviteCodeFn({
  payload: { groupId, onSuccess = () => {}, onError = () => {} },
}: RefreshInvitecodeAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    const inviteCode = yield call(getInviteCode);
    if (!inviteCode) {
      yield call(onError);
      return;
    }

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), {
      inviteCode,
    });
    yield call(asyncLogEvent, events.GROUP_CODE_REFRESH);
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const refreshInviteCodeSaga = function* () {
  yield takeLatest(refreshInviteCode.type, refreshInviteCodeFn);
};

export function* removeMemberFn({
  payload: { userId, groupId, onSuccess = () => {}, onError = () => {} },
}: RemoveMemberAction): SagaIterator {
  try {
    if (!groupId || !userId) {
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);
    const messagesCollection = getMessagesCollection(env);

    const groupData = yield call(getGroupData, groupId, onError);
    const members = (groupData?.members || []) as Member[];
    const subscribers = (groupData?.subscribers || []) as string[];

    // User is not a member of the group
    const userExists = !!members.find(({ id }) => id === userId);
    if (!userExists) {
      yield call(onSuccess);
      return;
    }

    // If the user was the last in the group, remove the group
    if (members.length <= 1) {
      yield put(deleteGroup({ groupId }));
      yield call(onSuccess);
      return;
    }

    // Otherwise, remove the user from the group
    const data = {
      members: members.filter(({ id }) => id !== userId),
      subscribers: subscribers.filter(
        (subscriberId) => subscriberId !== userId
      ),
    };
    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);

    // Add the corresponding message to the collection
    const id = uuid.v4() as string;
    const timestamp = new Date().getTime();
    const message: RawMessage = {
      id,
      timestamp,
      type: MessageType.MemberRemoved,
      userId,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, messagesCollection, groupData.id, "messages", id),
      message
    );

    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const removeGroupMemberSaga = function* () {
  yield takeLatest(removeMember.type, removeMemberFn);
};

export function* changeRoleFn({
  payload: {
    userId,
    groupId,
    isLeader,
    onSuccess = () => {},
    onError = () => {},
  },
}: ChangeRoleAction): SagaIterator {
  try {
    if (!groupId || !userId) {
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    const groupData = yield call(getGroupData, groupId, onError);
    const members = (groupData?.members || []) as Member[];

    // User is not a member of the group
    const userExists = !!members.find(({ id }) => id === userId);
    if (!userExists) {
      yield call(onError);
      return;
    }

    const updatedMembers = members.map(({ id, role, ...props }) => {
      if (id === userId) {
        const newRole = isLeader ? Roles.Leader : Roles.Member;
        return {
          ...props,
          id,
          role: newRole,
        };
      }
      return {
        ...props,
        id,
        role,
      };
    });

    const data = {
      members: updatedMembers,
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const changeRoleSaga = function* () {
  yield takeLatest(changeRole.type, changeRoleFn);
};

export function* setLastActivityFn({
  payload: { groupId },
}: SetLastActivityAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);

    // Note: serverTimestamp is not available inside arrays
    const now = new Date().getTime();
    const userId = yield select(getUserId);

    const groupData = yield call(getGroupData, groupId, () => {});
    const members = (groupData?.members || []) as Member[];

    // getGroupData call might fail in certain edge cases (e.g. weak network conditions)
    // Let's make sure it would not clear the members array
    if (!members.length) {
      return;
    }

    const currentReadMessages = groupData?.readMessages || {};
    const currentTotalMessages = groupData?.totalMessages || 0;

    const updatedMembers = members.map((member) => {
      if (member.id === userId) {
        return {
          ...member,
          lastActivity: now,
        };
      }
      return member;
    });

    const data = {
      members: updatedMembers,
      readMessages: {
        ...currentReadMessages,
        [userId]: currentTotalMessages,
      },
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, groupsCollection, groupId), data);
  } catch (e) {
    yield call(handleError, e);
  }
}

export const setLastActivitySaga = function* () {
  yield takeLatest(setLastActivity.type, setLastActivityFn);
};

export function* deleteGroupFn({
  payload: { groupId, onSuccess = () => {}, onError = () => {} },
}: DeleteGroupAction): SagaIterator {
  try {
    if (!groupId) {
      yield call(onError);
      return;
    }
    const env = yield select(getEnvironment);
    const groupsCollection = getGroupsCollection(env);
    const messagesCollection = getMessagesCollection(env);

    // Give time for the navigation to reset
    yield delay(500);

    const id = uuid.v4() as string;
    const timestamp = new Date().getTime();
    const userId = yield select(getUserId);

    // Store the message in order to trigger the notification
    const groupData = yield call(getGroupData, groupId, () => {});
    const userIds = groupData?.subscribers || [];
    const groupName = groupData?.name || "";
    yield call(
      // @ts-ignore
      setDoc,
      doc(database, messagesCollection, groupId, "messages", id),
      {
        id,
        userIds,
        userId,
        groupName,
        type: MessageType.GroupRemoved,
        timestamp,
      },
      { merge: true }
    );

    // Delete the group
    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, groupsCollection, groupId)
    );

    // Delete the group messages
    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, messagesCollection, groupId)
    );

    if (typeof onSuccess === "function") {
      yield call(onSuccess);
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }
    yield call(handleError, e);
  }
}

export const deleteGroupSaga = function* () {
  yield takeEvery(deleteGroup.type, deleteGroupFn);
};
