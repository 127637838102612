export const messages = {
  title: {
    id: "forgot-password.title",
    defaultMessage: "Forgot password",
  },
  email: {
    id: "forgot-password.email",
    defaultMessage: "Email",
  },
  button: {
    id: "forgot-password.button",
    defaultMessage: "Send",
  },
  sent: {
    id: "forgot-password.sent",
    defaultMessage: "Great! A link to reset your password has been send to your email.",
  },
  backToLogin: {
    id: "forgot-password.back-to-login",
    defaultMessage: "Back to login",
  },
};
