import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { css } from "styled-components/native";
import { spacers } from "~/styles/theme";
import { IconSizes } from "~/components/icon";
import { Loader } from "~/components/loader";

interface ListItemProgressIconProps {
  isSession: boolean;
  isHidden: boolean;
  iconSize?: IconSizes;
}

export const ListItemProgressIcon = styled(
  Icon
).attrs<ListItemProgressIconProps>(({ iconSize = IconSizes.Small }) => ({
  size: iconSize,
}))<ListItemProgressIconProps>`
  ${({ isSession }) =>
    !isSession &&
    css`
      margin: 0 ${spacers.ss4} 0 0;
    `};

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
    `};
`;

export const ProgressSpinnerContainer = styled.View`
  margin-right: 10px;
`;

export const ProgressSpinner = styled(Loader).attrs<
  Pick<ListItemProgressIconProps, "iconSize">
>(({ iconSize = 14 }) => ({
  size: iconSize,
}))<ListItemProgressIconProps>`
  width: 22px;
`;
