import * as React from "react";
import { TextInput, ScrollView, View } from "react-native";
import { BottomSheetModal } from "~/components/bottom-sheet";

import { HeaderBar } from "~/components/header-bar";
import { NotesAction } from "~/components/notes-action";
import { Screen } from "~/components/screen";
import { useShare } from "~/components/share";
import { getLastUpdated } from "~/screens/notes/utils";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";
import { asyncLogEvent, events } from "~/utils/analytics";
import { MessageData, MessageType } from "~/state/chat/types";
import { useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";
import { formatMessage } from "~/utils/translation";
import { useKeyboard } from "~/utils/keyboard";

import { ShareSheet } from "./share-sheet";
import {
  Container,
  HeaderBox,
  TitleBox,
  Title,
  LastEdited,
  Section,
  NoteText,
  NoteInputText,
  TitleInputText,
  NoteLabel,
} from "./styles";
import { formatShareMessage } from "./utils";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

export type SavePayload = {
  value: string;
  title?: string;
};

interface Props {
  children: React.ReactNode;
  title: string;
  text: string;
  lastUpdated?: number;
  data?: MessageData;
  type: MessageType;
  backButtonText: MessageDescriptor;
  isCreateMode?: boolean;
  canEditTitle?: boolean;
  screenName: string;
  onSave: (
    data: SavePayload,
    successCb: () => void,
    errorCb: () => void
  ) => void;
  onDelete?: () => void;
  hideActions?: boolean;
}

export const NoteViewEdit = React.memo<Props>(
  ({
    children,
    title: defaultTitle,
    lastUpdated,
    text,
    data,
    type,
    backButtonText,
    isCreateMode,
    canEditTitle,
    onSave,
    onDelete,
    screenName,
    hideActions,
  }) => {
    const [title, setTitle] = React.useState(defaultTitle);
    const [value, setValue] = React.useState(text);
    const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(isCreateMode);
    const inputRef = React.useRef<TextInput>(null);
    const scrollViewRef = React.useRef<ScrollView>(null);
    const { pushAlert } = useAlerts();
    const { share } = useShare();
    const { keyboardHeight } = useKeyboard();
    const groups = useAppSelector(getGroupsOrderedByTime);

    const titlePlaceholder = formatMessage(messages.titlePlaceholder);
    const textPlaceholder = formatMessage(messages.textPlaceholder);

    const description = getLastUpdated(lastUpdated);

    const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);

    const onShare = () => {
      asyncLogEvent(events.NOTES_SHARE, { type });
      if (groups.length) {
        if (!isWeb) {
          bottomSheetModalRef.current?.present();
        } else {
          setIsShareModalOpen(true);
        }
      } else {
        onShareGeneric();
      }
    };

    const onEdit = React.useCallback(() => {
      setIsEditing(true);
      inputRef?.current?.focus();
    }, []);

    React.useEffect(() => {
      if (isCreateMode) {
        onEdit();
      }
    }, [isCreateMode, onEdit]);

    const handleSave = React.useCallback(() => {
      setIsEditing(false);
      onSave(
        { title, value },
        () => {
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
          setIsEditing(false);
        },
        () => {
          pushAlert({ message: genericMessages.error });
          setIsEditing(false);
        }
      );
    }, [onSave, title, value, pushAlert]);

    const onShareGeneric = React.useCallback(async () => {
      await share({
        message: formatShareMessage(value, type, data),
      });
    }, [data, type, value, share]);

    React.useEffect(() => {
      if (keyboardHeight > 0) {
        setTimeout(() => {
          scrollViewRef?.current?.scrollToEnd();
        }, 0);
      }
    }, [keyboardHeight]);

    const isPersonalNotesWebEdit = isEditing && canEditTitle && isWeb;

    const renderTopContent = () => {
      if (isPersonalNotesWebEdit) {
        return <View style={{ flex: 1 }} />;
      }
      if (isEditing && canEditTitle) {
        return (
          <TitleInputText
            value={title}
            onChangeText={setTitle}
            placeholder={titlePlaceholder}
            multiline
          />
        );
      }
      return <Title>{title}</Title>;
    };

    const renderContent = () => (
      <>
        <Container>
          <HeaderBox>
            <TitleBox>
              {renderTopContent()}
              {!hideActions ? (
                <NotesAction
                  onEdit={onEdit}
                  onShare={onShare}
                  onDelete={onDelete}
                  onSubmit={handleSave}
                  isEditMode={isCreateMode}
                />
              ) : null}
            </TitleBox>

            {lastUpdated && !isPersonalNotesWebEdit ? (
              <LastEdited>{description}</LastEdited>
            ) : null}
          </HeaderBox>

          <Section
            ref={scrollViewRef}
            contentContainerStyle={{ paddingBottom: 240 }}
          >
            {children}

            {isPersonalNotesWebEdit ? (
              <>
                <NoteLabel>{messages.labelTitle}</NoteLabel>
                <TitleInputText
                  value={title}
                  onChangeText={setTitle}
                  placeholder={titlePlaceholder}
                />
              </>
            ) : null}

            {!isEditing ? (
              <NoteText>{value}</NoteText>
            ) : (
              <>
                {isWeb ? <NoteLabel>{messages.labelText}</NoteLabel> : null}
                <NoteInputText
                  ref={inputRef}
                  value={value}
                  onChangeText={setValue}
                  placeholder={textPlaceholder}
                  multiline
                />
              </>
            )}
            {lastUpdated && isPersonalNotesWebEdit ? (
              <LastEdited>{description}</LastEdited>
            ) : null}
          </Section>
        </Container>

        <ShareSheet
          onLoading={() => {}}
          modalRef={bottomSheetModalRef}
          content={value}
          data={data}
          type={type}
          onShare={onShareGeneric}
          isOpen={isShareModalOpen}
          setIsOpen={setIsShareModalOpen}
        />
      </>
    );

    if (isWeb) {
      return renderContent();
    }

    return (
      <Screen screenName={screenName} isView paddingBottom={keyboardHeight}>
        <HeaderBar
          iconColor={colors.black}
          withBackButton
          backButtonText={backButtonText}
        />

        {renderContent()}
      </Screen>
    );
  }
);
