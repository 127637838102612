export const messages = {
  monthly: {
    id: "pif.switch.monthly",
    defaultMessage: "Monthly",
  },
  oneTime: {
    id: "pif.switch.one-time",
    defaultMessage: "One Time",
  },
};
