import React from "react";
import { IconButton } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { IconSizes } from "~/components/icon";
import { HeaderTitle } from "~/components/header-title";
import { useAppSelector } from "~/state/hooks";
import { getLibraryFiltersCounter } from "~/state/flamelink";
import { TutorialStep } from "~/components/tutorial-step";
import { messages } from "../../intl";
import { LibraryProps } from "../..";
import {
  FilterContainer,
  FilterCounter,
  FilterCounterContainer,
  LibraryHeaderContainer,
  SearchIcon,
} from "./styles";

interface Props {
  onPressSearch: () => void;
  onFilterPress: () => void;
}

export function LibraryHeaderContent({ onPressSearch, onFilterPress }: Props) {
  const libraryFiltersCounter = useAppSelector(getLibraryFiltersCounter);

  return (
    <LibraryHeaderContainer>
      <FilterContainer>
        <IconButton
          icon="filter-variant"
          size={IconSizes.Large}
          onPress={onFilterPress}
        />

        {libraryFiltersCounter > 0 && (
          <FilterCounterContainer onPress={onFilterPress}>
            <FilterCounter>{`${libraryFiltersCounter}`}</FilterCounter>
          </FilterCounterContainer>
        )}
      </FilterContainer>

      <TutorialStep id="LIBRARY.HEADER">
        <HeaderTitle>{messages.title}</HeaderTitle>
      </TutorialStep>

      <SearchIcon onPress={onPressSearch} />
    </LibraryHeaderContainer>
  );
}
