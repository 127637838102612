import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { LibraryFiltersOptions, UIState } from "./types";

const getState = (state: RootState) => state.ui;

const getMoreButtonAnimationCount = createSelector(
  getState,
  (state) => state.moreButtonAnimationCount
);

export const getShouldShowMoreButtonAnimation = createSelector(
  getMoreButtonAnimationCount,
  (count) => count < 3
);

export const getLibraryFilters: (
  state: RootState
) => UIState["libraryFilters"] = createSelector(
  getState,
  (state) => state?.libraryFilters
);

export const getLibraryFiltersOptions: (
  state: RootState
) => LibraryFiltersOptions | undefined = createSelector(
  [getState],
  (state) => state?.libraryFiltersOptions
);

export const getTypeLibraryFilter: (
  state: RootState
) => UIState["libraryFilters"]["types"] = createSelector(
  getLibraryFilters,
  (libraryFilters) => libraryFilters.types
);

export const getAudienceLibraryFilter: (
  state: RootState
) => UIState["libraryFilters"]["audiences"] = createSelector(
  getLibraryFilters,
  (libraryFilters) => libraryFilters.audiences
);

export const getFiltersFetchTime: (state: RootState) => number = createSelector(
  [getState],
  (state) => state?.filtersFetchTime
);
