import React from "react";
import { useNavigation } from "@react-navigation/native";
import Icon from "@expo/vector-icons/MaterialIcons";

import * as routes from "~/constants/routes";

import { colors } from "~/styles/theme";

import { LinkTitle, TitleBox, Title } from "./styles";
import { messages } from "./intl";
import { navigateTo } from "~/utils/navigation";

interface Props {
  planId: string;
  planTitle: string;
  isBible?: boolean;
}

export const SectionTitle = ({ planId, planTitle, isBible }: Props) => {
  const navigation = useNavigation();

  const onTitlePress = () => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: {
        planId,
      },
    });
  };

  const title = isBible
    ? messages.bibleVersions
    : {
        ...messages.sessionTitle,
        values: { planTitle },
      };

  if (planId) {
    return (
      <LinkTitle onPress={onTitlePress}>
        <TitleBox>{planTitle ? <Title>{planTitle}</Title> : null}</TitleBox>
      </LinkTitle>
    );
  }

  return (
    <TitleBox>
      <Title>{title}</Title>
    </TitleBox>
  );
};
