import * as React from "react";

import { RichText } from "~/screens/session/components/content/components/rich-text";

import { QuestionBox, questionTextStyles } from "./styles";

interface Props {
  question: string;
}

export const NoteQuestion = React.memo<Props>(({ question }) => {
  const text = `**Q**: ${question}`;
  return (
    <QuestionBox>
      <RichText extraMarkdownStyles={questionTextStyles}>{text}</RichText>
    </QuestionBox>
  );
});
