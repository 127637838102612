import React from "react";

import {
  Container,
  TextBox,
  Title,
  Description,
  ButtonBox,
  Button,
  ButtonText,
} from "./styles";
import { Option } from "./types";

interface Props {
  title: string;
  description: string;
  options?: Option[];
  hideModal: () => void;
}

export const Modal = ({
  title,
  description,
  options = [],
  hideModal,
}: Props) => {
  return (
    <Container>
      <TextBox>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextBox>

      <ButtonBox>
        {options.map(({ text, onPress, style = "" }, index) => {
          const handleOnPress =
            typeof onPress === "function"
              ? () => {
                  onPress();
                  hideModal();
                }
              : hideModal;
          return (
            <Button
              key={index}
              onPress={handleOnPress}
              isLast={index === options.length - 1}
            >
              <ButtonText isDanger={["destructive", "cancel"].includes(style)}>
                {text}
              </ButtonText>
            </Button>
          );
        })}
      </ButtonBox>
    </Container>
  );
};
