import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import {
  Modal,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
} from "react-native";

import * as routes from "~/constants/routes";
import { colors } from "~/styles/theme";
import { Verse } from "~/state/bible/types";
import { BibleNoteAddComponent } from "~/screens/notes-bible-view/content";

import {
  Container,
  EditBox,
  HighlightBox,
  ColorCircle,
  IconButton,
  shadowStyle,
} from "./styles";
import { highlightColors } from "./constants";
import { useModal } from "~/state/modal/hook.web";

interface Props {
  top: number;
  selectedVerses: Verse[];
  bookId: string;
  chapterId: number;
  noteId?: string;
  onHighlightPress: (type: number) => void;
  onNavigation: () => void;
  onDismiss: () => void;
  isOpen: boolean;
}

export const EditMenu = React.memo<Props>(
  ({
    top,
    bookId,
    chapterId,
    noteId,
    selectedVerses,
    onHighlightPress,
    onNavigation,
    isOpen,
    onDismiss,
  }) => {
    const { showModal, hideModal } = useModal();

    const onEditPress = React.useCallback(() => {
      onNavigation();

      showModal(
        <BibleNoteAddComponent
          bookId={bookId}
          chapterId={chapterId}
          noteId={noteId}
          selectedVerses={selectedVerses}
          onSuccess={hideModal}
        />
      );
    }, [
      bookId,
      chapterId,
      noteId,
      selectedVerses,
      onNavigation,
      hideModal,
      showModal,
    ]);

    if (!isOpen) {
      return;
    }

    return (
      <Modal transparent visible onRequestClose={onDismiss}>
        <TouchableWithoutFeedback onPress={onDismiss}>
          <View style={styles.overlay}>
            <Container style={shadowStyle} top={top}>
              <EditBox>
                <IconButton onPress={onEditPress}>
                  <Icon name="edit" size={24} color={colors.gray700} />
                </IconButton>
              </EditBox>
              <HighlightBox>
                <IconButton onPress={() => onHighlightPress(-1)}>
                  <Icon
                    name="do-not-disturb-alt"
                    size={26}
                    color={colors.gray500}
                  />
                </IconButton>

                {highlightColors.map((color, index) => (
                  <ColorCircle
                    color={color}
                    onPress={() => onHighlightPress(index)}
                    key={index}
                  />
                ))}
              </HighlightBox>
            </Container>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0)",
    justifyContent: "center",
    alignItems: "center",
  },
});
