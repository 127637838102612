import * as React from "react";

import { TextInput } from "~/components/text-input";

import { Container, InputMask, OptionBox, Option, OptionText } from "./styles";
import { formatMessage } from "~/utils/translation";

type Option = {
  key: string;
  value: string;
};

interface Props {
  label: MessageDescriptorValues;
  value: string;
  options: Array<Option>;
  setOption: (value: any) => void;
  hasMargin?: boolean;
}

export const Select = ({
  label,
  value,
  options,
  setOption,
  hasMargin,
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisible = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible]);

  const handlePress = React.useCallback(
    (key: string) => {
      setOption(key);
      setIsVisible(false);
    },
    [setIsVisible]
  );

  const selectedValue = options.find((item) => item.key === value)?.value || "";

  if (!options.length) {
    return null;
  }

  return (
    <Container hasMargin={hasMargin}>
      <InputMask onPress={toggleVisible}>
        <TextInput
          label={formatMessage(label)}
          value={selectedValue}
          onChangeText={() => {}}
        />
      </InputMask>
      {isVisible ? (
        <OptionBox>
          {options.map((item) => (
            <Option onPress={() => handlePress(item.key)} key={item.key}>
              <OptionText>{item.value}</OptionText>
            </Option>
          ))}
        </OptionBox>
      ) : null}
    </Container>
  );
};
