import * as React from "react";
import { FlatList } from "react-native";

import { ListItem } from "~/components/list-item";
import { useAppSelector } from "~/state/hooks";
import { getPersonalNotesFiltered } from "~/state/personal-notes/selectors";
import { SortEnum } from "~/state/notes/types";

import { Section, EmptyText, contentContainerStyle } from "./styles";

interface Props {
  searchTerm: string;
  sortMethod: SortEnum;
  emptyMessage: TextType;
  onPress: (sessionId: string) => void;
}

export const Content = React.memo<Props>(
  ({ searchTerm, sortMethod, emptyMessage, onPress }) => {
    const data = useAppSelector((state) =>
      getPersonalNotesFiltered(state, `${sortMethod}:${searchTerm}`)
    );

    return data.length ? (
      <Section>
        <FlatList
          data={data}
          contentContainerStyle={contentContainerStyle}
          showsVerticalScrollIndicator={false}
          renderItem={({ item: { id, title, text, lastUpdated }, index }) => {
            return (
              <ListItem
                title={title}
                description={text}
                lastUpdated={lastUpdated}
                onPress={() => onPress(id)}
                isLast={index === data.length - 1}
                key={title}
                numberOfLines={2}
              />
            );
          }}
        />
      </Section>
    ) : (
      <EmptyText>{emptyMessage}</EmptyText>
    );
  }
);
