import uuid from "react-native-uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  DocumentSnapshot,
} from "firebase/firestore";
import NetInfo from "@react-native-community/netinfo";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";

import { updateNotes, addNote, deleteNote, editNote } from "./slice";
import {
  getPersonalNotes,
  addPersonalNote,
  deletePersonalNote,
  editPersonalNote,
} from "./actions";
import {
  GetPersonalNoteAction,
  AddPersonalNoteAction,
  DeletePersonalNoteAction,
  EditPersonalNoteAction,
  PersonalNote,
} from "./types";
import { getPersonalNotesCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";

export function* getPersonalNotesFn({
  payload: { onSuccess, onError },
}: GetPersonalNoteAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const personalNotesCollection = getPersonalNotesCollection(env);

    const q = query(
      collection(database, personalNotesCollection),
      where("userId", "==", userId)
    );

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = notesSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as PersonalNote[];

    if (data && Array.isArray(data)) {
      yield put(updateNotes(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addPersonalNoteFn({
  payload: { title, text, onSuccess, onError },
}: AddPersonalNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const personalNotesCollection = getPersonalNotesCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const lastUpdated = new Date().getTime();

    const note = {
      id,
      title,
      text,
      lastUpdated,
      userId,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, personalNotesCollection, id),
      note
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addNote(note));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* editPersonalNoteFn({
  payload: { noteId, title, text, onSuccess, onError },
}: EditPersonalNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const personalNotesCollection = getPersonalNotesCollection(env);

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, personalNotesCollection, noteId),
      { title, text }
    );

    // Update the local state to avoid an extra Firebase query
    yield put(editNote({ noteId, title, text }));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deletePersonalNoteFn({
  payload: { noteId, onSuccess, onError },
}: DeletePersonalNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const personalNotesCollection = getPersonalNotesCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, personalNotesCollection, noteId)
    );

    // Update the local state to avoid an extra Firebase query
    yield put(deleteNote(noteId));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const addPersonalNotesSaga = function* () {
  yield takeLatest(addPersonalNote.type, addPersonalNoteFn);
};

export const getPersonalNotesSaga = function* () {
  yield takeLatest(getPersonalNotes.type, getPersonalNotesFn);
};

export const editPersonalNoteSaga = function* () {
  yield takeLatest(editPersonalNote.type, editPersonalNoteFn);
};

export const deletePersonalNoteSaga = function* () {
  yield takeLatest(deletePersonalNote.type, deletePersonalNoteFn);
};
