import * as routes from "~/constants/routes";
import * as Linking from "expo-linking";
import { WEB_APP_URL, FIREBASE_WEB_APP_URL } from "~/constants";
import { getStateFromPath } from "@react-navigation/native";
import { isWeb } from "~/utils/platform";

const getAllPaths = () => {
  const webRoutes = routes as Record<string, string>;
  const routeKeys = Object.keys(routes);
  const config = {} as Record<string, { path: string }>;
  routeKeys.forEach((key: string) => {
    const path = webRoutes[key];
    if (!path) {
      return;
    }

    config[path] = {
      path: `/${path}`,
    };
  });

  return config;
};

const paths = isWeb ? getAllPaths() : {};

export const linkingConfiguration = {
  prefixes: [Linking.createURL("/"), WEB_APP_URL, FIREBASE_WEB_APP_URL],
  config: {
    screens: {
      [routes.inviteCode]: {
        path: "/i/:inviteCode",
      },
      [routes.auth]: {
        path: "/__/auth/:params",
      },
      ...paths,
    },
  },

  getStateFromPath: (path: string, options) => {
    if (path.includes("__/auth/action")) {
      return {
        routes: [
          {
            name: "__",
            params: { path },
          },
        ],
      };
    }
    return getStateFromPath(path, options);
  },
};
