export const messages = {
  personalNote: {
    id: "messages.note.personal",
    defaultMessage: "Note: {{title}}",
  },
  sessionNote: {
    id: "messages.note.session",
    defaultMessage: "**Q:** {{question}}",
  },
};
