import * as React from "react";

import {
  OfflineBannerContainer,
  OfflineBannerDescription,
  OfflineBannerTitle,
} from "./styles";
import { messages } from "./intl";
import { useAppNetInfo } from "~/state/hooks";

interface OfflineBannerProps {
  withTopMargin?: boolean;
}

export const OfflineBanner: React.FC<OfflineBannerProps> = React.memo(
  ({ withTopMargin = true }) => {
    const { isConnected } = useAppNetInfo();

    if (isConnected) return null;

    return (
      <OfflineBannerContainer withTopMargin={withTopMargin}>
        <OfflineBannerTitle>{messages.title}</OfflineBannerTitle>

        <OfflineBannerDescription>
          {messages.description}
        </OfflineBannerDescription>
      </OfflineBannerContainer>
    );
  }
);
