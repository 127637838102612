import * as routes from "~/constants/routes";

import { Alignment, Step } from "./types";
import { messages } from "./intl";

export const tutorialSteps: Step[] = [
  // Home
  {
    id: "NAVIGATION.HOME.TAB",
    text: "Welcome to your personalized home screen! Easily track progress and access the resources you've handpicked. Let's start by adding your first curriculum from the Library!",
    alignment: Alignment.Top,
    group: "home",
    buttonText: messages.seeLibrary,
    nextScreen: routes.libraryTab,
    next: "NAVIGATION.LIBRARY.TAB",
    route: routes.home,
    order: 0,
  },
  {
    id: "HOME.ACTION.PROFILE1",
    text: "Add a picture to your profile! You can also find Help, FAQs, and Support here.",
    alignment: Alignment.Bottom,
    group: "home-profile",
    route: routes.home,
    order: 1,
    isHorizontal: true,
  },
  {
    id: "HOME.ACTION.PROFILE2",
    text: "You can find all your downloaded files within your profile.",
    alignment: Alignment.Bottom,
    group: "home-downloads",
    route: routes.home,
    order: 2,
  },
  {
    id: "HOME.RESOURCES",
    text: "Swipe left and right to easily navigate through each curriculum you've started. Tapping on the curriculum icon will take you directly to its homepage.",
    alignment: Alignment.Bottom,
    group: "home-resources",
    next: "HOME.CONTENT.PROGRESS",
    route: routes.home,
    order: 2,
  },
  {
    id: "HOME.CONTENT.PROGRESS",
    text: "Stay on track with your curriculum! You can quickly access your next session by clicking below. ",
    alignment: Alignment.Top,
    group: "home-resources",
    route: routes.home,
    order: 2,
  },
  // Library
  {
    id: "NAVIGATION.LIBRARY.TAB",
    text: "Dive into each curriculum by tapping on its card to read a description. Select a resource that resonates with you, your family, or your group to kickstart your journey. Let the exploration begin!",
    alignment: Alignment.Top,
    group: "library",
    route: routes.library,
    order: 0,
  },
  // Plan
  {
    id: "PLAN.SESSION.DOWNLOADS",
    text: "Toggle to Session Downloads to access the downloadable resources associated with this session. You have the flexibility to choose where your saved files are stored.",
    alignment: Alignment.Top,
    group: "plan",
    route: routes.plan,
    order: 0,
  },
  // Bible
  {
    id: "NAVIGATION.BIBLE.TAB",
    text: "Engage with God's Word. Take notes and highlight your favorite passages.",
    alignment: Alignment.Top,
    group: "bible",
    next: "BIBLE.CONTENT.ARROWS",
    route: routes.bible,
    order: 0,
  },
  {
    id: "BIBLE.CONTENT.ARROWS",
    text: "For a better reading experience, the navigational arrows disappear. If you need to use them again, tap anywhere on the screen.",
    alignment: Alignment.Top,
    group: "bible",
    route: routes.bible,
    order: 1,
  },
  // Notes
  {
    id: "NAVIGATION.NOTES.TAB",
    text: "Find every note, whether it's an answer in a session, a Bible note, a sermon note, or any other note you've taken in the app.",
    alignment: Alignment.Top,
    next: "NOTES.CONTENT.SESSION",
    group: "notes",
    route: routes.notes,
    order: 0,
  },
  {
    id: "NOTES.CONTENT.SESSION",
    text: "All the notes you've taken during a session will be here, including answers to questions. We've sorted them for you so they're easier to find.",
    alignment: Alignment.Top,
    next: "NOTES.CONTENT.BIBLE",
    group: "notes",
    route: routes.notes,
    order: 1,
  },
  {
    id: "NOTES.CONTENT.BIBLE",
    text: "Find any notes you've taken in the Bible tab here.",
    alignment: Alignment.Top,
    next: "NOTES.CONTENT.PERSONAL",
    group: "notes",
    route: routes.notes,
    order: 1,
  },
  {
    id: "NOTES.CONTENT.PERSONAL",
    text: "Notes not associated with a Session or Bible are classified as Personal Notes. Feel free to utilize this space for journaling your thoughts as well.",
    alignment: Alignment.Top,
    group: "notes",
    route: routes.notes,
    order: 1,
  },
  // Groups
  {
    id: "NAVIGATION.GROUPS.TAB",
    text: "All our resources were meant to be experienced in community. Groups is where that happens.",
    alignment: Alignment.Top,
    group: "groups",
    next: "GROUPS.ACTION.CREATE",
    route: routes.groups,
    order: 0,
  },
  {
    id: "GROUPS.ACTION.CREATE",
    text: "It's easy to create a new group. To get started just follow the steps and start growing together!",
    alignment: Alignment.Bottom,
    group: "groups",
    route: routes.groups,
    order: 1,
  },
  {
    id: "GROUPS.CONTENT.PROFILE",
    text: "Manage your group by tapping the group icon. Easily invite new members or remove those who are no longer part of the group.",
    alignment: Alignment.Bottom,
    group: "groups-modal",
    next: "GROUPS.CONTENT.MESSAGES",
    route: routes.groupResources,
    order: 2,
  },
  {
    id: "GROUPS.CONTENT.MESSAGES",
    text: "Stay connected with your group and discuss content seamlessly using in-app messages!",
    alignment: Alignment.Bottom,
    group: "groups-modal",
    route: routes.groupResources,
    order: 2,
  },
  {
    id: "GROUPS.ACTION.MEMBERS.EDIT",
    text: "Remove group members by clicking the more icon and selecting the option to remove members.",
    alignment: Alignment.Bottom,
    group: "groups-members",
    route: routes.groups,
    order: 3,
  },
  // Sessions
  {
    id: "SESSIONS.DOWNLOADS",
    text: "Swipe left on any session to download files that go with that lesson. You have the flexibility to choose where your saved files are stored.",
    alignment: Alignment.Bottom,
    group: "sessions",
    route: routes.session,
    order: 1,
  },
];
