import {
  AddSessionProgressParams,
  DayProgress,
  ProgressState,
  SessionProgress,
} from "./types";

export function getNewSessionProgressToStore({
  userId,
  planId,
  volumeId,
  sessionId,
  totalDays,
  dayKey,
  initialState,
  omitMarkingDayInProgress = false,
}: AddSessionProgressParams & {
  userId: string;
}): SessionProgress {
  // Maps the initial state of the days to be stored
  const daysProgressMap = Array.from({ length: totalDays + 1 }, (_, index) => {
    const key = `day${index}`;

    // first day is marked as in progress by default if there is not a specific day that was pressed
    const state =
      !omitMarkingDayInProgress && !dayKey && index === 0
        ? ProgressState.InProgress
        : // if there is a provided dayKey, we mark it as in progrees
        dayKey === key
        ? ProgressState.InProgress
        : ProgressState.NotStarted;

    return {
      key,
      state: initialState ?? state,
    };
  });

  return {
    userId,
    planId,
    volumeId,
    sessionId,
    // Initializing session as in progress by default
    state: initialState ?? ProgressState.InProgress,
    days: daysProgressMap,
    lastUpdated: Date.now(),
  };
}

export const getHasAnyProgress = (data: DayProgress[]) =>
  data.find(({ state }) =>
    [ProgressState.InProgress, ProgressState.Completed].includes(state)
  );
