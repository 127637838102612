import * as React from "react";

import { messages as sessionMessages } from "~/screens/session/intl";
import type { EngageSection } from "~/state/flamelink/types";
import { formatMessage } from "~/utils/translation";

import { SectionTitle, SubsectionSubtitle, SubsectionTitle } from "../common";
import { Checklist } from "../checklist";
import { Media } from "../media";
import { RichText } from "../rich-text";
import { Question } from "../question";
import { Footnotes } from "../footnotes";
import { WhatWeBelieve } from "../what-we-believe";
import { ContentIcon } from "../content-icon";

interface EngageProps {
  engage: EngageSection;
}

export const Engage: React.FC<EngageProps> = ({ engage }) => {
  return (
    <>
      <SectionTitle>{engage?.sectionTitle}</SectionTitle>

      <RichText>{engage?.intro}</RichText>

      <Footnotes data={engage?.footnotes} />

      {Array.isArray(engage?.contentRepeater) &&
        engage?.contentRepeater.map(
          ({ question, uniqueKey, content, footnotes }, index) => (
            <React.Fragment key={`content-repeater-${index}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>

              <Footnotes data={footnotes} />
            </React.Fragment>
          )
        )}

      <RichText>{engage?.content}</RichText>

      <WhatWeBelieve data={engage?.whatWeBelieve} />

      <GroupActivity engage={engage} />

      <RichText>{engage?.conclusion}</RichText>

      {Array.isArray(engage?.conclusionRepeater) &&
        engage?.conclusionRepeater.map(
          ({ question, uniqueKey, content, footnotes }, index) => (
            <React.Fragment key={`conclusion-repeater-${index}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>

              <Footnotes data={footnotes} />
            </React.Fragment>
          )
        )}

      <ContentIcon
        content={[
          engage?.intro,
          engage?.content,
          engage?.contentRepeater,
          engage?.conclusion,
        ]}
      />
    </>
  );
};

const GroupActivity: React.FC<EngageProps> = ({ engage }) => {
  const hasContent = React.useMemo(
    () =>
      engage?.groupActivity?.subtitle ||
      engage?.groupActivity?.youWillNeed ||
      engage?.groupActivity?.gettingReady ||
      engage?.groupActivity?.content ||
      engage?.groupActivity?.media?.length ||
      engage?.groupActivity?.conclusion,
    [engage?.groupActivity]
  );

  return hasContent ? (
    <>
      {engage?.groupActivity?.sectionTitle && (
        <SubsectionTitle>{engage.groupActivity.sectionTitle}</SubsectionTitle>
      )}

      {engage?.groupActivity?.subtitle && (
        <SubsectionSubtitle>{engage.groupActivity.subtitle}</SubsectionSubtitle>
      )}

      {engage?.groupActivity?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={engage.groupActivity.youWillNeed}
          checklistId="groupActivity-youWillNeed"
        />
      )}

      {engage?.groupActivity?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={engage.groupActivity.gettingReady}
          checklistId="groupActivity-gettingReady"
        />
      )}

      {engage?.groupActivity?.content && (
        <RichText>{engage?.groupActivity?.content}</RichText>
      )}

      {Array.isArray(engage?.groupActivity?.media) &&
        engage?.groupActivity?.media.map((fileId, mediaIndex) => (
          <Media key={`groupActivity-media-${mediaIndex}`} fileId={fileId} />
        ))}

      {engage?.groupActivity?.conclusion && (
        <RichText>{engage?.groupActivity?.conclusion}</RichText>
      )}

      {Array.isArray(engage?.groupActivity?.questions) &&
        engage?.groupActivity?.questions.map(
          ({ question, uniqueKey, content }, index) => (
            <React.Fragment key={`groupActivity-questions-${index}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>
            </React.Fragment>
          )
        )}
    </>
  ) : null;
};
