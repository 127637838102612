export const messages = {
  title: {
    id: "resources.offline.title",
    defaultMessage: "You are offline",
  },
  description: {
    id: "resources.offline.description",
    defaultMessage:
      "Connect to the internet to use all app features. While offline you can access any session and Bible translation you have downloaded.",
  },
  buttonDownloads: {
    id: "resources.offline.button.downloads",
    defaultMessage: "Downloads",
  },
  buttonTryAgain: {
    id: "resources.offline.button.try-again",
    defaultMessage: "Try again",
  },
};
