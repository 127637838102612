export const messages = {
  title: {
    id: "pif.custom-amount.title",
    defaultMessage: "Enter donation amount",
  },
  enter: {
    id: "pif.custom-amount.enter",
    defaultMessage: "Enter custom amount",
  },
  done: {
    id: "pif.custom-amount.done",
    defaultMessage: "Done",
  },
};
