export const messages = {
  sessionDownloads: {
    id: "content-sections.downloads",
    defaultMessage: "Session Downloads",
  },
  gameSlideAMedia: {
    id: "session-downloads.game-slide-a-media",
    defaultMessage: "Game Slide A",
  },
  gameSlideBMedia: {
    id: "session-downloads.game-slide-b-media",
    defaultMessage: "Game Slide B",
  },
  bibleLessonIntroVideoMedia: {
    id: "session-downloads.bible-lesson-video-intro-media",
    defaultMessage: "Bible Lesson Video A",
  },
  bibleLessonStoryVideoMedia: {
    id: "session-downloads.bible-story-video-media",
    defaultMessage: "Bible Lesson Video B",
  },
  faithFactMedia: {
    id: "session-downloads.faith-fact-media",
    defaultMessage: "Faith Fact Slide",
  },
  watchVideoMedia: {
    id: "session-downloads.watch-video-media",
    defaultMessage: "Watch Video",
  },
  facilitatorGuideMedia: {
    id: "session-downloads.facilitator-guide-media",
    defaultMessage: "Facilitator Guide",
  },
  downloadSession: {
    id: "session-downloads.download-all",
    defaultMessage: "Download All Session {{index}}",
  },
};
