export const messages = {
  title: {
    id: "change-email.title",
    defaultMessage: "Edit Email",
  },
  email: {
    id: "change-email.email",
    defaultMessage: "Email",
  },
  save: {
    id: "change-email.save",
    defaultMessage: "Save",
  },
  emailSaved: {
    id: "change-email.email-saved",
    defaultMessage: "Email edited successfully!",
  },
  error: {
    id: "change-email.error",
    defaultMessage: "An error occurred. Please try again later.",
  },
};
