import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GroupsState, Group } from "./types";

const initialState: GroupsState = {
  data: [],
  inviteCode: "",
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    updateGroups: (state, action: PayloadAction<Group[]>) => {
      state.data = action.payload;
    },
    setInviteCode: (state, action: PayloadAction<string>) => {
      state.inviteCode = action.payload;
    },
    resetInviteCode: (state) => {
      state.inviteCode = initialState.inviteCode;
    },
    countUpdate: (state, action) => {
      const groupId = action.payload.groupId;
      const userId = action.payload.userId;

      const data = state.data.map((group) => {
        if (group.id !== groupId) {
          return group;
        }
        const totalMessages = group?.totalMessages || 0;

        const members = group?.members.map((member) => {
          if (member.id !== userId) {
            return member;
          }
          return {
            ...member,
            messagesRead: totalMessages,
          };
        });

        return {
          ...group,
          members,
        };
      });

      state.data = data;
    },
  },
});

export const { updateGroups, setInviteCode, resetInviteCode, countUpdate } =
  groupsSlice.actions;

export default groupsSlice.reducer;
