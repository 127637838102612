import * as React from "react";
import { GestureResponderEvent } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";

import { GroupAvatar } from "~/components/group-avatar";
import { AvatarSize } from "~/components/group-avatar/types";
import { Camera } from "~/components/camera";

import { useCamera, ImageProps } from "~/utils/hooks/use-camera";

import { Container, CameraBox } from "./styles";

const size = {
  width: 250,
  height: 250,
};

interface Props {
  groupName: string;
  imageUri?: string;
  setImageUri?: (uri: string) => void;
  isDisabled?: boolean;
  isSmall?: boolean;
  onChange?: (imageUri: string) => void;
}

export const ChangeImage = React.memo<Props>(
  ({ groupName, isDisabled, imageUri, setImageUri, isSmall, onChange }) => {
    const handleImage = React.useCallback(
      ({ uri }: ImageProps) => {
        if (setImageUri) {
          setImageUri(uri);
        }
        if (typeof onChange === "function") {
          onChange(uri);
        }
      },
      [setImageUri, onChange]
    );

    const { onShowOptions, isModalVisible, handleCloseModal } = useCamera({
      size,
      handleImage,
    });

    const onPress = React.useCallback(
      (e: GestureResponderEvent) => {
        if (isDisabled) {
          return;
        }
        onShowOptions(e);
      },
      [onShowOptions, isDisabled]
    );

    const avatarSize = isSmall ? AvatarSize.Medium : AvatarSize.Large;

    return (
      <>
        <Container onPress={onPress} disabled={isDisabled} isSmall={isSmall}>
          <GroupAvatar
            text={groupName || "Group 1"}
            size={avatarSize}
            uri={imageUri}
          />
          {!isDisabled ? (
            <CameraBox>
              <Icon name="camera-alt" />
            </CameraBox>
          ) : null}
        </Container>
        {isModalVisible ? (
          <Camera handlePicture={handleImage} onClose={handleCloseModal} />
        ) : null}
      </>
    );
  }
);
