import React from "react";
import { TouchableOpacity, SectionListRenderItem } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import { useActionSheet } from "@expo/react-native-action-sheet";

import { ModalHeader } from "~/components/modal-header";
import {
  SettingsListItemContainer,
  SettingsScreen,
} from "~/components/settings";
import { NoDownloadsFound } from "~/components/no-downloads-found";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  deleteAllDownloads,
  DownloadedFile,
  DownloadSection,
  getDownloadedFilesByPlan,
  getDownloadedFilesBySession,
  setDownloads,
} from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";

import { SectionTitle } from "./components/section-title";
import {
  DownloadsList,
  GeneralStatsText,
  GeneralStatsWrapper,
  MoreIcon,
} from "./styles";
import { bytesToMegabytes, getDownloadedBibles } from "./utils";
import { messages } from "./intl";

import { SectionPanel } from "./components/section-panel";
import { DownloadedItem } from "./components/download-item";

export const Downloads = () => {
  const [activeSessionId, setActiveSessionId] = React.useState("");
  const { bottom } = useSafeAreaInsets();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { showActionSheetWithOptions } = useActionSheet();
  const downloadedFiles = useAppSelector(getDownloadedFilesBySession);
  const downloadedFilesByPlan = useAppSelector(getDownloadedFilesByPlan);

  const totalFiles = React.useMemo(
    () =>
      downloadedFiles.reduce((acc, section) => acc + section.data.length, 0),
    [downloadedFiles]
  );

  const totalSize = React.useMemo(
    () => downloadedFiles.reduce((acc, section) => acc + section.size, 0),
    [downloadedFiles]
  );

  // TODO: find a better place for this dispatch
  React.useEffect(() => {
    getDownloadedBibles().then((data) =>
      data.map((item) => dispatch(setDownloads(item)))
    );
  }, []);

  const onDonePress = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  const renderItem: SectionListRenderItem<DownloadSection> = ({ item }) => {
    if (item.isBible) {
      return (
        <>
          {item?.data.map((file, index) => (
            <DownloadedItem
              key={index}
              item={file}
              isLast={false}
              isActive={false}
            />
          ))}
        </>
      );
    }
    return (
      <SectionPanel
        sessionId={item.sessionId}
        data={item?.data}
        isActive={activeSessionId === item.sessionId}
        setActive={setActiveSessionId}
      />
    );
  };

  const renderSectionHeader: SectionListRenderItem<DownloadedFile> = ({
    section: { planId, planTitle, isBible },
  }) => (
    <SectionTitle planId={planId} planTitle={planTitle} isBible={isBible} />
  );

  const onMorePress = React.useCallback(() => {
    showActionSheetWithOptions(
      {
        options: [
          formatMessage(messages.deleteAll),
          formatMessage(messages.cancel),
        ],
        destructiveButtonIndex: 0,
        cancelButtonIndex: 1,
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case 0: {
            dispatch(deleteAllDownloads());
            return;
          }
        }
      }
    );
  }, [dispatch, showActionSheetWithOptions]);

  return (
    <SettingsScreen screenName="downloads" withGrayBackground>
      <ModalHeader
        onLeftPress={onDonePress}
        onRightPress={onDonePress}
        title={messages.title}
      />

      {totalFiles === 0 ? (
        <NoDownloadsFound />
      ) : (
        <>
          <SettingsListItemContainer>
            <GeneralStatsWrapper>
              <GeneralStatsText>
                {`${totalFiles} files · ${bytesToMegabytes(totalSize)} MB`}
              </GeneralStatsText>

              <TouchableOpacity onPress={onMorePress}>
                <MoreIcon />
              </TouchableOpacity>
            </GeneralStatsWrapper>
          </SettingsListItemContainer>

          {/* @ts-ignore */}
          <DownloadsList
            sections={downloadedFilesByPlan}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            showsVerticalScrollIndicator={false}
            extraMarginBottom={bottom}
            stickySectionHeadersEnabled={false}
          />
        </>
      )}
    </SettingsScreen>
  );
};
