import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";

export const WebContainer = styled.View`
  flex-direction: row;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  flex: 1;
`;

export const NavigationContainer = styled.View`
  margin-top: ${spacers.ss8};
  width: 300px;
`;

export const ContentContainer = styled.View`
  flex: 1;
`;

export const Section = styled.View``;
