import styled, { css } from "styled-components/native";
import { Text2 } from "~/components/text";
import { spacers, colors, lineHeights } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";

export const webStyle = isWeb
  ? css`
      ${getWebContainerStyle(500)};
      margin-vertical: ${spacers.ss6};
    `
  : "";

export const NoItemsContainer = styled.View`
  margin: ${spacers.ss6};
  padding: ${spacers.ss6};
  border-width: 1px;
  border-color: ${colors.gray300};
  background-color: ${colors.gray50};
  border-radius: ${spacers.ss6};
  margin-bottom: ${spacers.ss6};
  align-items: center;
  ${webStyle}
`;

export const NoItemsTitle = styled(Text2)`
  font-family: MontserratBold;
  margin: ${spacers.ss4} 0 0;
  color: ${colors.gray600};
  line-height: ${lineHeights.lh2};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
