import React from "react";

import {
  getPaymentMethodString,
  getExpiryDateString,
} from "~/state/payments/utils";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { updateDefaultPaymentMethod } from "~/state/payments/actions";
import { getPaymentMethods, getSubscriptionMethodId } from "~/state/payments";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { AccountHeader } from "../../../header";
import { Title } from "../../styles";
import { Screens } from "../../../../types";

import {
  Container,
  TopContainer,
  Item,
  ItemTitle,
  ExpiryDate,
  Details,
  Selector,
  SelectorDot,
} from "./styles";
import { messages } from "./intl";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

export const PaymentMethod = React.memo<Props>(({ setScreen }) => {
  const paymentMethods = useAppSelector(getPaymentMethods);
  const paymentMethodId = useAppSelector(getSubscriptionMethodId);
  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<string | null>(
    paymentMethodId
  );

  const hasChanged = !!selectedId && selectedId !== paymentMethodId;

  const onSave = React.useCallback(() => {
    if (!selectedId) {
      return;
    }
    setIsSaving(true);
    dispatch(
      updateDefaultPaymentMethod({
        methodId: selectedId,
        onSuccess: () => {
          pushAlert({
            message: messages.saved,
            color: colors.emerald600,
          });
          setIsSaving(false);
          setScreen(Screens.Manage);
        },
        onError: () => setIsSaving(false),
      })
    );
  }, [dispatch, selectedId, setScreen, pushAlert]);

  return (
    <Container>
      <TopContainer>
        <AccountHeader
          onBackPress={() => setScreen(Screens.Manage)}
          onSave={onSave}
          isSaveDisabled={!hasChanged}
          isLoading={isSaving}
        />

        <Title>{messages.title}</Title>
      </TopContainer>

      {paymentMethods.map((item, index) => {
        const id = item.id;
        const isSelected = id === selectedId;
        return (
          <Item
            key={id}
            isLast={index === paymentMethods.length - 1}
            onPress={() => setSelectedId(id)}
          >
            <Selector>{isSelected ? <SelectorDot /> : null}</Selector>
            <Details>
              <ItemTitle>{getPaymentMethodString(item)}</ItemTitle>
              <ExpiryDate>
                {{
                  ...messages.expiryDate,
                  values: { date: getExpiryDateString(item) },
                }}
              </ExpiryDate>
            </Details>
          </Item>
        );
      })}

      <Button
        text={messages.addNewCard}
        type={ButtonTypes.Secondary}
        onPress={() => setScreen(Screens.PaymentMethodAdd)}
      />
    </Container>
  );
});
