import styled, { css } from "styled-components/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text1, Text2 } from "~/components/text";
import { IconSizes } from "~/components/icon";
import { colors, lineHeights, spacers } from "~/styles/theme";

export const Container = styled.View`
  margin: ${spacers.ss5};
`;

export const Title = styled(Text1)`
  font-family: SFProDisplayMedium;
  font-style: normal;
  line-height: ${lineHeights.lh1};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 ${spacers.ss4};
`;

interface OptionProps {
  isFirst: boolean;
  isLast: boolean;
}

export const Option = styled.TouchableOpacity<OptionProps>`
  flex-direction: row;
  background-color: ${colors.gray50};
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: ${spacers.ss5};
  border-color: ${colors.gray200};

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: ${spacers.ss5};
      border-top-right-radius: ${spacers.ss5};
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: ${spacers.ss5};
      border-bottom-right-radius: ${spacers.ss5};
    `}

  ${({ isLast }) =>
    !isLast &&
    css`
      border-bottom-width: 1px;
    `}
`;

export const OptionTitle = styled(Text2)`
  font-family: SFPro;
  line-height: ${lineHeights.lh2};
`;

export const CheckIcon = styled(MaterialCommunityIcons).attrs({
  name: "check",
  color: colors.green600,
  size: IconSizes.SMedium,
})``;
