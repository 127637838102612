import Constants from "expo-constants";

import { stripeProps } from "~/constants";

import { PaymentMethod, Transaction, TransactionSection } from "./types";
import { RootState } from "../store";
import { auth } from "<config>/firebase";

export const getStripeProps = (state: RootState) => {
  const env = state?.user?.environment;
  const config = Constants?.expoConfig?.extra;
  const publishableKey =
    env === "stg" ? config?.publishableKeyStg : config?.publishableKeyPrd;

  return {
    ...stripeProps,
    publishableKey,
  };
};

export const getTotalAmount = (transactions: Transaction[] = []) => {
  return transactions.reduce((acc, item) => {
    if (Number.isNaN(item.amount) || item?.status !== "succeeded") {
      return acc;
    }
    return acc + item.amount;
  }, 0);
};

export const getTransformedData = (transactions: Transaction[] = []) =>
  transactions.reduce((acc: Array<TransactionSection>, transaction) => {
    const date = new Date(transaction.created * 1000);
    const title = date.getFullYear();
    const existingData = acc.find((data) => data.title === title);

    if (transaction?.status !== "succeeded") {
      return acc;
    }

    if (existingData) {
      return acc.map((item) => {
        if (item.title === title) {
          return { title, data: [...existingData.data, transaction] };
        }
        return item;
      });
    }
    return [...acc, { title, data: [transaction] }];
  }, []);

const zeroDecimalCurrencies = [
  "bif",
  "clp",
  "djf",
  "gnf",
  "jpy",
  "kmf",
  "krw",
  "mga",
  "pyg",
  "rwf",
  "ugx",
  "vnd",
  "vuv",
  "xaf",
  "xof",
  "xpf",
];

export const supportedCurrencyMap = new Map([["usd", "$"]]);

export const transformAmount = (amount: number, currency: string) => {
  if (!amount || Number.isNaN(amount)) {
    return "";
  }
  const isZeroDecimal = zeroDecimalCurrencies.includes(currency);
  const normalisedAmount = isZeroDecimal ? amount : (amount / 100).toFixed(2);
  const currencySign = supportedCurrencyMap.get(currency) || "";

  return `${currencySign}${normalisedAmount}`;
};

export const getApiOptions = (data: Object) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
});

export const getApiHeaders = async () => {
  const bearerToken = await auth?.currentUser?.getIdToken();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  };
};

export const getPaymentMethodString = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod?.card) {
    return "";
  }

  const displayBrand = paymentMethod?.card?.display_brand || "";
  const last4 = paymentMethod?.card?.last4 || "";
  const brand = displayBrand.charAt(0).toUpperCase() + displayBrand.slice(1);

  return `${brand} **** **** **** ${last4}`;
};

export const getExpiryDateString = (paymentMethod: PaymentMethod) => {
  if (!paymentMethod?.card) {
    return "";
  }

  const expiryMonth = paymentMethod?.card?.exp_month || "";
  const expiryYear = paymentMethod?.card?.exp_year || "";

  return `${expiryMonth}/${expiryYear}`;
};

export const getOffSessionIntentId = (paymentIntents: Transaction[]) => {
  return paymentIntents.find(
    (item) => item.setup_future_usage === "off_session"
  )?.id;
};
