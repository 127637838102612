import styled from "styled-components/native";

import { colors } from "~/styles/theme";

export const MethodBox = styled.View`
  height: 48px;
  background-color: ${colors.gray100};
  border-radius: 8px;
  padding: 16px 10px;
`;
