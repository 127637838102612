import React from "react";

import { UiContext } from "./context";

type Props = {
  children: React.ReactNode;
};

export const UiProvider = ({ children }: Props) => {
  const [homeCarouselIndex, setHomeCarouselIndex] = React.useState(0);

  const state = {
    homeCarouselIndex,
    setHomeCarouselIndex,
  };

  return <UiContext.Provider value={state}>{children}</UiContext.Provider>;
};
