import AsyncStorage from "@react-native-async-storage/async-storage";
import { handleError } from "./logger";

const STORE_KEY = "@isFirstInstalled";

export const getIsFirstInstalled = async () => {
  try {
    const hasBeenInstalled = await AsyncStorage.getItem(STORE_KEY);
    if (!hasBeenInstalled) {
      await AsyncStorage.setItem(STORE_KEY, "true");
      return true;
    }
    return false;
  } catch (e) {
    handleError(e);
    return false;
  }
};
