import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentState,
  Subscription,
  PaymentMethod,
  Transaction,
} from "./types";

export const initialReducerState: PaymentState = {
  subscription: null,
  paymentIntents: [],
  paymentMethods: [],
  currency: "usd",
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialReducerState,
  reducers: {
    setSubscription: (state, action: PayloadAction<Subscription | null>) => {
      state.subscription = action.payload;
    },
    setSubscriptionPaymentMethod: (state, action: PayloadAction<string>) => {
      state.subscription = state.subscription
        ? {
            ...state.subscription,
            paymentMethodId: action.payload,
          }
        : null;
    },
    resetSubscription: (state) => {
      state.subscription = null;
    },
    setPaymentIntents: (state, action: PayloadAction<Transaction[]>) => {
      state.paymentIntents = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = action.payload;
    },
  },
});

export const {
  setSubscription,
  resetSubscription,
  setPaymentIntents,
  setPaymentMethods,
  setSubscriptionPaymentMethod,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
