import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Card, Grid, styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getStatsHistory, changeDateRange } from "~/state/stats";
import {
  getCurrentStatsRelativeTime,
  getiOSDownloadsHistory,
  getAndroidDownloadsHistory,
  getDateRange,
  getCurrentStats,
} from "~/state/stats/selectors";
import { Screen } from "~/components/screen";
import { HeaderBar } from "~/components/header-bar";
import { FlexBetween } from "~/dashboard/components/flex-box";
import { H3, Span, Paragraph } from "~/dashboard/components/typography";
import { LOOKER_GEO_REPORT } from "~/dashboard/constants";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";
import { ComparisonChart } from "./chart";
import { GaugeProgressCard } from "./gauge-card";
import { Cards } from "./cards";
import { StatCards } from "./stat-cards";
import { LineChart } from "./line-chart";
import { AppSessions } from "./app-sessions";
import { SessionProgress } from "./session-progress";
import { colors } from "~/styles/theme";

const AnalyticsRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  paddingBottom: "30px",
  fontFamily: "Roboto",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

export const Analytics = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useAppDispatch();

  const statsRelativeTime = useAppSelector(getCurrentStatsRelativeTime);
  const iosDownloads = useAppSelector(getiOSDownloadsHistory);
  const androidDownloads = useAppSelector(getAndroidDownloadsHistory);
  const dateRange = useAppSelector(getDateRange);
  const currentStats = useAppSelector(getCurrentStats);

  const onSettled = React.useMemo(
    () => ({
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    }),
    []
  );

  React.useEffect(() => {
    dispatch(
      getStatsHistory({
        onSuccess: () => setIsLoading(false),
        onError: () => setIsLoading(false),
      })
    );
  }, [dispatch, onSettled]);

  const setDateRange = React.useCallback(
    (newDateRange: Array<Dayjs>) => {
      dispatch(
        changeDateRange({
          from: newDateRange[0],
          to: newDateRange[1],
        })
      );
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(
      changeDateRange({
        from: dateRange.from,
        to: dayjs(),
      })
    );
  }, []);

  if (isLoading || !currentStats) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Screen screenName="analytics" backgroundColor={colors.gray50}>
        <HeaderBar withBackButton />

        <AnalyticsRoot>
          <FlexBetween mb={2}>
            <H3 sx={{ m: 0 }}>{formatMessage(messages.overview)}</H3>
            <SingleInputDateRangeField
              label="From - To"
              size="small"
              defaultValue={[dayjs(dateRange.from), dayjs(dateRange.to)]}
              style={{ width: 270 }}
              onChange={setDateRange}
            />
          </FlexBetween>

          <Cards />

          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <Card sx={{ my: 3 }} elevation={3}>
                <FlexBetween p={2} mb={2} bgcolor="rgba(0, 0, 0, 0.02)">
                  <Span sx={{ fontWeight: "500", color: "text.secondary" }}>
                    {formatMessage(messages.statistics)}
                  </Span>
                </FlexBetween>

                <ComparisonChart height={400} />
              </Card>
            </Grid>

            <Grid item md={4} xs={12} sx={{ my: 3 }}>
              <LineChart
                title="iOS"
                subtitle={formatMessage(messages.totalDownloads)}
                data={iosDownloads}
              />
              <LineChart
                title="Android"
                subtitle={formatMessage(messages.totalDownloads)}
                data={androidDownloads}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <StatCards />
            </Grid>

            <Grid item md={4} xs={12}>
              <GaugeProgressCard />
            </Grid>

            <Grid item md={4} xs={12}>
              <iframe
                width="100%"
                height="360"
                src={LOOKER_GEO_REPORT}
                style={{ border: 0 }}
              ></iframe>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <AppSessions />
            </Grid>

            <Grid item md={6} xs={12}>
              <SessionProgress />
            </Grid>
          </Grid>
          <Grid>
            {statsRelativeTime ? (
              <Paragraph sx={{ m: 0 }}>
                {formatMessage(messages.lastUpdated, {
                  value: statsRelativeTime,
                })}
              </Paragraph>
            ) : null}
          </Grid>
        </AnalyticsRoot>
      </Screen>
    </LocalizationProvider>
  );
};
