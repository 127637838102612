export const messages = {
  title: {
    id: "consent.title",
    defaultMessage: "Notice.",
  },
  text: {
    id: "consent.text",
    defaultMessage:
      "Bible Engagment Project uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our legal policies:",
  },
  privacy: {
    id: "consent.privacy",
    defaultMessage: "Privacy Policy",
  },
  cookies: {
    id: "consent.cookies",
    defaultMessage: "Cookies Policy",
  },
  and: {
    id: "consent.and",
    defaultMessage: "And",
  },
};
