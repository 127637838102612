export const messages = {
  inProgressTitle: {
    id: "library-progress.in-progress-title",
    defaultMessage: "In Progress ({{count}})",
  },
  completedTitle: {
    id: "library-progress.completed-title",
    defaultMessage: "Completed ({{count}})",
  },
  noPlansInProgress: {
    id: "library-progress.no-plans-found.no-plans-in-progress",
    defaultMessage: "No plans in progress yet",
  },
  noPlansCompleted: {
    id: "library-progress.no-plans-found.no-plans-completed",
    defaultMessage: "No plans completed yet",
  },
};
