import React from "react";
import { Keyboard, ScrollView, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import { SettingsScreen } from "~/components/settings";
import { ModalHeader } from "~/components/modal-header";
import * as routes from "~/constants/routes";
import { IconSizes } from "~/components/icon";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import { useAppDispatch } from "~/state/hooks";
import { addAppFeedback, AddAppFeedbackAction } from "~/state/app-feedback";
import { Button } from "~/components/button";
import { Chip } from "~/components/chip";

import { messages } from "./intl";
import { CHIPS, STARS } from "./constants";
import {
  AppFeedbackDescription,
  AppFeedbackTitle,
  ChipsContainer,
  FeedbackContainer,
  FeedbackInput,
  Separator,
  StarsContainer,
  SubmitButtonContainer,
} from "./styles";
import { useKeyboard } from "~/utils/keyboard";

const PADDING_BOTTOM_OFFSET = 20;

export type AppFeedbackProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.appFeedback
>;

export const AppFeedback = () => {
  const navigation = useNavigation<AppFeedbackProps["navigation"]>();
  const dispatch = useAppDispatch();
  const { keyboardHeight } = useKeyboard();
  const scrollViewRef = React.useRef<ScrollView>(null);

  const [selectedChips, setSelectedChips] = React.useState<string[]>([]);
  const [rating, setRating] =
    React.useState<AddAppFeedbackAction["payload"]["rating"]>(0);
  const [feedback, setFeedback] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const onDonePress = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onStarPress = React.useCallback(
    (star: AddAppFeedbackAction["payload"]["rating"]) => {
      setRating(star);
    },
    []
  );

  const onChipPress = React.useCallback(
    (chip: string) => {
      if (selectedChips.includes(chip)) {
        const filteredChips = selectedChips.filter((c) => c !== chip);
        setSelectedChips(filteredChips);

        return;
      }

      setSelectedChips([...selectedChips, chip]);
    },
    [selectedChips]
  );

  const onFeedbackChange = React.useCallback((text: string) => {
    setFeedback(text);
  }, []);

  const onFeedbackSubmit = React.useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);

    const data: AddAppFeedbackAction["payload"] = {
      rating,
      feedback,
      improvements: selectedChips,
      onCompleted: () => {
        setIsLoading(false);
        setIsSubmitted(true);
      },
    };

    dispatch(addAppFeedback(data));
  }, [dispatch, feedback, isLoading, rating, selectedChips]);

  const onSubmitEditting = () => Keyboard.dismiss();

  const isFormDisabled = React.useMemo(() => {
    return rating === 0 || feedback.length === 0;
  }, [feedback.length, rating]);

  React.useEffect(() => {
    if (keyboardHeight > 0) {
      setTimeout(() => {
        scrollViewRef?.current?.scrollToEnd();
      }, 0);
    }
  }, [keyboardHeight]);

  return (
    <SettingsScreen
      screenName="app-feedback"
      paddingBottom={keyboardHeight - PADDING_BOTTOM_OFFSET}
    >
      <ModalHeader
        onLeftPress={onDonePress}
        onRightPress={onDonePress}
        title={messages.title}
      />

      {isSubmitted ? (
        <AppFeedbackTitle>{messages.thankYou}</AppFeedbackTitle>
      ) : (
        <ScrollView
          ref={scrollViewRef}
          onContentSizeChange={() => {
            scrollViewRef?.current?.scrollToEnd();
          }}
        >
          <AppFeedbackTitle>{messages.subtitle}</AppFeedbackTitle>

          <AppFeedbackDescription>
            {messages.description}
          </AppFeedbackDescription>

          <StarsContainer>
            {STARS.map((star) => (
              <TouchableOpacity onPress={() => onStarPress(star)} key={star}>
                <Icon
                  name={rating >= star ? "star" : "star-outline"}
                  size={IconSizes.XLarge}
                  color={colors.primaryBlue}
                />
              </TouchableOpacity>
            ))}
          </StarsContainer>

          <Separator />

          <AppFeedbackDescription>
            {messages.improvements}
          </AppFeedbackDescription>

          <ChipsContainer>
            {CHIPS.map((chip) => (
              <Chip
                key={chip.value}
                selected={selectedChips.includes(chip.value)}
                label={chip.label}
                value={chip.value}
                onPress={onChipPress}
              />
            ))}
          </ChipsContainer>

          <Separator />

          <FeedbackContainer>
            <FeedbackInput
              onChangeText={onFeedbackChange}
              onSubmitEditing={onSubmitEditting}
              returnKeyType="done"
              value={feedback}
              selectionColor={colors.indigo400}
            />

            <SubmitButtonContainer>
              <Button
                onPress={onFeedbackSubmit}
                isDisabled={isFormDisabled || isLoading}
                text={formatMessage(messages.submit)}
                isLoading={isLoading}
              />
            </SubmitButtonContainer>
          </FeedbackContainer>
        </ScrollView>
      )}
    </SettingsScreen>
  );
};
