import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgLogoApple = (props) => (
  <Svg
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.22 3.76c.57-.683.966-1.62.966-2.557 0-.127-.012-.266-.035-.37-.918.035-2.035.613-2.697 1.4-.524.59-1.012 1.527-1.012 2.475 0 .139.023.29.035.336.058.011.15.023.244.023.837 0 1.884-.555 2.5-1.307Zm.652 1.503c-1.395 0-2.523.845-3.244.845-.768 0-1.79-.798-3-.798C5.338 5.31 3 7.207 3 10.758c0 2.232.872 4.569 1.93 6.084.919 1.284 1.721 2.325 2.86 2.325 1.14 0 1.652-.752 3.07-.752 1.43 0 1.756.729 3.012.729s2.082-1.134 2.86-2.256c.884-1.284 1.256-2.533 1.268-2.603-.07-.023-2.465-.983-2.465-3.713 0-2.348 1.884-3.4 1.988-3.481-1.232-1.781-3.128-1.828-3.65-1.828Z"
      fill="currentColor"
    />
  </Svg>
);

export default SvgLogoApple;
