export const messages = {
  title: {
    id: "groups.title",
    defaultMessage: "Groups",
  },
  oneMember: {
    id: "groups.one-member",
    defaultMessage: "1 member",
  },
  members: {
    id: "groups.members",
    defaultMessage: "{{count}} members",
  },
  createGroup: {
    id: "groups.button-create",
    defaultMessage: "Create a new group",
  },
  joinGroup: {
    id: "groups.button-join",
    defaultMessage: "Join a group",
  },
  cancel: {
    id: "groups.button-cancel",
    defaultMessage: "Cancel",
  },
  empty: {
    id: "groups.empty",
    defaultMessage: "You haven't joined any groups yet...",
  },
};
