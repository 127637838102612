import { createAction } from "@reduxjs/toolkit";

import {
  GetBibleNotePayload,
  AddBibleNotePayload,
  EditBibleNotePayload,
  DeleteNotePayload,
  AddHighlightPayload,
} from "./types";

export const getBibleNotes = createAction<GetBibleNotePayload>("getBibleNotes");

export const addBibleNote = createAction<AddBibleNotePayload>("addBibleNote");

export const editBibleNote =
  createAction<EditBibleNotePayload>("editBibleNote");

export const deleteBibleNote =
  createAction<DeleteNotePayload>("deleteBibleNote");

export const getBibleHighlights = createAction("getBibleHighlights");

export const addBibleHighlight =
  createAction<AddHighlightPayload>("addBibleHighlight");
