export const messages = {
  title: {
    id: "library-filters.title",
    defaultMessage: "Filters",
  },
  resetFilters: {
    id: "library-filters.reset-filters",
    defaultMessage: "Reset Filters",
  },
  type: {
    id: "library-filters.type",
    defaultMessage: "Type",
  },
  audience: {
    id: "library-filters.audience",
    defaultMessage: "Audience",
  },
  language: {
    id: "library-filters.language",
    defaultMessage: "Resource Languages",
  },
};
