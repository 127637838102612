import * as React from "react";

import { ModalContext } from "./context.web";

export const useModal = () => {
  const { isVisible, showModal, hideModal } = React.useContext(ModalContext);

  return {
    isVisible,
    showModal,
    hideModal,
  };
};
