import NetInfo, {
  NetInfoCellularGeneration,
  NetInfoStateType
} from "@react-native-community/netinfo";
import { handleError } from "./logger";

/**
 * These values are taken from the Hulu scale: https://help.hulu.com/s/article/video-quality
 * - To stream videos in standard definition, at least 1 Mbps is recommended.
 * - To stream videos in HD (1080p), at least 6 Mbps is recommended.
 * - To stream videos in HDR or 4K, at least 25 Mbps is recommended.
 */
export enum InternetSpeed {
  /**
   * < 1 Mbps
   */
  Poor = 0,
  /**
   * > 1 Mbps and < 6 Mbps
   */
  Normal = 1,
  /**
   * > 6 Mbps and < 25 Mbps
   */
  Good = 2,
  /**
   * +25 Mbps
   */
  Excellent = 3
}

export function getInternetSpeedKey(value: number): string | undefined {
  for (const key in InternetSpeed) {
    if (InternetSpeed[key as keyof typeof InternetSpeed] === value) {
      return key;
    }
  }
  return undefined;
}

export async function getInternetSpeed(): Promise<InternetSpeed> {
  try {
    const connectionInfo = await NetInfo.refresh();
    const { type, details } = connectionInfo;

    if (type === NetInfoStateType.cellular) {
      if (details.cellularGeneration === null) return InternetSpeed.Poor;

      // if the connection is expensive, we would like to avoid expensive operations by default
      if (details.isConnectionExpensive) return InternetSpeed.Normal;

      const cellularGenerationSpeeds = {
        [NetInfoCellularGeneration["5g"]]: InternetSpeed.Excellent,
        [NetInfoCellularGeneration["4g"]]: InternetSpeed.Good,
        [NetInfoCellularGeneration["3g"]]: InternetSpeed.Normal,
        [NetInfoCellularGeneration["2g"]]: InternetSpeed.Poor
      };

      return cellularGenerationSpeeds[details.cellularGeneration];
    }

    if (type === NetInfoStateType.wifi) {
      const speedTestResult = await downloadSpeedTest();

      return speedTestResult;
    }

    // If we can't get the speed/strength, we assume it's normal as a safe fallback
    return InternetSpeed.Normal;
  } catch (error) {
    handleError({
      message: "Error occurred while measuring internet speed",
      error
    });

    return InternetSpeed.Poor;
  }
}

// Sample image to test the download speed, 238 KB in size
const TEST_IMAGE_URL =
  "https://upload.wikimedia.org/wikipedia/commons/5/51/Google.png";
const MEGABYTE = 1000000;

async function downloadSpeedTest(): Promise<InternetSpeed> {
  const startTime = Date.now();

  try {
    const response = await fetch(TEST_IMAGE_URL);
    const endTime = Date.now();
    const duration = endTime - startTime;
    const contentLength = response.headers.get("content-length");
    const parsedContentLength = parseInt(contentLength || "", 10);
    const bytesPerSecond = parsedContentLength / (duration / 1000);

    switch (true) {
      case bytesPerSecond < MEGABYTE / 2:
        return InternetSpeed.Poor;
      case bytesPerSecond < MEGABYTE:
        return InternetSpeed.Normal;
      case bytesPerSecond < MEGABYTE * 6:
        return InternetSpeed.Good;
      case bytesPerSecond < MEGABYTE * 25:
        return InternetSpeed.Excellent;
      default:
        return InternetSpeed.Normal;
    }
  } catch (error) {
    handleError({
      message: "Error occurred while measuring download speed",
      error
    });

    return InternetSpeed.Poor;
  }
}
