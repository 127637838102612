export const messages = {
  title: {
    id: "giving.method-add.title",
    defaultMessage: "Add new card",
  },
  confirm: {
    id: "giving.method-add.confirm",
    defaultMessage: "New payment method successfully added",
  },
  error: {
    id: "giving.method-add.error",
    defaultMessage: "Failed to add a new card. Please try again later.",
  },
};
