import * as React from "react";

import { InputBox } from "~/components/auth-screen/styles";
import { TextInput } from "~/components/text-input";
import { Checkbox } from "~/components/checkbox";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { useAppSelector } from "~/state/hooks";
import { getAppUsage } from "~/state/user/selectors";
import { Usage } from "~/state/user/types";

import { messages as generalMessages } from "../intl";
import { messages } from "./intl";
import { UsageData } from "../types";
import {
  KeyboardAvoiding,
  Container,
  Content,
  ButtonWrapper,
  ButtonBox,
  BottomWrapper,
} from "../styles";
import { Title, Meta, ButtonContainer } from "./styles";
import Pager from "~/components/pager";

interface Props {
  onPress: (data: UsageData) => void;
  onBackPress: () => void;
  progressStep: (step: number) => void;
  usage?: Usage[];
  isLoading: boolean;
}

const options = [
  {
    id: Usage.FamilyDevos,
    label: messages.familyDevos,
  },
  {
    id: Usage.PersonalDevos,
    label: messages.personalDevos,
  },
  {
    id: Usage.SmallGroups,
    label: messages.smallGroups,
  },
  {
    id: Usage.KidsMinistry,
    label: messages.kidsMinistry,
  },
  {
    id: Usage.SundaySchool,
    label: messages.sundaySchool,
  },
  {
    id: Usage.YouthMinistry,
    label: messages.youthMinistry,
  },
];

export const UsageInputs = React.memo<Props>(
  ({ onPress, progressStep, usage: defaultUsage = [], isLoading }) => {
    const stateAppUsage = useAppSelector(getAppUsage);
    const [usage, setUsage] = React.useState(defaultUsage || stateAppUsage);

    const isValid = usage.length;

    const handleOnPress = React.useCallback(() => {
      if (isValid) {
        onPress({ usage });
      }
    }, [isValid, usage, onPress]);

    const handleCheck = React.useCallback(
      (item: Usage) => {
        const isSelected = usage.includes(item);
        if (isSelected) {
          const newItems = usage.filter((currentItem) => currentItem !== item);
          setUsage(newItems);
        } else {
          const newItems = [...usage, item];
          setUsage(newItems);
        }
      },
      [usage]
    );

    return (
      <>
        <KeyboardAvoiding>
          <Container>
            <Content>
              <Title>{messages.usageTitle}</Title>
              <Meta>{messages.selectAll}</Meta>
              <InputBox>
                {options.map(({ id, label }) => (
                  <ButtonContainer key={id}>
                    <Button
                      text={label}
                      type={
                        usage.includes(id)
                          ? ButtonTypes.Primary
                          : ButtonTypes.Outlined
                      }
                      onPress={() => handleCheck(id)}
                    />
                  </ButtonContainer>
                ))}
              </InputBox>
            </Content>
          </Container>
        </KeyboardAvoiding>

        <BottomWrapper>
          <Pager
            pages={3}
            currentPage={2}
            isValid={!!isValid}
            setPage={progressStep}
          />

          <ButtonBox>
            <Button
              text={generalMessages.buttonDone}
              onPress={handleOnPress}
              type={ButtonTypes.Primary}
              isDisabled={!isValid}
              isLoading={isLoading}
              isUppercase
            />
          </ButtonBox>
        </BottomWrapper>
      </>
    );
  }
);
