export const messages = {
  title: {
    id: "group-join.title",
    defaultMessage: "Join a group",
  },
  description: {
    id: "group-join.description",
    defaultMessage:
      "Please enter a group code that you received in the group invitation",
  },
  button: {
    id: "group-join.button",
    defaultMessage: "Confirm code",
  },
  pasteFromClipboard: {
    id: "group-join.paste-from-clipboard",
    defaultMessage: "Paste code from clipboard",
  },
};
