export const messages = {
  title: {
    id: "app-feedback.title",
    defaultMessage: "App Feedback",
  },
  subtitle: {
    id: "app-feedback.subtitle",
    defaultMessage: "Rate Your App Experience",
  },
  description: {
    id: "app-feedback.description",
    defaultMessage:
      "We’d love to hear your feedback on how we can improve your experience.",
  },
  improvements: {
    id: "app-feedback.improvements",
    defaultMessage: "What could be improved?",
  },
  submit: {
    id: "app-feedback.submit",
    defaultMessage: "Submit",
  },
  thankYou: {
    id: "app-feedback.thank-you",
    defaultMessage: "Thank you for your feedback!",
  },
  login: {
    id: "app-feedback.chip.login",
    defaultMessage: "Login",
  },
  signUp: {
    id: "app-feedback.chip.sign-up",
    defaultMessage: "Sign Up",
  },
  content: {
    id: "app-feedback.chip.content",
    defaultMessage: "Content",
  },
  progress: {
    id: "app-feedback.chip.progress",
    defaultMessage: "Progress",
  },
  bible: {
    id: "app-feedback.chip.bible",
    defaultMessage: "Bible",
  },
  notes: {
    id: "app-feedback.chip.notes",
    defaultMessage: "Notes",
  },
  missingFeatures: {
    id: "app-feedback.chip.missing-features",
    defaultMessage: "Missing Features",
  },
  howItLooks: {
    id: "app-feedback.chip.how-it-looks",
    defaultMessage: "How It Looks",
  },
  speed: {
    id: "app-feedback.chip.speed",
    defaultMessage: "Speed",
  },
  bugs: {
    id: "app-feedback.chip.bugs",
    defaultMessage: "Bugs",
  },
  crashes: {
    id: "app-feedback.chip.crashes",
    defaultMessage: "Crashes",
  },
  other: {
    id: "app-feedback.chip.other",
    defaultMessage: "Other",
  },
};
