import { IconButton } from "react-native-paper";
import styled from "styled-components/native";

import { IconSizes } from "~/components/icon";
import { Text1, Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const ProgressRowContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  margin: ${spacers.ss6} ${spacers.ss7};
`;

export const InfoColumn = styled.View`
  flex: 1;
  flex-direction: column;
  margin: 0 ${spacers.ss6};
`;

export const PlanTitle = styled(Text3)`
  font-family: SFProDisplay;
`;

export const ProgressBarContainer = styled.View`
  margin: ${spacers.ss4} 0;
`;

export const ProgressText = styled(Text1)`
  color: ${colors.gray600};
`;

export const ForwardIcon = styled(IconButton).attrs({
  icon: "chevron-right",
  size: IconSizes.Large,
  color: colors.primaryBlue,
})`
  margin: 0 -${spacers.ss6} 0 0;
`;

export const Divider = styled.View`
  height: 1px;
  background-color: ${colors.black};
  margin: 0 0 0 ${spacers.ss7};
  opacity: 0.2;
`;
