import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

export const ButtonBox = styled.View`
  ${getWebContainerStyle(340)}
`;

export const LoginButtonBox = styled.View`
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-vertical: ${spacers.ss5};
`;

export const LoginText = styled(Text3)`
  letter-spacing: 1px;
`;
