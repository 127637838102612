import uuid from "react-native-uuid";
import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  query,
  collection,
  where,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  QueryDocumentSnapshot,
  DocumentSnapshot,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import NetInfo from "@react-native-community/netinfo";

import {
  getFaithlifeNotesCollection,
  getSessionNotesCollection,
} from "~/constants/collections";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";
import {
  addSessionNotes,
  addNote,
  editNote,
  deleteNote,
  addSessionNotesMeta,
} from "~/state/notes/slice";
import { SessionNotesMeta } from "~/state/notes/types";

import {
  getSessionNotes,
  addSessionNote,
  editSessionNote,
  deleteSessionNote,
  getSessionNotesMeta,
} from "./actions";
import {
  FaithlifeNotes,
  ImportNotesPayload,
  GetSessionNotesAction,
  SessionNote,
  AddSessionNoteAction,
  EditSessionNoteAction,
  DeleteSessionNoteAction,
} from "./types";

export function* importFaithlifeNotes({
  email,
  userId,
}: ImportNotesPayload): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const faithlifeNotesCollection = getFaithlifeNotesCollection(env);
    const sessionNotesCollection = getSessionNotesCollection(env);

    const q = query(
      collection(database, faithlifeNotesCollection),
      where("userEmail", "==", email)
    );

    // @ts-ignore
    const querySnapshot = yield call(getDocs, q);
    const data = querySnapshot.docs.map(
      (snapshotDoc: QueryDocumentSnapshot<FaithlifeNotes>) => snapshotDoc.data()
    )[0] as FaithlifeNotes;

    if (!Array.isArray(data?.notes)) {
      return;
    }

    for (const note of data.notes) {
      const id = uuid.v4() as string;
      yield call(
        // @ts-ignore
        setDoc,
        doc(database, sessionNotesCollection, id),
        {
          ...note,
          userId,
          id,
        }
      );
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* getSessionNotesFn({
  payload: { userId: defaultUserId, sessionId = "", onSuccess, onError },
}: GetSessionNotesAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    if (onSuccess) yield call(onSuccess);

    return;
  }

  const savedUserId = yield select(getUserId);
  const userId = defaultUserId || savedUserId;
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const sessionNotesCollection = getSessionNotesCollection(env);

    const basicQuery = query(
      collection(database, sessionNotesCollection),
      where("userId", "==", userId)
    );

    const sessionQuery = query(
      collection(database, sessionNotesCollection),
      where("userId", "==", userId),
      where("sessionId", "==", sessionId)
    );

    const q = sessionId ? sessionQuery : basicQuery;

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = notesSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as SessionNote[];

    if (data && Array.isArray(data)) {
      yield put(addSessionNotes(data));
      if (typeof onSuccess === "function") {
        yield call(onSuccess);
      }
    } else {
      throw new Error();
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }

    yield call(handleError, e);
  }
}

export const getSessionNotesSaga = function* () {
  yield takeLatest(getSessionNotes.type, getSessionNotesFn);
};

export function* addSessionNoteFn({
  payload: { sessionId, note, questionId, question, onSuccess, onError },
}: AddSessionNoteAction): SagaIterator {
  try {
    // Prevent saving the note if it is empty
    if (!note) {
      return;
    }

    const env = yield select(getEnvironment);
    const sessionNotesCollection = getSessionNotesCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const lastUpdated = new Date().getTime();

    const data = {
      id,
      sessionId,
      note,
      questionId,
      question,
      lastUpdated,
      userId,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, sessionNotesCollection, id),
      data
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addNote(data));
    if (typeof onSuccess === "function") {
      yield call(onSuccess);
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }
    yield call(handleError, e);
  }
}

export function* editSessionNoteFn({
  payload: { noteId, note, onSuccess, onError },
}: EditSessionNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const sessionNotesCollection = getSessionNotesCollection(env);

    // Delete the note if it is empty
    if (!note) {
      yield put(deleteSessionNote({ noteId, onSuccess, onError }));
      return;
    }

    const res = yield call(
      // @ts-ignore
      updateDoc,
      doc(database, sessionNotesCollection, noteId),
      { note }
    );

    // Update the local state to avoid an extra Firebase query
    yield put(editNote({ id: noteId, note }));
    if (typeof onSuccess === "function") {
      yield call(onSuccess);
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }
    yield call(handleError, e);
  }
}

export function* deleteSessionNoteFn({
  payload: { noteId, onSuccess, onError },
}: DeleteSessionNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const sessionNotesCollection = getSessionNotesCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, sessionNotesCollection, noteId)
    );

    // Update the local state to avoid an extra Firebase query
    yield put(deleteNote(noteId));
    if (typeof onSuccess === "function") {
      yield call(onSuccess);
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }
    yield call(handleError, e);
  }
}

export const addSessionNoteSaga = function* () {
  yield takeLatest(addSessionNote.type, addSessionNoteFn);
};

export const editSessionNoteSaga = function* () {
  yield takeLatest(editSessionNote.type, editSessionNoteFn);
};

export const deleteSessionNoteSaga = function* () {
  yield takeLatest(deleteSessionNote.type, deleteSessionNoteFn);
};

export function* getSessionNotesMetaFn({
  payload: { onSuccess, onError },
}: GetSessionNotesAction): SagaIterator {
  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const sessionNotesCollection = getSessionNotesCollection(env);

    const q = query(
      collection(database, sessionNotesCollection),
      where("userId", "==", userId)
    );

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = notesSnapshots.docs.map((snapshot: DocumentSnapshot) => {
      const snapshotData = snapshot.data();
      return {
        sessionId: snapshotData?.sessionId || "",
        lastUpdated: snapshotData?.lastUpdated || 0,
        id: snapshotData?.id || "",
      };
    }) as SessionNotesMeta[];

    if (data && Array.isArray(data)) {
      yield put(addSessionNotesMeta(data));

      if (typeof onSuccess === "function") {
        yield call(onSuccess);
      }
    } else {
      throw new Error();
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }

    yield call(handleError, e);
  }
}

export const getSessionNotesMetaSaga = function* () {
  yield takeLatest(getSessionNotesMeta.type, getSessionNotesMetaFn);
};
