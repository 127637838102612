export const messages = {
  amount: {
    id: "giving.manage.amount",
    defaultMessage: "Giving {{amount}} per month",
  },
  amountEdit: {
    id: "giving.manage.amount-edit",
    defaultMessage: "(Edit amount)",
  },
  method: {
    id: "giving.manage.method",
    defaultMessage: "Payment method",
  },
  charge: {
    id: "giving.manage.charge",
    defaultMessage: "Charge on",
  },
  frequency: {
    id: "giving.manage.frequency",
    defaultMessage: "Frequency",
  },
  frequencyMonthly: {
    id: "giving.manage.frequency-monthly",
    defaultMessage: "Monthly",
  },
  frequencyQuarterly: {
    id: "giving.manage.frequency-quarterly",
    defaultMessage: "Quarterly",
  },
  frequencyYearly: {
    id: "giving.manage.frequency-yearly",
    defaultMessage: "Yearly",
  },
  charge1: {
    id: "giving.manage.charge-1",
    defaultMessage: "1st of the month",
  },
  charge15: {
    id: "giving.manage.charge-15",
    defaultMessage: "15th of the month",
  },
  charge30: {
    id: "giving.manage.charge-30",
    defaultMessage: "30th of the month",
  },
  email: {
    id: "giving.manage.email",
    defaultMessage: "Email address",
  },
  cancel: {
    id: "giving.manage.cancel",
    defaultMessage: "Cancel Recurring Giving",
  },
  cancelText: {
    id: "giving.manage.cancel-text",
    defaultMessage:
      "Are you sure you would like to cancel the recurring giving?",
  },
  empty: {
    id: "giving.manage.empty",
    defaultMessage: "You have not set up any recurring payments yet",
  },
  updateSuccess: {
    id: "giving.manage.update-success",
    defaultMessage: "The changes have been saved successfully",
  },
  updateError: {
    id: "giving.manage.update-error",
    defaultMessage: "There has been an error. Please try again later.",
  },
};
