import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { VolumeOverview as VolumeOverviewType } from "~/state/flamelink/types";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../checklist";
import { SectionTitle } from "../common";
import { Footnotes } from "../footnotes";
import { Media } from "../media";
import { RichText } from "../rich-text";
import { Scripture } from "../scripture";
import { VolumeOverviewHeading } from "./styles";
import { ContentIcon } from "../content-icon";

interface VolumeOverviewProps {
  volumeOverview: VolumeOverviewType;
}

export const VolumeOverview: React.FC<VolumeOverviewProps> = ({
  volumeOverview,
}) => {
  if (!volumeOverview) return null;

  return (
    <>
      {volumeOverview?.sectionTitle && (
        <VolumeOverviewHeading>
          {volumeOverview.sectionTitle}
        </VolumeOverviewHeading>
      )}

      {volumeOverview.title && (
        <SectionTitle>{volumeOverview.title}</SectionTitle>
      )}

      {volumeOverview.scripture && (
        <Scripture scripture={volumeOverview.scripture} hasMargin />
      )}

      {volumeOverview.content && <RichText>{volumeOverview.content}</RichText>}

      {Array.isArray(volumeOverview?.mediaRepeater) &&
        volumeOverview?.mediaRepeater.map(({ media, content }, i) => (
          <React.Fragment key={`mediaRepeater-${i}`}>
            {Array.isArray(media) &&
              media.map((fileId, index) => (
                <Media key={`mediaRepeater-media-${index}`} fileId={fileId} />
              ))}

            {content && <RichText>{content}</RichText>}
          </React.Fragment>
        ))}

      {volumeOverview?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={volumeOverview.youWillNeed}
          checklistId="volumeOverview-youWillNeed"
        />
      )}

      {volumeOverview?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={volumeOverview.gettingReady}
          checklistId="volumeOverview-gettingReady"
        />
      )}

      {volumeOverview?.facilitatorGuideMedia && (
        <Media fileId={volumeOverview.facilitatorGuideMedia[0]} />
      )}

      <Footnotes data={volumeOverview?.footnotes} />

      <ContentIcon
        content={[volumeOverview?.content, volumeOverview?.mediaRepeater]}
      />
    </>
  );
};
