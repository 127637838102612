import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { MessageData } from "~/state/chat/types";
import { navigateTo } from "~/utils/navigation";
import { RichText } from "~/screens/session/components/content/components/rich-text";
import { formatMessage } from "~/utils/translation";

import { SectionHeader } from "./section-header";
import { messages } from "./intl";
import {
  Container,
  NoteText,
  HighlightedBox,
  questionTextStyles,
} from "./styles";

interface SessionNoteProps {
  data?: MessageData;
  text: string;
}

export const NoteSession = React.memo<SessionNoteProps>(
  ({ data = {}, text }) => {
    const title = data?.title || "";
    const sessionId = data?.sessionId || "";
    const planId = data?.planId || "";
    const question = data?.question || "";
    const navigation = useNavigation();

    const questionText = formatMessage(messages.sessionNote, { question });

    const onPress = React.useCallback(() => {
      // @ts-ignore
      navigateTo(navigation, routes.libraryTab, {
        screen: routes.session,
        params: {
          planId,
          sessionId,
          volumeIndex: 0,
          sessionIndex: 0,
          dayIndex: 0,
        },
      });
    }, [navigation, planId, sessionId]);

    return (
      <Container>
        <SectionHeader text={title} onPress={onPress} hasLink={false} />

        <HighlightedBox>
          <RichText extraMarkdownStyles={questionTextStyles}>
            {questionText}
          </RichText>
        </HighlightedBox>

        <NoteText>{text}</NoteText>
      </Container>
    );
  }
);
