import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppNetInfo } from "~/state/hooks";
import { deleteGroup } from "~/state/groups";
import { useAlerts } from "~/state/alerts";
import { asyncLogEvent, events } from "~/utils/analytics";
import { colors } from "~/styles/theme";
import { genericMessages } from "~/constants/intl";
import { Alert, useAlert } from "~/components/alert";

import { Button, DeleteIcon, DeleteText } from "./styles";
import { messages } from "./intl";

interface Props {
  groupId: string;
}

interface ComponentProps {
  onSuccess: () => void;
  groupId: string;
}

export const DeleteButtonComponent = React.memo<ComponentProps>(
  ({ groupId, onSuccess }) => {
    const dispatch = useAppDispatch();

    const { pushAlert } = useAlerts();
    const { isConnected } = useAppNetInfo();
    const { showAlert } = useAlert();

    const onDelete = React.useCallback(() => {
      dispatch(
        deleteGroup({
          groupId,
          onSuccess: () => {
            asyncLogEvent(events.GROUP_DELETED);
            pushAlert({ message: messages.success, color: colors.emerald600 });
          },
          onError: () => {
            pushAlert({ message: messages.error });
          },
        })
      );
      onSuccess();
    }, [dispatch, groupId, onSuccess, pushAlert]);

    const handleOnPress = React.useCallback(async () => {
      if (!isConnected) {
        pushAlert({
          message: genericMessages.featureUnavailableOffline,
          color: colors.gray600,
        });

        return;
      }

      showAlert(
        formatMessage(messages.deleteGroup),
        formatMessage(messages.confirmation),
        [
          {
            text: formatMessage(messages.cancel),
            style: "cancel",
          },
          {
            text: formatMessage(messages.delete),
            onPress: onDelete,
            style: "destructive",
          },
        ]
      );
    }, [isConnected, pushAlert, onDelete, showAlert]);

    return (
      <Button onPress={handleOnPress}>
        <DeleteIcon />
        <DeleteText>{messages.deleteGroup}</DeleteText>
      </Button>
    );
  }
);

export const DeleteButton = React.memo<Props>(({ groupId }) => {
  const navigation = useNavigation();

  const onSuccess = () => {
    navigation.navigate(routes.groups);
  };

  return <DeleteButtonComponent groupId={groupId} onSuccess={onSuccess} />;
});
