import * as React from "react";

import { Wrapper, Text } from "./styles";

import { messages } from "./intl";

export const DeletedMessage = React.memo(() => {
  return (
    <Wrapper>
      <Text>{messages.deleted}</Text>
    </Wrapper>
  );
});
