export const messages = {
  header: {
    id: "pif.confirmation.header",
    defaultMessage: "Payment confirmation",
  },
  title: {
    id: "pif.confirmation.title",
    defaultMessage: "Thank you for your donation of {{amount}}, {{name}}!",
  },
  titleEmpty: {
    id: "pif.confirmation.title-empty",
    defaultMessage: "Thank you for your donation, {{name}}!",
  },
  description: {
    id: "pif.confirmation.description",
    defaultMessage: "Your partnership is making a difference for the Kingdom!",
  },
  done: {
    id: "pif.confirmation.done",
    defaultMessage: "Done",
  },
};
