import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TutorialReducer, WalkthroughContent } from "./types";

export const initialReducerState: TutorialReducer = {
  content: {},
  groupsSeen: [],
  shouldShowTutorial: false,
};

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState: initialReducerState,
  reducers: {
    setContent: (state, action: PayloadAction<WalkthroughContent>) => {
      state.content = action.payload;
    },
    setInitialGroupsSeen: (state, action: PayloadAction<string[]>) => {
      state.groupsSeen = action.payload;
    },
    setGroupsSeen: (state, action: PayloadAction<string[]>) => {
      state.groupsSeen = [...new Set([...state.groupsSeen, ...action.payload])];
    },
    setShouldShowTutorial: (state) => {
      state.shouldShowTutorial = true;
    },
    resetTutorialState: (state) => {
      state.groupsSeen = [];
    },
  },
});

export const {
  setContent,
  setInitialGroupsSeen,
  setGroupsSeen,
  setShouldShowTutorial,
  resetTutorialState,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;
