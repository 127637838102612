import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import { GroupAvatar } from "~/components/group-avatar";
import { ProgressBar } from "~/components/progress-bar";
import {
  GetPlanProgressesByUserIdData,
  getNormalizedProgressByPlanId,
  ProgressState,
} from "~/state/content-progress";
import { useAppSelector } from "~/state/hooks";
import { getPlanById, getPlanDaysCount } from "~/state/flamelink";
import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { getGroupsWithPlanId } from "~/state/groups/selectors";
import { Avatar } from "~/components/avatar";
import {
  getProfileImage,
  getUserDisplayName,
  getUserFullName,
} from "~/state/user/selectors";

import { LibraryProgressProps } from "../..";
import {
  Divider,
  ForwardIcon,
  InfoColumn,
  PlanTitle,
  ProgressBarContainer,
  ProgressRowContainer,
  ProgressText,
} from "./styles";
import { messages } from "./intl";

interface ProgressRowProps {
  isLast?: boolean;
  planProgress: GetPlanProgressesByUserIdData;
}
export const ProgressRow: React.FC<ProgressRowProps> = ({
  isLast,
  planProgress,
}) => {
  const { type } = useRoute<LibraryProgressProps["route"]>().params;
  const navigation = useNavigation<LibraryProgressProps["navigation"]>();

  const planId = planProgress.planId;
  const progressData = useAppSelector((state) =>
    getNormalizedProgressByPlanId(state, planId)
  );

  const planData = useAppSelector((state) => getPlanById(state, planId));
  const total = useAppSelector((state) => getPlanDaysCount(state, planId));
  const groups = useAppSelector((state) => getGroupsWithPlanId(state, planId));
  const userDisplayName = useAppSelector(getUserDisplayName);
  const userFullName = useAppSelector(getUserFullName);
  const profileImage = useAppSelector(getProfileImage);

  const daysCompleted = progressData?.numberOfDaysCompleted || 0;
  const progress = total ? Math.round((daysCompleted / total) * 100) : 0;

  const formattedCompletionDate = progressData?.completionDate
    ? new Date(progressData.completionDate).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : undefined;

  const isDisabled = type === ProgressState.Completed;

  const onPress = React.useCallback(() => {
    if (type === ProgressState.Completed) return;

    navigation.navigate(routes.plan, { planId });
  }, [navigation, planId, type]);

  return (
    <>
      <ProgressRowContainer
        onPress={onPress}
        disabled={type === ProgressState.Completed}
      >
        {groups.length > 0 ? (
          <GroupAvatar text={groups[0].name} uri={groups[0].imageUri} />
        ) : (
          <Avatar
            size={48}
            name={userFullName ?? userDisplayName}
            uri={profileImage}
          />
        )}

        <InfoColumn>
          {planData?.title && <PlanTitle>{planData.title}</PlanTitle>}

          <>
            <ProgressBarContainer>
              <ProgressBar
                progress={progress}
                isCompleted={type === ProgressState.Completed}
              />
            </ProgressBarContainer>

            <ProgressText>
              {type === ProgressState.Completed && formattedCompletionDate
                ? formatMessage(messages.completionDate, {
                    date: formattedCompletionDate,
                  })
                : formatMessage(messages.curriculumProgress, {
                    progress,
                  })}
            </ProgressText>
          </>
        </InfoColumn>

        {!isDisabled && <ForwardIcon />}
      </ProgressRowContainer>

      {!isLast && <Divider />}
    </>
  );
};
