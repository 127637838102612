import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { initialState } from "./slice";
import { FontSize, VideoQuality } from "./types";

const getState = (state: RootState) => state.settings;

export const getLanguagesToDisplay: (state: RootState) => string[] =
  createSelector(
    getState,
    (state) => state.languagesToDisplay || initialState.languagesToDisplay
  );

export const getVideoQuality: (state: RootState) => VideoQuality | undefined =
  createSelector(
    getState,
    (state) => state?.videoQuality || initialState.videoQuality
  );

export const getFontSize: (state: RootState) => FontSize | undefined =
  createSelector(getState, (state) => state.fontSize || initialState.fontSize);

export const getAppLanguage: (state: RootState) => string | undefined =
  createSelector(
    getState,
    (state) => state.appLanguage || initialState.appLanguage
  );
