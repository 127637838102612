import React from "react";

import { MessageData, MessageType } from "~/state/chat/types";

import { ShareContent } from "./content";
import { useModal } from "~/state/modal/hook.web";

interface Props {
  content: string;
  data?: MessageData;
  type: MessageType;
  onLoading: (arg0: boolean) => void;
  onShare: () => void;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

export const ShareSheet = React.memo<Props>(
  ({ onLoading, content, data, type, onShare, isOpen, setIsOpen }) => {
    const { isVisible, showModal, hideModal } = useModal();

    const handleClose = React.useCallback(() => {
      if (typeof setIsOpen === "function") {
        setIsOpen(false);
      }
      hideModal();
    }, [setIsOpen, hideModal]);

    const openBooksModal = React.useCallback(() => {
      if (isVisible) {
        return;
      }
      showModal(
        <ShareContent
          onLoading={onLoading}
          content={content}
          data={data}
          type={type}
          onShare={onShare}
          handleClose={handleClose}
        />
      );
    }, [
      showModal,
      content,
      data,
      handleClose,
      onLoading,
      onShare,
      type,
      isVisible,
    ]);

    React.useEffect(() => {
      if (isOpen) {
        openBooksModal();
      }
    }, [isOpen, openBooksModal, hideModal]);

    React.useEffect(() => {
      if (!isVisible) {
        if (typeof setIsOpen === "function") {
          setIsOpen(false);
          hideModal();
        }
      }
    }, [isVisible, setIsOpen, hideModal]);

    return null;
  }
);
