import { createSelector } from "@reduxjs/toolkit";

import { SessionNote } from "~/state/session-notes";
import { getFlamelinkData } from "~/state/flamelink/selectors";

import { RootState } from "../store";
import {
  SessionNotesData,
  SortEnum,
  PlanData,
  SessionNotesMeta,
  SessionNotesMetaData,
} from "./types";
import { notePlansMapping } from "./constants";
import { getSearchConditions } from "./utils";

const getState = (state: RootState) => state.notes;

export const getSessionNotes: (state: RootState) => SessionNote[] =
  createSelector(getState, (state) => state?.session || []);

export const getSessionNotesMeta: (state: RootState) => SessionNotesMeta[] =
  createSelector(getState, (state) => state?.sessionMeta || []);

export const getSessionNotesCount: (state: RootState) => number =
  createSelector(getSessionNotesMeta, (data) => data.length);

export const getSessionNoteByKey: (
  state: RootState,
  questionKey: string
) => SessionNote | undefined = createSelector(
  [getSessionNotes, (_, props) => props],
  (notes, key) => {
    return notes.find((note) => note.questionId === key);
  }
);

export const getSessionNotesData: (state: RootState) => SessionNotesData[] =
  createSelector([getSessionNotes, getFlamelinkData], (sessionNotes, data) => {
    return sessionNotes.reduce((acc: SessionNotesData[], note: SessionNote) => {
      const sessionDataIndex = acc.findIndex(
        ({ sessionId }) => sessionId === note.sessionId
      );

      if (sessionDataIndex > -1) {
        const sessionData = acc[sessionDataIndex] as SessionNotesData;
        if (!Array.isArray(sessionData.notes)) {
          return acc;
        }
        acc[sessionDataIndex] = {
          ...sessionData,
          notes: [...sessionData.notes, note],
        };
        return acc;
      }

      const noteSession = data.sessions?.find(
        (session) => session.id === note.sessionId
      );
      const noteVolume = data.volumes?.find((volume) =>
        volume.sessions.includes(noteSession?.id || "")
      );
      const notePlan = data.plans?.find((plan) =>
        plan.volumes.includes(noteVolume?.id || "")
      );

      return [
        ...acc,
        {
          planId: notePlan?.id || "",
          planTitle:
            notePlan?.title || notePlansMapping.get(note?.res || "") || "",
          volume: noteVolume?.title || "",
          volumeNumber: noteVolume?.volumeNumber || 1,
          sessionId: noteSession?.id || "",
          session: noteSession?.title || "",
          lastUpdated: note.lastUpdated || Date.now(),
          notes: [note],
        },
      ];
    }, []);
  });

export const getSessionNotesMetaData: (
  state: RootState
) => SessionNotesMetaData[] = createSelector(
  [getSessionNotesMeta, getFlamelinkData],
  (sessionNotes, data) => {
    return sessionNotes.reduce(
      (acc: SessionNotesMetaData[], meta: SessionNotesMeta) => {
        const sessionDataIndex = acc.findIndex(
          ({ sessionId }) => sessionId === meta.sessionId
        );

        if (sessionDataIndex > -1) {
          return acc;
        }

        const noteSession = data.sessions?.find(
          (session) => session.id === meta.sessionId
        );
        const noteVolume = data.volumes?.find((volume) =>
          volume.sessions.includes(noteSession?.id || "")
        );
        const notePlan = data.plans?.find((plan) =>
          plan.volumes.includes(noteVolume?.id || "")
        );

        return [
          ...acc,
          {
            planId: notePlan?.id || "",
            planTitle: notePlan?.title || "",
            volume: noteVolume?.title || "",
            volumeNumber: noteVolume?.volumeNumber || 1,
            sessionId: noteSession?.id || "",
            session: noteSession?.title || "",
            lastUpdated: meta.lastUpdated || Date.now(),
          },
        ];
      },
      []
    );
  }
);

export const getHasFaithlifeNotes: (state: RootState) => boolean =
  createSelector(
    getSessionNotes,
    (sessionNotes) => !!sessionNotes.find((note) => !!note.faithlifeId)
  );

const hasSearchTerm = (
  { planTitle, volume, session }: SessionNotesMetaData,
  searchTerm: string
) =>
  [planTitle, volume, session]
    .join("")
    .toLowerCase()
    .includes(searchTerm.toLowerCase());

const sortData = (data: SessionNotesMetaData[], sortMethod: string) => {
  return data.sort((a, b) =>
    sortMethod === SortEnum.ASC
      ? a.lastUpdated - b.lastUpdated
      : b.lastUpdated - a.lastUpdated
  );
};

export const getSessionNotesFiltered: (
  state: RootState,
  searchConditions: string
) => SessionNotesMetaData[] = createSelector(
  [getSessionNotesMetaData, (_, props) => props],
  (data, searchConditions) => {
    const { sortMethod, searchTerm } = getSearchConditions(searchConditions);
    const sortedData = sortData(data, sortMethod);
    return sortedData.filter((item) => hasSearchTerm(item, searchTerm));
  }
);

export const getSessionNotesPlans: (
  state: RootState,
  searchConditions: string
) => PlanData[] = createSelector(
  [getSessionNotesMetaData, (_, props) => props],
  (data, searchConditions) => {
    const { sortMethod, searchTerm } = getSearchConditions(searchConditions);
    const sortedData = sortData(data, sortMethod);
    return sortedData.reduce((acc: PlanData[], item) => {
      if (
        !acc.find(({ id }) => id === item.planId) &&
        hasSearchTerm(item, searchTerm)
      ) {
        return [...acc, { id: item.planId, title: item.planTitle }];
      }
      return acc;
    }, []);
  }
);

export const getSessionNotesByPlanId: (
  state: RootState,
  {
    planId,
    searchTerm,
  }: { planId: string; searchTerm: string; sortMethod: string }
) => SessionNotesMetaData[] = createSelector(
  [getSessionNotesMetaData, (_, props) => props],
  (data, { planId, searchTerm, sortMethod }) => {
    const sortedData = sortData(data, sortMethod);
    return sortedData.filter(
      (item) => item.planId === planId && hasSearchTerm(item, searchTerm)
    );
  }
);

export const getSessionNotesBySessionId: (
  state: RootState,
  sessionId: string
) => SessionNotesData | undefined = createSelector(
  [getSessionNotesData, (_, props) => props],
  (data, sessionId) => {
    const notesData = data.find((item) => item.sessionId === sessionId);
    const notes = notesData?.notes || [];

    if (!notesData || !notes.length) {
      return;
    }
    return {
      ...notesData,
      notes: notes.sort((a, b) => b.lastUpdated - a.lastUpdated),
    };
  }
);

export const getSessionNotesById: (
  state: RootState,
  noteId: string
) => SessionNote | undefined = createSelector(
  [getSessionNotes, (_, props) => props],
  (data, noteId) => {
    return data.find((item) => item.id === noteId);
  }
);
