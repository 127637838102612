import * as React from "react";
import { Image, ImageProps } from "react-native";
import { Image as ExpoImage } from "expo-image";

import { isWeb } from "~/utils/platform";

type Props = ImageProps & {
  uri: string;
};

export const ImageCache = React.memo<Props>(({ uri, ...rest }) => {
  const isLocal = uri.includes("file://");
  return isWeb || isLocal ? (
    <Image {...rest} source={{ uri }} />
  ) : (
    <ExpoImage {...rest} source={uri} tintColor={null} />
  );
});
