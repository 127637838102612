import * as React from "react";
import { colors } from "~/styles/theme";
import { isAndroid } from "~/utils/platform";

import { Screen } from "~/components/screen";

interface Props {
  children: React.ReactNode;
  screenName: string;
  withGrayBackground?: boolean;
  paddingBottom?: number;
}

export const SettingsScreen = ({
  children,
  screenName,
  withGrayBackground,
  paddingBottom,
}: Props) => (
  <Screen
    screenName={screenName}
    backgroundColor={withGrayBackground ? colors.gray100 : colors.white}
    isView={!isAndroid}
    paddingBottom={paddingBottom}
  >
    {children}
  </Screen>
);
