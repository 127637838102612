export const messages = {
  startButton: {
    id: "plan.add-resource.start-button",
    defaultMessage: "Start Plan",
  },
  inProgress: {
    id: "plan.add-resource.in-progress",
    defaultMessage: "In Progress",
  },
  resumeButton: {
    id: "plan.add-resource.resume-button",
    defaultMessage: "Resume Plan",
  },
  restartButton: {
    id: "plan.add-resource.restart-button",
    defaultMessage: "Restart Plan",
  },
};
