import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { Prayer } from "../../../prayer";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface WorshipProps {
  worship: LessonsContent["worship"];
}

export const Worship: React.FC<WorshipProps> = ({ worship }) => {
  const hasContent = React.useMemo(
    () =>
      worship?.introduction ||
      worship?.content ||
      worship?.thought ||
      worship?.prayer ||
      worship?.conclusion,
    [worship]
  );

  return hasContent ? (
    <>
      {worship?.sectionTitle && (
        <SectionTitle>{worship.sectionTitle}</SectionTitle>
      )}

      {worship?.introduction && <RichText>{worship.introduction}</RichText>}

      {worship?.content && <RichText>{worship.content}</RichText>}

      {worship?.thought && <Thought thought={worship.thought} />}

      {worship?.prayer && <Prayer prayer={worship.prayer} />}

      {worship?.conclusion && <RichText>{worship.conclusion}</RichText>}

      {Array.isArray(worship?.conclusionMedia) &&
        worship?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon
        content={[
          worship?.introduction || "",
          worship?.content || "",
          worship?.conclusion || "",
        ]}
      />
    </>
  ) : null;
};
