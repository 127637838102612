export const messages = {
  title: {
    id: "reset-password.title",
    defaultMessage: "Reset password",
  },
  password: {
    id: "reset-password.password",
    defaultMessage: "New password",
  },
  passwordRepeat: {
    id: "reset-password.password-repeat",
    defaultMessage: "Repeat new password",
  },
  button: {
    id: "reset-password.button",
    defaultMessage: "Reset password",
  },
  errorInvalid: {
    id: "reset-password.error-invalid",
    defaultMessage:
      "The request is invalid. Please retry the forgotten password action.",
  },
  success: {
    id: "reset-password.success",
    defaultMessage:
      "Your password has been successfully reset. You may now use your new password to login.",
  },
  backToLogin: {
    id: "reset-password.back-to-login",
    defaultMessage: "Back to login",
  },
};
