import styled from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";

export const SlideContainer = styled.TouchableOpacity`
  flex-direction: column;
  margin: ${spacers.ss5} 0;
  padding: ${spacers.ss6};
  background-color: ${colors.gray100};
`;

export const SlideTitle = styled(Text2)`
  font-family: SFProDisplayItalic;
  line-height: ${lineHeights.lh3};
  color: ${colors.gray800};
  margin: 0 0 ${spacers.ss4} 0;
`;

export const Preview = styled.Image`
  width: 100%;
  height: 196px;
`;
