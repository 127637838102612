import * as React from "react";
import { FlatList } from "react-native";

import { getLastUpdated } from "~/screens/notes/utils";
import { ListItem } from "~/components/list-item";
import { useAppSelector } from "~/state/hooks";
import { getSessionNotesFiltered } from "~/state/notes/selectors";
import { SessionNotesData, SortEnum } from "~/state/notes/types";
import { formatMessage } from "~/utils/translation";

import { messages } from "../intl";
import { Section, EmptyText, contentContainerStyle } from "./styles";

interface Props {
  searchTerm: string;
  sortMethod: SortEnum;
  emptyMessage: TextType;
  onPress: (sessionId: string, planId: string) => void;
}

export const UngroupedNotes = React.memo<Props>(
  ({ searchTerm, sortMethod, emptyMessage, onPress }) => {
    const data: SessionNotesData[] = useAppSelector((state) =>
      getSessionNotesFiltered(state, `${sortMethod}:${searchTerm}`)
    );

    return data.length ? (
      <Section>
        <FlatList
          data={data}
          contentContainerStyle={contentContainerStyle}
          renderItem={({
            item: {
              planId,
              planTitle,
              volumeNumber,
              session,
              sessionId,
              lastUpdated,
            },
            index,
          }) => {
            const sessionNumber = session.split(":")[0];
            const title = formatMessage(messages.noteTitle, {
              planTitle,
              volumeNumber,
              sessionNumber,
            });
            const description = getLastUpdated(lastUpdated);

            return (
              <ListItem
                title={title}
                description={description}
                onPress={() => onPress(sessionId, planId)}
                isLast={index === data.length - 1}
                key={title}
              />
            );
          }}
        />
      </Section>
    ) : (
      <EmptyText>{emptyMessage}</EmptyText>
    );
  }
);
