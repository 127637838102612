import * as React from "react";
import { formatMessage } from "~/utils/translation";
import {
  PrayerContainer,
  PrayerGradientSeparator,
  PrayerText,
  PrayerTextWrapper,
  PrayerTitle,
} from "./styles";
import { messages } from "./intl";

interface PrayerProps {
  prayer: string;
}

export const Prayer: React.FC<PrayerProps> = ({ prayer }) => {
  return (
    <PrayerContainer>
      <PrayerTitle>{formatMessage(messages.title)}</PrayerTitle>

      <PrayerTextWrapper>
        <PrayerGradientSeparator />

        <PrayerText>{prayer}</PrayerText>
      </PrayerTextWrapper>
    </PrayerContainer>
  );
};
