import React from "react";
import { Animated, Easing, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Tooltip } from "~/components/tooltip";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getEnvironment, getHasProfile } from "~/state/user/selectors";
import { getRemoteConfigValue } from "~/state/config";
import { toUpperCase } from "~/utils/strings";

import { TutorialContext } from "./context";
import { Alignment, Position } from "./types";
import { Overlay } from "./styles";

import { setGroupsSeen } from "./slice";
import { getTutorialStepById } from "./selectors";
import { windowHeight } from "~/utils/platform";

type Props = {
  children: React.ReactNode;
};

export const TutorialProvider = ({ children }: Props) => {
  const [stepId, setStepId] = React.useState<string | null>(null);
  const [position, setPosition] = React.useState<Position | null>(null);
  const [isDark, setIsDark] = React.useState(false);
  const data = useAppSelector((state) => getTutorialStepById(state, stepId));
  const env = useAppSelector(getEnvironment);
  const isEnabled = useAppSelector((state) =>
    getRemoteConfigValue(state, `walkthroughEnabled${toUpperCase(env)}`)
  );

  const hasProfile = useAppSelector(getHasProfile);

  const opacity = React.useRef(new Animated.Value(0)).current;

  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const skipTutorial = React.useCallback(() => {
    setStepId(null);
  }, []);

  const state = {
    stepId,
    setStepId,
    position,
    setPosition,
    setIsDark,
    skipTutorial,
  };

  const onSkip = React.useCallback(() => {
    if (data?.group) {
      dispatch(setGroupsSeen([data.group]));
    }

    setStepId(null);
  }, [dispatch, data]);

  const onDone = React.useCallback(() => {
    if (data?.group) {
      dispatch(setGroupsSeen([data.group]));
    }

    setStepId(null);
  }, [dispatch, data]);

  const onNext = React.useCallback(() => {
    if (data?.next) {
      setStepId(data.next);
    }
    if (data?.nextScreen) {
      onDone();
      navigation.navigate(data.nextScreen);
    }
  }, [data, navigation, onDone]);

  React.useEffect(() => {
    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 0,
        duration: 0,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
    ]).start();
  }, [opacity, stepId]);

  if (!hasProfile) {
    return children;
  }

  const renderTooltip = () => {
    let alignment = data?.alignment;

    // Prevent the top aligned box appear on the top of the screen
    if (
      data?.alignment === Alignment.Top &&
      Number(position?.pageY || 0) < 100
    ) {
      alignment = Alignment.Bottom;
    }
    // Prevent the bottom aligned box appear on the bottom of the screen
    if (
      data?.alignment === Alignment.Bottom &&
      windowHeight - Number(position?.pageY || 0) < 100
    ) {
      alignment = Alignment.Top;
    }

    return isEnabled && data?.text && (position?.width || position?.height) ? (
      <Animated.View style={[StyleSheet.absoluteFill, { opacity }]}>
        <Overlay isDark={isDark} />

        <Tooltip
          text={data?.text}
          position={position}
          alignmnent={alignment}
          isLast={!data.next}
          buttonText={data?.buttonText}
          onSkip={onSkip}
          onNext={onNext}
          onDone={onDone}
          key={stepId}
        />
      </Animated.View>
    ) : null;
  };

  return (
    <TutorialContext.Provider value={state}>
      {children}

      {renderTooltip()}
    </TutorialContext.Provider>
  );
};
