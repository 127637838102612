import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { RichText } from "../../../rich-text";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { Footnotes } from "../../../footnotes";
import { ContentIcon } from "../../../content-icon";

interface LessonOverviewProps {
  lesson?: LessonsContent;
}

export const LessonOverview: React.FC<LessonOverviewProps> = ({ lesson }) => {
  return (
    <>
      {lesson?.heading && <SectionTitle>{lesson.heading}</SectionTitle>}

      {lesson?.overview && <RichText>{lesson?.overview}</RichText>}

      {lesson?.lessonOverview?.overview && (
        <RichText>{lesson.lessonOverview.overview}</RichText>
      )}

      {lesson?.lessonOverview &&
        Array.isArray(lesson?.lessonOverview?.media) &&
        lesson.lessonOverview.media.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {lesson?.lessonOverview?.content && (
        <RichText>{lesson.lessonOverview.content}</RichText>
      )}

      {lesson?.lessonOverview?.conclusion && (
        <RichText>{lesson.lessonOverview.conclusion}</RichText>
      )}

      <Footnotes data={lesson?.lessonOverview?.footnotes} />

      <ContentIcon
        content={[
          lesson?.overview || "",
          lesson?.lessonOverview?.overview || "",
          lesson?.lessonOverview?.conclusion || "",
        ]}
      />
    </>
  );
};
