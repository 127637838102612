export const messages = {
  delete: {
    id: "downloaded-item.delete",
    defaultMessage: "Delete",
  },
  cancel: {
    id: "downloaded-item.cancel",
    defaultMessage: "Cancel",
  },
  deleteConfirmation: {
    id: "downloaded-item.delete-confirmation",
    defaultMessage: "Are you sure you want to delete this file?",
  },
};
