import * as React from "react";
import { AppState, AppStateStatus } from "react-native";

interface Props {
  onChange?: (next: AppStateStatus) => void;
  onForeground?: () => void;
  onBackground?: () => void;
}

export const useAppState = ({
  onChange,
  onForeground,
  onBackground,
}: Props) => {
  const [appState, setAppState] = React.useState<AppStateStatus>(
    AppState.currentState
  );

  React.useEffect(() => {
    function handleAppStateChange(nextAppState: AppStateStatus) {
      if (nextAppState === "active" && appState !== "active") {
        typeof onForeground === "function" && onForeground();
      } else if (
        appState === "active" &&
        nextAppState.match(/inactive|background/)
      ) {
        typeof onBackground === "function" && onBackground();
      }
      setAppState(nextAppState);
      typeof onChange === "function" && onChange(nextAppState);
    }

    const eventListener = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      eventListener.remove();
    };
  }, [onChange, onForeground, onBackground, appState]);

  return { appState };
};
