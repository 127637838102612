export const messages = {
  newMember: {
    id: "chat.new-member",
    defaultMessage: "🎉 {{username}} has just joined the group 🎉",
  },
  memberRemoved: {
    id: "chat.member-removed",
    defaultMessage: "🥲 {{username}} has left the group 🥲",
  },
};
