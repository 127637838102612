import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UsersState, User } from "./types";

const initialState: UsersState = {
  data: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsers: (state, action: PayloadAction<User[]>) => {
      state.data = [...state.data, ...action.payload];
    },
  },
});

export const { updateUsers } = usersSlice.actions;

export default usersSlice.reducer;
