import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { MessageData } from "~/state/chat/types";

import { SectionHeader } from "./section-header";
import { Container, ShortNoteText } from "./styles";
import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";
import { navigateTo } from "~/utils/navigation";

interface SessionNoteProps {
  data?: MessageData;
  isReversed: boolean;
}

export const NotePersonal = React.memo<SessionNoteProps>(({ data = {} }) => {
  const title = data?.title || "";
  const text = data?.text || "";

  const navigation = useNavigation();
  const noteTitle = formatMessage(messages.personalNote, { title });

  const onPress = React.useCallback(() => {
    // @ts-ignore
    navigateTo(navigation, routes.notesTab, {
      screen: routes.personalNotes,
    });
  }, [navigation]);

  return (
    <Container>
      <SectionHeader text={noteTitle} onPress={onPress} hasLink={false} />

      <ShortNoteText>{text}</ShortNoteText>
    </Container>
  );
});
