import styled from "styled-components/native";
import { lineHeights, spacers } from "~/styles/theme";
import { getWebContainerStyle, MaxWidthBreakpoints } from "~/utils/web";
import { getElementColorBasedOnDayTime } from "~/utils/theme";
import { isWeb } from "~/utils/platform";
import { Text3, Text9 } from "../text";

const elementsColor = getElementColorBasedOnDayTime();

export const GreetingsContainer = styled.View`
  margin: ${spacers.ss8};
  ${isWeb
    ? `margin-bottom: ${spacers.ss10}; margin-top: ${spacers.ss9};`
    : "min-height: 210px"}

  ${getWebContainerStyle(MaxWidthBreakpoints.Desktop, false, true)}
`;

export const GreetingsTitle = styled(Text9)`
  font-family: Montserrat;
  color: ${elementsColor};
`;

export const GreetingsDivider = styled.View`
  height: 3px;
  width: ${spacers.ss8};
  background-color: ${elementsColor};
  margin: ${spacers.ss4} 0;
`;

export const GreetingsPhrase = styled(Text3)`
  color: ${elementsColor};
`;
