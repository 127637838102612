export const messages = {
  inviteText: {
    id: "auth-screen.invite.text",
    defaultMessage: "Invited to a group?",
  },
  inviteLink: {
    id: "auth-screen.invite.link",
    defaultMessage: "Accept invitation",
  },
  title: {
    id: "auth-screen.invite.title",
    defaultMessage: "Accept a group invitation",
  },
  description: {
    id: "auth-screen.invite.description",
    defaultMessage:
      'If you were sent a link to join a group simply tap it again or, once registered, introduce the invite code in the "Groups" section',
  },
};
