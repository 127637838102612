import * as React from "react";
import { GestureResponderEvent } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { asyncLogEvent, events } from "~/utils/analytics";
import { NotesProps } from "~/navigation/notes-stack/types";
import { useActionSheet } from "~/components/action-sheet";
import { HeaderBar } from "~/components/header-bar";
import { SearchInput } from "~/components/search-input";
import { Screen } from "~/components/screen";
import { messages as notesMessages } from "~/screens/notes/intl";
import { SortEnum } from "~/state/notes/types";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import { Fab } from "~/components/fab";
import { getOptionText } from "~/screens/notes/utils";

import { Content } from "./content";
import {
  Container,
  TitleBox,
  Title,
  MoreButton,
  SearchBox,
} from "../notes/styles";
import { messages } from "./intl";

interface Props {
  onPress: (noteId: string) => void;
  onPressAdd: () => void;
}

export const PersonalNotesContent = ({ onPress, onPressAdd }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sortMethod, setSortMethod] = React.useState<SortEnum>(SortEnum.DESC);
  const { showActionSheetWithOptions } = useActionSheet();

  const handleSortMethod = (method: SortEnum) => {
    asyncLogEvent(events.SELECT_NOTES_FILTER, { type: "personal", method });
    setSortMethod(method);
  };

  const onFilterPress = React.useCallback(
    (event: GestureResponderEvent) => {
      const options = [
        notesMessages.sortNewToOld,
        notesMessages.sortOldToNew,
        notesMessages.cancel,
      ]
        .map((message) => formatMessage(message))
        .map(getOptionText(sortMethod));

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: 2,
        },
        (selectedIndex?: number) => {
          if (selectedIndex === 0) {
            handleSortMethod(SortEnum.DESC);
          }
          if (selectedIndex === 1) {
            handleSortMethod(SortEnum.ASC);
          }
        },
        // @ts-ignore
        event
      );
    },
    [showActionSheetWithOptions, sortMethod]
  );

  const emptyMessage = searchTerm ? messages.emptySearch : messages.empty;

  return (
    <>
      <Container>
        <TitleBox>
          <Title>{messages.title}</Title>
          <MoreButton onPress={onFilterPress}>
            <Icon name="filter-list" size={26} />
          </MoreButton>
        </TitleBox>
        <SearchBox>
          <SearchInput
            value={searchTerm}
            placeholder={messages.search}
            onChange={setSearchTerm}
          />
        </SearchBox>
        <Content
          searchTerm={searchTerm}
          sortMethod={sortMethod}
          emptyMessage={emptyMessage}
          onPress={onPress}
        />
      </Container>
      <Fab onPress={onPressAdd} />
    </>
  );
};

export const PersonalNotes = () => {
  const navigation = useNavigation<NotesProps["navigation"]>();

  const onPress = React.useCallback(
    (noteId: string) => {
      navigation.navigate(routes.personalNotesViewEdit, { noteId });
    },
    [navigation]
  );

  const onPressAdd = React.useCallback(() => {
    navigation.navigate(routes.personalNotesAdd);
  }, [navigation]);

  return (
    <Screen screenName="notes-personal" backgroundColor={colors.gray75}>
      <HeaderBar iconColor={colors.black} withBackButton noMargin />
      <PersonalNotesContent onPress={onPress} onPressAdd={onPressAdd} />
    </Screen>
  );
};
