import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import type { AddPrayerRequestsParams, PrayerRequests } from "./types";
import type { RootState } from "../store";
import { getPrayerRequestsCollection } from "~/constants/collections";
import { Environment } from "~/state/user/types";
import { handleError } from "~/utils/logger";

const PRAYER_REQUESTS_TAG = "SessionNotes";

export const prayerRequestsApi = createApi({
  reducerPath: "prayerRequestsApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: [PRAYER_REQUESTS_TAG],
  endpoints: (builder) => ({
    addPrayerRequests: builder.mutation<string, AddPrayerRequestsParams>({
      async queryFn({ sessionId, prayerRequests, onSuccess }, { getState }) {
        try {
          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env: Environment =
            (getState() as RootState).user.environment ?? "";
          const prayerRequestsCollection = getPrayerRequestsCollection(env);

          const q = query(
            collection(database, prayerRequestsCollection),
            where("userId", "==", userId),
            where("sessionId", "==", sessionId)
          );
          const querySnapshot = await getDocs(q);
          const data = querySnapshot.docs.map((snapshotDoc) =>
            snapshotDoc.data()
          )[0] as PrayerRequests;

          if (data) {
            await setDoc(
              doc(database, prayerRequestsCollection, querySnapshot.docs[0].id),
              { lastUpdated: Date.now(), prayerRequests },
              { merge: true }
            );
            onSuccess();

            return { data: "Prayer requests updated successfully" };
          }

          const prayerRequestsToStore: PrayerRequests = {
            userId,
            sessionId,
            lastUpdated: Date.now(),
            prayerRequests,
          };

          await addDoc(
            collection(database, prayerRequestsCollection),
            prayerRequestsToStore
          );
          onSuccess();

          return { data: "Prayer requests created successfully" };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [PRAYER_REQUESTS_TAG],
    }),

    getPrayerRequests: builder.query<PrayerRequests, string>({
      async queryFn(sessionId, { getState }) {
        try {
          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env: Environment =
            (getState() as RootState).user.environment ?? "";
          const prayerRequestsCollection = getPrayerRequestsCollection(env);

          const q = query(
            collection(database, prayerRequestsCollection),
            where("userId", "==", userId),
            where("sessionId", "==", sessionId)
          );

          const querySnapshot = await getDocs(q);
          const result = querySnapshot.docs.map((snapshotDoc) =>
            snapshotDoc.data()
          )[0] as PrayerRequests;

          if (!result) {
            return {
              error: `No prayer requests found for the key: ${sessionId}`,
            };
          }

          return { data: result };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      providesTags: [PRAYER_REQUESTS_TAG],
    }),
  }),
});

export const { useAddPrayerRequestsMutation, useGetPrayerRequestsQuery } =
  prayerRequestsApi;
