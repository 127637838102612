import { MessageType, MessageContent } from "./types";

export const getDataByType = ({ content, type, data }: MessageContent) => {
  switch (type) {
    case MessageType.NoteSession:
    case MessageType.NoteBible:
    case MessageType.NotePersonal:
      return {
        data,
        content,
      };
    default:
      return {
        content,
      };
  }
};
