export const messages = {
  search: {
    id: "library-search.search",
    defaultMessage: "Search",
  },
  cancel: {
    id: "library-search.cancel",
    defaultMessage: "Cancel",
  },
  resetSearchTerm: {
    id: "library-search.reset-search-term",
    defaultMessage: "Clear Search",
  },
};
