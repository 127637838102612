export enum Alignment {
  Top,
  Bottom,
}

export type Step = {
  id: string;
  text: string;
  next?: string;
  nextScreen?: string;
  alignment?: Alignment;
  group: string;
  buttonText?: TextType;
  route: string;
  order: number;
  isHorizontal?: boolean;
};

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
};

export type WalkthroughContent = Record<string, Record<string, string>>;

export type TutorialState = {
  stepId: string | null;
  position: Position | null;
  setPosition: (position: Position) => void;
  setStepId: (stepId: string) => void;
  setIsDark: (isDark: boolean) => void;
  skipTutorial: () => void;
};

export type TutorialReducer = {
  content: WalkthroughContent;
  groupsSeen: string[];
  shouldShowTutorial: boolean;
};
