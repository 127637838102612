import { LinearGradient } from "expo-linear-gradient";
import styled, { css } from "styled-components/native";

import { ImageCache } from "~/components/image-cache";
import { Text2 } from "~/components/text";
import { colors } from "~/styles/theme";
import { isAndroid, isWeb } from "~/utils/platform";

interface Props {
  size: number;
}

export const ImageBox = styled.View<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${colors.gray300};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const GradientContainer = styled(LinearGradient).attrs({
  colors: isWeb || isAndroid ? ["#F08E12", "#D53E1B"] : ["#D53E1B", "#F08E12"],
  start: isAndroid ? { x: 0.7, y: 0.7 } : { x: 0.1, y: 0.7 },
})<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${colors.gray300};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const imageStyles = css<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const AvatarImage = styled(ImageCache)<Props>`
  ${imageStyles}
`;

export const LocalImage = styled.Image<Props>`
  ${imageStyles}
`;

export const Text = styled(Text2)`
  font-family: SFProDisplayMedium;
  color: ${colors.white};
`;

export const LoaderOverlay = styled.View<Props>`
  width: 100%;
  height: 100%;
  background-color: ${colors.gray300};
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0.5;
`;
