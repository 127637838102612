export const messages = {
  skip: {
    id: "tooltip.skip",
    defaultMessage: "Skip",
  },
  next: {
    id: "tooltip.next",
    defaultMessage: "Next",
  },
  done: {
    id: "tooltip.done",
    defaultMessage: "Done",
  },
};
