import styled from "styled-components/native";
import { Text2 } from "~/components/text";
import { lineHeights, spacers } from "~/styles/theme";

export const ItemDescription = styled(Text2)`
  margin: ${spacers.ss4} 0 0;
  line-height: ${lineHeights.lh3};
  letter-spacing: 0.5px;
`;

export const SubContainer = styled.ScrollView`
  margin-bottom: ${spacers.ss9};
`;
