export const messages = {
  share: {
    id: "notes-action.share",
    defaultMessage: "Share note",
  },
  edit: {
    id: "notes-action.edit",
    defaultMessage: "Edit note",
  },
  delete: {
    id: "notes-action.delete",
    defaultMessage: "Delete",
  },
  deleteNote: {
    id: "notes-action.delete-note",
    defaultMessage: "Delete note",
  },
  deleteQuestion: {
    id: "notes-action.delete-question",
    defaultMessage: "Are you sure you want to delete this note?",
  },
  cancel: {
    id: "notes-action.cancel",
    defaultMessage: "Cancel",
  },
  done: {
    id: "notes-action.done",
    defaultMessage: "Done",
  },
};
