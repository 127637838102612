import uuid from "react-native-uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  DocumentSnapshot,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import NetInfo from "@react-native-community/netinfo";

import { database, storage } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";

import {
  updateInAppNotifications,
  addInAppNotification,
  editInAppNotification,
  deleteInAppNotification,
} from "./slice";
import {
  getInAppNotifications,
  addInAppNotification as addInAppNotificationAction,
  editInAppNotification as editInAppNotificationAction,
  removeInAppNotification,
} from "./actions";
import {
  GetInAppNotificationsAction,
  AddInAppNotificationAction,
  DeleteInAppNotificationAction,
  EditInAppNotificationAction,
  InAppNotification,
  NotificationType,
  LinkType,
  LanguageKey,
  LocalisedText,
  ResourceType,
} from "./types";
import { getInAppNotificationsCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { getInAppNotificationById } from "./selectors";
import { defaultString } from "~/screens/in-app-messages/constants";

function* uploadFileSaga(file: File, type: string): SagaIterator {
  try {
    const path = type === "video" ? "videos" : "images";
    const storageRef = ref(storage, `${path}/${file.name}`);
    // @ts-ignore
    yield call(uploadBytesResumable, storageRef, file);
    // @ts-ignore
    const fileUrl = yield call(getDownloadURL, storageRef);

    return fileUrl;
  } catch (error) {
    handleError(error);
    return "";
  }
}

export function* getInAppNotificationsFn({
  payload: { onSuccess, onError },
}: GetInAppNotificationsAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const inAppNotificationsCollection = getInAppNotificationsCollection(env);

    const q = query(collection(database, inAppNotificationsCollection));

    const snapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = snapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as InAppNotification[];

    if (data && Array.isArray(data)) {
      yield put(updateInAppNotifications(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addInAppNotificationFn({
  payload: {
    type = NotificationType.MODAL,
    resourceType = ResourceType.IMAGE,
    titleText = defaultString,
    descriptionText = defaultString,
    imageSet,
    videoSet,
    ctaText = defaultString,
    navigateTo = "",
    options = {},
    schedule = "",
    expiration = "",
    route = "",
    url = "",
    timeoutInSeconds = 0,
    maxTimes = 1,
    isActive = false,
    linkType = LinkType.INTERNAL,
    onSuccess,
    onError,
  },
}: AddInAppNotificationAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const inAppNotificationsCollection = getInAppNotificationsCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const imageUrls = {} as LocalisedText;
    const videoUrls = {} as LocalisedText;

    if (imageSet) {
      for (const [language, file] of Object.entries(imageSet)) {
        if (file) {
          const imageUrl = yield call(uploadFileSaga, file, "image");
          imageUrls[language as LanguageKey] = imageUrl;
        }
      }
    }

    if (videoSet) {
      for (const [language, file] of Object.entries(videoSet)) {
        if (file) {
          const videoUrl = yield call(uploadFileSaga, file, "video");
          videoUrls[language as LanguageKey] = videoUrl;
        }
      }
    }

    const inAppNotification = {
      id,
      type,
      resourceType,
      titleText,
      descriptionText,
      imageUrls,
      videoUrls,
      ctaText,
      navigateTo,
      options,
      schedule,
      expiration,
      linkType,
      route,
      url,
      timeoutInSeconds: Number(timeoutInSeconds),
      maxTimes: Number(maxTimes),
      userId,
      isActive,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, inAppNotificationsCollection, id),
      inAppNotification
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addInAppNotification(inAppNotification));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* editInAppNotificationFn({
  payload: {
    id,
    type = NotificationType.MODAL,
    resourceType = ResourceType.IMAGE,
    titleText = defaultString,
    descriptionText = defaultString,
    ctaText = defaultString,
    imageSet,
    videoSet,
    navigateTo = "",
    options = {},
    schedule = "",
    expiration = "",
    route = "",
    url = "",
    timeoutInSeconds = 0,
    maxTimes = 1,
    isActive = false,
    linkType = LinkType.INTERNAL,
    onSuccess,
    onError,
  },
}: EditInAppNotificationAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const currentData = yield select(getInAppNotificationById, id);
    const inAppNotificationsCollection = getInAppNotificationsCollection(env);

    const newImageUrls = {} as LocalisedText;
    const newVideoUrls = {} as LocalisedText;

    if (imageSet) {
      for (const [language, file] of Object.entries(imageSet)) {
        if (file) {
          const imageUrl = yield call(uploadFileSaga, file, "image");
          newImageUrls[language as LanguageKey] = imageUrl;
        }
      }
    }

    if (videoSet) {
      for (const [language, file] of Object.entries(videoSet)) {
        if (file) {
          const videoUrl = yield call(uploadFileSaga, file, "video");
          newVideoUrls[language as LanguageKey] = videoUrl;
        }
      }
    }

    const inAppNotification = {
      id,
      type,
      resourceType,
      titleText,
      descriptionText,
      imageUrls: { ...(currentData?.imageUrls || {}), ...newImageUrls },
      videoUrls: { ...(currentData?.videoUrls || {}), ...newVideoUrls },
      ctaText,
      navigateTo,
      options,
      schedule,
      expiration,
      route,
      url,
      linkType,
      timeoutInSeconds: Number(timeoutInSeconds),
      maxTimes: Number(maxTimes),
      isActive,
    };

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, inAppNotificationsCollection, id),
      inAppNotification
    );

    // Update the local state to avoid an extra Firebase query
    yield put(editInAppNotification(inAppNotification));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deleteInAppNotificationFn({
  payload: { id, onSuccess, onError },
}: DeleteInAppNotificationAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const inAppNotificationsCollection = getInAppNotificationsCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, inAppNotificationsCollection, id)
    );

    // Update the local state to avoid an extra Firebase query
    yield put(deleteInAppNotification(id));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const addInAppNotificationsSaga = function* () {
  yield takeLatest(addInAppNotificationAction.type, addInAppNotificationFn);
};

export const getInAppNotificationsSaga = function* () {
  yield takeLatest(getInAppNotifications.type, getInAppNotificationsFn);
};

export const editInAppNotificationSaga = function* () {
  yield takeLatest(editInAppNotificationAction.type, editInAppNotificationFn);
};

export const deleteInAppNotificationSaga = function* () {
  yield takeLatest(removeInAppNotification.type, deleteInAppNotificationFn);
};
