import * as React from "react";
import { FlatList } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getSessionNotesBySessionId } from "~/state/notes/selectors";
import { HeaderBar } from "~/components/header-bar";
import { NotesViewProps } from "~/navigation/notes-stack/types";
import { getLastUpdated } from "~/screens/notes/utils";
import { NoteQuestion } from "~/components/note-question";
import { Loader } from "~/components/loader";
import { colors } from "~/styles/theme";
import { messages as noteMessages } from "~/screens/notes-session/intl";

import {
  Screen,
  Container,
  HeaderBox,
  TitleBox,
  Title,
  LastEdited,
  Section,
  Answer,
  contentContainerStyle,
} from "./styles";
import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";
import { getSessionNotes } from "~/state/session-notes/actions";

export type SessionNoteOptions = {
  sessionId: string;
  planId: string;
  noteId: string;
  title: string;
  sessionTitle: string;
  isSingleNote: boolean;
};

interface Props {
  sessionId: string;
  planId: string;
  onPress: (options: SessionNoteOptions) => void;
}

export const SessionNotesViewComponent = ({
  sessionId,
  planId,
  onPress,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(true);

  const notes = useAppSelector((state) =>
    getSessionNotesBySessionId(state, sessionId)
  );

  const sessionNumber = (notes?.session || "").split(":")[0];
  const title = formatMessage(noteMessages.noteTitle, {
    planTitle: notes?.planTitle || "",
    volumeNumber: notes?.volumeNumber || 1,
    sessionNumber,
  });

  const sessionTitle = (notes?.session || "").split(":")[0];
  const data = React.useMemo(() => notes?.notes || [], [notes]);

  const description = getLastUpdated(notes?.lastUpdated);

  const onNotePress = React.useCallback(
    (noteId: string) => {
      const isSingleNote = data.length === 1;
      onPress({
        sessionId,
        planId,
        noteId,
        title,
        sessionTitle,
        isSingleNote,
      });
    },
    [onPress, sessionId, planId, title, data, sessionTitle]
  );

  React.useEffect(() => {
    dispatch(
      getSessionNotes({
        sessionId,
        onSuccess: () => setIsLoading(false),
        onError: () => setIsLoading(false),
      })
    );
  }, [dispatch, sessionId]);

  return !isLoading ? (
    <Container>
      <HeaderBox>
        <TitleBox>
          <Title>{title}</Title>
        </TitleBox>

        <LastEdited>{description}</LastEdited>
      </HeaderBox>

      <FlatList
        contentContainerStyle={contentContainerStyle}
        showsVerticalScrollIndicator={false}
        data={data}
        renderItem={({ item }) => {
          const { question, note: answer, id } = item;
          return (
            <Section onPress={() => onNotePress(id)}>
              <NoteQuestion question={question} />
              <Answer>{answer}</Answer>
            </Section>
          );
        }}
      />
    </Container>
  ) : (
    <Loader fullScreen />
  );
};

export const SessionNotesView = () => {
  const {
    params: { sessionId, planId },
  } = useRoute<NotesViewProps["route"]>();
  const navigation = useNavigation();

  const onNotePress = React.useCallback(
    (options: Object) => {
      navigation.navigate(routes.sessionNoteView, options);
    },
    [navigation]
  );

  return (
    <Screen screenName="notes-session-view">
      <HeaderBar
        iconColor={colors.black}
        withBackButton
        backButtonText={messages.backButton}
      />
      <SessionNotesViewComponent
        sessionId={sessionId}
        planId={planId}
        onPress={onNotePress}
      />
    </Screen>
  );
};
