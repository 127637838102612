import { deviceName } from "expo-device";
import { addDoc, collection } from "firebase/firestore";
import { database } from "<config>/firebase";

import { APP_VERSION } from "~/constants";
import { getAppFeedbackCollection } from "~/constants/collections";
import { Environment } from "~/state/user/types";

import type { AddAppFeedbackAction, AppFeedback } from "./types";
import { getFormattedOSForFeedback } from "./utils";
import { getMemoryData } from "~/utils/ram-info";
import { getInternetSpeed } from "~/utils/internet-speed";
import {
  getFreeDiskStorage,
  getFirstInstallTime,
  isLowRamDevice,
} from "~/utils/device-info";

interface SendAppFeedbackParams {
  payload: AddAppFeedbackAction["payload"];
  env: Environment;
  userId: string;
  email: string;
}

const bytesToMB = (bytes?: number) =>
  bytes ? Math.round(bytes / (1024 * 1024)) : Infinity;

export async function sendAppFeedback({
  payload: { onCompleted, ...payload },
  env,
  userId,
  email,
}: SendAppFeedbackParams): Promise<string> {
  const appFeedbackCollection = getAppFeedbackCollection(env);

  const memoryData = await getMemoryData();
  const freeDiskStorage = await getFreeDiskStorage();
  const firstInstallTime = await getFirstInstallTime();
  const isLowRam = await isLowRamDevice();
  const internetSpeed = await getInternetSpeed();

  const data: AppFeedback = {
    ...payload,
    appVersion: APP_VERSION,
    OS: getFormattedOSForFeedback(),
    device: deviceName || "unknown",
    userId,
    email,
    created: new Date().getTime(),
    isLowRamDevice: isLowRam,
    freeMemoryMB: bytesToMB(memoryData?.freeMemory),
    freeDiskStorage,
    firstInstallTime,
    internetSpeed,
  };

  const newPlanProgressResult = await addDoc(
    collection(database, appFeedbackCollection),
    data
  );

  return newPlanProgressResult.id;
}
