import {
  getAnalytics,
  logEvent,
  setUserId as firebaseSetUserId,
} from "firebase/analytics";
import ReactGA from "react-ga4";

import { app } from "<config>/firebase.web";
import { MEASUREMENT_ID } from "~/constants";
import { events } from "./events";

const analytics = getAnalytics(app);

export const setUserId = (id: string) => firebaseSetUserId(analytics, id);

export const asyncLogScreenView = (screenName: string, params: any = {}) => {
  logEvent(analytics, events.SCREEN_VIEW, {
    screen_name: screenName,
    ...params,
  });

  ReactGA.event({
    category: events.SCREEN_VIEW,
    action: screenName,
  });
};

export const asyncLogEvent = (eventName: string, params: any = {}) => {
  logEvent(analytics, eventName, params);

  ReactGA.event({
    category: "event",
    action: eventName,
  });
};

export const initialiseAnalytics = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export { events };
