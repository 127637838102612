import * as React from "react";
import type { ConsiderSection } from "~/state/flamelink/types";
import { messages as sessionMessages } from "~/screens/session/intl";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../checklist";
import { SectionTitle, SubsectionTitle } from "../common";
import { Media } from "../media";
import { RichText } from "../rich-text";
import { Question } from "../question";
import { Scripture } from "../scripture";
import { Footnotes } from "../footnotes";
import { WhatWeBelieve } from "../what-we-believe";
import { ContentIcon } from "../content-icon";

interface ConsiderProps {
  consider: ConsiderSection;
}

export const Consider: React.FC<ConsiderProps> = ({ consider }) => {
  return (
    <>
      <SectionTitle>{consider?.sectionTitle}</SectionTitle>

      {consider?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={consider.youWillNeed}
          checklistId="consider-youWillNeed"
        />
      )}

      {consider?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={consider.gettingReady}
          checklistId="consider-gettingReady"
        />
      )}

      <RichText>{consider?.intro}</RichText>

      {Array.isArray(consider?.questions) &&
        consider?.questions.map(({ question, uniqueKey, content }, i) => (
          <React.Fragment key={`question-repeater-${i}`}>
            <Question question={{ question, uniqueKey }} />

            <RichText>{content}</RichText>
          </React.Fragment>
        ))}

      <RichText>{consider?.conclusion}</RichText>

      <>
        {Array.isArray(consider?.subsectionRepeater) &&
          consider?.subsectionRepeater.map((subsection, index) => (
            <React.Fragment key={`subsection-${index}`}>
              <SubsectionTitle>{subsection.title}</SubsectionTitle>

              {Array.isArray(subsection?.mediaRepeater) &&
                subsection?.mediaRepeater.map(
                  ({ media, content, questionRepeater }, i) => (
                    <React.Fragment key={`mediaRepeater-${i}`}>
                      {Array.isArray(media) &&
                        media.map((fileId, mediaIndex) => (
                          <Media
                            key={`mediaRepeater-media-${mediaIndex}`}
                            fileId={fileId}
                          />
                        ))}

                      {content && <RichText>{content}</RichText>}

                      {Array.isArray(questionRepeater) &&
                        questionRepeater.map(
                          ({ question, uniqueKey, content: qrContent }, j) => (
                            <React.Fragment
                              key={`mediaRepeater-question-repeater-${j}`}
                            >
                              <Question question={{ question, uniqueKey }} />

                              <RichText>{qrContent}</RichText>
                            </React.Fragment>
                          )
                        )}
                    </React.Fragment>
                  )
                )}

              {subsection?.scripture && (
                <Scripture scripture={subsection.scripture} />
              )}

              <RichText>{subsection.intro}</RichText>

              <RichText>{subsection.body}</RichText>

              {Array.isArray(subsection?.questionRepeater) &&
                subsection?.questionRepeater.map(
                  ({ question, uniqueKey, content }, i) => (
                    <React.Fragment key={`content-repeater-${i}`}>
                      <Question question={{ question, uniqueKey }} />

                      <RichText>{content}</RichText>
                    </React.Fragment>
                  )
                )}

              <RichText>{subsection.conclusion}</RichText>

              <Footnotes data={subsection?.footnotes} />
            </React.Fragment>
          ))}
      </>

      <WhatWeBelieve data={consider?.whatWeBelieve} />

      <Footnotes data={consider?.footnotes} />

      <ContentIcon
        content={[
          consider?.intro,
          consider?.questions,
          consider?.conclusion,
          consider?.subsectionRepeater
            ?.map((subsection) =>
              [
                subsection.title,
                subsection.intro,
                Array.isArray(subsection.mediaRepeater) &&
                  subsection.mediaRepeater
                    .map(({ content }) => content)
                    .join("\n"),
                Array.isArray(subsection.questionRepeater) &&
                  subsection.questionRepeater
                    ?.map(({ question, content }) =>
                      [question, content].join("\n")
                    )
                    .join("\n"),
                subsection.body,
                subsection.conclusion,
              ].join("\n")
            )
            .join(""),
          consider?.whatWeBelieve?.whatWeBelieveSectionTitle || "",
          consider?.whatWeBelieve?.title || "",
          consider?.whatWeBelieve?.title || "",
          consider?.whatWeBelieve?.mediaRepeater,
          consider?.whatWeBelieve?.conclusion || "",
        ]}
      />
    </>
  );
};
