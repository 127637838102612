import { useMenu } from "~/state/menu/hook.web";

export const useActionSheet = () => {
  const { showMenu } = useMenu();

  const showActionSheetWithOptions = (
    { options }: { options: string[] },
    handleSelectedIndex: (index: number) => void,
    event: React.MouseEvent,
    position: DOMRect
  ) => {
    showMenu(
      options.slice(0, options.length - 1),
      handleSelectedIndex,
      event,
      position
    );
  };

  return {
    showActionSheetWithOptions,
  };
};
