import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { UIState } from "./types";

export const initialState: UIState = {
  moreButtonAnimationCount: 0,
  libraryFilters: {
    types: [],
    audiences: [],
  },
  libraryFiltersOptions: undefined,
  filtersFetchTime: 0,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    incrementMoreButtonAnimationCount: (state) => {
      state.moreButtonAnimationCount = state.moreButtonAnimationCount + 1;
    },
    resetMoreButtonAnimationCount: (state) => {
      state.moreButtonAnimationCount = 0;
    },
    setLibraryFilters: (
      state,
      action: PayloadAction<UIState["libraryFilters"]>
    ) => {
      state.libraryFilters = action.payload;
      state.filtersFetchTime = new Date().getTime();
    },

    setTypeFilter: (
      { libraryFilters },
      action: PayloadAction<{ value: string; isAllChecked: boolean }>
    ) => {
      const { value, isAllChecked } = action.payload;

      const typeExists = libraryFilters.types.find((type) => type === value);

      if (isAllChecked) {
        libraryFilters.types = [value];
        return;
      }

      if (typeExists && libraryFilters.types.length > 1) {
        libraryFilters.types = libraryFilters.types.filter(
          (type) => type !== value
        );

        return;
      }
      if (typeExists) {
        return;
      }

      libraryFilters.types = [...libraryFilters.types, value];
    },

    setAudienceFilter: (
      { libraryFilters },
      action: PayloadAction<{ value: string; isAllChecked: boolean }>
    ) => {
      const { value, isAllChecked } = action.payload;
      const audienceExists = libraryFilters.audiences.find(
        (audience) => audience === value
      );

      if (isAllChecked) {
        libraryFilters.audiences = [value];
        return;
      }

      if (audienceExists && libraryFilters.audiences.length > 1) {
        libraryFilters.audiences = libraryFilters.audiences.filter(
          (audience) => audience !== value
        );

        return;
      }
      if (audienceExists) {
        return;
      }

      libraryFilters.audiences = [...libraryFilters.audiences, value];
    },

    setAllTypeFilter: ({ libraryFilters }, action: PayloadAction<string[]>) => {
      libraryFilters.types = action.payload;
    },

    setAllAudienceFilter: (
      { libraryFilters },
      action: PayloadAction<string[]>
    ) => {
      libraryFilters.audiences = action.payload;
    },

    setLibraryFiltersOptions: (
      state,
      action: PayloadAction<UIState["libraryFiltersOptions"]>
    ) => {
      state.libraryFiltersOptions = action.payload;
    },

    resetFiltersFetchTime: (state) => {
      state.filtersFetchTime = 0;
    },
  },
});

export const {
  incrementMoreButtonAnimationCount,
  resetMoreButtonAnimationCount,
  setLibraryFilters,
  setTypeFilter,
  setAllTypeFilter,
  setAudienceFilter,
  setAllAudienceFilter,
  setLibraryFiltersOptions,
  resetFiltersFetchTime,
} = uiSlice.actions;

export default uiSlice.reducer;
