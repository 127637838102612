import React from "react";
import { View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { colors, Colors } from "~/styles/theme";
import { asyncLogScreenView } from "~/utils/analytics";
import { ErrorBoundary } from "~/components/error-boundary";

import {
  SafeAreaContainer,
  ViewContainer,
  KeyboardContainer,
  ScrollViewContainer,
} from "./styles";
import { isAndroid, isWeb } from "~/utils/platform";
import { HEADER_BAR_HEIGHT } from "~/components/header-bar/web/styles";

export type Props = {
  children: React.ReactNode;
  backgroundColor?: Colors;
  screenName?: string;
  isView?: boolean;
  isScrollView?: boolean;
  isListView?: boolean;
  isModal?: boolean;
  isFragment?: boolean;
  isKeyboardAvoiding?: boolean;
  paddingBottom?: number;
  hasFixedHeader?: boolean;
};

export const Screen = React.memo<Props>(
  ({
    children,
    backgroundColor = colors.white,
    screenName,
    isView,
    isScrollView,
    isListView,
    isModal,
    isFragment,
    isKeyboardAvoiding,
    paddingBottom = 0,
    hasFixedHeader,
  }) => {
    useFocusEffect(
      // To avoid running the effect too often, we need to wrap the callback in useCallback before passing it to useFocusEffect
      // https://reactnavigation.org/docs/use-focus-effect/
      React.useCallback(() => {
        if (screenName) {
          asyncLogScreenView(screenName, {});
        }
      }, [screenName])
    );

    const paddingTop = hasFixedHeader ? HEADER_BAR_HEIGHT : 0;

    if (isView) {
      return (
        <ErrorBoundary>
          <ViewContainer
            backgroundColor={backgroundColor}
            paddingBottom={paddingBottom}
            hasOverflow={!isListView}
          >
            {children as React.ReactNode}
          </ViewContainer>
        </ErrorBoundary>
      );
    }

    if (isKeyboardAvoiding) {
      return (
        <ErrorBoundary>
          <KeyboardContainer
            backgroundColor={backgroundColor}
            behavior={isAndroid ? "height" : "padding"}
          >
            {children as React.ReactNode}
          </KeyboardContainer>
        </ErrorBoundary>
      );
    }

    if (isModal) {
      return (
        <ErrorBoundary>
          <View>{children as React.ReactNode}</View>
        </ErrorBoundary>
      );
    }

    if (isScrollView) {
      return (
        <ErrorBoundary>
          <ScrollViewContainer>
            {children as React.ReactNode}
          </ScrollViewContainer>
        </ErrorBoundary>
      );
    }

    if (isFragment) {
      return <ErrorBoundary>{children as React.ReactNode}</ErrorBoundary>;
    }

    return (
      <ErrorBoundary>
        <SafeAreaContainer
          backgroundColor={backgroundColor}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          hasOverflow={!isListView}
        >
          {children as React.ReactNode}
        </SafeAreaContainer>
      </ErrorBoundary>
    );
  }
);
