export const messages = {
  changeRoleTitle: {
    id: "group.members.change-role.title",
    defaultMessage: "Change role",
  },
  changeRoleLeader: {
    id: "group.members.change-role.leader",
    defaultMessage: "Change role to Leader",
  },
  changeRoleMember: {
    id: "group.members.change-role.member",
    defaultMessage: "Change role to Member",
  },
  remove: {
    id: "group.members.remove",
    defaultMessage: "Remove from group",
  },
  removeButton: {
    id: "group.members.remove-button",
    defaultMessage: "Remove",
  },
  cancel: {
    id: "group.members.cancel",
    defaultMessage: "Cancel",
  },
  roleChanged: {
    id: "group.members.role-changed",
    defaultMessage: "Member role has been successfully changed",
  },
  memberRemoved: {
    id: "group.members.member-removed",
    defaultMessage: "Member has been successfully removed from the group",
  },
  removeYourself: {
    id: "group.members.remove-yourself",
    defaultMessage: "Are you sure you want to remove yourself from this group?",
  },
  removeMember: {
    id: "group.members.remove-member",
    defaultMessage: "Are you sure you want to remove {{name}} from this group?",
  },
  removeOnlyLeader: {
    id: "group.members.remove-only-leader",
    defaultMessage:
      "Before removing yourself from this group, you need to first assign a new leader.",
  },
  changeRoleOnlyLeader: {
    id: "group.members.change-role-only-leader",
    defaultMessage:
      "You cannot change your current role. There should be at least one Leader in the group",
  },
};
