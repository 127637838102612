import React from "react";
import { getIsVolumePublished } from "~/state/flamelink";
import { useAppSelector } from "~/state/hooks";
import { ListSection } from "./styles";

interface VolumeSectionProps {
  title: string;
  children: React.ReactNode;
  planId: string;
  volumeId: string;
  hidden: boolean;
}

export const VolumeSection: React.FC<VolumeSectionProps> = ({
  title,
  children,
  planId,
  volumeId,
  hidden,
}) => {
  const isVolumePublished = useAppSelector((state) =>
    getIsVolumePublished(state, volumeId, planId)
  );

  if (!isVolumePublished) return null;

  const sectionProps = !hidden ? { title } : {};

  return <ListSection {...sectionProps}>{children}</ListSection>;
};
