export const messages = {
  english: {
    id: "use-change-languages.english",
    defaultMessage: "English",
  },
  spanish: {
    id: "use-change-languages.spanish",
    defaultMessage: "Spanish",
  },
  system: {
    id: "use-change-languages.system",
    defaultMessage: "Use System Language",
  },
  all: {
    id: "use-change-languages.all",
    defaultMessage: "All",
  },
  cancel: {
    id: "use-change-languages.cancel",
    defaultMessage: "Cancel",
  },
};
