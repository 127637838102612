import * as React from "react";
import {
  useNavigation,
  useRoute,
  CommonActions,
} from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { useAppDispatch } from "~/state/hooks";
import { fetchUserGroups, joinGroup, setInviteCode } from "~/state/groups";
import { useAppSelector } from "~/state/hooks";
import { getIsLoggedIn, getUserId } from "~/state/user/selectors";
import { useAlerts } from "~/state/alerts";
import { Loader } from "~/components/loader";
import { Landing } from "~/screens/landing";
import { isMobileDevice, isWeb } from "~/utils/platform";

import { InviteCodeRouteProp } from "./types";
import { Container } from "./styles";

export const InviteCode = React.memo(() => {
  const navigation = useNavigation<any>();
  const {
    params: { inviteCode },
  } = useRoute<InviteCodeRouteProp>();

  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const userId = useAppSelector(getUserId);
  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const goToLogin = React.useCallback(() => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: routes.login }],
      })
    );
  }, [navigation]);

  const onSuccess = React.useCallback(
    (groupId?: string) => {
      // This is temporary until we get the web ready after v1
      if (isWeb) {
        navigation.navigate(routes.home);
        return;
      }

      if (!groupId) {
        navigation.navigate(routes.home, {
          screen: routes.groupsTab,
        });
        return;
      }
      navigation.navigate(routes.home, {
        screen: routes.groupsTab,
      });
      dispatch(fetchUserGroups());
    },
    [navigation, dispatch]
  );

  const onError = React.useCallback(
    (message: TextType) => {
      pushAlert({ message });
      navigation.navigate(routes.home);
    },
    [navigation, pushAlert]
  );

  React.useEffect(() => {
    // On mobile web we want to redirect user to the fallback landing screen
    if (isMobileDevice) {
      return;
    }

    // If the user is not logged in we will store the inviteCode
    // in the state and use it as soon as the user ID is available
    if (!isLoggedIn) {
      dispatch(setInviteCode(inviteCode));
      goToLogin();
      return;
    }

    if (!inviteCode) {
      onSuccess();
      return;
    }

    dispatch(
      joinGroup({
        inviteCode,
        userId,
        onSuccess,
        onError,
      })
    );
    // eslint-disable-next-line
  }, [inviteCode]);

  if (isMobileDevice) {
    return <Landing />;
  }

  return (
    <Container>
      <Loader fullScreen />
    </Container>
  );
});
