import { SagaIterator } from "redux-saga";
import { put, takeLatest, call } from "redux-saga/effects";

import { setIsUserReady } from "~/state/user/actions";
import { loadInitialProgress } from "~/state/content-progress";
import { deleteAllDownloads } from "~/state/flamelink";
import { getRecentAppVersion } from "~/utils/app-version";

import { renderBubbles, splashScreenFinishedLoading } from "./slice";
import { UserReadyAction } from "./types";
import { getWalkthrough } from "../tutorial/actions";

export function* handleStartLoading(): SagaIterator {
  yield put(getWalkthrough());
  yield put(renderBubbles());
  // Temporary fix for the issue with downloads for the apps being upgraded to Expo v49
  // Delete all downloads on the pre v49 builds
  const currentAppVersion = yield call(getRecentAppVersion);
  if (!currentAppVersion) {
    yield put(deleteAllDownloads());
  }
}

export function* startupSaga() {
  yield takeLatest(splashScreenFinishedLoading.type, handleStartLoading);
}

// Call those functions whenever we get a valid authenticated user
export function* handleIsUserReady({
  payload: { userId },
}: UserReadyAction): SagaIterator {
  yield put(loadInitialProgress({ userId }));
}

export function* isUserReadySaga() {
  yield takeLatest(setIsUserReady.type, handleIsUserReady);
}
