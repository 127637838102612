import styled, { css } from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";

const BUTTON_CONTAINER_HEIGHT = "44px";

export const ButtonContainer = styled.View`
  height: ${BUTTON_CONTAINER_HEIGHT};
  margin-horizontal: ${spacers.ss5};
  margin-top: ${spacers.ss5};
  border-radius: ${BUTTON_CONTAINER_HEIGHT};
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: ${isWeb ? 40 : 120}px;
  left: 10px;
  right: 10px;
  ${getWebContainerStyle(360)}
`;

export const ButtonTextBox = styled.View<{ hasMargin: boolean }>`
  align-items: center;
  justify-content: center;
  flex: 1;
  height: ${BUTTON_CONTAINER_HEIGHT};
  margin-right: ${({ hasMargin }) => (hasMargin ? spacers.ss7 : spacers.ss0)};
  margin-left: ${({ hasMargin }) => (hasMargin ? spacers.ss0 : spacers.ss8)};
`;

export const ActionButton = styled.TouchableOpacity<{ disabled?: boolean }>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? colors.gray500 : colors.gray700};
  height: ${BUTTON_CONTAINER_HEIGHT};
  border-radius: ${BUTTON_CONTAINER_HEIGHT};
  border-right-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const buttonStyle = css<{ disabled?: boolean }>`
  justify-content: center;
  align-items: center;
  width: 64px;
  height: ${BUTTON_CONTAINER_HEIGHT};
`;

export const ResumeButtonProgress = styled.View`
  margin-vertical: ${spacers.ss5};
  ${buttonStyle};
`;

export const ResumeButtonGroup = styled.TouchableOpacity<{
  disabled?: boolean;
}>`
  ${buttonStyle};
  border-left-width: 2px;
  border-left-color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.gray500 : colors.gray700};
  border-radius: ${BUTTON_CONTAINER_HEIGHT};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const ButtonText = styled(Text2)`
  font-family: SFProDisplayMedium;
  color: ${colors.white};
  letter-spacing: 1px;
  text-transform: uppercase;
`;
