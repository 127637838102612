import * as React from "react";
import { FileType, getDownloadedFileById } from "~/state/flamelink";
import { loadFlamelinkFile, openFile } from "~/state/flamelink/actions";
import { getFlamelinkFilesById } from "~/state/flamelink/selectors";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { Video } from "~/components/video";
import { Pdf } from "../pdf";
import { Slide } from "../slide";

interface MediaProps {
  fileId: string;
}

/**
 * Component that decides which type of component to render based on the file type
 */
export const Media: React.FC<MediaProps> = ({ fileId }) => {
  const dispatch = useAppDispatch();
  const fileData = useAppSelector((state) =>
    getFlamelinkFilesById(state, fileId)
  );
  const { id, file, contentType } =
    fileData?.file || fileData?.fileHD || fileData?.fileUltraHD || {};
  const downloadedFileResourceLocation = useAppSelector((state) =>
    getDownloadedFileById(state, id || "")
  );

  const onPress = React.useCallback(() => {
    if (!file || !contentType) return;

    dispatch(openFile({ fileName: file, fileType: contentType }));
  }, [contentType, dispatch, file]);

  React.useEffect(() => {
    if (!fileId) return;

    dispatch(loadFlamelinkFile({ fileId }));
  }, [dispatch, fileId]);

  if (!fileData || !contentType) return null;

  if (contentType.includes(FileType.PDF)) {
    return <Pdf onPress={onPress} file={fileData} />;
  }

  if (
    contentType.includes(FileType.PRESENTATION) ||
    contentType.includes(FileType.IMAGE)
  ) {
    return (
      <Slide
        onPress={onPress}
        file={fileData}
        downloadedFileResourceLocation={downloadedFileResourceLocation}
      />
    );
  }

  if (
    contentType.includes(FileType.VIDEO) ||
    contentType.includes(FileType.AUDIO)
  ) {
    return (
      <Video
        file={downloadedFileResourceLocation || file}
        fileData={fileData}
        isDownloaded={!!downloadedFileResourceLocation}
      />
    );
  }

  // returns null by default if the type of file is not supported
  return null;
};
