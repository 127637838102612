import * as React from "react";
import { Switch } from "react-native-paper";

import { TextInput } from "~/components/text-input";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getAnnouncementById } from "~/state/announcements";
import {
  addAnnouncement,
  removeAnnouncement,
  editAnnouncement,
} from "~/state/announcements/actions";
import { ActionButtons } from "~/components/cms/action-buttons";
import { CharacterCount } from "~/components/cms/character-count";
import {
  Container,
  InputBox,
  Label,
  InputSection,
  SectionTitle,
  HeaderBox,
  dateInputStyle,
  Disclaimer,
} from "~/components/cms/styles";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";
import { LanguageKey } from "~/state/in-app-notifications-setup/types";
import {
  languageOptions,
  defaultString,
} from "~/screens/in-app-messages/constants";
import { Select } from "~/screens/in-app-messages/components/select";

import { messages } from "./intl";
import { messages as sectionMessages } from "../../intl";

interface Props {
  id?: string;
  onBack: () => void;
}

const MAX_TITLE_LENGTH = 50;
const MAX_BODY_LENGTH = 178;

export const AnnouncementForm = React.memo<Props>(({ id = "", onBack }) => {
  const data = useAppSelector((state) => getAnnouncementById(state, id));

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const [language, setLanguage] = React.useState<LanguageKey>("en");
  const [title, setTitle] = React.useState(data?.titleText || defaultString);
  const [body, setBody] = React.useState(data?.bodyText || defaultString);
  const [emails, setEmails] = React.useState<string[]>(data?.emails || []);
  const [schedule, setSchedule] = React.useState(
    data?.schedule.toString() || ""
  );
  const [isActive, setIsActive] = React.useState(!!data?.isActive);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleEmailChange = (value: string) => {
    setEmails(value.split(",").map((item) => item.trim()));
  };

  const isInitialState =
    title === data?.titleText &&
    body === data?.bodyText &&
    emails === data?.emails &&
    schedule === data?.schedule &&
    isActive === data?.isActive;

  const isButtonDisabled =
    !title || !body || !schedule || isInitialState || isSaving;
  const sectionTitle = id ? sectionMessages.edit : sectionMessages.create;

  const handleSetTitle = React.useCallback(
    (titleValue: string) => {
      if (!title[language] || title[language]?.length <= MAX_TITLE_LENGTH) {
        setTitle({ ...title, [language]: titleValue });
      }
    },
    [setTitle, language, title]
  );

  const handleSetBody = React.useCallback(
    (bodyValue: string) => {
      if (!body[language] || body[language]?.length <= MAX_BODY_LENGTH) {
        setBody({ ...body, [language]: bodyValue });
      }
    },
    [setBody, language, body]
  );

  const onSave = React.useCallback(() => {
    setIsSaving(true);

    const dataToSave = {
      titleText: title,
      bodyText: body,
      emails,
      schedule,
      isActive,
    };

    if (!id) {
      dispatch(
        addAnnouncement({
          ...dataToSave,
          onSuccess: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.saveSuccess,
              color: colors.emerald600,
            });
            onBack();
          },
          onError: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.error,
            });
            onBack();
          },
        })
      );
      return;
    }

    dispatch(
      editAnnouncement({
        id,
        ...dataToSave,
        onSuccess: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  }, [title, body, emails, schedule, id, isActive]);

  const onDelete = () => {
    if (!id) {
      return;
    }
    setIsDeleting(true);
    dispatch(
      removeAnnouncement({
        id,
        onSuccess: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.itemDeleted,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  };

  return (
    <Container>
      <HeaderBox>
        <SectionTitle>{sectionTitle}</SectionTitle>
      </HeaderBox>

      <InputBox>
        <Label>{messages.enabled}</Label>
        <Switch value={isActive} onValueChange={setIsActive} />
      </InputBox>

      <Select
        label={messages.language}
        options={languageOptions}
        setOption={setLanguage}
        value={language}
        hasMargin
      />

      <TextInput
        label={messages.messageTitle}
        onChangeText={handleSetTitle}
        value={title[language]}
        key={`title-${language}`}
      />
      <CharacterCount text={title[language]} limit={MAX_TITLE_LENGTH} />

      <TextInput
        label={messages.messageBody}
        onChangeText={handleSetBody}
        value={body[language]}
        key={`body-${language}`}
      />
      <CharacterCount text={body[language]} limit={MAX_BODY_LENGTH} />

      <InputSection>
        <Label>{messages.schedule}</Label>
        <input
          type="datetime-local"
          onChange={(e) => setSchedule(e.target.value)}
          value={schedule}
          style={dateInputStyle}
        />
      </InputSection>

      <TextInput
        multiline
        label={messages.emails}
        onChangeText={handleEmailChange}
        value={emails.join(", ")}
        style={{ minHeight: 100 }}
      />
      <Disclaimer>{messages.disclaimer}</Disclaimer>

      <ActionButtons
        onCancel={onBack}
        onSave={onSave}
        onDelete={onDelete}
        isSaveDisabled={isButtonDisabled}
        isDeleteDisabled={isDeleting}
        hideDelete={!id}
      />
    </Container>
  );
});
