import { FlamelinkFile } from "~/state/flamelink";
import { VideoQuality } from "~/state/settings";
import { getInternetSpeed, InternetSpeed } from "~/utils/internet-speed";
import { isAndroid } from "~/utils/platform";

interface GetFileUrlAndSpeedResponse {
  fileUrl: string | undefined;
  speed: InternetSpeed;
}

export const getFileUrlAndSpeed = async (
  fileData: FlamelinkFile | undefined,
  videoQuality?: VideoQuality
): Promise<GetFileUrlAndSpeedResponse> => {
  const internetSpeed = await getInternetSpeed();

  const defaultFile = fileData?.file?.file;
  const hdFile = fileData?.fileHD?.file ?? defaultFile;

  // Android mediaplayer does not support 4K
  // https://developer.android.com/guide/topics/media/platform/supported-formats#video-encoding
  const uhdFile = isAndroid
    ? fileData?.fileHD?.file ?? defaultFile
    : fileData?.fileUltraHD?.file ?? defaultFile;

  const fileToSpeed = {
    [InternetSpeed.Poor]: defaultFile,
    [InternetSpeed.Normal]: defaultFile,
    [InternetSpeed.Good]: hdFile,
    [InternetSpeed.Excellent]: uhdFile
  };
  const videoQualityToSpeed = {
    [VideoQuality.Normal]: defaultFile,
    [VideoQuality.High]: hdFile,
    [VideoQuality.UltraHigh]: uhdFile
  };

  return {
    speed: internetSpeed,
    fileUrl: videoQuality
      ? videoQualityToSpeed[videoQuality]
      : fileToSpeed[internetSpeed]
  };
};
