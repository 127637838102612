import * as React from "react";

import { getContent } from "~/components/chat/message";
import { MessageData, MessageType } from "~/state/chat/types";

import { Container, Content, MessageContainer } from "./styles";

interface Props {
  onPress: () => void;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  text?: string;
  type: MessageType;
  data?: MessageData;
  isReversed?: boolean;
}

export const ActionOverlay = React.memo<Props>(
  ({
    onPress,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    text = "",
    type,
    data,
    isReversed,
  }) => {
    return (
      <Container onPress={onPress}>
        <Content>
          <MessageContainer style={{ top: y, left: x, width, height }}>
            {getContent(type, text, isReversed, data)}
          </MessageContainer>
        </Content>
      </Container>
    );
  }
);
