import * as React from "react";
import { CheckIcon, Container, Option, OptionTitle, Title } from "./styles";

interface Option {
  title: string;
  selected: boolean;
  value: string | number | string[];
  onPress?(): void;
}

export interface FilterProps {
  title: string;
  options: Option[];
  onReset?: () => void;
}

export const Filter: React.FC<FilterProps> = React.memo(
  ({ title, options, onReset }) => {
    const isAllSelected = !options.find((option) => !option.selected);
    const hasAllOption = typeof onReset === "function";

    return (
      <Container>
        <Title>{title}</Title>

        {options.map((option, index) => (
          <Option
            key={`option-${index}`}
            isFirst={index === 0}
            isLast={!hasAllOption && index === options.length - 1}
            onPress={option.onPress}
          >
            <OptionTitle>{option.title}</OptionTitle>

            {option.selected && !isAllSelected && <CheckIcon />}
          </Option>
        ))}
        {hasAllOption ? (
          <Option key="option-all" isLast onPress={onReset}>
            <OptionTitle>All</OptionTitle>

            {isAllSelected ? <CheckIcon /> : null}
          </Option>
        ) : null}
      </Container>
    );
  }
);
