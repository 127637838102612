import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { genericMessages } from "~/constants/intl";
import { AuthScreen } from "~/components/auth-screen";
import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { TextInput } from "~/components/text-input";
import { useAppDispatch } from "~/state/hooks";
import { sendPasswordReset } from "~/state/user/actions";
import { isValidEmail } from "~/utils/strings";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { messages } from "./intl";
import { Container, Message } from "./styles";

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<MessageDescriptor>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasBeenSent, setHasBeenSent] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { pushAlert } = useAlerts();

  const isValid = isValidEmail(email);

  const resetEmailError = React.useCallback(() => setEmailError(undefined), []);

  const handleError = React.useCallback((error: string = "") => {
    if (error.includes("auth/invalid-email")) {
      setEmailError(error);
      return;
    }
    if (error.includes("auth/user-not-found")) {
      setEmailError(error);
      return;
    }

    pushAlert({ message: genericMessages.error, color: colors.black });
  }, []);

  const handleLogin = React.useCallback(() => {
    setEmailError(undefined);
    setIsLoading(true);

    dispatch(
      sendPasswordReset({
        email,
        onSuccess: () => {
          setIsLoading(false);
          setHasBeenSent(true);
        },
        onError: (e) => {
          setIsLoading(false);
          handleError(e);
        },
      })
    );
  }, [dispatch, email, handleError]);

  const navigateBackToLogin = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  return (
    <AuthScreen
      screenName="forgot-password"
      title={messages.title}
      hasBackButton
      hideSocialButtons
      id="forgot-password"
    >
      {!hasBeenSent ? (
        <>
          <InputBox>
            <TextInput
              label={messages.email}
              value={email}
              onChangeText={setEmail}
              onFocus={resetEmailError}
              errorMessage={emailError}
              autoCorrect={false}
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
            />
          </InputBox>

          <Button
            text={messages.button}
            onPress={handleLogin}
            type={ButtonTypes.Primary}
            isDisabled={!isValid}
            isLoading={isLoading}
            isUppercase
          />
        </>
      ) : (
        <>
          <Container>
            <Message>{messages.sent}</Message>
          </Container>
          <Button
            text={messages.backToLogin}
            onPress={navigateBackToLogin}
            type={ButtonTypes.Primary}
            isUppercase
          />
        </>
      )}
    </AuthScreen>
  );
};
