export const messages = {
  titlePlaceholder: {
    id: "notes.view-edit.title-placeholder",
    defaultMessage: "Add a note title",
  },
  textPlaceholder: {
    id: "notes.view-edit.text-placeholder",
    defaultMessage: "Add a note text",
  },
  labelTitle: {
    id: "notes.view-edit.label-title",
    defaultMessage: "Note title",
  },
  labelText: {
    id: "notes.view-edit.label-text",
    defaultMessage: "Note text",
  },
};
