import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { User } from "./types";

const getState = (state: RootState) => state.users;

export const getUsers: (state: RootState) => User[] = createSelector(
  getState,
  (state) => (Array.isArray(state?.data) ? state.data : [])
);

export const getUserIds: (state: RootState) => string[] = createSelector(
  getUsers,
  (users) => users.map(({ id }) => id)
);
