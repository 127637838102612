export const messages = {
  resourcesTitle: {
    id: "resources.title",
    defaultMessage: "Resources",
  },
  resourcesEmptyDescription: {
    id: "resources.empty.description",
    defaultMessage:
      "Here you can track your progress on all current resources. Start exploring resources in the Library!",
  },
  resourceDescription: {
    id: "resources.description",
    defaultMessage: "Volume {{volumeIndex}}, {{sessionTitle}}",
  },
};
