import styled, { css } from "styled-components/native";

import { spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

const webStyles = isWeb
  ? css`
      flex-basis: auto;
      padding-bottom: ${spacers.ss8};
    `
  : "";

export const Content = styled.View`
  margin-horizontal: ${spacers.ss6};
  flex: 1;
  ${webStyles}
`;

export const SectionBox = styled.View`
  margin-bottom: ${spacers.ss6};
`;

export const Spacer = styled.View`
  padding-bottom: ${spacers.ss8};
`;
