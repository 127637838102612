import { StyleSheet, Platform } from "react-native";
import Animated from "react-native-reanimated";
import styled, { css } from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { Image as ExpoImage } from "expo-image";

import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { Text1, Text2, Text3, Text6 } from "~/components/text";
import { hexToRGB } from "~/utils/colors";
import { IconSizes } from "~/components/icon";
import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";
import { getWebContainerStyle, MaxWidthBreakpoints } from "~/utils/web";

export const HEADER_HEIGHT = 300;

export const Content = styled(Animated.ScrollView)<{ extraMargin: number }>`
  margin-bottom: ${({ extraMargin }) =>
    isWeb ? 0 : TAB_BAR_HEIGHT + extraMargin ?? 0}px;
  flex: 1;
`;

export const PlanCoverBox = styled(Animated.View)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
`;

export const Header = styled(Animated.View)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 60px;
  padding-top: ${Platform.select({
    ios: spacers.ss11,
    android: spacers.ss10,
    default: spacers.ss5,
  })};
  background-color: ${colors.white};
`;

export const PlanCoverImageBox = styled(Animated.View)`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  border-bottom-left-radius: ${spacers.ss9};
  border-bottom-right-radius: ${spacers.ss9};
  overflow: hidden;
`;

export const PlanCoverImage = styled(ExpoImage)`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
`;

export const PlanCoverImagePlaceholder = styled(Animated.View)`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  border-bottom-left-radius: ${spacers.ss9};
  border-bottom-right-radius: ${spacers.ss9};
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-color: ${hexToRGB(colors.black, 0.5)};
  z-index: 1;
  ${StyleSheet.absoluteFill}
`;

export const PlanTitle = styled(Text6)`
  font-family: MontserratMedium;
  margin: ${spacers.ss4} ${spacers.ss5} 0px;
  letter-spacing: -0.5px;
`;

export const Label = styled.View`
  border-width: 1px;
  border-color: ${colors.gray500};
  border-radius: 40px;
  align-self: flex-start;
  margin-top: ${spacers.ss6};
  margin-horizontal: ${spacers.ss5};
`;

export const LabelText = styled(Text1)`
  padding: ${spacers.ss3} ${spacers.ss5};
  color: ${colors.gray500};
  text-transform: uppercase;
  font-family: SFProDisplayBold;
  font-size: 10px;
`;

export const MetaBox = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spacers.ss5};
`;

export const MetaText = styled(Text2)`
  margin: 0 ${spacers.ss5};
  color: ${colors.gray600};
  font-family: SFProDisplayMedium;
`;

const LIST_ITEM_HEIGHT = isWeb ? 72 : 60;

interface AccordionListProps {
  isExpanded: boolean;
  isBlinking: boolean;
  isFirst?: boolean;
}

export const AccordionList = styled.View<AccordionListProps>`
  height: ${LIST_ITEM_HEIGHT}px;
  align-items: center;
  flex-direction: row;
  padding: 0 ${spacers.ss5};
  border-bottom-color: ${({ isFirst }) =>
    isFirst ? colors.gray100 : colors.transparent};
  border-bottom-width: ${({ isFirst }) => (isFirst ? 1 : 0)}px;
  background-color: ${({ isExpanded }) =>
    isExpanded ? colors.blue100 : colors.white};
`;

export const ActionButtonsContainer = styled.View`
  flex-direction: column;
`;

interface AccordionListIconProps {
  isExpanded: boolean;
}

export const AccordionListIconContainer = styled.TouchableOpacity`
  justify-content: center;
`;

export const AccordionListIcon = styled(MaterialIcons).attrs(
  ({ isExpanded }: AccordionListIconProps) => ({
    name: isExpanded ? "arrow-drop-up" : "arrow-drop-down",
    size: IconSizes.SMedium,
  })
)<AccordionListIconProps>`
  margin: 0 -${spacers.ss2};
`;

export const MoreListIcon = styled(MaterialIcons).attrs<{
  isSession?: boolean;
  isInSwipeable?: boolean;
}>((props) => ({
  name: isWeb ? "file-download" : "more-horiz",
  size: isWeb ? 14 : IconSizes.SMedium,
  color: props?.isInSwipeable ? colors.white : undefined,
}))<{ isSession?: boolean; isInSwipeable?: boolean }>`
  ${({ isSession }) =>
    !isSession &&
    css`
      margin-left: ${spacers.ss1};
    `}
`;

export const AccordionListItem = styled.View<{
  isLast: boolean;
}>`
  background-color: ${colors.blue50};
  height: ${LIST_ITEM_HEIGHT}px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-bottom-color: ${colors.gray100};
  border-bottom-width: 1px;
  padding: 0 ${spacers.ss5};

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: ${spacers.ss4};
      border-bottom-right-radius: ${spacers.ss4};
      border-bottom-width: 0;
    `}
`;

export const AccordionListItemIcon = styled(MaterialIcons).attrs({
  name: "arrow-forward-ios",
  size: IconSizes.Small,
})``;

export const VolumesView = styled.View`
  padding-top: ${HEADER_HEIGHT}px;

  ${getWebContainerStyle(MaxWidthBreakpoints.Desktop, false, true)}
`;

export const ResetProgressText = styled(Text3)`
  color: ${colors.red500};
  align-self: center;
  margin: ${spacers.ss5} 0 ${spacers.ss9} 0;
`;

export const ResetProgressSpinner = styled.ActivityIndicator.attrs({
  size: "small",
  color: colors.red500,
})`
  align-self: center;
  margin: ${spacers.ss5} 0 ${spacers.ss9} 0;
`;

export const VolumesLoaderContainer = styled.View`
  height: 200px;
  justify-content: center;
`;
