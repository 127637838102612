import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import {
  SectionTitle,
  SubsectionTitle,
  SubsectionSubtitle,
} from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { ContentIcon } from "../../../content-icon";

interface ActivityPageProps {
  activityPage: LessonsContent["activityPage"];
}

export const ActivityPage: React.FC<ActivityPageProps> = ({ activityPage }) => {
  const hasContent = React.useMemo(
    () =>
      activityPage?.youWillNeed ||
      activityPage?.gettingReady ||
      activityPage?.gettingReadyMedia?.length ||
      activityPage?.body ||
      activityPage?.activityMedia?.length ||
      activityPage?.mediaRepeater?.length ||
      activityPage?.conclusion,
    [activityPage]
  );

  return hasContent ? (
    <>
      {activityPage?.sectionTitle && (
        <SectionTitle>{activityPage.sectionTitle}</SectionTitle>
      )}

      {activityPage?.subheading && (
        <SubsectionTitle>{activityPage.subheading}</SubsectionTitle>
      )}

      {activityPage?.subtitle && (
        <SubsectionSubtitle>{activityPage.subtitle}</SubsectionSubtitle>
      )}

      {activityPage?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={activityPage.youWillNeed}
          checklistId="activityPage-youWillNeed"
        />
      )}

      {activityPage?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={activityPage.gettingReady}
          checklistId="activityPage-gettingReady"
        />
      )}

      {Array.isArray(activityPage?.gettingReadyMedia) &&
        activityPage?.gettingReadyMedia.map((fileId, index) => (
          <Media key={`gettingReadyMedia-${index}`} fileId={fileId} />
        ))}

      {activityPage?.body && <RichText>{activityPage.body}</RichText>}

      {Array.isArray(activityPage?.mediaRepeater) &&
        activityPage?.mediaRepeater.map(({ media, content }, i) => (
          <React.Fragment key={`mediaRepeater-${i}`}>
            {Array.isArray(media) &&
              media.map((fileId, j) => (
                <Media key={`mediaRepeater-media-${j}`} fileId={fileId} />
              ))}

            {content && <RichText>{content}</RichText>}
          </React.Fragment>
        ))}

      {Array.isArray(activityPage?.activityMedia) &&
        activityPage?.activityMedia.map((fileId, index) => (
          <Media key={`activityMedia-${index}`} fileId={fileId} />
        ))}

      {activityPage?.conclusion && (
        <RichText>{activityPage.conclusion}</RichText>
      )}

      <ContentIcon
        content={[activityPage?.body || "", activityPage?.conclusion || ""]}
      />
    </>
  ) : null;
};
