import type { Action } from "@reduxjs/toolkit";
import type { DocumentReference } from "firebase/firestore";
import type { BubbleResource } from "~/state/flamelink";

export interface DayProgress {
  key: string;
  state: ProgressState;
}

export type DaysProgress = Array<DayProgress> | [];

export enum ProgressState {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
}

export interface ProgressHierarchyIds {
  sessionId: string;
  volumeId: string;
  planId: string;
}

export interface SessionProgress extends ProgressHierarchyIds {
  userId: string;
  state: ProgressState;
  days: DaysProgress;
  lastUpdated: number;
  sessionProgressId?: string;
  planProgressId?: string;
}

export interface AddSessionProgressParams extends ProgressHierarchyIds {
  totalDays: number;
  dayKey?: string;
  initialState?: ProgressState;
  planProgressId?: string;
  omitMarkingDayInProgress?: boolean;
}

export interface SessionProgressCheckConditions {
  shouldOmitFirstDayCheck?: boolean;
  shouldOmitLastDayCheck?: boolean;
}

export interface UpdateSessionProgressParams
  extends Partial<AddSessionProgressParams>,
    SessionProgressCheckConditions {
  shouldMarkDayAsComplete?: boolean;
  sessionProgressId: string;
  omitMarkingDayInProgress?: boolean;
  /**
   * Useful if you don't want to change a completed session to another state
   * @default true
   */
  allowOverwriteSessionState?: boolean;
}

export interface ResetSessionProgressParams {
  sessionProgressId: string;
  newState: ProgressState;
}

export interface ResetPlanProgressParams {
  planProgressId: string;
  newState: ProgressState;
}

export interface DeletePlanProgressParams {
  planId: string;
}

export interface ResetDayProgressParams {
  sessionProgressId: string;
  dayKey: string;
  newState: ProgressState;
}

export interface GetProgressByPlanData {
  planProgressId: string;
  planProgress: SessionProgress[] | [];
  currentSessionInProgress?: SessionProgress;
  furthestSessionInProgress?: SessionProgress;
  firstSessionNotStarted?: SessionProgress;
  firstSessionNotStartedWithoutProgressRecord?: BubbleResource;
  hasSessionsCompleted: boolean;
  numberOfDaysCompleted: number;
  completionDate?: number;
  isPlanCompleted?: boolean;
}

export interface GetPlanProgressesData {
  userId: string;
  planId: string;
  sessions: DocumentReference[];
}

export interface GetPlanProgressesByUserIdData {
  planProgressId: string;
  userId: string;
  planId: string;
  activeSession?: SessionProgress;
  sessions: SessionProgress[];
  isCompleted: boolean;
  dateCompleted?: number;
}

export enum MainButtonType {
  Start = "start",
  Resume = "resume",
  Restart = "restart",
}

export interface ContentProgressState {
  progress?: Array<GetPlanProgressesByUserIdData>;
}

interface LoadInitialProgressPayload {
  allowReset?: boolean;
  userId?: string;
}

export interface LoadInitialProgressAction extends Action {
  payload?: LoadInitialProgressPayload;
}
