import styled from "styled-components/native";
import { Text1 } from "~/components/text";

import { spacers } from "~/styles/theme";

export const Container = styled.View`
  padding: ${spacers.ss6};
`;

export const Button = styled.TouchableOpacity`
  padding-bottom: ${spacers.ss2};
  flex-direction: row;
`;

export const Content = styled.View``;

export const Title = styled(Text1)`
  padding-right: ${spacers.ss3};
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
`;
