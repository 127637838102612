export const messages = {
  titleOneTime: {
    id: "pif.details.title-one-time",
    defaultMessage: "Giving {{amount}}",
  },
  titleMonthly: {
    id: "pif.details.title-monthly",
    defaultMessage: "Giving {{amount}} per month",
  },
  edit: {
    id: "pif.details.edit",
    defaultMessage: "(Edit amount)",
  },
  buttonOneTime: {
    id: "pif.details.button-one-time",
    defaultMessage: "Donate {{amount}}",
  },
  buttonMonthly: {
    id: "pif.details.button-monthly",
    defaultMessage: "Donate {{amount}} monthly",
  },
  disclaimer: {
    id: "pif.details.disclaimer",
    defaultMessage:
      "We’ll store your payment method through Stripe’s secure platform. You’ll still be able to change your linked payment at anytime to ensure your giving goes uninterrupted!",
  },
  firstName: {
    id: "pif.details.first-name",
    defaultMessage: "First name",
  },
  lastName: {
    id: "pif.details.last-name",
    defaultMessage: "Last name",
  },
  name: {
    id: "pif.details.name",
    defaultMessage: "Name",
  },
  email: {
    id: "pif.details.email",
    defaultMessage: "Email",
  },
  cardNumber: {
    id: "pif.details.card-number",
    defaultMessage: "Card number",
  },
  date: {
    id: "pif.details.date",
    defaultMessage: "MM/YY",
  },
  cvc: {
    id: "pif.details.cvc",
    defaultMessage: "CVC",
  },
  zipcode: {
    id: "pif.details.zipcode",
    defaultMessage: "Zipcode",
  },
  considerTitle: {
    id: "pif.details.consider-title",
    defaultMessage: "Would you consider something?",
  },
  considerText: {
    id: "pif.details.consider-text",
    defaultMessage:
      "By making a monthly donation, your partnership will support our ability to develop more resources, translate discipleship materials into other languages, and continue to make Bible Engagement Project the best experience possible for people around the world.",
  },
  considerSwitch: {
    id: "pif.details.consider-switch",
    defaultMessage: "Give {{amount}} per month instead",
  },
  errorPayment: {
    id: "pif.error.payment",
    defaultMessage: "Unable to process payment",
  },
  errorPaymentConfirmation: {
    id: "pif.error.payment-confirmation",
    defaultMessage: "There has been an error confirming the payment",
  },
  successPayment: {
    id: "pif.success.payment",
    defaultMessage: "Payment has been processed successfully",
  },
  testMode: {
    id: "pif.details.test-mode",
    defaultMessage:
      "You are in the Stripe Test Mode. The card will not be charged.",
  },
};
