import * as React from "react";
import { Intro as NativeIntro } from "./intro";

import { Landing } from "~/screens/landing";
import { isMobileDevice } from "~/utils/platform";

export const Intro = () => {
  if (isMobileDevice) {
    return <Landing />;
  }
  return <NativeIntro />;
};
