export const messages = {
  leader: {
    id: "group.members.leader",
    defaultMessage: "Leader",
  },
  member: {
    id: "group.members.member",
    defaultMessage: "Member",
  },
};
