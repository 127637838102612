import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { SettingsListItem } from "~/components/settings";
import { formatMessage } from "~/utils/translation";
import { useAppNetInfo } from "~/state/hooks";

import { useModal } from "~/state/modal/hook.web";
import { ChangePasswordComponent } from "~/screens/change-password";

import { messages } from "../intl";

interface Props {
  handleOffline: () => void;
}

export const EditPassword = ({ handleOffline }: Props) => {
  const navigation = useNavigation();
  const { isConnected } = useAppNetInfo();

  const { showModal, hideModal } = useModal();

  const onPress = () => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    showModal(
      <ChangePasswordComponent onGoBack={hideModal} onModalClose={hideModal} />
    );
  };

  return (
    <SettingsListItem
      title={formatMessage(messages.changePassword)}
      type="edit"
      isFirstItem
      isLastItem
      onPress={onPress}
    />
  );
};
