import styled from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";

export const SubContainer = styled.View`
  top: 30%;
  justify-content: center;
  justify-items: center;
`;

export const InfoContainer = styled.View`
  margin: ${spacers.ss6};
  padding: ${spacers.ss6} ${spacers.ss6} ${spacers.ss3};
  border-width: 1px;
  border-color: ${colors.gray300};
  background-color: ${colors.gray50};
  border-radius: ${spacers.ss6};
  margin-bottom: ${spacers.ss6};
  align-items: center;
`;

export const InfoTitle = styled(Text2)`
  font-family: MontserratBold;
  margin: ${spacers.ss3} 0;
  color: ${colors.gray600};
  line-height: ${lineHeights.lh2};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const LoaderWrapper = styled.View`
  margin: ${spacers.ss4};
`;
