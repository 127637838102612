export const messages = {
  errorInvalid: {
    id: "groups.error-invalid",
    defaultMessage: "Code invalid or expired.",
  },
  errorGeneric: {
    id: "groups.error-generic",
    defaultMessage: "An error occured. Please try again later.",
  },
};
