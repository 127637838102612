import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const contentContainerStyle = {
  paddingBottom: isWeb ? 0 : 400,
};

export const Section = styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  margin-bottom: ${isWeb ? 32 : 0}px;
`;

export const EmptyText = styled(Text3)`
  color: ${colors.gray800};
  text-align: center;
  padding: ${spacers.ss6};
`;
