import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";

import { colors } from "~/styles/theme";

import { Header, HeaderText, IconButton } from "./styles";

interface HeaderProps {
  onPress: () => void;
  text: string;
  hasLink?: boolean;
}

export const hitSlop = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

export const SectionHeader = React.memo<HeaderProps>(
  ({ text, onPress, hasLink = true }) => {
    return (
      <Header>
        <HeaderText>{text}</HeaderText>
        {hasLink ? (
          <IconButton onPress={onPress} hitSlop={hitSlop}>
            <Icon name="link" size={18} color={colors.primaryBlue} />
          </IconButton>
        ) : null}
      </Header>
    );
  }
);
