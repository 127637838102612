import * as React from "react";
import { Animated, Easing, ScrollView } from "react-native";
import { useRoute } from "@react-navigation/native";

import type { SessionProps } from "../../types";
import { ContentContainerProps } from "./types";
import { OPEN_BOTTOM_SHEET_TIMEOUT_WEB } from "./constants";

export const ContentContainer: React.FC<ContentContainerProps> = React.memo(
  ({ children, setIsBottomSheetOpen, isBottomSheetOpen, currentSection }) => {
    const { lessonId, childSessionId, dayIndex } =
      useRoute<SessionProps["route"]>().params;
    const scrollRef = React.useRef<ScrollView>(null);
    const top = React.useRef(
      new Animated.Value(isBottomSheetOpen ? 250 : 350)
    ).current;
    const opacity = top.interpolate({
      inputRange: [250, 350],
      outputRange: [1, 0.7],
    });

    React.useEffect(() => {
      scrollRef.current?.scrollTo({ top: 0 });

      // make sure we scroll up every time we change the current section, day,lesson or child session
    }, [currentSection, lessonId, childSessionId, dayIndex]);

    const fadeIn = React.useCallback(
      () =>
        Animated.timing(top, {
          toValue: 250,
          duration: OPEN_BOTTOM_SHEET_TIMEOUT_WEB,
          useNativeDriver: false,
          easing: Easing.ease,
        }).start(() => setIsBottomSheetOpen(true)),
      [top, setIsBottomSheetOpen]
    );

    React.useLayoutEffect(() => {
      if (isBottomSheetOpen) return;

      fadeIn();
    }, [fadeIn]);

    return (
      <Animated.ScrollView
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          width: 960,
          position: "absolute",
          top,
          bottom: 0,
          left: "50%",
          backgroundColor: "white",
          transform: [{ translateX: "-50%" }],
          overflow: "scroll",
          opacity,
        }}
        ref={scrollRef}
      >
        {children}
      </Animated.ScrollView>
    );
  }
);
