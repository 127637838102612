export const messages = {
  title: {
    id: "offline-banner.title",
    defaultMessage: "You are offline",
  },
  description: {
    id: "offline-banner.description",
    defaultMessage: "Limited functionality available.",
  },
};
