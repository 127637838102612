export const messages = {
  copy: {
    id: "session-action.copy",
    defaultMessage: "Copy to clipboard",
  },
  copied: {
    id: "session-action.copied",
    defaultMessage: "Text copied to clipboard!",
  },
  share: {
    id: "session-action.share",
    defaultMessage: "Share",
  },
  cancel: {
    id: "session-action.cancel",
    defaultMessage: "Cancel",
  },
};
