import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { query, collection, where, getDocs } from "firebase/firestore";
import { database } from "<config>/firebase";
import NetInfo from "@react-native-community/netinfo";

import {
  getSessionNotesCollection,
  getBibleNotesCollection,
  getPersonalNotesCollection,
} from "~/constants/collections";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";

import { getNotesCount } from "./actions";
import { GetNotesCountAction, NoteTypes } from "./types";
import { Environment } from "../user/types";

const getCollectionByType = (type: NoteTypes, env: Environment) => {
  switch (type) {
    case NoteTypes.Session:
      return getSessionNotesCollection(env);
    case NoteTypes.Bible:
      return getBibleNotesCollection(env);
    default:
      return getPersonalNotesCollection(env);
  }
};

export function* getNotesCountFn({
  payload: { type, onSuccess, onError },
}: GetNotesCountAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    if (onSuccess) yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const collectionName = getCollectionByType(type, env);

    const q = query(
      collection(database, collectionName),
      where("userId", "==", userId)
    );

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const count = notesSnapshots?.size;

    if (Number.isInteger(count)) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess);
      }
    } else {
      throw new Error();
    }
  } catch (e) {
    if (typeof onError === "function") {
      yield call(onError);
    }

    yield call(handleError, e);
  }
}

export const getNotesCountSaga = function* () {
  yield takeLatest(getNotesCount.type, getNotesCountFn);
};
