import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
`;

export const Button = styled.TouchableOpacity.attrs({
  hitSlop: {
    top: 8,
    left: 8,
    right: 8,
    bottom: 8,
  },
})``;

export const Dot = styled.View<{ isActive?: boolean }>`
  width: ${spacers.ss5};
  height: ${spacers.ss5};
  border-radius: ${spacers.ss4};
  margin: 0 ${spacers.ss6};
  background-color: ${({ isActive }) =>
    isActive ? colors.primaryBlue : colors.blue100};
`;
