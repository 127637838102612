import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Icon, IconSizes, IconTypes } from "~/components/icon";
import { TabParamList } from "~/navigation/post-auth";
import { messages } from "~/screens/session/intl";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import * as routes from "~/constants/routes";
import {
  ScriptureContainer,
  ScriptureText,
  IconBox,
  TextWrapper,
} from "./styles";
import { navigateTo } from "~/utils/navigation";

type ScriptureNavigationProp = NativeStackScreenProps<
  TabParamList,
  "library.tab"
>;

interface ScriptureProps {
  scripture: string;
  hasMargin?: boolean;
}

export const Scripture: React.FC<ScriptureProps> = ({
  scripture,
  hasMargin,
}) => {
  const navigation = useNavigation<ScriptureNavigationProp["navigation"]>();

  const onPress = React.useCallback(
    (formattedScripture: string) => {
      navigateTo(navigation, routes.bibleTab, {
        screen: routes.bible,
        params: {
          scripture: formattedScripture,
          showBackButton: true,
          isSession: true,
        },
      });
    },
    [navigation]
  );

  return (
    <ScriptureContainer hasMargin={hasMargin}>
      <IconBox>
        <Icon
          type={IconTypes.Bible}
          color={colors.primaryBlue}
          size={IconSizes.Medium}
        />
      </IconBox>

      <TextWrapper>
        {scripture.split(";").map((s, i) => (
          <ScriptureText
            isFirst={i === 0}
            onPress={() => onPress(s)}
            key={`scripture-${i}`}
            suppressHighlighting
          >
            {i === 0
              ? formatMessage(messages.scripture, { scripture: s })
              : `; ${s}`}
          </ScriptureText>
        ))}
      </TextWrapper>
    </ScriptureContainer>
  );
};
