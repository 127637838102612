export const messages = {
  downloads: {
    id: "plan.downloads",
    defaultMessage: "Download Files",
  },
  downloadsTitle: {
    id: "plan.downloads-title",
    defaultMessage: "Downloads",
  },
  downloadSession: {
    id: "plan.download-session",
    defaultMessage: "Offline Viewing",
  },
  cancel: {
    id: "plan.reset-session-progress.cancel",
    defaultMessage: "Cancel",
  },
  additionalAssets: {
    id: "plan.volume-sessions.additional-assets",
    defaultMessage: "VOL. {{volumeIndex}}: Additional Assets",
  },
};
