import * as React from "react";
import { StyleProp, View } from "react-native";
import type { ASTNode } from "react-native-markdown-display";
import { RichText } from "../rich-text";
import {
  ItemWrapper,
  ListItemContainer,
  markdownStyles,
  Microphone,
} from "./styles";

interface ThoughtProps {
  thought: string;
}

const renderBulletList = (
  node: ASTNode,
  children: React.ReactNode[],
  _parent: ASTNode[],
  styles: StyleProp<any>
) => {
  return (
    <View key={node.key} style={styles.bullet_list}>
      {children.map((item, index) => (
        <ListItemContainer key={index}>
          <Microphone index={index} />

          <ItemWrapper>{item}</ItemWrapper>
        </ListItemContainer>
      ))}
    </View>
  );
};

const markdownRules = {
  bullet_list: renderBulletList,
};

export const Thought: React.FC<ThoughtProps> = ({ thought }) => {
  return (
    <RichText extraMarkdownStyles={markdownStyles} rules={markdownRules}>
      {thought}
    </RichText>
  );
};
