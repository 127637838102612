import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

export const getBookNameFromId = (bookId: string) => {
  const message = messages[bookId];
  if (!message) {
    return;
  }
  return formatMessage(message);
};

export const getScriptureText = (
  bookId: string,
  chapterId: string,
  verses: number[]
) => {
  const verseText =
    verses.length < 2 ? verses[0] : `${verses[0]}-${verses[verses.length - 1]}`;
  const bookName = getBookNameFromId(bookId);
  return `${bookName} ${chapterId}:${verseText}`;
};
