import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { StatsState, Stats, DateRange } from "./types";

export const initialState: StatsState = {
  statsHistory: [],
  dateRange: {
    from: dayjs("2024-01-01"),
    to: dayjs(new Date()),
  },
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStatsHistory: (state, action: PayloadAction<Stats[]>) => {
      state.statsHistory = action.payload;
    },

    changeDateRange: (state, action: PayloadAction<DateRange>) => {
      state.dateRange = action.payload;
    },
  },
});

export const { setStatsHistory, changeDateRange } = statsSlice.actions;

export default statsSlice.reducer;
