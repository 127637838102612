export const messages = {
  title: {
    id: "landing-web.title",
    defaultMessage: "Success!"
  },
  getStartedHeader: {
    id: "landing-web.get-started-header",
    defaultMessage:
      "You can now access the Bible Engagement Project curriculum library on your mobile device. Here’s how to get started:"
  },
  getStarted: {
    id: "landing-web.get-started",
    defaultMessage: `1. Download our mobile app
2. Login with your login credentials
3. Access your resources!`
  },
  comingSoon: {
    id: "landing-web.coming-soon",
    defaultMessage:
      "Stay tuned for the release of the Web App where you’ll be able to access all of the resources online from a desktop! Coming soon!"
  },
  footerText: {
    id: "landing-web.footer-text",
    defaultMessage: "Have the app already?"
  },
  footerLink: {
    id: "landing-web.footer-link",
    defaultMessage: "Open BEP"
  }
};
