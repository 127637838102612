import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";

import type { SessionRouteProp } from "~/screens/session/types";
import type { LibraryStackParamList } from "~/navigation/library-stack";
import { CarouselDots } from "~/components/carousel-dots";
import { asyncLogEvent, events } from "~/utils/analytics";
import { Text2 } from "~/components/text";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import {
  useUpdateSessionProgressMutation,
  getNormalizedProgressByPlanId,
  UpdateSessionProgressParams,
  useResetDayProgressMutation,
  ResetDayProgressParams,
  ProgressState,
} from "~/state/content-progress";
import { getSessionsBySessionId } from "~/state/flamelink";
import { useAppSelector } from "~/state/hooks";
import * as routes from "~/constants/routes";

import { messages } from "../../../../intl";
import {
  BackButton,
  CompleteButton,
  ContinueButton,
  Footer,
  NavigationButtonsContainer,
} from "./styles";

type FooterNavigationProp = NativeStackScreenProps<
  LibraryStackParamList,
  "library"
>;

interface SessionFooterProps {
  navigatePrevious(): void;
  navigateNext(): void;
  daysLength: number;
  currentDay: number;
  shouldRenderDays: boolean;
  heading?: string;
  shouldRenderLessons: boolean;
  lessonIndex: number;
  lessonsLength: number;
  childSessionId?: string;
  previousLessionsSectionsCount: number;
  setLessonIndex?(index: number): void;
  setCurrentSection(index: number): void;
  isLoading: boolean;
}

export const SessionFooter = ({
  navigatePrevious,
  navigateNext,
  daysLength,
  currentDay,
  shouldRenderDays,
  heading,
  shouldRenderLessons,
  lessonIndex,
  lessonsLength,
  childSessionId,
  previousLessionsSectionsCount,
  setLessonIndex,
  setCurrentSection,
  isLoading,
}: SessionFooterProps) => {
  const {
    params: { sessionId, planId, sessionProgressId },
  } = useRoute<SessionRouteProp>();
  const navigation = useNavigation<FooterNavigationProp["navigation"]>();
  const childSessions = useAppSelector((state) =>
    getSessionsBySessionId(state, sessionId)
  );
  const [updateSessionProgress] = useUpdateSessionProgressMutation();
  const [resetDayProgress] = useResetDayProgressMutation();
  const progressData = useAppSelector((state) =>
    getNormalizedProgressByPlanId(state, planId)
  );
  // @TODO: could be moved to a selector?
  const queriedSessionProgressId = progressData?.planProgress.find(
    (p) => p.sessionId === sessionId
  )?.sessionProgressId;

  const currentChildSessionIndex = childSessions.findIndex(
    (s) => s.id === childSessionId
  );
  const isLastDay = daysLength - 1 === currentDay;
  const isFirstDay = currentDay === 0 && lessonIndex === 0;
  const shouldDisplayBackButton = shouldRenderDays ? false : daysLength > 1;

  const onNavigatePrevious = React.useCallback(() => {
    // if we are on the first section and it is a lesson, we would like to navigate to the last section of the previous lesson
    if (shouldRenderLessons && currentDay === 0 && lessonIndex !== 0) {
      setLessonIndex?.(lessonIndex - 1);
      setCurrentSection(previousLessionsSectionsCount - 1);

      return;
    }

    navigatePrevious();
  }, [
    shouldRenderLessons,
    currentDay,
    navigatePrevious,
    lessonIndex,
    setLessonIndex,
    setCurrentSection,
    previousLessionsSectionsCount,
  ]);

  const onPressFinish = React.useCallback(async () => {
    const getDayKey = (offset = 0) => {
      if (shouldRenderLessons) return `day${lessonIndex + offset}`;

      if (shouldRenderDays) return `day${currentDay + offset}`;

      if (childSessionId)
        // if it is a child session, we want to mark the day as complete for the child session instead of the current section
        return `day${currentChildSessionIndex + offset}`;

      return "day0";
    };

    const validSessionProgressId =
      queriedSessionProgressId ?? sessionProgressId ?? "";

    const data: ResetDayProgressParams = {
      dayKey: getDayKey(0),
      sessionProgressId: validSessionProgressId,
      newState: ProgressState.Completed,
    };

    asyncLogEvent(events.LIBRARY_COMPLETED_DAY, data);
    await resetDayProgress(data);

    // if it is rendering lessons and it is the last section, we complete the lesson progress
    // but instead of going to the complete day screen, we go to the next lesson
    if (shouldRenderLessons && lessonIndex !== lessonsLength - 1) {
      setLessonIndex?.(lessonIndex + 1);
      setCurrentSection(0);

      // as we are starting a new lesson, we want to mark it as in progress
      const nextLessonData: UpdateSessionProgressParams = {
        sessionId,
        dayKey: getDayKey(1),
        shouldMarkDayAsComplete: false,
        sessionProgressId: validSessionProgressId,
      };

      await updateSessionProgress(nextLessonData);

      return;
    }

    const getCompleteDayHeading = () => {
      if (shouldRenderDays) return undefined;

      if (shouldRenderLessons) return formatMessage(messages.sessionGeneric);

      return heading;
    };

    navigation.navigate(routes.completeDay, {
      planId,
      // for childSessionId, + 1 in order to match the day number with the ToC
      // moving forward, we should probably just pass the text all together when opening the ToC
      // to avoid this kind of logic and make it more flexible
      day: childSessionId ? currentChildSessionIndex + 1 : currentDay,
      heading: getCompleteDayHeading(),
    });
  }, [
    queriedSessionProgressId,
    sessionProgressId,
    resetDayProgress,
    shouldRenderLessons,
    lessonIndex,
    lessonsLength,
    navigation,
    planId,
    childSessionId,
    currentChildSessionIndex,
    currentDay,
    shouldRenderDays,
    setLessonIndex,
    setCurrentSection,
    sessionId,
    updateSessionProgress,
    heading,
  ]);

  const isLastLesson =
    shouldRenderLessons && isLastDay && lessonIndex !== lessonsLength - 1;

  const getFinishButtonText = React.useCallback(() => {
    if (shouldDisplayBackButton) return formatMessage(messages.finishButton);

    if (currentDay === 0) return formatMessage(messages.completeGenericButton);

    return formatMessage(messages.completeDayButton, {
      day: currentDay,
    });
  }, [shouldDisplayBackButton, currentDay]);

  if (isLoading) return null;

  if (isLastDay || shouldRenderDays)
    return (
      <Footer hasExtraSpace>
        <NavigationButtonsContainer>
          {shouldDisplayBackButton && (
            <BackButton onPress={onNavigatePrevious}>
              <Text2 color={colors.gray700}>{messages.backButton}</Text2>
            </BackButton>
          )}

          {isLastLesson ? (
            <ContinueButton onPress={onPressFinish}>
              <Text2 color={colors.white}>{messages.continueButton}</Text2>
            </ContinueButton>
          ) : (
            <CompleteButton
              onPress={onPressFinish}
              isFinishButton={shouldDisplayBackButton}
            >
              <Text2 color={colors.white}>{getFinishButtonText()}</Text2>
            </CompleteButton>
          )}
        </NavigationButtonsContainer>
        {shouldRenderLessons ? (
          <CarouselDots size={daysLength} activeIndex={currentDay} />
        ) : null}
      </Footer>
    );

  return (
    <Footer>
      <NavigationButtonsContainer>
        <BackButton onPress={onNavigatePrevious} isFirstDay={isFirstDay}>
          <Text2 color={colors.gray700}>{messages.backButton}</Text2>
        </BackButton>

        <ContinueButton onPress={navigateNext}>
          <Text2 color={colors.white}>{messages.continueButton}</Text2>
        </ContinueButton>
      </NavigationButtonsContainer>

      <CarouselDots size={daysLength} activeIndex={currentDay} />
    </Footer>
  );
};
