import * as React from "react";

import { messages } from "./intl";
import {
  ButtonBox,
  ButtonIcon,
  ButtonText,
  OptionBox,
  OptionInput,
} from "./styles";

interface Props {
  options: Record<string, string>;
  setOptions: (arg0: Record<string, string>) => void;
}

type Option = {
  key: string;
  value: string;
};

const optionsTransform = (options: Option[]) => {
  return options.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});
};

export const Options = ({ options, setOptions }: Props) => {
  const [values, setValues] = React.useState<Array<Option>>(
    Object.keys(options).map((key) => ({ key, value: options[key] }))
  );

  const handleInputChange = React.useCallback(
    (index: number, value: string, name: string) => {
      const updatedOptions = [...values];
      updatedOptions[index][name] = value;
      setValues(updatedOptions);
      setOptions(optionsTransform(updatedOptions));
    },
    [values, setValues, setOptions]
  );

  const handleAddOption = () => {
    setValues([...values, { key: "", value: "" }]);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = [...values];
    updatedOptions.splice(index, 1);
    setValues(updatedOptions);
    setOptions(optionsTransform(updatedOptions));
  };

  return (
    <>
      {values.map((item, index) => (
        <OptionBox key={index}>
          <OptionInput
            label={messages.key}
            value={item.key}
            onChangeText={(value) => handleInputChange(index, value, "key")}
          />
          <OptionInput
            label={messages.value}
            value={item.value}
            onChangeText={(value) => handleInputChange(index, value, "value")}
          />
          <ButtonBox onPress={() => handleRemoveOption(index)}>
            <ButtonText type="error">{messages.removeOption}</ButtonText>
          </ButtonBox>
        </OptionBox>
      ))}
      <ButtonBox onPress={handleAddOption}>
        <ButtonIcon name="plus" />
        <ButtonText>{messages.addOption}</ButtonText>
      </ButtonBox>
    </>
  );
};
