import React, { Fragment } from "react";
import { useNavigation } from "@react-navigation/native";

import { ModalHeader } from "~/components/modal-header";
import { SettingsScreen } from "~/components/settings";
import { Alerts } from "~/components/alerts";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getUserFirstName, getUserLastName } from "~/state/user/selectors";
import { editProfile } from "~/state/user/actions";
import { colors } from "~/styles/theme";
import { useAlerts } from "~/state/alerts";

import { EditInput, Container } from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

interface Props {
  callback: () => void;
}

export const ChangeNameComponent = ({ callback }: Props) => {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = React.useState(false);
  const userFirstName = useAppSelector(getUserFirstName);
  const userLastName = useAppSelector(getUserLastName);
  const { pushAlert } = useAlerts();

  const [firstName, setFirstName] = React.useState(userFirstName);
  const [lastName, setLastName] = React.useState(userLastName);

  const hasDataChanged =
    userFirstName !== firstName || userLastName !== lastName;

  const onDonePress = React.useCallback(() => {
    if (hasDataChanged) {
      setIsSaving(true);

      dispatch(
        editProfile({
          firstName,
          lastName,
          onSuccess: () => {
            pushAlert({
              message: messages.profileSaved,
              color: colors.emerald600,
            });

            setIsSaving(false);

            if (isWeb) {
              callback();
            }
          },
          onError: () => {
            pushAlert({
              message: messages.error,
              color: colors.red600,
            });

            setIsSaving(false);
          },
        })
      );

      return;
    }

    callback();
  }, [dispatch, firstName, hasDataChanged, callback, lastName, pushAlert]);

  const onFirstNameChange = React.useCallback(
    (text: string) => setFirstName(text),
    []
  );

  const onLastNameChange = React.useCallback(
    (text: string) => setLastName(text),
    []
  );

  const Wrapper = isWeb ? Container : Fragment;

  return (
    <Wrapper>
      <ModalHeader
        onLeftPress={onDonePress}
        onRightPress={onDonePress}
        title={messages.title}
        rightButtonText={hasDataChanged ? messages.save : undefined}
        isSaving={isSaving}
      />

      <EditInput
        label={messages.firstName}
        value={firstName}
        onChangeText={onFirstNameChange}
      />

      <EditInput
        label={messages.lastName}
        value={lastName}
        onChangeText={onLastNameChange}
      />

      <Alerts />
    </Wrapper>
  );
};

export const ChangeName = () => {
  const navigation = useNavigation();

  return (
    <SettingsScreen screenName="change-name">
      <ChangeNameComponent callback={navigation.goBack} />
    </SettingsScreen>
  );
};
