import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { LibraryStackParamList } from "~/navigation/library-stack";
import { Video } from "~/components/video";
import { Screen } from "~/components/screen";
import { ModalHeader } from "~/components/modal-header";
import * as routes from "~/constants/routes";

import { messages } from "./intl";
import { VideoPlayerContainer } from "./styles";

export type VideoPlayerProps = NativeStackScreenProps<
  LibraryStackParamList,
  typeof routes.videoPlayer
>;

export const VideoPlayer: React.FC<VideoPlayerProps> = () => {
  const { resourceLocation } = useRoute<VideoPlayerProps["route"]>().params;
  const navigation = useNavigation<VideoPlayerProps["navigation"]>();

  const onDonePress = React.useCallback(
    () => navigation.goBack(),
    [navigation]
  );

  return (
    <Screen screenName="video-player" isModal>
      <ModalHeader
        onLeftPress={onDonePress}
        onRightPress={onDonePress}
        title={messages.title}
      />

      <VideoPlayerContainer>
        <Video file={resourceLocation} isDownloaded />
      </VideoPlayerContainer>
    </Screen>
  );
};
