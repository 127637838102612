import * as React from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { colors } from "~/styles/theme";

import { Loader } from "../loader";
import {
  ImageBox,
  Text,
  AvatarImage,
  GradientContainer,
  LocalImage,
  LoaderOverlay,
} from "./styles";

interface Props {
  uri?: string;
  localUri?: string;
  size?: number;
  name?: string;
  isSettings?: boolean;
  isSaving?: boolean;
}

export const Avatar = React.memo<Props>(
  ({ uri, localUri, size = 32, name = "", isSettings, isSaving }) => {
    if (localUri) {
      return (
        <ImageBox size={size}>
          <LocalImage size={size} source={{ uri: localUri }} />

          {isSaving && (
            <LoaderOverlay>
              <Loader size={16} />
            </LoaderOverlay>
          )}
        </ImageBox>
      );
    }

    if (uri) {
      return (
        <ImageBox size={size}>
          <AvatarImage size={size} uri={uri} />
        </ImageBox>
      );
    }

    return (
      <GradientContainer size={size}>
        {isSettings ? (
          <Icon
            name="account-outline"
            size={size / 1.75}
            color={colors.white}
          />
        ) : (
          <Text>{name.charAt(0)}</Text>
        )}
      </GradientContainer>
    );
  }
);
