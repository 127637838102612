import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

export const EmptyGroupImage = () => (
  <Svg viewBox="0 0 167 120" fill="none" width="167" height="120">
    <G clipPath="url(#empty-group_svg__a)">
      <Path
        fill="#222747"
        d="M74.886 85.324v22.365H47.682l-.806-20.377 28.01-1.988Z"
      />
      <Path
        fill="#303D72"
        d="m43.265 33.06-4.852 26.124-16.745 1.202s-.852 2.231-.425 4.05l.425 1.82s18.505.319 21.506-.65c3.002-.968 2.993-24.66 2.939-27.264-.054-2.603-2.848-5.285-2.848-5.285v.003Zm27.163-1.429 12.98 10.667 23.764-4.275s1.561 3.066 1.678 4.206l.116 1.142s-23.439 7.106-26.483 6.963c-3.043-.143-14.373-9.315-14.373-9.315l2.318-9.388Z"
      />
      <Path
        fill="#303D72"
        d="m52.756 29.035-9.49 4.027.609 55.857s18.227 3.57 25.416 1.727l7.19-1.845-5.113-55.582a2.16 2.16 0 0 0-1.624-1.893l-8.468-2.173-8.522-.116.002-.002Z"
      />
      <Path
        fill="#26335E"
        d="m43.17 41.935.513 46.982s14.46 2.832 22.702 2.148L43.17 41.933v.002Z"
      />
      <Path
        fill="#E68D5C"
        d="m87.686 53.806-1.237 13.18-10.433 19.066 3.305 1.619 14.093-19.369.483-15.062-6.21.566Z"
      />
      <Path
        fill="#303D72"
        d="M114.249 95.991s.513 4.753.513 6.989c0 2.236-.987 5.588-.987 5.588H96.906l1.225-14.066 16.118 1.49Z"
      />
      <Path
        fill="#0CBFD1"
        d="M86.61 53.554s1.454-8.94 4.176-11.43c1.96-1.793 3.872-1.742 5.205-1.442 1.333.303 2.558 4.841 1.267 10.97-.893 4.247-1.01 4.693-1.01 4.693s-7.023.233-9.64-2.79h.002Z"
      />
      <Path
        fill="#60D6E6"
        d="m105.456 38.023 11.017 4.867-2.024 53.568H87.684l2.406-52.574a2.994 2.994 0 0 1 1.531-2.471l5.845-3.281 7.99-.109Z"
      />
      <Path
        fill="#E2E2E2"
        d="m48.47 66.085 2.335 22.945L75.78 99.54s1.867 2.178 0 5.024l-28.098-8.106.789-30.373Z"
      />
      <Path
        fill="#F0F1F4"
        d="m32.204 60.886-8.512 3.25a3.786 3.786 0 0 0-2.447 3.65l1.314 41.082h26.61l-.65-42.166a4.736 4.736 0 0 0-3.237-4.409l-5.653-1.905-7.425.498Z"
      />
      <Path
        fill="#303D72"
        d="M139.952 36.243h-.233v73.108h.233V36.243Zm3.294 7.077h-.234v64.318h.234V43.32Zm-1.168 13.25h-.233v53.2h.233v-53.2Zm-5.959 11.494h-.233v41.706h.233V68.064Zm9.127 11.732h-.234v29.553h.234V79.796Z"
      />
      <Path
        fill="#303D72"
        d="M134.56 51.39c3.954-1.983 8.44-1.088 10.018 1.996 1.578 3.084-.35 7.194-4.304 9.178-3.954 1.983-12.338 9.405-13.916 6.318-1.578-3.086 4.251-15.51 8.205-17.492h-.003Zm-4.628 39.478c3.393-3.625 9.222-3.736 13.017-.25 3.795 3.487 4.122 9.253.728 12.875-3.394 3.625-8.564 15.4-12.359 11.913-3.795-3.486-4.78-20.915-1.386-24.54v.002Z"
      />
      <Path
        fill="#00C99C"
        d="M126.893 69.07c4.605-3.09 10.328-2.682 12.786.91 2.458 3.593.717 9.01-3.889 12.098-4.605 3.09-13.85 13.643-16.308 10.05s2.805-19.97 7.411-23.058Z"
      />
      <Path
        fill="#00B98F"
        d="M126.893 69.07c-4.333 2.906-9.248 17.577-7.766 22.293 3.998-11.973 6.596-15.515 10.737-19.866a20.176 20.176 0 0 1 5.777-4.228c-2.659-.675-5.917-.1-8.751 1.8h.003Z"
      />
      <Path
        fill="#00C99C"
        d="M153.352 54.44c-5.284-1.718-10.674.231-12.041 4.353-1.368 4.122 1.808 8.857 7.091 10.577 5.284 1.718 17.099 9.352 18.467 5.23 1.368-4.123-8.232-18.44-13.517-20.158v-.002Z"
      />
      <Path
        fill="#00B98F"
        d="M153.352 54.438c4.972 1.618 13.765 14.389 13.646 19.325-7.166-10.425-10.644-13.125-15.833-16.184a20.316 20.316 0 0 0-6.727-2.494c2.369-1.373 5.66-1.706 8.914-.647Z"
      />
      <Path
        fill="#E2E2E2"
        d="m21.553 77.397 1.006 31.471h26.61l-.252-7.689-22.02-11.934 1.993-15.834-7.337 3.986Z"
      />
      <Path
        fill="#00C99C"
        d="M146.962 38.546c-3.406 1.417-5.255 4.72-4.127 7.38 1.127 2.658 4.803 3.661 8.209 2.244 3.405-1.417 12.691-2.689 11.563-5.345-1.127-2.659-12.24-5.696-15.645-4.28Z"
      />
      <Path
        fill="#303D72"
        d="M139.698 30.575c2.126 1.447 2.917 3.967 1.767 5.627-1.151 1.66-3.81 1.83-5.936.383s-8.296-3.618-7.145-5.278c1.151-1.66 9.187-2.18 11.314-.732Z"
      />
      <Path
        fill="#00B98F"
        d="M146.962 38.546c3.204-1.334 13.232 1.278 15.344 3.805-8.179-2.138-11.113-1.99-15.06-1.285a13.563 13.563 0 0 0-4.472 1.65c.586-1.71 2.089-3.299 4.185-4.17h.003Z"
      />
      <Path fill="#A9C2DC" d="M161.832 106.508H0v2.36h161.832v-2.36Z" />
      <Path fill="#303D72" d="M161.832 108.868H0v1.182h161.832v-1.182Z" />
      <Path
        fill="#00C99C"
        d="M127.857 96.717c4.827-2.733 10.503-1.896 12.679 1.87 2.175 3.766.023 9.038-4.806 11.77-4.827 2.735-14.857 12.558-17.032 8.792-2.176-3.766 4.329-19.7 9.156-22.434l.003.002Z"
      />
      <Path
        fill="#00B98F"
        d="M127.857 96.717c-4.543 2.573-10.569 16.831-9.454 21.644 4.907-11.638 7.768-14.974 12.231-19a20.274 20.274 0 0 1 6.085-3.781c-2.6-.874-5.891-.546-8.862 1.137Z"
      />
      <Path
        fill="#00C99C"
        d="M157.6 81.882c-6.131-4.166-14.263-4.16-18.166.018-3.903 4.178-2.091 10.94 4.04 15.108 6.132 4.166 17.924 17.846 21.827 13.668 3.902-4.177-1.569-24.626-7.701-28.794Z"
      />
      <Path
        fill="#00B98F"
        d="M157.603 81.882c5.77 3.92 10.951 22.259 8.293 27.888-4.213-14.965-7.455-19.512-12.782-25.179-2.509-2.668-5.289-4.468-7.651-5.66 3.837-.596 8.368.385 12.142 2.948l-.002.003Z"
      />
      <Path
        fill="#FBAD83"
        d="M43.947 99.695s4.666 1.329 7.06 2.081c2.396.751 3.88 1.937 3.763 2.339-.116.402-.291.49-.291.49h-9.745l-4.085-2.326 3.296-2.584h.002Z"
      />
      <Path
        fill="#F0F1F4"
        d="M21.861 65.609s-8.87 21.664-7.002 28.401c1.867 6.737 29.02 10.554 29.02 10.554s.79-1.965.941-3.468l.15-1.503-23.727-8.096s7.628-15.226 8.046-20.113c.417-4.888-7.43-5.775-7.43-5.775h.002Z"
      />
      <Path
        fill="#fff"
        d="M78.063 90.588c0 1.612.99 2.918 2.208 2.918 1.218 0 2.208-1.306 2.208-2.918 0-1.611-.99-2.917-2.208-2.917-1.219 0-2.208 1.306-2.208 2.917Z"
      />
      <Path fill="#19192B" d="M60.587 104.605H39.706v1.903h20.88v-1.903Z" />
      <Path fill="#303D72" d="M97.788 104.605H60.59v1.903h37.2v-1.903Z" />
      <Path
        fill="#0CBFD1"
        d="m114.424 96.458 1.727-45.745-4.5-1.029-10.655 46.774h13.428Z"
      />
      <Path
        fill="#fff"
        d="M32.204 60.886s2.005 3.61 5.08 3.553c3.073-.058 2.345-4.05 2.345-4.05l-7.425.496Z"
      />
      <Path
        fill="#F9C200"
        d="M45.182 42.229s1.536 12.32.922 14.73c-.614 2.412-6.758 1.92-9.83 1.672-3.07-.247-4.688.164-6.03-.573-1.343-.737 1.115-8.448 1.115-8.448l13.82-7.381h.003Z"
      />
      <Path
        fill="#fff"
        d="M42.04 59.302c-2.063 0-3.744-1.665-3.744-3.708a3.718 3.718 0 0 1 2.764-3.58.117.117 0 0 1 .142.082.114.114 0 0 1-.082.14 3.487 3.487 0 0 0-2.59 3.358c0 1.916 1.575 3.477 3.51 3.477 1.935 0 3.51-1.56 3.51-3.477 0-1.917-1.575-3.477-3.51-3.477a.115.115 0 0 1-.117-.116c0-.065.052-.116.117-.116 2.066 0 3.744 1.665 3.744 3.709 0 2.043-1.68 3.708-3.744 3.708Z"
      />
      <Path
        fill="#FBAD83"
        d="m40.217 55.379-.59 5.01s-.694 1.16-4.358 1.16c-1.865 0-3.067-.664-3.067-.664l.007-8.84 8.006 3.334h.002Z"
      />
      <Path
        fill="#E68D5C"
        d="m40.217 55.379-.406 3.447a6.92 6.92 0 0 1-1.998-.148c-.864-.197-3.329-2.721-4.892-6.337l7.296 3.035v.003Z"
      />
      <Path
        fill="#FBAD83"
        d="M44.941 39.443s.855 8.399-.338 12.468c-1.193 4.069-4.911 5.789-8.19 5.04-1.22-.278-5.63-5.188-6.186-11.257s-.026-7.897.97-9.18c.998-1.283 10.054-2.88 11.7-1.478 1.645 1.404 2.047 4.407 2.047 4.407h-.003Z"
      />
      <Path
        fill="#F9C200"
        d="M42.04 39.135s2.782 3.884 3.47 3.998c.692.113.778-2.467.603-4.788-.275-3.66-2.847-5.285-2.847-5.285l-1.226 6.075Z"
      />
      <Path
        fill="#F9C200"
        d="M44.68 36.063s-.829 4.524-2.568 7.231c-1.739 2.708-8.281 1.723-9.439 1.806-1.16.08.166 9.185.995 12.22.828 3.036-5.136 2.132-7.038 1.723-1.904-.41-2.899-16.4-.413-22.594 2.484-6.193 12.751-5.953 15.569-4.596 2.815 1.355 2.899 4.212 2.899 4.212l-.005-.002Z"
      />
      <Path
        fill="#FBAD83"
        d="M29.844 50.16c0 1.196 1.601 2.255 2.587 2.255.985 0 .975-1.061.975-2.254s-.798-2.162-1.78-2.162c-.984 0-1.782.969-1.782 2.162Z"
      />
      <Path
        fill="#E68D5C"
        d="M30.62 49.633c.113-.584 1-.954 1.484-.862.48.093-.782.428-.897 1.013-.114.585.56 1.167.077 1.075-.48-.093-.78-.643-.665-1.228v.002Z"
      />
      <Path
        fill="#F76A00"
        d="M37.526 47.347a.23.23 0 0 1-.196-.106.23.23 0 0 1 .067-.32l2.043-1.31a.242.242 0 0 1 .187-.03l1.51.39a.231.231 0 1 1-.12.447l-1.412-.366-1.955 1.256a.235.235 0 0 1-.127.037l.003.002Zm8.364-.538a.223.223 0 0 1-.127-.037l-1.118-.717-1.412.365a.234.234 0 0 1-.285-.164.231.231 0 0 1 .166-.282l1.51-.39a.232.232 0 0 1 .187.03l1.204.771c.108.07.14.213.068.32a.23.23 0 0 1-.196.106l.002-.002Z"
      />
      <Path
        fill="#fff"
        d="M32.204 59.302c-2.066 0-3.744-1.665-3.744-3.708a3.718 3.718 0 0 1 2.764-3.58.117.117 0 0 1 .142.082.114.114 0 0 1-.082.14 3.487 3.487 0 0 0-2.59 3.358c0 1.916 1.575 3.477 3.51 3.477 1.935 0 3.51-1.56 3.51-3.477 0-1.917-1.575-3.477-3.51-3.477a.115.115 0 0 1-.117-.116c0-.065.052-.116.117-.116 2.063 0 3.744 1.665 3.744 3.709 0 2.043-1.68 3.708-3.744 3.708Z"
      />
      <Path
        fill="#FBAD83"
        d="M105.456 27.879v10.144s-1.72 1.448-4.745 1.274c-2.799-.16-3.245-1.165-3.245-1.165L96.31 32.87l9.147-4.991Z"
      />
      <Path
        fill="#E68D5C"
        d="M97.27 37.244c.722-.277 1.802-.811 3.1-1.833 2.397-1.886 3.018-4.21 3.018-4.21l-6.136 1.156-.943.516.961 4.371Z"
      />
      <Path fill="#1A2249" d="M94.757 22.002h-5.199v.231h5.198v-.23Z" />
      <Path
        fill="#7A4029"
        d="M91.106 14.865s-.883 2.162-.883 4.053c0 1.521.619 4.4.619 4.4l3.536-8.945-3.272.492Z"
      />
      <Path
        fill="#FBAD83"
        d="M91.32 31.994c2.797 5.828 9.739 2.386 12.712-.552 2.972-2.939 2.561-14.17 1.739-16.295-.821-2.127-7.693-3.854-11.316-2.48-3.622 1.373-5.93 13.501-3.132 19.327"
      />
      <Path
        fill="#D7C5BE"
        d="M90.226 27.888c.182 1.53.537 2.943 1.094 4.106 1.114 2.319 2.88 3.17 4.795 3.17-1.912 0-3.681-.854-4.792-3.17-.558-1.163-.913-2.575-1.095-4.106"
      />
      <Path
        fill="#D48C68"
        d="M106.303 23.317h-3.13s-2.075 5.39-3.858 5.905c-.14.042-.287.06-.439.06-1.484 0-3.46-1.785-5.952-1.891a15.85 15.85 0 0 0-.628-.014c-1.067 0-1.543.157-2.07.509.182 1.528.537 2.943 1.094 4.106 1.112 2.316 2.88 3.17 4.793 3.17 2.896 0 6.127-1.954 7.917-3.723 1.438-1.422 2.084-4.788 2.276-8.122"
      />
      <Path
        fill="#7A4029"
        d="M105.37 24.03c-2.117-.195-3.653-2.086-3.389-4.178.158-1.265.534-1.667.203-2.864-.661-2.384-3.546-2.162-5.495-1.274-1.949.888-4.073.888-5.747-.368-.798-.596-1.804-2.258-.716-3.995C91.314 9.615 95.17 7.53 95.17 7.53s-.467 1.042-.313 1.581c.156.541 10.403-2.508 12.508 2.007 2.106 4.515-.7 13.025-.7 13.025l-1.295-.118v.005Z"
      />
      <Path
        fill="#FBAD83"
        d="M107.171 24.09c0 1.502-2.016 2.836-3.253 2.836s-1.228-1.334-1.228-2.836c0-1.503 1.004-2.721 2.241-2.721s2.24 1.218 2.24 2.72Z"
      />
      <Path
        fill="#E68D5C"
        d="M106.247 23.956c.145.672-.63 1.458-1.185 1.576-.556.118.637-.802.492-1.475-.145-.672-1.125-.991-.572-1.107.553-.116 1.121.335 1.263 1.008l.002-.002Z"
      />
      <Path
        fill="#1A2249"
        d="M91.486 25.287c-1.127 0-2.045-1.177-2.045-2.624s.918-2.624 2.045-2.624c1.127 0 2.045 1.177 2.045 2.624s-.918 2.624-2.045 2.624Zm0-5.017c-.999 0-1.811 1.073-1.811 2.393 0 1.32.812 2.393 1.811 2.393 1 0 1.811-1.073 1.811-2.393 0-1.32-.812-2.393-1.81-2.393Zm4.823 5.017c-1.128 0-2.045-1.177-2.045-2.624s.917-2.624 2.045-2.624c1.127 0 2.044 1.177 2.044 2.624s-.917 2.624-2.044 2.624Zm0-5.017c-1 0-1.812 1.073-1.812 2.393 0 1.32.813 2.393 1.812 2.393.999 0 1.81-1.073 1.81-2.393 0-1.32-.811-2.393-1.81-2.393Z"
      />
      <Path
        fill="#1A2249"
        d="M102.991 22.002H98.19v.231h4.801v-.23Zm-8.613 0h-.964v.231h.964v-.23Z"
      />
      <Path
        fill="#fff"
        d="M91.516 28.882h4.356s-.882 2.042-2.353 2.1c-1.47.057-2.005-2.1-2.005-2.1h.002Z"
      />
      <Path
        fill="#FBAD83"
        d="m120.905 53.806-6.766 2.303 3.268 13.05-7.26 28.088-2.056 1.204s-.117 3.005 2.129 3.583c2.245.578 3.062-1.068 3.062-1.068v-2.203c1.8-4.501 10.831-27.155 10.971-28.616.156-1.623-3.348-16.338-3.348-16.338v-.002Z"
      />
      <Path
        fill="#60D6E6"
        d="M113.387 56.925s-2.667-7.805-1.547-10.953c.808-2.27 2.367-3.034 3.569-3.353 1.202-.319 4.136 2.819 5.728 8.29 1.104 3.792 1.202 4.201 1.202 4.201s-5.551 3.147-8.954 1.815h.002Z"
      />
      <Path
        fill="#A55B3A"
        d="M107.104 37.977s.691-.684 2.502 0 2.168 1.831 2.07 2.97c-.098 1.14-2.299 1.719-3.687 1.332-1.389-.386-2.626-1.89-2.526-2.499.1-.608 1.643-1.805 1.643-1.805l-.002.002ZM21.157 67.564c-1.707-1.065-2.092-3.398-.817-4.947.072-.088.149-.178.23-.266 1.6-1.76 3.091-1.653 4.37-1.036 1.282.618.971 3.383-.093 4.793-1.065 1.41-2.939 1.893-3.602 1.51l-.088-.054Z"
      />
      <Path
        fill="#fff"
        d="m52.756 29.035 2.845 4.025 2.958-2.153 3.14 1.512-.421-3.269-8.522-.115Z"
      />
      <Path
        fill="#A55B3A"
        d="M52.756 18.213v10.82s1.835 1.544 5.063 1.359c2.985-.171 3.461-1.242 3.461-1.242l1.235-5.61-9.757-5.325-.002-.002Z"
      />
      <Path
        fill="#8E4A2F"
        d="M61.488 28.203c-1.603.353-3.462-.636-4.846-1.727-2.556-2.014-3.59-6.004-3.59-6.004l8.457 2.517 1.006.55-1.027 4.666v-.002Z"
      />
      <Path
        fill="#1A2249"
        d="M67.657 5.195s1.447.534 1.347 3.955c-.047 1.623-.66 4.694-.66 4.694L64.57 4.302l3.086.893Z"
      />
      <Path
        fill="#A55B3A"
        d="M67.835 23.096c-2.984 6.216-10.387 2.543-13.557-.59-3.17-3.135-2.733-15.113-1.856-17.38.878-2.269 8.205-4.111 12.07-2.645 3.865 1.463 6.325 14.4 3.343 20.617v-.002Z"
      />
      <Path
        fill="#1A2249"
        d="M56.58 12.71s.823 6.88 2.726 7.431c1.627.472 3.886-1.829 6.815-1.953 1.63-.07 2.218.085 2.883.529-.194 1.63-.572 3.137-1.167 4.379-2.983 6.216-10.387 2.543-13.557-.59-1.536-1.517-2.224-5.105-2.427-8.662l4.729-1.133h-.003Z"
      />
      <Path
        fill="#1A2249"
        d="M55.335 15.178s.915.09 1.289-.449c.373-.539-.698-3.581.385-6.806.772-2.303 4.285-3.637 7.16-3.077 2.877.56 3.6.811 3.894-.016.294-.828-.859-2.772-1.858-3.545-.996-.772-5.674-2.129-9.57-.554-3.895 1.574-4.675 2.624-5.548 5.125-.873 2.501-.313 8.769 0 9.171.31.403 4.25.155 4.25.155l-.002-.005Z"
      />
      <Path
        fill="#A55B3A"
        d="M50.928 14.666c0 1.603 2.15 3.027 3.471 3.027 1.321 0 1.31-1.422 1.31-3.027 0-1.604-1.07-2.9-2.39-2.9-1.322 0-2.39 1.298-2.39 2.9Z"
      />
      <Path
        fill="#1A2249"
        d="M69.004 18.717s-.01 7.534-.894 8.891c-.885 1.36-3.837 1.653-5.168 1.087-1.33-.567-3.492-2.61-4.03-4.34-.54-1.729 7.88-4.198 7.88-4.198l2.212-1.442v.002Z"
      />
      <Path
        fill="#8E4A2F"
        d="M51.801 13.918c-.154.716.672 1.555 1.263 1.68.59.125-.68-.855-.525-1.574.154-.717 1.2-1.059.61-1.181-.592-.125-1.196.355-1.348 1.075Z"
      />
      <Path
        fill="#fff"
        d="M67.466 21.181s-3.156.084-3.681-.083l-.525-.166s.014 1.528 1.475 2.055c1.463.527 2.266-.446 2.46-.985.194-.539.27-.82.27-.82Z"
      />
      <Path
        fill="#1A2249"
        d="M66.681 11.603a.233.233 0 0 1-.219-.155.233.233 0 0 1 .142-.296c.052-.018 1.256-.427 2.755-.004a.233.233 0 0 1 .16.287.235.235 0 0 1-.289.16c-1.356-.385-2.458-.008-2.47-.006a.226.226 0 0 1-.076.014h-.003Zm-5.324.777a.233.233 0 0 1-.154-.058.23.23 0 0 1-.023-.326c.056-.062 1.372-1.53 3.718-.788a.23.23 0 0 1 .152.291.234.234 0 0 1-.294.15c-2.045-.647-3.177.6-3.226.653a.233.233 0 0 1-.175.078h.002Z"
      />
    </G>
    <Defs>
      <ClipPath id="empty-group_svg__a">
        <Path fill="#fff" d="M0 0h167v120H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
