export const messages = {
  or: {
    id: "pif.buttons.or",
    defaultMessage: "Or",
  },
  error: {
    id: "pif.buttons.error",
    defaultMessage: "Payment error",
  },
};
