import { createAction } from "@reduxjs/toolkit";

import {
  LoginPayload,
  SignupPayload,
  VerifyEmailPayload,
  ForgottenPasswordPayload,
  UpdatePasswordPayload,
  SaveProfilePayload,
  GoogleLoginPayload,
  Callbacks,
  EditProfilePayload,
  ChangePasswordPayload,
  DeleteAccountPayload,
  EmailOptInPayload,
  UserReadyPayload,
  ChangeEmailPayload,
} from "./types";

export const login = createAction<LoginPayload>("login");

export const preLogout = createAction("preLogout");

export const verifyEmail = createAction<VerifyEmailPayload>("verifyEmail");

export const sendEmailVerification = createAction<Callbacks>(
  "sendEmailVerification"
);
export const sendPasswordReset =
  createAction<ForgottenPasswordPayload>("sendPasswordReset");

export const updatePassword =
  createAction<UpdatePasswordPayload>("updatePassword");

export const loginWithApple = createAction("loginWithApple");

export const loginWithGoogle =
  createAction<GoogleLoginPayload>("loginWithGoogle");

export const signup = createAction<SignupPayload>("signup");

export const saveProfile = createAction<SaveProfilePayload>("saveProfile");

export const editProfile = createAction<EditProfilePayload>("editProfile");

export const changePassword =
  createAction<ChangePasswordPayload>("changePassword");

export const deleteAccount =
  createAction<DeleteAccountPayload>("deleteAccount");

export const triggerResetAPICache = createAction("triggerResetAPICache");

export const startEmailVerificationPolling = createAction(
  "startEmailVerificationPolling"
);

export const stopEmailVerificationPolling = createAction(
  "stopEmailVerificationPolling"
);

export const changeEmailOptIn =
  createAction<EmailOptInPayload>("changeEmailOptIn");

export const setIsUserReady = createAction<UserReadyPayload>("setIsUserReady");

export const changeEmail = createAction<ChangeEmailPayload>("changeEmail");
