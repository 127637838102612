import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text2 } from "~/components/text";

interface Props {
  isActive: boolean;
}

export const Container = styled.View`
  margin-horizontal: ${spacers.ss8};
  margin-bottom: ${spacers.ss4};
  margin-top: ${spacers.ss7};
  height: 40px;
  border-radius: 20px;
  border-width: 1px;
  border-color: ${colors.gray400};
  background-color: ${colors.white};
  flex-direction: row;
`;

export const ButtonWrapper = styled.View`
  flex: 1;
`;

export const Button = styled.TouchableOpacity<Props>`
  height: 38px;
  border-radius: 19px;
  background-color: ${({ isActive }) =>
    isActive ? colors.zinc700 : colors.white};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled(Text2)<Props>`
  color: ${({ isActive }) => (isActive ? colors.white : colors.zinc700)};
`;

export const IconBox = styled.View`
  margin-right: ${spacers.ss3};
`;
