export const messages = {
  summary: {
    id: "note.summary",
    defaultMessage: "{{count}} Notes",
  },
  loading: {
    id: "note.loading",
    defaultMessage: "Loading...",
  },
};
