import * as React from "react";

import { Container, Text } from "./styles";

interface Props {
  text: string;
}

export const PlanDescription = React.memo(({ text }: Props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExplanded = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  if (!text) {
    return null;
  }
  return (
    <Container onPress={toggleExplanded}>
      <Text numberOfLines={!isExpanded ? 3 : undefined}>{text}</Text>
    </Container>
  );
});
