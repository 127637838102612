import * as React from "react";
import type { WhatWeBelieveSection } from "~/state/flamelink/types";
import { RichText } from "../rich-text";
import { SectionTitle, SubsectionTitle } from "../common";
import { Media } from "../media";
import { Footnotes } from "../footnotes";

interface Props {
  data: WhatWeBelieveSection;
}

export const WhatWeBelieve: React.FC<Props> = ({ data }) => {
  const hasContent = React.useMemo(
    () =>
      data?.whatWeBelieveSectionTitle ||
      data?.title ||
      data?.mediaRepeater?.length ||
      data?.conclusion,
    [data]
  );

  return hasContent ? (
    <>
      {data?.whatWeBelieveSectionTitle && (
        <SectionTitle>{data?.whatWeBelieveSectionTitle}</SectionTitle>
      )}

      {data?.title && <SubsectionTitle>{data?.title}</SubsectionTitle>}

      {Array.isArray(data?.mediaRepeater) &&
        data?.mediaRepeater.map(({ media, content }, i) => (
          <React.Fragment key={`mediaRepeater-${i}`}>
            {Array.isArray(media) &&
              media.map((fileId, index) => (
                <Media key={`mediaRepeater-media-${index}`} fileId={fileId} />
              ))}

            {content && <RichText>{content}</RichText>}
          </React.Fragment>
        ))}

      {data?.conclusion && <RichText>{data?.conclusion}</RichText>}

      <Footnotes data={data.footnotes} />
    </>
  ) : null;
};
