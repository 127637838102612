export const messages = {
  title: {
    id: "dev-tools.title",
    defaultMessage: "Developer tools",
  },
  environment: {
    id: "dev-tools.environment",
    defaultMessage: "Environment",
  },
  userData: {
    id: "dev-tools.user-data",
    defaultMessage: "User Data",
  },
  staging: {
    id: "dev-tools.staging",
    defaultMessage: "Staging",
  },
  production: {
    id: "dev-tools.production",
    defaultMessage: "Production",
  },
  disclaimer: {
    id: "dev-tools.disclaimer",
    defaultMessage:
      "It is recommended to re-login after changing the environment to avoid confusion with cached data.",
  },
  devDisclaimer: {
    id: "dev-tools.dev-disclaimer",
    defaultMessage: "If working on the simulator use `Cmd+R` to reload",
  },
  deleteUserDataDisclaimer: {
    id: "dev-tools.delete-user-data-disclaimer",
    defaultMessage:
      "Please reload the app afterwards to refresh the cache properly.",
  },
  deleteUserData: {
    id: "dev-tools.delete-user-data",
    defaultMessage: "Delete user data",
  },
  reloadTitle: {
    id: "dev-tools.reload-title",
    defaultMessage: "App environment change",
  },
  reloadDescription: {
    id: "dev-tools.reload-description",
    defaultMessage:
      "In order to get reliable results after the environment change it is recommended to close and re-open or re-login to the app.",
  },
};
