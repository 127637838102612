import { createSelector } from "@reduxjs/toolkit";

import { SortEnum } from "~/state/notes/types";
import { getSearchConditions } from "~/state/notes/utils";
import { bookNameMapping } from "~/utils/strings";

import { RootState } from "../store";
import { BibleNote, HighlightData, Highlights } from "./types";

const getState = (state: RootState) => state.bibleNotes;

export const getBibleNotes: (state: RootState) => BibleNote[] = createSelector(
  getState,
  (state) => state?.notes || []
);

export const getBibleHighlights: (state: RootState) => HighlightData[] =
  createSelector(getState, (state) => state?.highlights || []);

const hasSearchTerm = (
  { bookId, text, verses = [] }: BibleNote,
  searchTerm: string
) => {
  const bookNames = bookNameMapping[bookId] || [];
  const verseContent = verses.map(({ content }) => content);
  const noteText = [...bookNames, ...verseContent, text].join("").toLowerCase();
  return noteText.includes(searchTerm.toLowerCase());
};

const sortData = (data: BibleNote[], sortMethod: string) => {
  return [...data].sort((a, b) =>
    sortMethod === SortEnum.ASC
      ? a.lastUpdated - b.lastUpdated
      : b.lastUpdated - a.lastUpdated
  );
};

export const getBibleNotesFiltered: (
  state: RootState,
  searchTerm: string
) => BibleNote[] = createSelector(
  [getBibleNotes, (_, props) => props],
  (data, searchConditions) => {
    const { sortMethod, searchTerm } = getSearchConditions(searchConditions);
    const sortedData = sortData(data, sortMethod);
    return sortedData.filter((item) => hasSearchTerm(item, searchTerm));
  }
);

export const getBibleNotesCount: (state: RootState) => number = createSelector(
  getBibleNotes,
  (notes) => notes.length
);

export const getBibleNotesByChapterId: (
  state: RootState,
  chapterId: string
) => BibleNote[] = createSelector(
  [getBibleNotes, (_, props) => props],
  (notes, chapterId) => notes.filter((note) => note.chapterId === chapterId)
);

export const getBibleNoteById: (
  state: RootState,
  noteId: string
) => BibleNote | undefined = createSelector(
  [getBibleNotes, (_, props) => props],
  (notes, noteId) => notes.find((note) => note.id === noteId)
);

export const getBibleNotesVersesByChapterId: (
  state: RootState,
  chapterId: number
) => { [verseId: string]: string } = createSelector(
  [getBibleNotes, (_, props) => props],
  (notes, chapterId) =>
    notes
      .filter((note) => note.chapterId === chapterId)
      .map(({ id, verses }) => verses.map(({ verseId }) => ({ [verseId]: id })))
      .flat()
      .reduce(
        (acc, data) => ({
          ...acc,
          ...data,
        }),
        {}
      )
);

export const getBiblehighlightsByChapterId: (
  state: RootState,
  props: { bookId: string; chapterId: number }
) => Highlights | undefined = createSelector(
  [getBibleHighlights, (_, props) => props],
  (highlights, { bookId, chapterId }) => {
    if (Array.isArray(highlights)) {
      return highlights.find(
        (data) => data.chapterId === chapterId && data.bookId === bookId
      )?.highlights;
    }
  }
);
