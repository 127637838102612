import { StyleSheet } from "react-native";
import { IconButton } from "react-native-paper";
import styled from "styled-components/native";
import { css } from "styled-components/native";
import { IconSizes } from "~/components/icon";
import { Text2, Text4 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const ProgressRow = styled.TouchableOpacity<{
  isFirst?: boolean;
  isLast?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-width: ${StyleSheet.hairlineWidth}px;
      border-top-color: ${colors.black};
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-width: ${StyleSheet.hairlineWidth}px;
      border-bottom-color: ${colors.black};
      margin-bottom: ${isWeb ? 32 : 0}px;
    `}
`;

export const ProgressTextContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 0 ${spacers.ss6};
`;

export const ProgressText = styled(Text4)`
  color: ${colors.gray600};
  font-family: SFProDisplayMedium;
`;

export const ProgressIcon = styled(IconButton).attrs({
  icon: "arrow-right",
  size: IconSizes.Large,
})``;

export const ProgressCounterContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 0 -${spacers.ss2} 0 0;
`;

export const ProgressCounter = styled(Text2)`
  color: ${colors.gray600};
  margin: 0 -${spacers.ss4} 0 0;
  color: ${colors.primaryBlue};
`;

export const ForwardIcon = styled(IconButton).attrs({
  icon: "chevron-right",
  size: IconSizes.Medium,
  color: colors.primaryBlue,
})``;

export const Divider = styled.View`
  height: ${StyleSheet.hairlineWidth}px;
  background-color: ${colors.black};
  margin: 0 0 0 ${spacers.ss7};
`;
