export const messages = {
  title: {
    id: "save-prayer-requests.title",
    defaultMessage: "Prayer Requests"
  },
  cancelButton: {
    id: "save-prayer-requests.cancel",
    defaultMessage: "Cancel"
  },
  doneButton: {
    id: "save-prayer-requests.done",
    defaultMessage: "Done"
  },
  inputPlaceholder: {
    id: "save-prayer-requests.input-placeholder",
    defaultMessage: "Type your prayer requests here..."
  }
};
