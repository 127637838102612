import React from "react";
import { ActivityIndicator, View } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { format } from "date-fns";

import { getPaymentIntents } from "~/state/payments";
import {
  getTotalAmount,
  getTransformedData,
  transformAmount,
} from "~/state/payments/utils";
import { Transaction } from "~/state/payments/types";
import { getUserEmail } from "~/state/user/selectors";
import { useAppSelector } from "~/state/hooks";

import {
  Row,
  DataBox,
  Amount,
  Date,
  TotalBox,
  Total,
  SectionHeaderBox,
  SectionHeader,
  Disclaimer,
  ContentBox,
  EmptyText,
  LoaderBox,
} from "./styles";
import { messages } from "./intl";

interface Props {
  isLoading: boolean;
}

export const HistoryContent = React.memo<Props>(({ isLoading }) => {
  const email = useAppSelector(getUserEmail);
  const data = useAppSelector(getPaymentIntents);

  const [hiddenSections, setHiddenSections] = React.useState(new Set());

  const handleToggle = (title: number) => {
    setHiddenSections((sections) => {
      const next = new Set(sections);
      if (next.has(title)) {
        next.delete(title);
      } else {
        next.add(title);
      }
      return next;
    });
  };

  const history = getTransformedData(data);
  const totalAmount = getTotalAmount(data);
  const total = transformAmount(totalAmount, data[0]?.currency);

  const renderSectionHeader = ({ section: { title } }) => {
    const iconName = hiddenSections.has(title) ? "chevron-up" : "chevron-down";
    return (
      <SectionHeaderBox onPress={() => handleToggle(title)}>
        <>
          <SectionHeader>{title}</SectionHeader>
          <Icon name={iconName} size={20} />
        </>
      </SectionHeaderBox>
    );
  };

  const renderItem = ({ item, section: { title } }: { item: Transaction }) => {
    const isHidden = hiddenSections.has(title);

    if (isHidden) return null;

    const date = format(item.created * 1000, "MMM dd");
    const amount = transformAmount(item.amount, item.currency);

    return (
      <Row>
        <DataBox>
          <Date>{date}</Date>
          <Amount>{amount}</Amount>
        </DataBox>
      </Row>
    );
  };

  if (isLoading) {
    return (
      <LoaderBox>
        <ActivityIndicator />
      </LoaderBox>
    );
  }

  if (!history.length) {
    return <EmptyText>{messages.empty}</EmptyText>;
  }

  return (
    <>
      <TotalBox>
        <Total>{{ ...messages.total, values: { total } }}</Total>
      </TotalBox>
      <ContentBox>
        {history.map((item) => {
          return (
            <View key={item.title}>
              {renderSectionHeader({ section: { title: item.title } })}
              {item.data.map((row) => {
                return (
                  <View key={row.id}>
                    {renderItem({
                      item: row,
                      section: { title: item.title },
                    })}
                  </View>
                );
              })}
            </View>
          );
        })}
      </ContentBox>

      <Disclaimer>{{ ...messages.disclaimer, values: { email } }}</Disclaimer>
    </>
  );
});
