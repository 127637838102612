import * as React from "react";
import { isSameDay } from "date-fns";
import {
  Platform,
  Keyboard,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";

import { database } from "<config>/firebase";
import { useAppSelector, useAppDispatch, useAppNetInfo } from "~/state/hooks";
import { useAppState } from "~/utils/hooks/use-app-state";
import { getGroupById } from "~/state/groups/selectors";
import {
  getUserId,
  getProfileImage,
  getEnvironment,
} from "~/state/user/selectors";
import { Message, MessageType } from "~/state/chat/types";
import { addMessages } from "~/state/chat/slice";
import { getUserChatMessages } from "~/state/chat/selectors";
import { createMessage } from "~/state/chat/actions";
import { setLastActivity } from "~/state/groups/actions";
import { countUpdate } from "~/state/groups/slice";
import { getMessagesCollection } from "~/constants/collections";
import { PostAuthParamList } from "~/navigation/post-auth";
import { GroupHeaderBar } from "~/components/group-header-bar";
import { ChatMessage } from "~/components/chat";
import { ViewData } from "~/components/chat/message";
import { OfflineBanner } from "~/components/offline-banner";

import { InputBox } from "./components/input-box";
import { DayLabel } from "./components/day-label";
import { ActivityMessage } from "./components/activity-message";
import { ActionOverlay } from "./components/action-overlay";

import { Container, Content, ChatContent, LoaderBox } from "./styles";
import { isWeb } from "~/utils/platform";

export type Props = NativeStackScreenProps<PostAuthParamList, "group.chat">;

interface ContentProps {
  groupId: string;
}

const isActivityMessage = (type: MessageType) =>
  [MessageType.NewMember, MessageType.MemberRemoved].includes(type);

export const GroupChatComponent = ({ groupId }: ContentProps) => {
  const groupData = useAppSelector((state) => getGroupById(state, groupId));
  const messages = useAppSelector(getUserChatMessages);
  const currentUserId = useAppSelector(getUserId);
  const currentUserImage = useAppSelector(getProfileImage);
  const env = useAppSelector(getEnvironment);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { isConnected } = useAppNetInfo();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSending, setIsSending] = React.useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [contentType, setContentType] = React.useState(MessageType.Text);
  const [highligtedView, setHighligtedView] = React.useState({});
  const scrollViewRef = React.useRef<ScrollView>();

  const onContentChange = React.useCallback(
    () => scrollViewRef?.current?.scrollToEnd({ animated: !isWeb }),
    []
  );

  React.useEffect(() => {
    const showKeyboardSubscription = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        onContentChange();
      }
    );

    return () => {
      showKeyboardSubscription.remove();
    };
  }, [onContentChange]);

  React.useEffect(() => {
    if (!isConnected) {
      setIsLoading(false);

      return;
    }

    const messagesCollection = getMessagesCollection(env);
    const messagesRef = query(
      collection(database, messagesCollection, groupId, "messages"),
      orderBy("timestamp")
    );

    const unsubscribe = onSnapshot(messagesRef, (snap) => {
      const data: Message[] = [];

      snap.forEach((doc) => {
        data.push(doc.data() as Message);
      });

      setIsLoading(false);
      dispatch(addMessages(data));
      setTimeout(() => {
        dispatch(countUpdate({ groupId, userId: currentUserId }));
      }, 1000);
    });

    return () => unsubscribe();
  }, [groupId, currentUserId, dispatch, env, isConnected]);

  const onSubmitMessage = React.useCallback(
    (content: string) => {
      if (!content.trim()) {
        return;
      }
      setIsSending(true);

      dispatch(
        createMessage({
          content,
          groupId,
          type: MessageType.Text,
          onSuccess: () => setIsSending(false),
          onError: () => setIsSending(false),
        })
      );
    },
    [dispatch, groupId]
  );

  React.useEffect(() => {
    return () => {
      // Update activity when the screen is unmounted
      dispatch(setLastActivity({ groupId }));
    };
  }, [dispatch, groupId]);

  useAppState({
    onBackground: () => {
      // Update activity when the app is backgrounded
      dispatch(setLastActivity({ groupId }));
    },
  });

  const onMessagePress = React.useCallback(
    (type: MessageType) => (data: ViewData) => {
      setContentType(type);
      setHighligtedView(data);
      setIsOverlayOpen(true);
    },
    []
  );

  const closeOverlay = React.useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  React.useEffect(() => {
    if (!groupData && !isWeb) {
      navigation.goBack();
      return;
    }
  }, [navigation, groupData]);

  if (!groupData) {
    return null;
  }
  const { name, imageUri } = groupData;

  return (
    <Container>
      <Content behavior={Platform.OS === "ios" ? "padding" : "height"}>
        {!isWeb ? (
          <GroupHeaderBar
            groupId={groupId}
            uri={imageUri}
            name={name}
            hideChatIcon
            hideBackButton={isWeb}
          />
        ) : null}

        <OfflineBanner withTopMargin={false} />

        {!isLoading ? (
          <ChatContent
            ref={scrollViewRef}
            onContentSizeChange={onContentChange}
          >
            {messages.map(
              (
                { id, content, sender, timestamp, type, data, isDeleted },
                index
              ) => {
                const {
                  id: userId,
                  firstName,
                  imageUri: senderImageUri,
                } = sender;
                const previousMessage = messages[index - 1];
                const lastType = previousMessage?.type;
                const lastTimestamp = previousMessage?.timestamp;
                const lastSenderId = previousMessage?.sender?.id;
                // After 30s start displaying the header again
                const isFirst =
                  !lastTimestamp ||
                  timestamp - lastTimestamp > 30000 ||
                  userId !== lastSenderId ||
                  isActivityMessage(lastType);
                const shouldShowDayLabel =
                  !lastTimestamp || !isSameDay(timestamp, lastTimestamp);
                const isCurrentUser = userId === currentUserId;
                const image = isCurrentUser ? currentUserImage : senderImageUri;

                const showActivityMessage = [
                  MessageType.NewMember,
                  MessageType.MemberRemoved,
                ].includes(type);

                if (showActivityMessage) {
                  return (
                    <ActivityMessage
                      key={id}
                      username={firstName}
                      type={type}
                    />
                  );
                }

                return [
                  shouldShowDayLabel ? (
                    <DayLabel key={`${id}-label`} timestamp={timestamp} />
                  ) : null,
                  <ChatMessage
                    key={`${id}-message`}
                    isReversed={!isCurrentUser}
                    isFirst={isFirst}
                    text={content}
                    data={data}
                    name={firstName}
                    imageUri={image}
                    timestamp={timestamp}
                    messageId={id}
                    groupId={groupId}
                    isDeleted={isDeleted}
                    onPress={onMessagePress(type)}
                    onDismiss={closeOverlay}
                    type={type}
                  />,
                ];
              }
            )}
          </ChatContent>
        ) : (
          <LoaderBox>
            <ActivityIndicator />
          </LoaderBox>
        )}

        <InputBox
          onContentSizeChange={onContentChange}
          onSubmit={onSubmitMessage}
        />
      </Content>
      {isOverlayOpen ? (
        <ActionOverlay
          type={contentType}
          onPress={closeOverlay}
          {...highligtedView}
        />
      ) : null}
    </Container>
  );
};

export const GroupChat = ({
  route: {
    params: { groupId },
  },
}: Props) => {
  return <GroupChatComponent groupId={groupId} />;
};
