import React from "react";

import { Screen } from "~/components/screen";
import { RichText } from "~/screens/session/components/content/components/rich-text";
import { HeaderBar } from "~/components/header-bar";
import { colors } from "~/styles/theme";

import { content } from "./content";
import { Container } from "./styles";

export const CookiePolicy = () => {
  return (
    <Screen screenName="cookies-policy" hasFixedHeader>
      <HeaderBar iconColor={colors.black} withBackButton isSticky />
      <Container>
        <RichText extraMarkdownStyles={{ heading1: { marginTop: 24 } }}>
          {content}
        </RichText>
      </Container>
    </Screen>
  );
};
