import * as React from "react";

import { Icon, IconTypes } from "~/components/icon";

import {
  Container,
  IconContainer,
  Title,
  TextContent,
  TextRegular,
} from "./styles";
import { messages } from "./intl";

export const Content = React.memo(() => {
  return (
    <Container>
      <IconContainer>
        <Icon type={IconTypes.ChurchBig} size={50} />
      </IconContainer>

      <Title>{messages.title}</Title>
      <TextContent>
        <TextRegular>{messages.description}</TextRegular>
      </TextContent>
    </Container>
  );
});
