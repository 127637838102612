import React from "react";
import { TouchableOpacity, Keyboard } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import { ModalHeader } from "~/components/modal-header";
import { SettingsScreen } from "~/components/settings";
import { Alerts } from "~/components/alerts";
import { useAlert } from "~/components/alert";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  changePassword,
  preLogout,
  sendPasswordReset,
} from "~/state/user/actions";
import { getUserEmail } from "~/state/user/selectors";
import { colors } from "~/styles/theme";
import { useAlerts } from "~/state/alerts";
import * as routes from "~/constants/routes";

import {
  EditInput,
  ForgotPasswordLink,
  InputsContainer,
  HintContainer,
  HintText,
} from "./styles";
import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";
import { messages as signUpMessages } from "~/screens/sign-up/intl";
import { isValidPassword } from "~/utils/strings";

export type ChangePasswordProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.changePassword
>;

interface Props {
  onGoBack: () => void;
  onModalClose: () => void;
}

export const ChangePasswordComponent = ({ onGoBack, onModalClose }: Props) => {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(getUserEmail);
  const [isSaving, setIsSaving] = React.useState(false);
  const { pushAlert } = useAlerts();
  const { showAlert } = useAlert();

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const [currentPasswordError, setCurrentPasswordError] = React.useState<
    MessageDescriptor | undefined
  >();
  const [newPasswordError, setNewPasswordError] = React.useState<
    MessageDescriptor | undefined
  >();

  const hasDataChanged =
    currentPassword !== "" || newPassword !== "" || newPasswordConfirm !== "";

  const resetFields = React.useCallback(() => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
  }, []);

  const resetErrors = React.useCallback(() => {
    setCurrentPasswordError(undefined);
    setNewPasswordError(undefined);
  }, []);

  const onDonePress = React.useCallback(async () => {
    resetErrors();

    if (hasDataChanged) {
      if (!isValidPassword(newPassword)) {
        setNewPasswordError(messages.weakPassword);
        return;
      }

      if (newPassword !== newPasswordConfirm) {
        setNewPasswordError(messages.passwordsDoNotMatch);

        return;
      }

      setIsSaving(true);

      dispatch(
        changePassword({
          currentPassword,
          newPassword,
          newPasswordConfirm,
          onSuccess: () => {
            pushAlert({
              message: messages.passwordUpdated,
              color: colors.emerald600,
            });

            setIsSaving(false);
            resetFields();
          },
          onError: (errorMessage) => {
            if (errorMessage?.includes("auth/wrong-password")) {
              setCurrentPasswordError(messages.wrongPassword);

              setIsSaving(false);
            } else {
              pushAlert({
                message: messages.error,
                color: colors.red600,
              });

              setIsSaving(false);
            }
          },
        })
      );

      Keyboard.dismiss();

      return;
    }

    onGoBack();
  }, [
    currentPassword,
    dispatch,
    hasDataChanged,
    onGoBack,
    newPassword,
    newPasswordConfirm,
    pushAlert,
    resetErrors,
    resetFields,
  ]);

  const onCurrentPasswordChange = React.useCallback(
    (text: string) => setCurrentPassword(text),
    []
  );

  const onNewPasswordChange = React.useCallback(
    (text: string) => setNewPassword(text),
    []
  );

  const onNewPasswordConfirmChange = React.useCallback(
    (text: string) => setNewPasswordConfirm(text),
    []
  );

  const onForgotPasswordPress = React.useCallback(() => {
    showAlert(formatMessage(messages.resetPasswordConfirmation), "", [
      {
        text: formatMessage(messages.resetPasswordConfirmationCancel),
        style: "cancel",
      },
      {
        text: formatMessage(messages.resetPasswordConfirmationYes),
        onPress: () => {
          if (userEmail && userEmail !== "") {
            dispatch(
              sendPasswordReset({
                email: userEmail,
                onSuccess: () => {
                  showAlert(
                    "",
                    formatMessage(messages.resetPasswordEmailSent),
                    [
                      {
                        text: formatMessage(messages.resetPasswordEmailSentOk),
                        onPress: () => {
                          onModalClose();
                          setTimeout(() => dispatch(preLogout()), 1000);
                        },
                      },
                    ]
                  );
                },
                onError: () => {
                  pushAlert({
                    message: messages.error,
                    color: colors.red600,
                  });
                },
              })
            );
          }
        },
      },
    ]);
  }, [dispatch, onModalClose, pushAlert, userEmail, showAlert]);

  return (
    <>
      <ModalHeader
        onLeftPress={onGoBack}
        onRightPress={onDonePress}
        title={messages.title}
        rightButtonText={hasDataChanged ? messages.save : undefined}
        isSaving={isSaving}
      />

      <InputsContainer>
        <EditInput
          label={messages.currentPassword}
          value={currentPassword}
          onChangeText={onCurrentPasswordChange}
          secureTextEntry
          errorMessage={currentPasswordError}
        />

        <EditInput
          label={messages.newPassword}
          value={newPassword}
          onChangeText={onNewPasswordChange}
          secureTextEntry
          errorMessage={newPasswordError}
        />

        <EditInput
          label={messages.newPasswordConfirm}
          value={newPasswordConfirm}
          onChangeText={onNewPasswordConfirmChange}
          secureTextEntry
          errorMessage={newPasswordError}
        />
      </InputsContainer>

      <HintContainer>
        <HintText>{signUpMessages.hintPassword}</HintText>
      </HintContainer>

      <TouchableOpacity onPress={onForgotPasswordPress}>
        <ForgotPasswordLink>{messages.forgotPassword}</ForgotPasswordLink>
      </TouchableOpacity>

      <Alerts />
    </>
  );
};

export const ChangePassword = () => {
  const navigation = useNavigation<ChangePasswordProps["navigation"]>();

  const onModalClose = () => {
    navigation.getParent()?.goBack();
  };

  return (
    <SettingsScreen screenName="change-password">
      <ChangePasswordComponent
        onGoBack={navigation.goBack}
        onModalClose={onModalClose}
      />
    </SettingsScreen>
  );
};
