import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import { SectionTitle, SubsectionSubtitle } from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface TeachingTimeProps {
  teachingTime: LessonsContent["teachingTime"];
}

export const TeachingTime: React.FC<TeachingTimeProps> = ({ teachingTime }) => {
  const hasContent = React.useMemo(
    () =>
      teachingTime?.youWillNeed ||
      teachingTime?.gettingReady ||
      teachingTime?.description ||
      teachingTime?.teachingPoints,
    [teachingTime]
  );

  return hasContent ? (
    <>
      {teachingTime?.sectionTitle && (
        <SectionTitle>{teachingTime.sectionTitle}</SectionTitle>
      )}

      {teachingTime?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={teachingTime.youWillNeed}
          checklistId="teachingTime-youWillNeed"
        />
      )}

      {teachingTime?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={teachingTime.gettingReady}
          checklistId="teachingTime-gettingReady"
        />
      )}

      {Array.isArray(teachingTime?.gettingReadyMedia) &&
        teachingTime?.gettingReadyMedia.map((fileId, i) => (
          <Media key={`gettingReadyMedia-${i}`} fileId={fileId} />
        ))}

      {teachingTime?.description && (
        <RichText>{teachingTime.description}</RichText>
      )}

      {(teachingTime?.teachingPoints ?? []).map((teachingPoint, index) => (
        <React.Fragment key={`teachingPoints-${index}`}>
          {teachingPoint?.title && (
            <SubsectionSubtitle>{teachingPoint.title}</SubsectionSubtitle>
          )}

          {teachingPoint?.slideAThought && (
            <Thought thought={teachingPoint.slideAThought} />
          )}

          {Array.isArray(teachingPoint?.teachingPointSlideAMedia) &&
            teachingPoint?.teachingPointSlideAMedia.map((fileId, i) => (
              <Media key={`teachingPointSlideAMedia-${i}`} fileId={fileId} />
            ))}

          {teachingPoint?.description && (
            <RichText>{teachingPoint.description}</RichText>
          )}

          {teachingPoint?.slideBThought && (
            <Thought thought={teachingPoint.slideBThought} />
          )}

          {Array.isArray(teachingPoint?.teachingPointSlideBMedia) &&
            teachingPoint?.teachingPointSlideBMedia.map((fileId, i) => (
              <Media key={`teachingPointSlideBMedia-${i}`} fileId={fileId} />
            ))}

          {teachingPoint?.slideBDescription && (
            <RichText>{teachingPoint.slideBDescription}</RichText>
          )}

          {teachingPoint?.slideCThought && (
            <Thought thought={teachingPoint.slideCThought} />
          )}

          {Array.isArray(teachingPoint?.teachingPointSlideCMedia) &&
            teachingPoint?.teachingPointSlideCMedia.map((fileId, i) => (
              <Media key={`teachingPointSlideCMedia-${i}`} fileId={fileId} />
            ))}

          {teachingPoint?.slideCDescription && (
            <RichText>{teachingPoint.slideCDescription}</RichText>
          )}

          {teachingPoint?.conclusion && (
            <RichText>{teachingPoint.conclusion}</RichText>
          )}
        </React.Fragment>
      ))}

      <ContentIcon content={[teachingTime?.description || ""]} />
    </>
  ) : null;
};
