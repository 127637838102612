import React from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";
import { createMessages } from "~/state/chat/actions";
import { MessageData } from "~/state/chat/types";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { GroupAvatar } from "~/components/group-avatar";
import { AvatarSize } from "~/components/group-avatar/types";
import { Button } from "~/components/button";
import { MessageType } from "~/state/chat/types";
import { ButtonTypes } from "~/components/button/types";

import {
  Container,
  Content,
  Title,
  Item,
  ImageBox,
  Name,
  ContentBox,
} from "./styles";
import { messages } from "./intl";

interface Props {
  content: string;
  data?: MessageData;
  type: MessageType;
  onLoading: (arg0: boolean) => void;
  onShare: () => void;
  handleClose: () => void;
}

export const ShareContent = React.memo<Props>(
  ({ onLoading, content, data, type, onShare, handleClose }) => {
    const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);
    const groups = useAppSelector(getGroupsOrderedByTime);

    const { pushAlert } = useAlerts();
    const dispatch = useAppDispatch();

    const onSuccess = React.useCallback(() => {
      onLoading(false);

      pushAlert({
        message: messages.success,
        color: colors.emerald600,
      });
    }, [pushAlert, onLoading]);

    const onError = React.useCallback(() => {
      onLoading(false);
    }, [onLoading]);

    const onSelectGroup = React.useCallback(
      (id: string) => {
        if (selectedGroups.includes(id)) {
          setSelectedGroups(selectedGroups.filter((groupId) => groupId !== id));
        } else {
          setSelectedGroups([...selectedGroups, id]);
        }
      },
      [setSelectedGroups, selectedGroups]
    );

    const handleShare = React.useCallback(() => {
      onLoading(true);
      handleClose();
      dispatch(
        createMessages({
          groupIds: selectedGroups,
          type,
          data,
          content,
          onSuccess,
          onError,
        })
      );
    }, [
      dispatch,
      onSuccess,
      onError,
      onLoading,
      handleClose,
      selectedGroups,
      content,
      data,
      type,
    ]);

    const handleShareOther = React.useCallback(async () => {
      handleClose();
      onShare();
    }, [handleClose, onShare]);

    return (
      <Container>
        <Title>{messages.title}</Title>
        <Content>
          {groups.map(({ id, name }) => {
            const isSelected = selectedGroups.includes(id);
            const iconName = isSelected
              ? "checkbox-marked-circle"
              : "checkbox-blank-circle-outline";
            const iconColor = isSelected ? colors.emerald600 : colors.gray400;

            return (
              <Item key={id} onPress={() => onSelectGroup(id)}>
                <ContentBox>
                  <ImageBox>
                    <GroupAvatar text={name} size={AvatarSize.Regular} />
                  </ImageBox>
                  <Name>{name}</Name>
                </ContentBox>
                <Icon name={iconName} color={iconColor} size={28} />
              </Item>
            );
          })}
        </Content>
        <Button
          text={messages.button}
          onPress={handleShare}
          isDisabled={!selectedGroups.length}
        />
        <Button
          text={messages.shareOther}
          onPress={handleShareOther}
          type={ButtonTypes.Secondary}
        />
      </Container>
    );
  }
);
