import React from "react";
import { Alert } from "react-native";
import * as Device from "expo-device";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import { ModalHeader } from "~/components/modal-header";
import {
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { formatMessage } from "~/utils/translation";
import { useChangeEnvironment } from "~/utils/hooks/use-change-environment";
import { installations } from "~/utils/installations";
import { useAppSelector } from "~/state/hooks";
import { Environment } from "~/state/user/types";
import { getEnvironment } from "~/state/user/selectors";
import * as routes from "~/constants/routes";

import { messages } from "./intl";
import { InfoText } from "./styles";
import { isWeb } from "~/utils/platform";

type DevToolsProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.devTools
>;

export const DevTools = () => {
  const currentEnvironment = useAppSelector(getEnvironment);
  const navigation = useNavigation<DevToolsProps["navigation"]>();
  const { changeEnvironment } = useChangeEnvironment();
  const [localEnvironment, setLocalEnvironment] =
    React.useState(currentEnvironment);

  const [installId, setInstallId] = React.useState<string>("");
  const title =
    currentEnvironment === Environment.Staging
      ? messages.staging
      : messages.production;

  React.useEffect(() => {
    const getInstallationId = async () => {
      const install = installations();
      const id = await install.getId();
      setInstallId(id);
    };

    getInstallationId();
  }, [installations]);

  const onDeleteUserData = React.useCallback(() => {
    navigation.navigate(routes.deleteAccount, {
      deleteOnlyData: true,
    });
  }, [navigation]);

  const onDonePress = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  React.useEffect(() => {
    if (localEnvironment !== currentEnvironment && Device.isDevice) {
      // Reload the JS bundle after the environment change
      // Note: not available in the local development
      Alert.alert(
        formatMessage(messages.reloadTitle),
        formatMessage(messages.reloadDescription)
      );
      setLocalEnvironment(currentEnvironment);
    }
  }, [localEnvironment, currentEnvironment]);

  return (
    <SettingsScreen screenName="dev-tools">
      {!isWeb ? (
        <ModalHeader
          onLeftPress={onDonePress}
          onRightPress={onDonePress}
          title={messages.title}
        />
      ) : null}

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.userData)} />

        <SettingsListItem
          title={formatMessage(messages.deleteUserData)}
          type="navigate"
          isFirstItem
          isLastItem
          onPress={onDeleteUserData}
        />

        <InfoText>{messages.deleteUserDataDisclaimer}</InfoText>
      </SettingsListItemContainer>

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.environment)} />

        <SettingsListItem
          title={formatMessage(title)}
          type="select"
          isFirstItem
          isLastItem
          onPress={changeEnvironment}
        />

        <InfoText>Installations</InfoText>

        <InfoText selectable>{installId}</InfoText>

        <InfoText>{messages.disclaimer}</InfoText>

        {!Device.isDevice ? (
          <InfoText>{messages.devDisclaimer}</InfoText>
        ) : null}
      </SettingsListItemContainer>
    </SettingsScreen>
  );
};
