export const messages = {
  title: {
    id: "groups.empty.title",
    defaultMessage: "Engage the Bible with others",
  },
  description: {
    id: "groups.empty.description",
    defaultMessage:
      "Groups are a great way to build relationships, create community around discipleship, and stay connected with those who are engaging the Bible with you.",
  },
  buttonCreate: {
    id: "groups.empty.button-create",
    defaultMessage: "Create a new group",
  },
  buttonJoin: {
    id: "groups.empty.button-join",
    defaultMessage: "Join a group",
  },
};
