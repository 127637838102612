import * as React from "react";

import { LibraryContext } from "./context";

export const useLibrary = () => {
  const { planProgressId, setPlanProgressId } =
    React.useContext(LibraryContext);

  return {
    planProgressId,
    setPlanProgressId,
  };
};
