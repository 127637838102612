import styled from "styled-components/native";
import { Text3 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";

export const PreparationTime = styled(Text3)`
  font-family: SFProDisplayItalic;
  color: ${colors.black};
  line-height: ${lineHeights.lh3};
  margin: 0 ${spacers.ss5} ${spacers.ss6};
`;
