import { StyleSheet } from "react-native";
import styled from "styled-components/native";

import { Text2, Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  padding-top: ${spacers.ss6};
  padding-horizontal: ${spacers.ss8};
  padding-bottom: ${spacers.ss12};
`;

export const Content = styled.ScrollView`
  padding-top: ${spacers.ss4};
`;

export const Title = styled(Text2)`
  font-family: SFProDisplayMedium;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: ${spacers.ss4};
  color: ${colors.gray800};
`;

export const Item = styled.TouchableOpacity`
  flex-direction: row;
  margin-bottom: ${spacers.ss6};
  align-items: center;
`;

export const ImageBox = styled.View`
  margin-right: ${spacers.ss5};
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const Name = styled(Text3)`
  font-family: SFProDisplay;
`;

export const Overlay = styled.Pressable`
  background-color: ${colors.black};
  ${StyleSheet.absoluteFill};
  opacity: 0.3;
`;
