import * as React from "react";

import { TutorialState } from "./types";

export const initialState: TutorialState = {
  stepId: null,
  setStepId: (stepId: string) => {},
  position: null,
  setPosition: () => {},
  skipTutorial: () => {},
};

export const TutorialContext = React.createContext(initialState);
