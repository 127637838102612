export const messages = {
  heading: {
    id: "complete-day.heading",
    defaultMessage: "Day {{day}} Complete!",
  },
  headingDay0: {
    id: "complete-day.heading-day-0",
    defaultMessage: "{{heading}} Complete!",
  },
};
