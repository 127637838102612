export const messages = {
  title: {
    id: "app-settings.title",
    defaultMessage: "App Settings",
  },
  appLanguage: {
    id: "app-settings.app-language",
    defaultMessage: "App Language",
  },
  resourceLanguages: {
    id: "app-settings.resource-languages",
    defaultMessage: "Resource Languages",
  },
  english: {
    id: "app-settings.english",
    defaultMessage: "English",
  },
  spanish: {
    id: "app-settings.spanish",
    defaultMessage: "Spanish",
  },
  videoQuality: {
    id: "app-settings.video-quality",
    defaultMessage: "Video Quality",
  },
  fontSize: {
    id: "app-settings.font-size",
    defaultMessage: "Font Size",
  },
};
