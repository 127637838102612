import * as React from "react";
import {
  Text,
  View,
  Image,
  ImageProps,
  Pressable,
  GestureResponderEvent,
} from "react-native";
import Toast from "react-native-root-toast";
import * as Clipboard from "expo-clipboard";
import type { ASTNode, RenderRules } from "react-native-markdown-display";

import { useActionSheet } from "~/components/action-sheet";
import { formatMessage } from "~/utils/translation";

import { markdownStyles } from "./styles";
import { messages } from "./intl";

type Props = ImageProps & {
  src: string;
};

const getTextContentFromChildren = (children: React.ReactNode): string => {
  let textContent = "";

  const extractText = (node: React.ReactNode) => {
    if (typeof node === "string") {
      textContent += node;
    } else if (React.isValidElement(node)) {
      React.Children.forEach(node.props.children, (child) => {
        extractText(child);
      });
    }
  };

  React.Children.forEach(children, (child) => {
    extractText(child);
  });

  return textContent;
};

const SizedImage = ({ src, ...props }: Props) => {
  const [aspectRatio, setAspectRatio] = React.useState(1);

  Image.getSize(src, (width, height) => {
    setAspectRatio(width / height);
  });

  return (
    <Image
      {...props}
      source={{ uri: src }}
      style={{ ...markdownStyles.image, aspectRatio }}
      resizeMode="contain"
    />
  );
};

const renderImage = (
  node: ASTNode,
  _children: React.ReactNode[],
  _parent: ASTNode[]
) => {
  const { src, alt } = node.attributes;

  const imageProps: ImageProps = {
    source: { uri: src },
  };

  if (alt) {
    imageProps.accessible = true;
    imageProps.accessibilityLabel = alt;
  }

  return <SizedImage {...imageProps} src={src} key={node.key} />;
};

const renderSup = (
  node: ASTNode,
  _children: React.ReactNode[],
  _parent: ASTNode[]
) => {
  return (
    <View key={node.key} style={{ alignItems: "flex-start" }}>
      <Text style={markdownStyles.superscript}>{_children}</Text>
    </View>
  );
};

const BodyView = ({ children }: any) => {
  const [isSelected, setIsSelected] = React.useState(false);
  const { showActionSheetWithOptions } = useActionSheet();

  const options = [messages.copy, messages.cancel].map((message) =>
    formatMessage(message)
  );

  const handleOnPress = (event: GestureResponderEvent) => {
    setIsSelected(true);
    const textContent = getTextContentFromChildren(children);

    showActionSheetWithOptions(
      {
        options,
        destructiveButtonIndex: 1,
        cancelButtonIndex: options.length - 1,
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case 0: {
            Clipboard.setStringAsync(textContent);
            Toast.show(formatMessage(messages.copied), {
              duration: Toast.durations.LONG,
              position: Toast.positions.BOTTOM,
              shadow: true,
              animation: true,
              hideOnPress: true,
              delay: 0,
            });
            return;
          }
          case 1: {
            // TODO: add share functionality later on
            return;
          }
        }
        setIsSelected(false);
      },
      // @ts-ignore
      event
    );
  };

  return (
    <Pressable onLongPress={handleOnPress}>
      <>{children}</>
    </Pressable>
  );
};

const renderBody = (
  node: ASTNode,
  _children: React.ReactNode[],
  _parent: ASTNode[],
  styles: any
) => {
  return (
    <View key={node.key} style={styles._VIEW_SAFE_body}>
      <BodyView>{_children}</BodyView>
    </View>
  );
};

export const markdownRules: RenderRules = {
  /**
   * Due to a problem with the image component the library uses, we need to override the image rule to be able to use the images
   * properly in the simulators
   */
  image: renderImage,
  sup: renderSup,
  body: renderBody,
};
