import { createNavigationContainerRef } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { isWeb } from "~/utils/platform";

export const navigationRef = createNavigationContainerRef();

type ScreenOptions = {
  screen: string;
  params?: Record<string, any>;
  initial?: boolean;
};

export const navigateTo = (
  navigation: NativeStackNavigationProp<any, string>,
  route: string,
  screenOptions: ScreenOptions
) => {
  if (!isWeb) {
    navigation.navigate(route, screenOptions);
    return;
  }
  navigation.navigate(screenOptions.screen, screenOptions?.params || {});
};
