export const messages = {
  alreadyAccount: {
    id: "login-box.already-account",
    defaultMessage: "Already have an account?",
  },
  logIn: {
    id: "login-box.log-in",
    defaultMessage: "Log In",
  },
};
