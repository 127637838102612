import { Platform } from "react-native";
import { SagaIterator } from "redux-saga";
import { takeLatest, call, select } from "redux-saga/effects";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import Constants from "expo-constants";

import {
  doc,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { getIsFirstInstalled } from "~/utils/installation";

import { saveToken } from "./actions";
import { SaveTokenAction } from "./types";
import { getUsersCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { setIsUserReady } from "../user/actions";

export function* getUserData(userId: string): SagaIterator {
  try {
    const usersCollection = getUsersCollection();

    const userRef = doc(database, usersCollection, userId);
    // @ts-ignore
    const userSnapshot = yield call(getDoc, userRef);
    if (!userSnapshot.exists()) {
      return;
    }
    return userSnapshot.data();
  } catch (e) {
    yield call(handleError, e);
  }
}

export const saveTokenFn = function* ({
  payload: { token },
}: SaveTokenAction): SagaIterator {
  if (!token) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const usersCollection = getUsersCollection();

    const userId = yield select(getUserId);
    const userData = yield call(getUserData, userId);
    const hasBeenInstalled = yield call(getIsFirstInstalled);

    // Reset the token on first installation. This will prevent from
    // having the obsolete tokens after the app uninstall
    if (!hasBeenInstalled) {
      const data = {
        tokens: [token],
      };
      // @ts-ignore
      yield call(updateDoc, doc(database, usersCollection, userId), data);
      return;
    }

    // Token already exists in the profile
    if (userData?.tokens?.includes(token)) {
      return;
    }
    const data = {
      tokens: arrayUnion(token),
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, usersCollection, userId), data);
  } catch (e) {
    yield call(handleError, e);
  }
};

export const saveTokenSaga = function* () {
  yield takeLatest(saveToken.type, saveTokenFn);
};

// We need to be careful to remove the token in the right moment,
// Before we lose access to the current userId
export const removeTokenSaga = function* (): SagaIterator {
  try {
    // Expo >0.38 does not support notifications on web
    if (!Device.isDevice || Platform.OS === "web") {
      return;
    }
    const env = yield select(getEnvironment);
    const usersCollection = getUsersCollection();

    const userId = yield select(getUserId);
    const projectId = Constants?.expoConfig?.extra?.eas?.projectId;
    const { data: token } = yield call(Notifications.getExpoPushTokenAsync, {
      projectId,
    });
    const userData = yield call(getUserData, userId);

    // Token does not exist in the profile
    if (!userData?.tokens?.includes(token)) {
      return;
    }
    const data = {
      tokens: arrayRemove(token),
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, usersCollection, userId), data);
  } catch (e) {
    yield call(handleError, e);
  }
};
