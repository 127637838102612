import * as React from "react";
import { FlamelinkFile } from "~/state/flamelink";
import { makeMediaUrl, MediaSize } from "~/state/flamelink/utils";
import { Preview, SlideContainer, SlideTitle } from "./styles";

interface SlideProps {
  onPress?(): void;
  file: FlamelinkFile;
  downloadedFileResourceLocation?: string;
}

export const Slide: React.FC<SlideProps> = ({
  onPress,
  file,
  downloadedFileResourceLocation,
}) => {
  const previewUri = file?.preview?.file
    ? makeMediaUrl(file.preview.file, MediaSize.Small)
    : undefined;

  return (
    <SlideContainer onPress={onPress}>
      <SlideTitle>{file.title}</SlideTitle>

      {downloadedFileResourceLocation ? (
        <Preview
          source={{ uri: downloadedFileResourceLocation }}
          resizeMode="contain"
        />
      ) : previewUri ? (
        <Preview source={{ uri: previewUri }} resizeMode="contain" />
      ) : null}
    </SlideContainer>
  );
};
