import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { messages as sessionMessages } from "~/screens/session/intl";
import { Checklist } from "../../../checklist";
import { SectionTitle, SubsectionSubtitle } from "../../../common";
import { Media } from "../../../media";
import { Prayer } from "../../../prayer";
import { RichText } from "../../../rich-text";
import { Scripture } from "../../../scripture";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface PrayerAndResponseProps {
  prayerAndResponse: LessonsContent["prayerAndResponse"];
}

export const PrayerAndResponse: React.FC<PrayerAndResponseProps> = ({
  prayerAndResponse,
}) => {
  const hasContent = React.useMemo(
    () =>
      prayerAndResponse?.intro ||
      prayerAndResponse?.youWillNeed ||
      prayerAndResponse?.gettingReady ||
      prayerAndResponse?.description ||
      prayerAndResponse?.prayer ||
      prayerAndResponse?.faithFact ||
      prayerAndResponse?.faithVerse ||
      prayerAndResponse?.conclusion ||
      prayerAndResponse?.conclusionContent ||
      prayerAndResponse?.conclusionPrayer ||
      prayerAndResponse?.conclusionMedia?.length ||
      prayerAndResponse?.epilogue ||
      prayerAndResponse?.epilogueMedia?.length ||
      prayerAndResponse?.postscript ||
      prayerAndResponse?.salvationPrayer ||
      prayerAndResponse?.content ||
      prayerAndResponse?.mediaRepeater?.length,
    [prayerAndResponse]
  );

  return hasContent ? (
    <>
      {prayerAndResponse?.sectionTitle && (
        <SectionTitle>{prayerAndResponse.sectionTitle}</SectionTitle>
      )}

      {prayerAndResponse?.intro && (
        <RichText>{prayerAndResponse.intro}</RichText>
      )}

      {prayerAndResponse?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={prayerAndResponse.youWillNeed}
          checklistId="prayerAndResponse-youWillNeed"
        />
      )}

      {prayerAndResponse?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={prayerAndResponse.gettingReady}
          checklistId="prayerAndResponse-gettingReady"
        />
      )}

      {prayerAndResponse?.description && (
        <RichText>{prayerAndResponse.description}</RichText>
      )}

      {prayerAndResponse?.prayerThought && (
        <Thought thought={prayerAndResponse.prayerThought} />
      )}

      {Array.isArray(prayerAndResponse?.prayerMedia) &&
        prayerAndResponse?.prayerMedia.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {prayerAndResponse?.content && (
        <RichText>{prayerAndResponse.content}</RichText>
      )}

      {Array.isArray(prayerAndResponse?.mediaRepeater) &&
        prayerAndResponse?.mediaRepeater.map(
          ({ media: mrMedia, content: mrContent }, i) => (
            <React.Fragment key={`mediaRepeater-${i}`}>
              {Array.isArray(mrMedia) &&
                mrMedia.map((fileId, j) => (
                  <Media key={`mediaRepeater-media-${j}`} fileId={fileId} />
                ))}

              {mrContent && <RichText>{mrContent}</RichText>}
            </React.Fragment>
          )
        )}

      {prayerAndResponse?.prayer && (
        <Prayer prayer={prayerAndResponse.prayer} />
      )}

      {Array.isArray(prayerAndResponse?.media) &&
        prayerAndResponse?.media.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {prayerAndResponse?.conclusion && (
        <RichText>{prayerAndResponse.conclusion}</RichText>
      )}

      {prayerAndResponse?.conclusionContent && (
        <RichText>{prayerAndResponse.conclusionContent}</RichText>
      )}

      {prayerAndResponse?.conclusionPrayer && (
        <Prayer prayer={prayerAndResponse?.conclusionPrayer} />
      )}

      {Array.isArray(prayerAndResponse?.conclusionMedia) &&
        prayerAndResponse?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {prayerAndResponse?.epilogue && (
        <RichText>{prayerAndResponse.epilogue}</RichText>
      )}

      {Array.isArray(prayerAndResponse?.epilogueMedia) &&
        prayerAndResponse?.epilogueMedia.map((fileId, index) => (
          <Media key={`epilogueMedia-${index}`} fileId={fileId} />
        ))}

      {prayerAndResponse?.postscript && (
        <RichText>{prayerAndResponse.postscript}</RichText>
      )}

      {prayerAndResponse?.faithVerse && (
        <PrayerAndResponseFaithVerse prayerAndResponse={prayerAndResponse} />
      )}

      {prayerAndResponse?.faithFact && (
        <PrayerAndResponseFaithFact prayerAndResponse={prayerAndResponse} />
      )}

      {prayerAndResponse?.salvationPrayer && (
        <RichText>{prayerAndResponse.salvationPrayer}</RichText>
      )}

      {Array.isArray(prayerAndResponse?.salvationPrayerMedia) &&
        prayerAndResponse?.salvationPrayerMedia.map((fileId, index) => (
          <Media key={`salvationPrayerMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon
        content={[
          prayerAndResponse?.intro || "",
          prayerAndResponse?.description || "",
          prayerAndResponse?.content || "",
          prayerAndResponse?.mediaRepeater || "",
          prayerAndResponse?.conclusion || "",
          prayerAndResponse?.conclusionContent || "",
          prayerAndResponse?.epilogue || "",
          prayerAndResponse?.postscript || "",
          prayerAndResponse?.salvationPrayer || "",
        ]}
      />
    </>
  ) : null;
};

const PrayerAndResponseFaithVerse: React.FC<PrayerAndResponseProps> = ({
  prayerAndResponse,
}) => {
  return (
    <>
      {prayerAndResponse?.faithVerse?.title &&
        prayerAndResponse?.faithVerse?.introduction && (
          <SubsectionSubtitle>
            {prayerAndResponse.faithVerse.title}
          </SubsectionSubtitle>
        )}

      {prayerAndResponse?.faithVerse?.introduction && (
        <RichText>{prayerAndResponse.faithVerse.introduction}</RichText>
      )}

      {Array.isArray(prayerAndResponse?.faithVerse?.faithVerseVideoMedia) &&
        prayerAndResponse?.faithVerse?.faithVerseVideoMedia.map(
          (fileId, index) => (
            <Media key={`faithVerseVideoMedia-${index}`} fileId={fileId} />
          )
        )}

      {prayerAndResponse?.faithVerse?.scripture && (
        <Scripture scripture={prayerAndResponse.faithVerse?.scripture} />
      )}

      {prayerAndResponse?.faithVerse?.description && (
        <RichText>{prayerAndResponse.faithVerse.description}</RichText>
      )}
    </>
  );
};

const PrayerAndResponseFaithFact: React.FC<PrayerAndResponseProps> = ({
  prayerAndResponse,
}) => {
  return (
    <>
      {prayerAndResponse?.faithFact?.title &&
        prayerAndResponse?.faithFact?.introduction && (
          <SubsectionSubtitle>
            {prayerAndResponse.faithFact.title}
          </SubsectionSubtitle>
        )}

      {prayerAndResponse?.faithFact?.introduction && (
        <RichText>{prayerAndResponse.faithFact.introduction}</RichText>
      )}

      {prayerAndResponse?.faithFact?.faithFactSlideMedia?.length ? (
        <Media fileId={prayerAndResponse.faithFact.faithFactSlideMedia[0]} />
      ) : null}

      {prayerAndResponse?.faithFact?.description && (
        <RichText>{prayerAndResponse.faithFact.description}</RichText>
      )}
    </>
  );
};
