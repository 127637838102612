import * as React from "react";
import { Animated } from "react-native";

import { Button } from "~/components/button";
import { TextInput } from "~/components/text-input";
import { createGroup, editGroup } from "~/state/groups";
import { useAlerts } from "~/state/alerts";
import { useAppDispatch } from "~/state/hooks";
import { asyncLogEvent, events } from "~/utils/analytics";

import { ChangeImage } from "../change-image";
import { SectionBox } from "../../styles";
import { messages } from "./intl";
import { colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

interface Props {
  isCreateMode: boolean;
  groupId?: string;
  planId?: string;
  groupName?: string;
  imageUri?: string;
  onClose: () => void;
  onSuccess?: (groupId: string) => void;
  setGroupId: (groupId: string) => void;
}

export const GroupCreateEdit = React.memo<Props>(
  ({
    isCreateMode,
    groupId,
    planId,
    groupName = "",
    imageUri: defaultImageUri = "",
    onClose,
    onSuccess: handleSuccess,
    setGroupId,
  }) => {
    const [name, setName] = React.useState(groupName);
    const [imageUri, setImageUri] = React.useState(defaultImageUri);
    const [isLoading, setIsLoading] = React.useState(false);
    const opacity = React.useRef(new Animated.Value(1)).current;
    const { pushAlert } = useAlerts();
    const dispatch = useAppDispatch();

    const fadeOut = React.useCallback(
      (cb: () => void) =>
        Animated.timing(opacity, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start(cb),
      [opacity]
    );

    const buttonText = isCreateMode
      ? messages.buttonCreate
      : messages.buttonSave;

    const onSuccess = React.useCallback(
      (id: string) => {
        setIsLoading(false);

        if (isCreateMode) {
          fadeOut(() => {
            if (!isWeb) {
              setGroupId(id);
            } else {
              onClose();
            }
            if (typeof handleSuccess === "function") {
              handleSuccess(id);
            }
          });
        } else {
          onClose();
          pushAlert({
            message: messages.editSuccess,
            color: colors.green600,
          });
        }
      },
      [isCreateMode, fadeOut, setGroupId, onClose, pushAlert, handleSuccess]
    );

    const onError = React.useCallback(() => {
      setIsLoading(false);
      onClose();
      pushAlert({
        message: messages.saveError,
      });
    }, [onClose, pushAlert]);

    const onPress = React.useCallback(() => {
      setIsLoading(true);
      if (groupId) {
        asyncLogEvent(events.GROUP_EDITED);
        dispatch(
          editGroup({
            groupId,
            name,
            imageUri,
            onSuccess,
            onError,
          })
        );
      } else {
        asyncLogEvent(events.GROUP_CREATED);
        dispatch(
          createGroup({
            name,
            imageUri,
            planId,
            onSuccess,
            onError,
          })
        );
      }
    }, [groupId, dispatch, name, imageUri, onSuccess, onError, planId]);

    return (
      <Animated.View style={{ opacity }}>
        <ChangeImage
          groupName={name}
          imageUri={imageUri}
          setImageUri={setImageUri}
          isDisabled={isWeb}
        />

        <SectionBox>
          <TextInput
            label={messages.placeholder}
            value={name}
            onChangeText={setName}
          />
        </SectionBox>

        <Button
          text={buttonText}
          onPress={onPress}
          isDisabled={!name || isLoading}
          isLoading={isLoading}
        />
      </Animated.View>
    );
  }
);
