import { Platform, Animated } from "react-native";
import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";

const BACK_BUTTON_SIZE = 48;

export const Container = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: ${Platform.select({
    ios: spacers.ss11,
    android: spacers.ss10,
    default: spacers.ss8,
  })};
`;

export const AnimatedContainer = styled(Animated.View)``;

export const IconContainer = styled.TouchableOpacity`
  height: ${BACK_BUTTON_SIZE}px;
  width: ${BACK_BUTTON_SIZE}px;
  border-radius: ${BACK_BUTTON_SIZE / 2}px;
  background-color: ${colors.white};
  margin-left: ${spacers.ss5};
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
