export const messages = {
  title: {
    id: "landing.title",
    defaultMessage:
      "Start by downloading the Bible Engagement Project mobile app",
  },
  footerText: {
    id: "landing.footer-text",
    defaultMessage: "Have the app already?",
  },
  footerLink: {
    id: "landing.footer-link",
    defaultMessage: "Open BEP",
  },
};
