import * as React from "react";
import { TextInput } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

import { messages } from "./intl";
import { NoteViewEdit, SavePayload } from "~/components/note-view-edit";
import { MessageType } from "~/state/chat/types";
import { PersonalNotesViewProps } from "~/navigation/notes-stack/types";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getPersonalNoteById } from "~/state/personal-notes/selectors";
import { asyncLogEvent, events } from "~/utils/analytics";
import { useAlerts } from "~/state/alerts";
import {
  addPersonalNote,
  deletePersonalNote,
  editPersonalNote,
} from "~/state/personal-notes/actions";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";

interface Props {
  noteId?: string;
  isCreateMode?: boolean;
  lastUpdated?: number;
  title?: string;
  text?: string;
  onSave: (data: SavePayload) => void;
  onDelete?: () => void;
}

export const PersonalNotesView = React.memo<Props>(
  ({
    noteId,
    title = "",
    isCreateMode,
    lastUpdated,
    text = "",
    onSave,
    onDelete,
  }) => {
    const backButtonText = isCreateMode
      ? messages.genericBackButton
      : messages.backButton;

    return (
      <NoteViewEdit
        screenName="personal-note-view"
        title={title}
        text={text}
        isCreateMode={isCreateMode}
        lastUpdated={lastUpdated}
        backButtonText={backButtonText}
        type={MessageType.NotePersonal}
        onSave={onSave}
        onDelete={onDelete}
        data={{
          title,
          text,
        }}
        canEditTitle
      >
        {null}
      </NoteViewEdit>
    );
  }
);

interface AddProps {
  onDone: () => void;
}

export const PersonalNoteAddComponent = ({ onDone }: AddProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const onSave = React.useCallback(
    ({ title = "", value: text }: SavePayload) => {
      if (isLoading) {
        return;
      }

      if (!title && !text) {
        onDone();
        return;
      }

      setIsLoading(true);
      dispatch(
        addPersonalNote({
          title,
          text,
          onSuccess: () => {
            asyncLogEvent(events.PERSONAL_NOTE_ADD);
            pushAlert({
              message: genericMessages.saveSuccess,
              color: colors.emerald600,
            });
            setIsLoading(false);
            onDone();
          },
          onError: () => {
            pushAlert({ message: genericMessages.error });
            setIsLoading(false);
          },
        })
      );
    },
    [dispatch, pushAlert, onDone, isLoading]
  );

  return <PersonalNotesView onSave={onSave} isCreateMode />;
};

interface ViewEditProps {
  noteId: string;
  handleDelete: () => void;
}

export const PersonalNoteViewEditComponent = ({
  noteId,
  handleDelete,
}: ViewEditProps) => {
  const note = useAppSelector((state) => getPersonalNoteById(state, noteId));

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const onSave = ({ title, value: text }: SavePayload) => {
    if (!title && !text) {
      onDelete();
      return;
    }

    dispatch(
      editPersonalNote({
        noteId,
        title: title || "",
        text,
        onSuccess: () => {
          asyncLogEvent(events.PERSONAL_NOTE_EDIT);
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
        },
        onError: () => {
          pushAlert({
            message: genericMessages.error,
          });
        },
      })
    );
  };

  const onDelete = () => {
    handleDelete();
    dispatch(
      deletePersonalNote({
        noteId,
        onSuccess: () => {
          asyncLogEvent(events.PERSONAL_NOTE_DELETE);
          pushAlert({
            message: genericMessages.noteDeleted,
            color: colors.emerald600,
          });
        },
        onError: () => {
          pushAlert({
            message: genericMessages.error,
          });
        },
      })
    );
  };

  const title = note?.title || "";
  const text = note?.text || "";
  const lastUpdated = note?.lastUpdated;

  return (
    <PersonalNotesView
      noteId={noteId}
      lastUpdated={lastUpdated}
      title={title}
      text={text}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export const PersonalNoteViewEdit = () => {
  const {
    params: { noteId },
  } = useRoute<PersonalNotesViewProps["route"]>();
  const navigation = useNavigation();

  const handleDelete = () => {
    navigation.goBack();
  };

  return (
    <PersonalNoteViewEditComponent
      noteId={noteId}
      handleDelete={handleDelete}
    />
  );
};

export const PersonalNoteAdd = () => {
  const navigation = useNavigation();

  const onDone = () => {
    navigation.goBack();
  };
  return <PersonalNoteAddComponent onDone={onDone} />;
};
