import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { TutorialProvider } from "~/state/tutorial";

import { linkingConfiguration } from "./linking-config";
import { PostAuthNavigator } from "./post-auth";
import { PreAuthNavigator } from "./pre-auth";
import { useAppSelector } from "~/state/hooks";
import { getHasProfile } from "~/state/user/selectors";
import { NotificationHandler } from "~/utils/push-notifications";
import { CookiesConsent } from "~/components/cookies-consent";
import { InAppNotification } from "~/components/in-app-notification";
import { isWeb } from "~/utils/platform";
import { navigationRef } from "~/utils/navigation";

import { formatDocumentTitle } from "./utils";

export const Navigation = React.memo(() => {
  const hasProfile = useAppSelector(getHasProfile);

  return (
    <NavigationContainer
      linking={linkingConfiguration}
      ref={navigationRef}
      documentTitle={{
        formatter: (options, route) =>
          formatDocumentTitle(options?.title, route?.name),
      }}
    >
      <TutorialProvider>
        {!!hasProfile ? (
          <NotificationHandler>
            <PostAuthNavigator />
          </NotificationHandler>
        ) : (
          <PreAuthNavigator />
        )}
        {isWeb ? <CookiesConsent /> : null}
        <InAppNotification />
      </TutorialProvider>
    </NavigationContainer>
  );
});
