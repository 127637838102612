import React from "react";
import { EmptyGroupImage } from "~/components/empty-group/group-image";
import { messages } from "./intl";
import {
  Container,
  ResetButton,
  ResetButtonText,
  Title,
} from "./styles";

interface NoPlansFoundProps {
  resetText: string;
  onResetPress(): void;
}

export const NoPlansFound: React.FC<NoPlansFoundProps> = ({
  resetText,
  onResetPress,
}) => {
  return (
    <Container>
      <EmptyGroupImage />

      <Title>{messages.title}</Title>

      <ResetButton onPress={onResetPress}>
        <ResetButtonText>{resetText}</ResetButtonText>
      </ResetButton>
    </Container>
  );
};
