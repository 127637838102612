export const messages = {
  title: {
    id: "downloads.title",
    defaultMessage: "Downloads",
  },
  deleteAll: {
    id: "downloads.delete-all",
    defaultMessage: "Delete All Files",
  },
  cancel: {
    id: "downloads.cancel",
    defaultMessage: "Cancel",
  },
  bibleVersion: {
    id: "downloads.bible-version",
    defaultMessage: "Bible: {{abbreviation}}",
  },
};
