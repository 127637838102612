import * as React from "react";

import { useAppSelector } from "~/state/hooks";
import { getUserId } from "~/state/user/selectors";

import { Button } from "../buttons";
import { Screens } from "../..//types";

import {
  Container,
  Header,
  Title,
  Description,
  Image,
  ButtonBox,
} from "./styles";
import { messages } from "./intl";

interface Props {
  setScreen: (screen: Screens) => void;
  amount: string;
  name: string;
}

export const PaymentSuccess = React.memo<Props>(
  ({ amount, name, setScreen }) => {
    const uid = useAppSelector(getUserId);
    const title = amount ? messages.title : messages.titleEmpty;
    const nextScreen = uid ? Screens.Intro : Screens.AmountSelection;

    return (
      <Container>
        <Header>{messages.header}</Header>

        <Title>{{ ...title, values: { amount, name } }}</Title>

        <Image source={require("./payment-success.png")} contentFit="contain" />

        <Description>{messages.description}</Description>

        <ButtonBox>
          <Button
            text={messages.done}
            onPress={() => setScreen(nextScreen)}
            isActive
          />
        </ButtonBox>
      </Container>
    );
  }
);
