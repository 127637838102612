export const messages = {
  shift: {
    id: "input-box.shift",
    defaultMessage: "Shift + Return",
  },
  newLine: {
    id: "input-box.new-line",
    defaultMessage: "to add a new line",
  },
};
