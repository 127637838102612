import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";

import * as routes from "~/constants/routes";
import { HeaderBar } from "~/components/header-bar";
import { HeaderTitle } from "~/components/header-title";
import { InputCode } from "~/components/input-code";
import { Button } from "~/components/button";
import { colors } from "~/styles/theme";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { joinGroup } from "~/state/groups";
import { getUserId } from "~/state/user/selectors";
import { useAlerts } from "~/state/alerts";
import { asyncLogEvent, events } from "~/utils/analytics";
import { isWeb } from "~/utils/platform";

import {
  Container,
  Content,
  TitleBox,
  Description,
  InputBox,
  ErrorText,
  ButtonBox,
  PasteButton,
  PasteText,
} from "./styles";
import { messages } from "./intl";

const CODE_LENGTH = 6;

interface Props {
  onSuccess: (groupId: string) => void;
  onError: () => void;
}

export const GroupJoinComponent = ({ onSuccess, onError }: Props) => {
  const [code, setCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const userId = useAppSelector(getUserId);
  const { pushAlert, alerts } = useAlerts();

  const dispatch = useAppDispatch();

  const onConfirm = React.useCallback(() => {
    setIsLoading(true);
    dispatch(
      joinGroup({
        inviteCode: code.toUpperCase(),
        userId,
        onSuccess: (groupId: string) => {
          asyncLogEvent(events.GROUP_JOINED);
          setIsLoading(false);
          onSuccess(groupId);
        },
        onError: (message: TextType) => {
          setIsLoading(false);
          pushAlert({ message, isSilent: true });
          setCode("");
          onError();
        },
      })
    );
  }, [dispatch, onSuccess, onError, pushAlert, code, userId]);

  const pasteFromClipboard = React.useCallback(async () => {
    const text = await Clipboard.getStringAsync();

    setCode(text);
  }, []);

  React.useEffect(() => {
    if (code.length === CODE_LENGTH) {
      onConfirm();
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <Container>
      {!isWeb ? <HeaderBar iconColor={colors.black} withBackButton /> : null}

      <Content>
        <TitleBox>
          <HeaderTitle>{messages.title}</HeaderTitle>
        </TitleBox>

        <Description>{messages.description}</Description>

        <InputBox>
          <InputCode
            codeLength={CODE_LENGTH}
            value={code}
            onChange={setCode}
            isLoading={isLoading}
          />
        </InputBox>

        <PasteButton onPress={pasteFromClipboard}>
          <PasteText>{messages.pasteFromClipboard}</PasteText>
        </PasteButton>

        {alerts.map(({ id, message }) => (
          <ErrorText key={id}>{message}</ErrorText>
        ))}
      </Content>
      <ButtonBox>
        <Button
          text={messages.button}
          onPress={onConfirm}
          isLoading={isLoading}
        />
      </ButtonBox>
    </Container>
  );
};

export const GroupJoin = () => {
  const navigation = useNavigation();

  const onSuccess = (groupId: string) => {
    navigation.goBack();
    navigation.navigate(routes.groupResources, { groupId });
  };

  const onError = () => {};

  return <GroupJoinComponent onSuccess={onSuccess} onError={onError} />;
};
