import React from "react";
import { useNavigation } from "@react-navigation/native";
import * as routes from "~/constants/routes";

import { useModal } from "~/state/modal/hook.web";
import { LibraryFiltersComponent } from "~/screens/library-filters";
import { LibrarySearchComponent } from "~/screens/library-search";

import { LibraryProps } from "../..";
import { LibraryHeaderContent } from "./content";
import { ModalBox } from "./styles";

export function LibraryHeader() {
  const navigation = useNavigation<LibraryProps["navigation"]>();

  const { showModal, hideModal } = useModal();

  const onPlanPress = React.useCallback(
    (planId: string) => {
      hideModal();
      navigation.navigate(routes.plan, { planId });
    },
    [navigation, hideModal]
  );

  const onPressSearch = React.useCallback(() => {
    showModal(
      <ModalBox>
        <LibrarySearchComponent
          onCancel={hideModal}
          onPlanPress={onPlanPress}
        />
      </ModalBox>
    );
  }, [showModal, hideModal, onPlanPress]);

  const onFilterPress = React.useCallback(() => {
    showModal(
      <ModalBox>
        <LibraryFiltersComponent onGoBack={hideModal} />
      </ModalBox>
    );
  }, [showModal, hideModal]);

  return (
    <LibraryHeaderContent
      onPressSearch={onPressSearch}
      onFilterPress={onFilterPress}
    />
  );
}
