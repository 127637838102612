import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import type { ChecklistState } from "./types";

const getState = (state: RootState): ChecklistState => state.checklist;

export const getChecklists = createSelector(
  getState,
  (checklistState) => checklistState.checklists
);

export const getChecklistById = (parentId: string, checklistId: string) =>
  createSelector(
    [getChecklists],
    (checklists) => checklists?.[parentId]?.[checklistId] ?? { items: [] }
  );
