import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { RichText } from "../../../rich-text";
import { Media } from "../../../media";
import { Prayer } from "../../../prayer";
import { ContentIcon } from "../../../content-icon";

interface ApplicationProps {
  application: LessonsContent["application"];
}

export const Application: React.FC<ApplicationProps> = ({ application }) => {
  const hasContent = React.useMemo(
    () =>
      application?.introduction ||
      application?.applicationMedia?.length ||
      application?.conclusion ||
      application?.prayer,
    [application]
  );

  return hasContent ? (
    <>
      {application?.sectionTitle && (
        <SectionTitle>{application.sectionTitle}</SectionTitle>
      )}

      {application?.introduction && (
        <RichText>{application.introduction}</RichText>
      )}

      {Array.isArray(application?.applicationMedia) &&
        application?.applicationMedia.map((fileId, index) => (
          <Media key={`applicationMedia-${index}`} fileId={fileId} />
        ))}

      {application?.conclusion && <RichText>{application.conclusion}</RichText>}

      {application?.prayer && <Prayer prayer={application.prayer} />}

      <ContentIcon
        content={[
          application?.introduction || "",
          application?.conclusion || "",
        ]}
      />
    </>
  ) : null;
};
