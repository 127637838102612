import * as React from "react";

import { useAppSelector } from "~/state/hooks";
import { getSessionFileIds } from "~/state/flamelink";
import { NoDownloadsFound } from "~/components/no-downloads-found";
import { DownloadItem } from "~/components/download-item";

import { ListContainer } from "./styles";
import { DownloadAllSession } from "./download-session";

interface SessionDownloadsProps {
  sessionId: string;
  audienceId: string;
  files?: string[];
}

export const SessionDownloads = React.memo<SessionDownloadsProps>(
  ({ sessionId, audienceId, files: defaultFiles }) => {
    const [shouldDownloadAll, setShouldDownloadAll] = React.useState(false);
    const sessionFiles = useAppSelector((state) =>
      getSessionFileIds(state, sessionId)
    );
    const files = defaultFiles || sessionFiles;

    const renderItems = () => {
      return (
        <>
          {files.map((fileId) => (
            <DownloadItem
              key={fileId}
              fileId={fileId}
              sessionId={sessionId}
              audienceId={audienceId}
              isAutoDownload={shouldDownloadAll}
            />
          ))}
        </>
      );
    };

    if (!files.length) return <NoDownloadsFound />;

    return (
      <ListContainer>
        {renderItems()}

        <DownloadAllSession
          sessionId={sessionId}
          onPress={() => setShouldDownloadAll(true)}
          isLoading={shouldDownloadAll}
        />
      </ListContainer>
    );
  }
);
