import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

interface Props {
  top: number;
  padding: number;
}

interface TipProps {
  size: number;
  left: number;
  isBottom: boolean;
}

export const Container = styled.View<Props>`
  padding: ${spacers.ss4} ${spacers.ss6};
  position: absolute;
  top: ${({ top }) => top}px;
  right: ${({ padding }) => padding}px;
  left: ${({ padding }) => padding}px;
  background-color: ${colors.gray800};
  border-radius: 8px;
`;

export const Text = styled(Text2)`
  color: ${colors.white};
  font-family: SFCompact;
  line-height: 20px;
`;

export const Tip = styled.View<TipProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;
  ${({ isBottom, size }) =>
    isBottom ? `top: -${size}px;` : `bottom: -${size}px;`}
  right: ${({ left }) => left}px;
  align-items: center;
  justify-content: center;
`;

export const ButtonBox = styled.View<{ isSingle?: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${spacers.ss4};
  padding-bottom: ${spacers.ss3};
  ${({ isSingle }) => isSingle && "align-self: flex-end"}
`;

export const ButtonText = styled(Text2)<{ isActive?: boolean }>`
  font-family: SFProDisplayBold;
  color: ${({ isActive }) => (isActive ? colors.indigo400 : colors.gray400)};
  text-transform: uppercase;
  font-size: 12px;
`;
