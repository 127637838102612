import { SortEnum } from "./types";

export const getSearchConditions = (
  searchConditions: string = ""
): { sortMethod: string; searchTerm: string } => {
  const conditions = searchConditions.split(":");
  return {
    sortMethod: conditions[0] || SortEnum.DESC,
    searchTerm: conditions[1] || "",
  };
};
