export const messages = {
  title: {
    id: "giving.history.title",
    defaultMessage: "Giving History",
  },
  total: {
    id: "giving.history.help",
    defaultMessage: "Total Giving {{total}}",
  },
  disclaimer: {
    id: "giving.history.disclaimer",
    defaultMessage:
      "You will be emailed at {{email}} once your annual giving statement is ready to download.",
  },
  empty: {
    id: "giving.history.empty",
    defaultMessage: "You don't have any donations yet",
  },
};
