import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { PostAuthParamList } from "~/navigation/post-auth";

import { getGroupsMessages } from "~/state/groups/selectors";
import { useAppSelector } from "~/state/hooks";
import { GroupChatComponent } from "~/screens/group-chat";
import { HeaderBar } from "~/components/header-bar";
import { HeaderTitle } from "~/components/header-title";
import { Screen } from "~/components/screen";
import { colors } from "~/styles/theme";
import { WebListContainer } from "~/styles/web";

import { MessagesList } from "./list";
import {
  HeaderContainer,
  WebContainer,
  NavigationContainer,
  ContentContainer,
} from "./styles";
import { messages } from "./intl";

export type ChatProps = NativeStackScreenProps<PostAuthParamList, "group.chat">;

export const Messages = () => {
  const data = useAppSelector(getGroupsMessages);

  const [groupId, setGroupId] = React.useState(data[0]?.groupId);

  return (
    <Screen screenName="messages" isView backgroundColor={colors.gray75}>
      <HeaderBar
        iconColor={colors.black}
        withCastButton={false}
        withChatButton={false}
      />

      <WebContainer>
        <NavigationContainer>
          <HeaderContainer hasBorder={false}>
            <HeaderTitle>{messages.title}</HeaderTitle>
          </HeaderContainer>
          <WebListContainer>
            <MessagesList
              data={data}
              handleOpenChat={setGroupId}
              currentGroupId={groupId}
            />
          </WebListContainer>
        </NavigationContainer>
        <ContentContainer>
          {groupId ? (
            <GroupChatComponent groupId={groupId} key={groupId} />
          ) : null}
        </ContentContainer>
      </WebContainer>
    </Screen>
  );
};
