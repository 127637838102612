import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const LoaderContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

export const Text = styled(Text3)`
  margin-top: ${spacers.ss6};
`;
