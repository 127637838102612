export const messages = {
  newUsers: {
    id: "stats.new-users",
    defaultMessage: "New Users",
  },
  newChurches: {
    id: "stats.new-churches",
    defaultMessage: "New Churches",
  },
  newGroups: {
    id: "stats.new-groups",
    defaultMessage: "New Groups",
  },
};
