import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { useAppNetInfo, useAppSelector } from "~/state/hooks";
import { getGroupById } from "~/state/groups/selectors";
import { ModalHeader } from "~/components/modal-header";
import { Alerts } from "~/components/alerts";
import { Screen } from "~/components/screen";
import { isAndroid, isWeb } from "~/utils/platform";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";

import { GroupCreateEdit } from "./components/create-edit";
import { GroupOverview } from "./components/overview";

import { Content, Spacer } from "./styles";
import { messages } from "./intl";

type Params = {
  groupId?: string;
  planId?: string;
  isEditMode?: boolean;
  onGoBack: () => void;
  onSuccess?: (groupId: string) => void;
};

interface Props {
  route: {
    params: Params;
  };
}

export const GroupModalComponent = React.memo<Params>(
  ({
    groupId: defaultGroupId = "",
    planId = "",
    isEditMode: isDefaultEditMode,
    onGoBack,
    onSuccess,
  }) => {
    const [groupId, setGroupId] = React.useState(defaultGroupId);
    const [isEditMode, setIsEditMode] = React.useState(!!isDefaultEditMode);
    const data = useAppSelector((state) => getGroupById(state, groupId));

    const { isConnected } = useAppNetInfo();
    const { pushAlert } = useAlerts();

    const handleOnPress = React.useCallback(() => {
      onGoBack();
    }, [onGoBack]);

    const handleClose = React.useCallback(() => {
      onGoBack();
    }, [onGoBack]);

    const setEditMode = React.useCallback(() => {
      if (!isConnected) {
        pushAlert({
          message: genericMessages.featureUnavailableOffline,
          color: colors.gray600,
        });

        return;
      }

      setIsEditMode(true);
    }, [isConnected, pushAlert]);

    const isCreateMode = !groupId;
    const modalTitle = isCreateMode
      ? messages.titleCreate
      : isEditMode
      ? messages.titleEdit
      : undefined;

    React.useEffect(() => {
      if (!data && !isCreateMode) {
        onGoBack();
        return;
      }
    }, [onGoBack, data, isCreateMode]);

    return (
      <>
        {!isWeb || isCreateMode ? (
          <ModalHeader
            title={modalTitle}
            onLeftPress={handleClose}
            onRightPress={handleOnPress}
          />
        ) : (
          <Spacer />
        )}

        <Content>
          {isCreateMode || isEditMode ? (
            <GroupCreateEdit
              groupId={groupId}
              planId={planId}
              isCreateMode={isCreateMode}
              groupName={data?.name}
              imageUri={data?.imageUri}
              onClose={handleClose}
              setGroupId={setGroupId}
              onSuccess={onSuccess}
            />
          ) : (
            <GroupOverview
              groupName={data?.name}
              imageUri={data?.imageUri}
              inviteCode={data?.inviteCode}
              groupId={groupId}
              onEdit={setEditMode}
            />
          )}
        </Content>
        <Alerts />
      </>
    );
  }
);

export const GroupModal = React.memo<Props>(
  ({ route: { params: { groupId = "", planId = "" } = {} } }) => {
    const navigation = useNavigation();

    return (
      <Screen screenName="group-modal" isView={!isAndroid}>
        <GroupModalComponent
          groupId={groupId}
          planId={planId}
          onGoBack={navigation.goBack}
        />
      </Screen>
    );
  }
);
