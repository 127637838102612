import * as Updates from "expo-updates";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { logError } from "./logger";
import { isIOS } from "./platform";

const authStorageKey =
  "firebase:authUser:AIzaSyBv0jXx8H4vdQn5Kpx3BEw702HvJw5_ZQI:[DEFAULT]";
const storeKey = "persist:root";

const getStorageValue = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key);
    return JSON.parse(value || "");
  } catch (e) {
    return {};
  }
};

export const migrateAuth = async () => {
  try {
    const authStorageValue = await getStorageValue(authStorageKey);
    const persistedStoreValue = await getStorageValue(storeKey);

    if (!persistedStoreValue?.user) {
      return;
    }

    const userData = JSON.parse(persistedStoreValue?.user)?.user;

    if (Object.keys(authStorageValue).length > 0) {
      return;
    }

    if (!userData) {
      return;
    }

    await AsyncStorage.setItem(authStorageKey, JSON.stringify(userData));
    setTimeout(() => {
      if (isIOS) {
        Updates.reloadAsync();
      }
    }, 3500);
  } catch (e) {
    logError("Migration failed");
  }
};
