import styled from "styled-components/native";

import { Text4 } from "~/components/text";
import { spacers } from "~/styles/theme";
import { webModalStyles } from "~/utils/web";

export const Container = styled.ScrollView.attrs({
  contentContainerStyle: webModalStyles,
})``;

export const SectionText = styled(Text4)`
  font-family: SFProDisplayBold;
  padding: ${spacers.ss7} ${spacers.ss6};
`;
