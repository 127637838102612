import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgApplePay = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 54 22"
    {...props}
  >
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M9.934 2.959c-.621.732-1.615 1.309-2.609 1.226-.124-.99.363-2.04.932-2.69C8.878.742 9.965.206 10.845.165c.104 1.03-.3 2.041-.91 2.794Zm.9 1.422c-.875-.05-1.674.263-2.32.515-.414.162-.766.3-1.034.3-.3 0-.666-.145-1.077-.308-.539-.213-1.155-.457-1.8-.445-1.481.02-2.858.856-3.614 2.186-1.553 2.66-.403 6.598 1.098 8.763.735 1.072 1.615 2.247 2.774 2.206.51-.02.877-.174 1.257-.335.437-.184.891-.376 1.6-.376.685 0 1.12.186 1.536.366.397.17.777.334 1.343.324 1.2-.02 1.956-1.072 2.691-2.144.794-1.15 1.142-2.274 1.195-2.444l.006-.02a.262.262 0 0 0-.028-.013c-.265-.121-2.292-1.045-2.312-3.523-.019-2.08 1.608-3.133 1.864-3.3a.849.849 0 0 0 .031-.02c-1.035-1.525-2.65-1.69-3.21-1.732Zm8.314 13.083V1.392h6.056c3.127 0 5.311 2.144 5.311 5.278 0 3.134-2.226 5.3-5.393 5.3h-3.469v5.494h-2.505Zm2.505-13.97h2.889c2.174 0 3.416 1.155 3.416 3.186 0 2.031-1.242 3.196-3.427 3.196h-2.878V3.495Zm17.134 12.042c-.662 1.258-2.122 2.052-3.696 2.052-2.329 0-3.954-1.382-3.954-3.464 0-2.062 1.573-3.248 4.482-3.423l3.127-.186V9.63c0-1.31-.86-2.02-2.392-2.02-1.263 0-2.184.649-2.37 1.638h-2.257c.072-2.082 2.04-3.598 4.7-3.598 2.868 0 4.731 1.495 4.731 3.815v8H38.84v-1.928h-.052Zm-3.023.144c-1.335 0-2.184-.639-2.184-1.618 0-1.01.818-1.598 2.38-1.69l2.786-.176v.907c0 1.505-1.284 2.577-2.982 2.577Zm13.086 2.413c-1.004 2.814-2.153 3.742-4.597 3.742-.186 0-.807-.02-.952-.062v-1.927c.155.02.538.04.735.04 1.108 0 1.729-.463 2.112-1.67l.228-.71L42.13 5.803h2.62L47.7 15.3h.052l2.95-9.495h2.547l-4.4 12.289Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgApplePay;
