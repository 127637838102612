import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PersonalNotesState, PersonalNote, EditPersonalNote } from "./types";

const initialState: PersonalNotesState = {
  notes: []
};

export const personalNotesSlice = createSlice({
  name: "personalNotes",
  initialState,
  reducers: {
    updateNotes: (state, action: PayloadAction<PersonalNote[]>) => {
      state.notes = action.payload;
    },
    addNote: (state, action: PayloadAction<PersonalNote>) => {
      state.notes = [...state.notes, action.payload];
    },
    deleteNote: (state, action: PayloadAction<string>) => {
      state.notes = state.notes.filter(({ id }) => id !== action.payload);
    },
    editNote: (state, action: PayloadAction<EditPersonalNote>) => {
      state.notes = state.notes.map((note) => {
        if (note.id !== action.payload.noteId) {
          return note;
        }
        return {
          ...note,
          title: action.payload.title,
          text: action.payload.text,
          lastUpdated: Date.now()
        };
      });
    }
  }
});

export const { updateNotes, addNote, deleteNote, editNote } =
  personalNotesSlice.actions;

export default personalNotesSlice.reducer;
