import { createAction } from "@reduxjs/toolkit";

import {
  GetSessionNotesPayload,
  AddSessionNotePayload,
  EditSessionNotePayload,
  DeleteSessionNotePayload,
} from "./types";

export const getSessionNotes =
  createAction<GetSessionNotesPayload>("getSessionNotes");

export const addSessionNote =
  createAction<AddSessionNotePayload>("addSessionNote");

export const editSessionNote =
  createAction<EditSessionNotePayload>("editSessionNote");

export const deleteSessionNote =
  createAction<DeleteSessionNotePayload>("deleteSessionNote");

export const getSessionNotesMeta = createAction<GetSessionNotesPayload>(
  "getSessionNotesMeta"
);
