import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ContentProgressState } from "./types";

export const initialState: ContentProgressState = {
  progress: undefined,
};

export const contentProgressSlice = createSlice({
  name: "contentProgress",
  initialState,
  reducers: {
    setContentProgress(
      state,
      action: PayloadAction<ContentProgressState["progress"] | undefined>
    ) {
      state.progress = action.payload;
    },
  },
});

export const { setContentProgress } = contentProgressSlice.actions;
