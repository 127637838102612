import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgLibrary = (props) => (
  <Svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m29.34 25.25-1.332-5.14-4.2-15.452a.693.693 0 0 0-.846-.492h-.002l-5.536 1.43-.26-.287a.693.693 0 0 0-.502-.229H3.333a.693.693 0 0 0-.693.693v21.334a.693.693 0 0 0 .693.693h13.334a.693.693 0 0 0 .693-.693V16.44l1.258-.166 2.933 10.96a.694.694 0 0 0 .694.513l.047-.002.047.006a.894.894 0 0 0 .161.003l6.388-1.706a.693.693 0 0 0 .41-.305.76.76 0 0 0 .042-.491v-.001ZM3.986 26.468h5.28V22.52h-5.28v3.947Zm0-5.334h5.28v-9.28h-5.28v9.28Zm0-10.666h5.28V6.52h-5.28v3.947Zm6.666 16h5.28V22.52h-5.28v3.947Zm0-5.334h5.28v-9.28h-5.28v9.28Zm0-10.666h5.28V6.52h-5.28v3.947Zm11.958-4.785L17.51 7.055l1.026 3.81 5.103-1.373-1.027-3.81Zm-3.714 6.52 2.397 8.97 5.102-1.373L24 10.829 18.897 12.2Zm2.705 10.306 1.026 3.81 5.103-1.373-1.026-3.81-5.103 1.373Z"
      fill="currentColor"
    />
  </Svg>
);

export default SvgLibrary;
