export const messages = {
  cancel: {
    id: "pif.header.cancel",
    defaultMessage: "Cancel",
  },
  back: {
    id: "pif.header.back",
    defaultMessage: "Back",
  },
  account: {
    id: "pif.header.account",
    defaultMessage: "Account",
  },
  help: {
    id: "pif.header.help",
    defaultMessage: "Help",
  },
  save: {
    id: "pif.header.save",
    defaultMessage: "Save",
  },
};
