export function getFileIcon(type?: string) {
  if (type?.includes("presentation") || type?.includes("image")) {
    return "image-outline";
  } else if (type?.includes("video")) {
    return "play-circle-outline";
  } else if (type?.includes("pdf")) {
    return "file-document-outline";
  } else {
    return "text-box-outline";
  }
}
