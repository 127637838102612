import * as React from "react";
import type { ReflectSection } from "~/state/flamelink/types";
import { SectionTitle, SubsectionTitle } from "../common";
import { RichText } from "../rich-text";
import { Question } from "../question";
import { Scripture } from "../scripture";
import { Footnotes } from "../footnotes";
import { ContentIcon } from "../content-icon";

interface ReflectProps {
  reflect: ReflectSection;
}

export const Reflect: React.FC<ReflectProps> = ({ reflect }) => {
  return (
    <>
      <SectionTitle>{reflect?.sectionTitle}</SectionTitle>

      <RichText>{reflect?.content}</RichText>

      {Array.isArray(reflect?.questions) &&
        reflect?.questions.map(({ question, uniqueKey, content }, index) => (
          <React.Fragment key={`content-repeater-${index}`}>
            <Question question={{ question, uniqueKey }} />

            <RichText>{content}</RichText>
          </React.Fragment>
        ))}

      {Array.isArray(reflect?.subsectionRepeater) &&
        reflect?.subsectionRepeater.map((subsection, index) => (
          <React.Fragment key={`subsection-${index}`}>
            <SubsectionTitle>{subsection.title}</SubsectionTitle>

            {subsection?.scripture && (
              <Scripture scripture={subsection.scripture} />
            )}

            <RichText>{subsection.intro}</RichText>

            {Array.isArray(subsection?.questions) &&
              subsection?.questions.map(
                ({ question, uniqueKey, content }, i) => (
                  <React.Fragment key={`subsection-questions-${i}`}>
                    <Question question={{ question, uniqueKey }} />

                    <RichText>{content}</RichText>
                  </React.Fragment>
                )
              )}
          </React.Fragment>
        ))}

      <Footnotes data={reflect?.footnotes} />

      <ContentIcon content={[reflect?.content, reflect?.questions]} />
    </>
  );
};
