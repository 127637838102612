import { Platform } from "react-native";

export function getFormattedOSForFeedback() {
  switch (Platform.OS) {
    case "android":
      return "Android";
    case "ios":
      return "iOS";
    case "web":
      return "Web";
    default:
      return "Unsupported";
  }
}
