import * as React from "react";
import * as FileSystem from "~/utils/file-system";
import { ActivityIndicator } from "react-native";

import { useAppDispatch } from "~/state/hooks";
import { getFullBible, removeBibleData } from "~/state/bible/actions";
import { asyncLogEvent, events } from "~/utils/analytics";
import { BIBLE_DIRECTORY } from "~/constants";
import { handleError } from "~/utils/logger";

interface Props {
  abbreviation: string;
  abbreviationLocal?: string;
  name: string;
  isActive: boolean;
  onPress: (arg0: string) => void;
  isConnected: boolean | null;
}

import {
  Item,
  IconBox,
  TextBox,
  DownloadButton,
  Title,
  Description,
  CheckIcon,
  DownloadIcon,
} from "./styles";
import { isWeb } from "~/utils/platform";

export const BibleItem = React.memo<Props>(
  ({
    abbreviation,
    abbreviationLocal,
    name,
    isActive,
    onPress,
    isConnected,
  }) => {
    const [isDownloaded, setIsDownloaded] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    const booksUri = `${BIBLE_DIRECTORY}/${abbreviation}/books.json`;

    React.useEffect(() => {
      FileSystem.getInfoAsync(booksUri)
        .then(({ exists }) => {
          setIsDownloaded(exists);
        })
        .catch(handleError);
    }, []);

    const onDownload = React.useCallback(
      (bibleId: string) => {
        setIsLoading(true);
        dispatch(
          getFullBible({
            bibleId,
            onSuccess: () => {
              asyncLogEvent(events.BIBLE_DOWNLOAD);
              setIsDownloaded(true);
              setIsLoading(false);
            },
            onError: () => setIsLoading(false),
          })
        );
      },
      [dispatch]
    );

    const onRemove = React.useCallback(
      (bibleId: string) => {
        setIsLoading(true);
        dispatch(
          removeBibleData({
            bibleId,
            onSuccess: () => {
              asyncLogEvent(events.BIBLE_DELETE_DOWNLOADED);
              setIsDownloaded(false);
              setIsLoading(false);
            },
            onError: () => setIsLoading(false),
          })
        );
      },
      [dispatch]
    );

    const handlePress = React.useCallback(
      (item: string) => {
        if (!isConnected && !isDownloaded) {
          return;
        }
        onPress(item);
      },
      [onPress, isConnected, isDownloaded]
    );

    const onAction = React.useCallback(
      (bibleId: string) => {
        isDownloaded ? onRemove(bibleId) : onDownload(bibleId);
      },
      [isDownloaded, onPress, onRemove, onDownload]
    );

    return (
      <Item isActive={isActive} isDisabled={!isConnected && !isDownloaded}>
        <IconBox isActive={isActive}>{isActive ? <CheckIcon /> : null}</IconBox>
        <TextBox onPress={() => handlePress(abbreviation)}>
          <Title>{abbreviationLocal || abbreviation}</Title>
          <Description>{name}</Description>
        </TextBox>
        {isLoading ? (
          <ActivityIndicator />
        ) : !isWeb ? (
          <DownloadButton onPress={() => onAction(abbreviation)}>
            <DownloadIcon isDownloaded={isDownloaded} />
          </DownloadButton>
        ) : null}
      </Item>
    );
  }
);
