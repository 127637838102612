import styled from "styled-components/native";

import { Text1, Text2, Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const Container = styled.TouchableOpacity<{
  isLast: boolean;
  isActive: boolean;
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${spacers.ss6} ${spacers.ss7};
  border-bottom-width: ${({ isLast }) => (isLast ? 0 : 1)}px;
  border-bottom-color: ${colors.gray200};
  background-color: ${({ isActive }) =>
    isActive && isWeb ? colors.gray50 : colors.white};
  padding-left: 16px;
  border-left-width: 4px;
  border-left-color: ${({ isActive }) =>
    isActive ? colors.primaryBlue : colors.transparent};
`;

export const SideBox = styled.View`
  padding-right: ${spacers.ss5};
`;

export const Content = styled.View`
  flex: 1;
  padding-right: ${spacers.ss4};
`;

export const Title = styled(Text3)`
  font-family: SFProDisplayMedium;
  margin-bottom: ${spacers.ss2};
`;

export const Description = styled(Text2)`
  color: ${colors.gray500};
`;

export const DescriptionBold = styled(Text2)`
  font-family: SFProDisplayBold;
  color: ${colors.gray800};
`;

export const RightTextBox = styled.View`
  align-self: flex-start;
  margin-left: ${spacers.ss2};
`;

export const RightText = styled(Text2)`
  color: ${colors.gray500};
`;

export const Badge = styled.View`
  border-radius: 16px;
  margin-top: ${spacers.ss3};
  padding: ${spacers.ss3};
  width: 30px;
  background-color: ${colors.gray300};
  align-items: center;
  justify-content: center;
  align-self: flex-end;
`;

export const BadgeText = styled(Text1)`
  font-family: SFProDisplayMedium;
  color: ${colors.white};
`;

export const MetaText = styled(Text1)`
  color: ${colors.gray400};
  margin-top: ${spacers.ss3};
`;
