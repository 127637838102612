import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import NetInfo from "@react-native-community/netinfo";

import { handleError } from "~/utils/logger";

import { getEnvironment, getUserId } from "../user/selectors";
import {
  startPlan,
  resumePlan,
  navigateToSessionItem,
  navigateToNextSession,
  navigateToPrevSession,
  loadInitialProgress,
} from "./actions";
import { getPlanProgressesByUserId } from "./side-effects";
import { setContentProgress } from "./slice";
import { LoadInitialProgressAction } from "./types";

// WIP: These are placeholder sagas. We need to move the control flow here to have a
// single source of truth for the content progress state.
// Here we should trigger the navigation and ensure the progress always gets updated
// the progress that is currently in API should be moved to sagas too - as it became complex

function* handleStartPlan(): SagaIterator {}

function* handleResumePlan(): SagaIterator {}

/**
 * Used to navigate to a specific session item in the plan.
 * Can be used from the ToC or from the bubbles, for example.
 */
function* handleNavigateToSessionItem(): SagaIterator {}

/**
 * Navigates to the next session in the plan. It also navigates through the volumes when needed.
 */
function* handleNavigateToNextSession(): SagaIterator {}

/**
 * Navigates to the previous session in the plan. It also navigates through the volumes when needed.
 */
function* handleNavigateToPrevSession(): SagaIterator {}

function* handleLoadInitialProgress({
  payload,
}: LoadInitialProgressAction): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) return;

    const env = yield select(getEnvironment);
    const savedUserId = yield select(getUserId);
    const userId = payload?.userId || savedUserId;

    const progress = yield call(getPlanProgressesByUserId, userId, env);

    // sometimes when there is no connection, the progress is undefined
    // we don't want to reset the progress in this case.
    if (!progress || progress.length === 0) {
      if (payload?.allowReset) {
        yield put(setContentProgress([]));
      }
      return;
    }

    yield put(setContentProgress(progress));
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* contentProgressSaga() {
  yield takeLatest(startPlan.type, handleStartPlan);
  yield takeLatest(resumePlan.type, handleResumePlan);
  yield takeLatest(navigateToSessionItem.type, handleNavigateToSessionItem);
  yield takeLatest(navigateToNextSession.type, handleNavigateToNextSession);
  yield takeLatest(navigateToPrevSession.type, handleNavigateToPrevSession);
  // progress state related sagas
  yield takeLatest(loadInitialProgress.type, handleLoadInitialProgress);
}
