import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BibleNotesState,
  BibleNote,
  EditBibleNote,
  HighlightData
} from "./types";

const initialState: BibleNotesState = {
  notes: [],
  highlights: []
};

export const bibleNotesSlice = createSlice({
  name: "bibleNotes",
  initialState,
  reducers: {
    updateNotes: (state, action: PayloadAction<BibleNote[]>) => {
      state.notes = action.payload;
    },
    addNote: (state, action: PayloadAction<BibleNote>) => {
      state.notes = [...state.notes, action.payload];
    },
    deleteNote: (state, action: PayloadAction<string>) => {
      state.notes = state.notes.filter(({ id }) => id !== action.payload);
    },
    editNote: (state, action: PayloadAction<EditBibleNote>) => {
      state.notes = state.notes.map((note) => {
        if (note.id !== action.payload.noteId) {
          return note;
        }
        return {
          ...note,
          text: action.payload.text,
          lastUpdated: Date.now()
        };
      });
    },
    updateHighlights: (state, action: PayloadAction<HighlightData[]>) => {
      state.highlights = action.payload;
    },
    addEditHighlight: (state, action: PayloadAction<HighlightData>) => {
      const chapterDataExists = state.highlights.find(
        (data) => data.id === action.payload.id
      );
      if (!chapterDataExists) {
        state.highlights = [...state.highlights, action.payload];
      } else {
        state.highlights = state.highlights.map((data) => {
          if (data.id !== action.payload.id) {
            return data;
          }
          return action.payload;
        });
      }
    }
  }
});

export const {
  updateNotes,
  addNote,
  deleteNote,
  editNote,
  updateHighlights,
  addEditHighlight
} = bibleNotesSlice.actions;

export default bibleNotesSlice.reducer;
