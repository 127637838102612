import styled from "styled-components/native";

import { Text7 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View``;

export const CodeContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CodeBox = styled.View<{ isLoading: boolean; isActive: boolean }>`
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  margin-horizontal: ${spacers.ss2};
  border-radius: 8px;
  border-width: 1px;
  border-color: ${({ isActive }) =>
    isActive ? colors.primaryBlue : colors.gray200};
  height: 70px;
  width: 50px;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

export const Code = styled(Text7)`
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
`;

export const InputBox = styled.View`
  height: 0px;
  opacity: 0;
`;

export const Input = styled.TextInput`
  margin-left: ${spacers.ss4};
`;
