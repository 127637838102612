import * as Device from "expo-device";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import * as routes from "~/constants/routes";
import { navigationRef } from "~/utils/navigation";
import { isAndroid } from "~/utils/platform";

export const GRANTED_PERMISSION = "granted";

Notifications.setNotificationHandler({
  handleNotification: async (notification) => {
    // We should not show alert if we already in the corresponding group chat
    const groupId = notification?.request?.content?.data?.groupId;
    const currentRoute = navigationRef?.getCurrentRoute();
    const isChatScreen = currentRoute?.name === routes.groupChat;
    const isSameAsNotification = currentRoute?.params?.groupId === groupId;

    const shouldHide = groupId && isChatScreen && isSameAsNotification;

    return {
      shouldShowAlert: !shouldHide,
      shouldPlaySound: false,
      shouldSetBadge: true,
    };
  },
});

const requestPermissionsIfNeededAsync = async (
  existingStatus: Notifications.NotificationPermissionsStatus["status"]
) => {
  if (existingStatus !== GRANTED_PERMISSION) {
    const { status } = await Notifications.requestPermissionsAsync();
    return status;
  }

  return existingStatus;
};

export const registerForPushNotificationsAsync = async () => {
  // Expo >0.38 does not support notifications on web
  if (!Device.isDevice || Platform.OS === "web") {
    return;
  }

  if (isAndroid) {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();

  const finalStatus = await requestPermissionsIfNeededAsync(existingStatus);

  if (finalStatus !== GRANTED_PERMISSION) {
    return;
  }

  const projectId = Constants?.expoConfig?.extra?.eas?.projectId;
  const token = (
    await Notifications.getExpoPushTokenAsync({
      projectId,
    })
  ).data;

  return token;
};
