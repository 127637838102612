import { LinearGradient } from "expo-linear-gradient";
import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { Text2, Text3 } from "~/components/text";
import { SCALING_FACTORS } from "~/components/text/constants";
import { colors, spacers, lineHeights, fontSizes } from "~/styles/theme";
import { FontSize } from "~/state/settings";
import { pxToNumber } from "~/utils/theme";

export const getQuestionTextStyles = (fontScale?: FontSize) => {
  const scalingFactor = SCALING_FACTORS[fontScale || FontSize.Medium];

  const pxScaled = (px: number) => px * scalingFactor;

  return {
    body: {
      color: colors.gray800,
      letterSpacing: -pxToNumber(spacers.ss1) / 2,
      paddingHorizontal: pxToNumber(spacers.ss5),
      lineHeight: pxScaled(pxToNumber(lineHeights.lh3)),
      fontSize: pxScaled(pxToNumber(fontSizes.fs3)),
    },
  };
};

export const QuestionContainer = styled.View`
  margin: ${spacers.ss5} 0;
  padding: ${spacers.ss5};
  background-color: ${colors.gray100};
`;

export const QuestionTitle = styled(Text2)`
  font-family: SFProDisplayBold;
  color: ${colors.gray800};
  letter-spacing: ${pxToNumber(spacers.ss1) / 2}px;
  text-transform: uppercase;
`;

export const QuestionTextWrapper = styled.View`
  flex-direction: row;
  padding: ${spacers.ss5} 0;
`;

export const QuestionWebWrapper = styled.View`
  display: block;
  width: 100%;
`;

export const QuestionGradientSeparator = styled(LinearGradient).attrs({
  colors: ["#2563EB", "#EB9025"],
  start: { x: 0.1, y: 0.2 },
})`
  width: 2px;
`;

export const AnswerActionContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const AnswerActionText = styled(Text3)`
  font-family: SFPro;
  padding: ${spacers.ss5} ${spacers.ss4};
  color: ${colors.primaryBlue};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
`;

export const webModalContainerStyles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: pxToNumber(spacers.ss5),
  },
}).container;
