import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import {
  SectionTitle,
  SubsectionTitle,
  SubsectionSubtitle,
} from "../../../common";
import { Checklist } from "../../../checklist";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { Prayer } from "../../../prayer";
import { ContentIcon } from "../../../content-icon";

interface GroupConnectionProps {
  groupConnection: LessonsContent["groupConnection"];
}

export const GroupConnection: React.FC<GroupConnectionProps> = ({
  groupConnection,
}) => {
  const hasContent = React.useMemo(
    () =>
      groupConnection?.youWillNeed ||
      groupConnection?.gettingReady ||
      groupConnection?.gettingReadyMedia ||
      groupConnection?.media?.length ||
      groupConnection?.description ||
      groupConnection?.conclusion ||
      groupConnection?.conclusionMedia ||
      groupConnection?.prayer,
    [groupConnection]
  );

  return hasContent ? (
    <>
      {groupConnection?.sectionTitle && (
        <SectionTitle>{groupConnection.sectionTitle}</SectionTitle>
      )}

      {groupConnection?.subheading && (
        <SubsectionTitle>{groupConnection.subheading}</SubsectionTitle>
      )}

      {groupConnection?.subtitle && (
        <SubsectionSubtitle>{groupConnection.subtitle}</SubsectionSubtitle>
      )}

      {groupConnection?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={groupConnection.youWillNeed}
          checklistId="groupConnection-youWillNeed"
        />
      )}

      {groupConnection?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={groupConnection.gettingReady}
          checklistId="groupConnection-gettingReady"
        />
      )}

      {Array.isArray(groupConnection?.gettingReadyMedia) &&
        groupConnection?.gettingReadyMedia.map((fileId, index) => (
          <Media key={`gettingReadyMedia-${index}`} fileId={fileId} />
        ))}

      {groupConnection?.description && (
        <RichText>{groupConnection.description}</RichText>
      )}

      {Array.isArray(groupConnection?.media) &&
        groupConnection?.media.map((fileId, index) => (
          <Media key={`media-${index}`} fileId={fileId} />
        ))}

      {groupConnection?.conclusion && (
        <RichText>{groupConnection.conclusion}</RichText>
      )}

      {Array.isArray(groupConnection?.conclusionMedia) &&
        groupConnection?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {groupConnection?.epilogue && (
        <RichText>{groupConnection.epilogue}</RichText>
      )}

      {groupConnection?.prayer && <Prayer prayer={groupConnection.prayer} />}

      <ContentIcon
        content={[
          groupConnection?.description || "",
          groupConnection?.conclusion || "",
          groupConnection?.epilogue || "",
        ]}
      />
    </>
  ) : null;
};
