import * as React from "react";
import { FlamelinkFile } from "~/state/flamelink";
import { makeMediaUrl, MediaSize } from "~/state/flamelink/utils";
import {
  PdfContainer,
  PdfSubtitle,
  PdfTextContainer,
  PdfTitle,
  Preview,
} from "./styles";

interface PdfProps {
  onPress?(): void;
  file: FlamelinkFile;
}

export const Pdf: React.FC<PdfProps> = ({ onPress, file }) => {
  const previewUri = file?.preview?.file
    ? makeMediaUrl(file.preview.file, MediaSize.Small)
    : undefined;

  return file.hidden ? null : (
    <PdfContainer onPress={onPress}>
      {previewUri && (
        <Preview source={{ uri: previewUri }} resizeMode="contain" />
      )}

      <PdfTextContainer>
        <PdfTitle>{file.title}</PdfTitle>

        <PdfSubtitle>{file.description}</PdfSubtitle>
      </PdfTextContainer>
    </PdfContainer>
  );
};
