import * as React from "react";

import { Wrapper, Content, Text } from "./styles";

interface Props {
  isReversed: boolean;
  text: string;
}

export const TextMessage = React.memo<Props>(({ isReversed, text }) => {
  return (
    <Wrapper>
      <Content isReversed={isReversed}>
        <Text isReversed={isReversed}>{text}</Text>
      </Content>
    </Wrapper>
  );
});
