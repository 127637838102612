import styled from "styled-components/native";

import { windowHeight } from "~/utils/platform";
import { Text3, Text4 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

const contentHeight = 240;
export const contentBoxHeight = Math.max(
  Math.round((contentHeight * 100) / windowHeight),
  20
);

export const Container = styled.TouchableOpacity`
  padding-top: ${spacers.ss6};
  padding-bottom: ${spacers.ss9};
  align-self: center;
  flex-direction: row;
`;

export const InviteText = styled(Text3)`
  color: ${colors.white};
  margin-right: ${spacers.ss3};
`;

export const InviteLink = styled(Text3)`
  color: ${colors.white};
  text-decoration: underline;
  text-decoration-color: ${colors.white};
`;

export const Content = styled.View`
  padding-bottom: ${spacers.ss6};
  padding-horizontal: ${spacers.ss7};
  align-items: center;
`;

export const ImageBox = styled.View`
  padding-top: ${spacers.ss4};
  padding-bottom: ${spacers.ss5};
`;

export const Image = styled.Image`
  width: 50px;
  height: 50px;
  border-radius: 12px;
`;

export const Title = styled(Text4)`
  font-family: SFProDisplayBold;
  margin-bottom: ${spacers.ss4};
`;

export const Description = styled(Text3)`
  margin-bottom: ${spacers.ss12};
  color: ${colors.gray700};
  text-align: center;
`;

export const IconBox = styled.TouchableOpacity`
  position: absolute;
  top: -${spacers.ss6};
  right: ${spacers.ss3};
  padding: ${spacers.ss5};
`;
