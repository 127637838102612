import { StyleSheet } from "react-native";
import styled from "styled-components/native";

import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";
import { spacers } from "~/styles/theme";

export const flatListStyles = StyleSheet.create({
  container: {
    paddingBottom: 100,
  },
}).container;

export const Container = styled.View`
  ${isWeb ? `margin-top: ${spacers.ss8};` : ""}
  ${getWebContainerStyle(960)}
`;

export const EmptyContainer = styled.View`
  ${isWeb ? `margin-top: 120px;` : ""}
`;
