export const notePlansMapping = new Map([
  ["LLS:BEPADULT", "Listen Adult"],
  ["LLS:BEPADULT-ES", "Escucha Adultos"],
  ["LLS:BEPLEARNADULT", "Learn Adult"],
  ["LLS:BEPLEARNADULT-ES", "Aprende Adultos"],
  ["LLS:BEPLIVEADULT", "Live Adult"],
  ["LLS:BEPLIVEADULT-ES", "Vive Adultos"],
  ["LLS:BEPYOUTH", "Listen Youth Student"],
  ["LLS:BEPYOUTH-ES", "Escucha Jóvenes"],
  ["LLS:BEPLEARNYOUTH", "Learn Youth Student"],
  ["LLS:BEPLEARNYOUTH-ES", "Aprende Jóvenes"],
  ["LLS:BEPLIVEYOUTH", "Live Youth Student"],
  ["LLS:BEPLIVEYOUTH-ES", "Vive Jóvenes"],
]);
