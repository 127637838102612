type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

type Callbacks = {
  onSuccess: () => void;
  onError: () => void;
};

export enum NotificationType {
  BANNER = "banner",
  MODAL = "modal",
}

export enum ResourceType {
  IMAGE = "image",
  VIDEO = "video",
}

export enum LinkType {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export type LanguageKey = "en" | "es";

export type LocalisedText = Record<LanguageKey, string>;

export type LocalisedFile = Record<LanguageKey, File>;

export type InAppNotificationData = {
  type: NotificationType;
  resourceType: ResourceType;
  titleText: LocalisedText;
  descriptionText: LocalisedText;
  imageSet?: LocalisedFile;
  imageUrls?: LocalisedText;
  videoSet?: LocalisedFile;
  videoUrls?: LocalisedText;
  ctaText?: LocalisedText;
  navigateTo?: string;
  url?: string;
  options?: Record<string, string>;
  schedule: string | number;
  expiration: string | number;
  date?: string | number;
  linkType?: LinkType;
  route?: string;
  timeoutInSeconds?: number;
  maxTimes?: number;
  isActive?: boolean;
};

export type InAppNotification = {
  id: string;
} & InAppNotificationData;

export type InAppNotificationPayload = InAppNotificationData;

export type InAppNotificationsState = {
  data: InAppNotification[];
};

export type GetInAppNotificationsPayload = Callbacks;

export type AddInAppNotificationPayload = Callbacks & InAppNotificationPayload;

export type DeleteInAppNotificationPayload = Callbacks & {
  id: string;
};

export type EditInAppNotification = InAppNotification;

export type EditInAppNotificationPayload = Callbacks & EditInAppNotification;

export type GetInAppNotificationsAction = Action<GetInAppNotificationsPayload>;
export type AddInAppNotificationAction = Action<AddInAppNotificationPayload>;
export type EditInAppNotificationAction = Action<EditInAppNotificationPayload>;
export type DeleteInAppNotificationAction =
  Action<DeleteInAppNotificationPayload>;
