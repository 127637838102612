import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import {
  SectionTitle,
  SubsectionTitle,
  SubsectionSubtitle,
} from "../../../common";
import { Media } from "../../../media";
import { RichText } from "../../../rich-text";
import { ContentIcon } from "../../../content-icon";

interface LearningProps {
  learning: LessonsContent["learning"];
}

export const Learning: React.FC<LearningProps> = ({ learning }) => {
  const hasContent = React.useMemo(
    () =>
      learning?.youWillNeed ||
      learning?.gettingReady ||
      learning?.gettingReadyMedia?.length ||
      learning?.content ||
      learning?.learningMedia?.length ||
      learning?.conclusion ||
      learning?.conclusionMedia?.length,
    [learning]
  );

  return hasContent ? (
    <>
      {learning?.sectionTitle && (
        <SectionTitle>{learning.sectionTitle}</SectionTitle>
      )}

      {learning?.subheading && (
        <SubsectionTitle>{learning.subheading}</SubsectionTitle>
      )}

      {learning?.subtitle && (
        <SubsectionSubtitle>{learning.subtitle}</SubsectionSubtitle>
      )}

      {learning?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={learning.youWillNeed}
          checklistId="learning-youWillNeed"
        />
      )}

      {learning?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={learning.gettingReady}
          checklistId="learning-gettingReady"
        />
      )}

      {Array.isArray(learning?.gettingReadyMedia) &&
        learning?.gettingReadyMedia.map((fileId, index) => (
          <Media key={`gettingReadyMedia-${index}`} fileId={fileId} />
        ))}

      {learning?.content && <RichText>{learning.content}</RichText>}

      {Array.isArray(learning?.learningMedia) &&
        learning?.learningMedia.map((fileId, index) => (
          <Media key={`learningMedia-${index}`} fileId={fileId} />
        ))}

      {learning?.conclusion && <RichText>{learning.conclusion}</RichText>}

      {Array.isArray(learning?.conclusionMedia) &&
        learning?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {learning?.epilogue && <RichText>{learning.epilogue}</RichText>}

      {Array.isArray(learning?.epilogueMedia) &&
        learning?.epilogueMedia.map((fileId, index) => (
          <Media key={`epilogueMedia-${index}`} fileId={fileId} />
        ))}

      <ContentIcon
        content={[
          learning?.content || "",
          learning?.conclusion || "",
          learning?.epilogue || "",
        ]}
      />
    </>
  ) : null;
};
