import * as React from "react";
import { Text } from "react-native";

import * as routes from "~/constants/routes";
import { makeExternalLinks } from "~/utils/external-links";
import { genericMessages } from "~/constants/intl";
import { useNavigation } from "@react-navigation/native";
import {
  acceptCookiesPolicy,
  getIsCookiePolicyAccepted,
} from "~/utils/cookie-consent";

import {
  Container,
  Content,
  ButtonBox,
  TextBox,
  ConsentTitle,
  ConsentText,
  ConsentButton,
  ConsentButtonText,
  Button,
  ButtonText,
} from "./styles";
import { messages } from "./intl";

export const CookiesConsent = React.memo(() => {
  const [isVisible, setIsVisible] = React.useState(false);
  const navigation = useNavigation();

  const onPrivacyPolicy = () => {
    window.open(makeExternalLinks().PRIVACY_POLICY, "_blank");
  };
  const onCookiePolicy = () => {
    navigation.navigate(routes.cookiePolicy);
  };

  const onAccept = React.useCallback(() => {
    acceptCookiesPolicy();
    setIsVisible(false);
  }, []);

  React.useEffect(() => {
    getIsCookiePolicyAccepted().then((isAccepted) => setIsVisible(!isAccepted));
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <Content>
        <ButtonBox>
          <Button onPress={onAccept}>
            <ButtonText>{genericMessages.ok}</ButtonText>
          </Button>
        </ButtonBox>

        <TextBox>
          <Text>
            <ConsentTitle>{messages.title}</ConsentTitle>
            <ConsentText>{messages.text}</ConsentText>
            <ConsentButton onPress={onPrivacyPolicy}>
              <ConsentButtonText>{messages.privacy}</ConsentButtonText>
            </ConsentButton>
            <ConsentText>{messages.and}</ConsentText>
            <ConsentButton onPress={onCookiePolicy}>
              <ConsentButtonText>{messages.cookies}</ConsentButtonText>
            </ConsentButton>
          </Text>
        </TextBox>
      </Content>
    </Container>
  );
});
