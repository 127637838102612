import React from "react";
import {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { HEADER_BAR_HEIGHT, HEADER_BAR_TOP_OFFSET } from "~/constants";
import {
  ANIMATION_TIMEOUT_OFFSET,
  HEADER_TEXT_OVERLAP_OFFSET,
  OPEN_BOTTOM_SHEET_TIMEOUT,
} from "../content-container/constants";

export const useLandingContentStyles = () => {
  const contentPosition = useSharedValue(0);
  const safeAreaInsets = useSafeAreaInsets();

  const animatedLogoStyles = useAnimatedStyle(() => {
    const height = interpolate(contentPosition.value, [0, 1], [190, 0]);

    return { height };
  });

  const animatedContainerStyles = useAnimatedStyle(() => {
    const LAST_TOP_POSITION =
      safeAreaInsets.top +
      HEADER_BAR_HEIGHT +
      HEADER_BAR_TOP_OFFSET +
      HEADER_TEXT_OVERLAP_OFFSET;

    const top = interpolate(
      contentPosition.value,
      [0, 1],
      [400 + LAST_TOP_POSITION, LAST_TOP_POSITION]
    );

    return { top };
  });

  const animatedSessionDayStyles = useAnimatedStyle(() => {
    const fontSize = interpolate(contentPosition.value, [0, 1], [48, 26]);

    return { fontSize };
  });

  const animatedSeparatorStyles = useAnimatedStyle(() => {
    const width = interpolate(contentPosition.value, [0, 1], [160, 200]);

    return { width };
  });

  React.useEffect(() => {
    contentPosition.value = withTiming(1, {
      duration: OPEN_BOTTOM_SHEET_TIMEOUT - ANIMATION_TIMEOUT_OFFSET,
    });
  }, [contentPosition]);

  return {
    animatedLogoStyles,
    animatedContainerStyles,
    animatedSessionDayStyles,
    animatedSeparatorStyles,
  };
};
