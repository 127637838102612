import * as React from "react";
import { Box, Card, Icon, useTheme } from "@mui/material";
import Chart from "react-apexcharts";

import { FlexBetween } from "~/dashboard/components/flex-box";
import { H4, Small } from "~/dashboard/components/typography";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

interface Props {
  data: Array<{ label: string; value: number }>;
  title: string;
  subtitle: string;
}

export const LineChart = ({ data, title, subtitle }: Props) => {
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  const labels = data?.map(({ label }) => label);
  const values = data?.map(({ value }) => Number(value));

  if (!labels.length || !values.length) {
    return null;
  }

  const series = [
    {
      name: formatMessage(messages.downloads),
      data: values,
    },
  ];

  const lastItem = values[values.length - 1];
  const beforeLastItem = values[values.length - 2];
  const latestValue = Math.round(lastItem / 1000);
  const latestChange = Math.round((lastItem * 100) / beforeLastItem) % 100;

  return (
    <Card sx={{ p: 2, mb: 3 }} elevation={3}>
      <FlexBetween>
        <H4 sx={{ m: 0 }}>{title}</H4>

        <Box display="flex" alignItems="center">
          <H4 sx={{ m: 0 }}>{latestValue}K</H4>

          <Box ml={1} textAlign="center">
            <Icon
              size="small"
              color="primary"
              sx={{ mt: -1, display: "block" }}
            >
              arrow_drop_up
            </Icon>

            <Box mt={-1} fontSize="11px" color={textMuted}>
              +{latestChange}%
            </Box>
          </Box>
        </Box>
      </FlexBetween>

      <Small sx={{ color: textMuted }}>{subtitle}</Small>
      <div style={{ width: "100%" }}>
        <Chart
          height={148}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: labels,
            },
            yaxis: {
              show: false,
            },
            series,
            dataLabels: {
              enabled: false,
            },
          }}
          series={series}
          type="area"
        />
      </div>
    </Card>
  );
};
