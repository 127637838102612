import * as React from "react";

import { Container, GradientContainer, Text, AvatarImage } from "./styles";
import { getInitials } from "./utils";
import { AvatarSize } from "./types";

interface Props {
  text?: string;
  uri?: string;
  size?: AvatarSize;
  count?: number;
}

export const GroupAvatar = React.memo<Props>(
  ({ text = "", uri, size = AvatarSize.Medium, count }) => {
    return uri ? (
      <Container size={size}>
        <AvatarImage uri={uri} size={size} />
      </Container>
    ) : (
      <GradientContainer size={size}>
        <Text size={size}>
          {count && count > 0 ? `+${count}` : getInitials(text)}
        </Text>
      </GradientContainer>
    );
  }
);
