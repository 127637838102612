import en from "~/i18n/bad-words/en.json";
import es from "~/i18n/bad-words/es.json";

export interface BadWord {
  /**
   * language id
   * @type {string}
   */
  id: string;

  /**
   * Words list
   * @type {string[]}
   */
  words: string[];

  lookalike: object;
}

class ProfanityFilter {
  regexp: RegExp;

  constructor({ language }: { language: string }) {
    let expression = "";

    const data = (language === "es" ? es : en) as BadWord;

    // build expression
    for (const word of data?.words) {
      // escape regex word
      let exp = word.replace(/[.?^${}()|[\]\\]/g, "\\$&").replace(/\b\*\b/, "");

      if (word.startsWith("*")) {
        exp = `[^\\s\\b^]*${exp.slice(1)}`;
      }
      if (exp.endsWith("*")) {
        exp = `${exp.slice(0, -1)}[^\\s\\b$]*`;
      }

      expression += expression !== "" ? `|${exp}` : exp;
    }

    this.regexp = new RegExp(expression, "igm");
  }

  replace(str: string): string {
    return str?.replace(this.regexp, "");
  }
}

export default ProfanityFilter;
