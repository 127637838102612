import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import NetInfo from "@react-native-community/netinfo";

import {
  getPlanProgressCollection,
  getSessionProgressCollection,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";
import {
  getPlanBySessionId,
  getSessionProgressCheckConditions,
  getSessionsByPlanId,
} from "~/state/flamelink/selectors";
import { Environment } from "~/state/user/types";
import type { RootState } from "~/state/store";

import {
  AddSessionProgressParams,
  DeletePlanProgressParams,
  ProgressState,
  ResetDayProgressParams,
  ResetSessionProgressParams,
  SessionProgress,
  UpdateSessionProgressParams,
} from "./types";
import { addSessionProgress, updateSessionProgress } from "./side-effects";
import { SESSION_PROGRESS_TAG } from "./constants";
import { loadInitialProgress } from "./actions";
import {
  getNormalizedProgressByPlanId,
  getSessionIdBySessionProgressId,
} from "./selectors";
import { removeChecklistByParentId } from "../checklist";
import { getHasAnyProgress } from "./utils";

export const contentProgressApi = createApi({
  reducerPath: "contentProgressApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: [SESSION_PROGRESS_TAG],
  endpoints: (builder) => ({
    /**
     * @deprecated
     * move calls to sagas
     */
    addSessionProgress: builder.mutation<string, AddSessionProgressParams>({
      async queryFn(addSessionProgressParams, { dispatch, getState }) {
        try {
          const { isConnected } = await NetInfo.fetch();

          if (!isConnected) return { error: "No internet connection" };

          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env: Environment =
            (getState() as RootState).user.environment ?? "";

          const result: Awaited<ReturnType<typeof addSessionProgress>> =
            await addSessionProgress(addSessionProgressParams, userId, env);

          dispatch(loadInitialProgress());

          return result;
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_PROGRESS_TAG],
    }),

    /**
     * @deprecated
     * move calls to sagas
     */
    updateSessionProgress: builder.mutation<
      string,
      UpdateSessionProgressParams
    >({
      async queryFn(updateSessionProgressParams, { dispatch, getState }) {
        try {
          const { isConnected } = await NetInfo.fetch();

          if (!isConnected) return { error: "No internet connection" };

          const env: Environment =
            (getState() as RootState).user.environment ?? "";

          await updateSessionProgress(
            updateSessionProgressParams,
            env,
            getState as () => RootState
          );

          dispatch(loadInitialProgress());

          return { data: "Session progress updated successfully" };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_PROGRESS_TAG],
    }),

    /**
     * @deprecated
     * move calls to sagas
     */
    resetSessionProgress: builder.mutation<string, ResetSessionProgressParams>({
      async queryFn({ sessionProgressId, newState }, { dispatch, getState }) {
        try {
          const { isConnected } = await NetInfo.fetch();

          if (!isConnected) return { error: "No internet connection" };

          const env: Environment =
            (getState() as RootState).user.environment ?? "";
          const sessionProgressCollection = getSessionProgressCollection(env);

          const querySnapshot = await getDoc(
            doc(database, sessionProgressCollection, sessionProgressId)
          );

          const data = querySnapshot.data() as SessionProgress;

          const resettedDaysProgressToStore = data?.days.map((day) => ({
            key: day.key,
            state: newState,
          }));

          await updateDoc(
            doc(database, sessionProgressCollection, sessionProgressId),
            {
              days: resettedDaysProgressToStore,
              state: newState,
              lastUpdated: Date.now(),
            } as Partial<SessionProgress>
          );

          dispatch(loadInitialProgress());

          return { data: "Session progress resetted successfully" };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_PROGRESS_TAG],
    }),

    /**
     * @deprecated
     * move calls to sagas
     */
    deletePlanProgress: builder.mutation<string, DeletePlanProgressParams>({
      async queryFn({ planId }, { dispatch, getState }) {
        try {
          const { isConnected } = await NetInfo.fetch();

          if (!isConnected) return { error: "No internet connection" };

          const userId: string = (getState() as RootState).user.user?.uid ?? "";
          const env: Environment =
            (getState() as RootState).user.environment ?? "";
          const planProgressCollection = getPlanProgressCollection(env);
          const progress = getNormalizedProgressByPlanId(
            getState() as RootState,
            planId
          );

          const q = query(
            collection(database, planProgressCollection),
            where("userId", "==", userId),
            where("planId", "==", planId)
          );

          const querySnapshot = await getDocs(q);
          const promises: Promise<void>[] = querySnapshot.docs.map(
            (queryDoc) => {
              const data = queryDoc.data();

              if (progress?.isPlanCompleted) {
                return updateDoc(queryDoc.ref, {
                  ...data,
                  sessions: [],
                  isCompleted: true,
                  dateCompleted: progress?.completionDate,
                });
              }

              return deleteDoc(queryDoc.ref);
            }
          );

          await Promise.all(promises);

          const sessions = getSessionsByPlanId(getState() as RootState, planId);

          if (sessions?.length) {
            // clears all checklist items for the sessions under the plan
            sessions.map((session) => {
              dispatch(removeChecklistByParentId(session));
            });
          }

          dispatch(loadInitialProgress({ allowReset: true }));

          return { data: "Plan progress deleted successfully" };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_PROGRESS_TAG],
    }),

    /**
     * @deprecated
     * move calls to sagas
     */
    resetDayProgress: builder.mutation<string, ResetDayProgressParams>({
      async queryFn(
        { sessionProgressId, dayKey, newState },
        { dispatch, getState }
      ) {
        try {
          const { isConnected } = await NetInfo.fetch();

          if (!isConnected) return { error: "No internet connection" };

          const env: Environment =
            (getState() as RootState).user.environment ?? "";
          const sessionProgressCollection = getSessionProgressCollection(env);

          // if the new state is completed, we need to check if the session should be marked as completed
          // @TODO: probably this logic can be unified with the one in the updateSessionProgress
          if (newState === ProgressState.Completed) {
            const sessionId = getSessionIdBySessionProgressId(
              getState() as RootState,
              sessionProgressId
            );
            const sessionProgressCheckConditions =
              getSessionProgressCheckConditions(
                getState() as RootState,
                sessionId ?? ""
              );

            await updateSessionProgress(
              {
                dayKey,
                shouldMarkDayAsComplete: true,
                sessionProgressId,
                ...sessionProgressCheckConditions,
              },
              env,
              getState as () => RootState
            );

            dispatch(loadInitialProgress());

            return { data: "Day progress updated successfully" };
          }

          const querySnapshot = await getDoc(
            doc(database, sessionProgressCollection, sessionProgressId)
          );

          const data = querySnapshot.data() as SessionProgress;

          const resettedDaysProgressToStore = data?.days.map((day) => {
            if (day.key === dayKey) {
              return { key: day.key, state: newState };
            }

            return day;
          });

          const shouldMarkSessionAsInProgress =
            newState === ProgressState.InProgress ||
            newState === ProgressState.NotStarted;

          const hasAnyProgress = getHasAnyProgress(resettedDaysProgressToStore);

          if (!hasAnyProgress) {
            await deleteDoc(
              doc(database, sessionProgressCollection, sessionProgressId)
            );
          } else {
            await updateDoc(
              doc(database, sessionProgressCollection, sessionProgressId),
              {
                days: resettedDaysProgressToStore,
                lastUpdated: Date.now(),
                ...(shouldMarkSessionAsInProgress && {
                  state: hasAnyProgress
                    ? ProgressState.InProgress
                    : ProgressState.NotStarted,
                }),
              } as Partial<SessionProgress>
            );
          }

          dispatch(loadInitialProgress());

          return { data: "Day progress resetted successfully" };
        } catch (error) {
          handleError(error);
          return { error };
        }
      },
      invalidatesTags: [SESSION_PROGRESS_TAG],
    }),
  }),
});

export const {
  useAddSessionProgressMutation,
  useUpdateSessionProgressMutation,
  useResetSessionProgressMutation,
  useDeletePlanProgressMutation,
  useResetDayProgressMutation,
} = contentProgressApi;
