import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import { Loader } from "~/components/loader";
import { useAppDispatch } from "~/state/hooks";
import { verifyEmail } from "~/state/user/actions";
import { AuthModes } from "~/state/user/types";
import { PreAuthViewProps } from "~/navigation/pre-auth";
import { ResetPassword } from "~/screens/reset-password";

import { Container } from "./styles";
import { getSearchParamFromPath } from "./utils";
import { messages } from "./intl";

export const Auth = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { params: { path } = { path: "" } } =
    useRoute<PreAuthViewProps["route"]>();

  const code = getSearchParamFromPath(path, "oobCode") || "";
  const mode = getSearchParamFromPath(path, "mode") || "";

  const onAction = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  React.useEffect(() => {
    if (!code) {
      return;
    }

    if (mode === AuthModes.VerifyEmail) {
      dispatch(
        verifyEmail({
          code,
          onSuccess: onAction,
          onError: onAction,
        })
      );
      return;
    }
  }, []);

  const text =
    mode === AuthModes.VerifyEmail ? messages.verifyText : messages.wait;

  if (mode === AuthModes.ResetPassword) {
    return <ResetPassword code={code} />;
  }

  return (
    <Container>
      <Loader text={text} fullScreen />
    </Container>
  );
};
