import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  SortDirection,
} from "@mui/material";

import {
  TableHead,
  stableSort,
  getComparator,
} from "~/dashboard/components/table";
import { useTable } from "~/dashboard/hooks/useTable";
import { useAppSelector } from "~/state/hooks";
import { getProgressStats } from "~/state/stats/selectors";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";
import { Paragraph } from "~/dashboard/components/typography";

export const SessionProgress = () => {
  const progressStats = useAppSelector(getProgressStats);

  const columns = [
    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: formatMessage(messages.plan),
    },
    {
      id: "numUsersCompleted",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.completedAll),
    },
    {
      id: "numUsersCompletedSome",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.completedSome),
    },
    {
      id: "numUsersStarted",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.started),
    },
  ];

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleChangePage,
    handleRequestSort,
    handleChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "name" });

  if (!progressStats) {
    return null;
  }

  return (
    <>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 600 }}>
            <TableHead
              order={order as SortDirection}
              orderBy={orderBy}
              headCells={columns}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(progressStats, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.planId}
                      onClick={() => {}}
                    >
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>

                      <TableCell align="center">
                        {row.numUsersCompleted}
                      </TableCell>

                      <TableCell align="center">
                        {row.numUsersCompletedSome}
                      </TableCell>

                      <TableCell align="center">
                        {row.numUsersStarted}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={page}
          component="div"
          count={progressStats.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[6, 12, 18]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Paragraph sx={{ color: "text.secondary" }}>
        {formatMessage(messages.sessionDisclaimer)}
      </Paragraph>
    </>
  );
};
