import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { ContentRepeater, MediaContentRepeater } from "~/state/flamelink";

import { Container, Icon } from "./styles";
import { isWeb } from "~/utils/platform";

interface Props {
  content: Array<string | ContentRepeater[] | MediaContentRepeater[]>;
}

export const ContentIcon = ({ content: data }: Props) => {
  const navigation = useNavigation();

  if (isWeb) {
    return null;
  }

  const text = data.reduce((acc, item) => {
    if (typeof item === "string") {
      return [...acc, item];
    }
    if (Array.isArray(item)) {
      const subItems = item.map(({ content }) => content?.trim());
      return [...acc, ...subItems];
    }
    return acc;
  }, []);

  const handleOpenModal = () => {
    navigation.navigate(routes.sessionModal, {
      text: text.join("\n"),
    });
  };

  return (
    <Container onPress={handleOpenModal}>
      <Icon />
    </Container>
  );
};
