import React from "react";

import { Container, Button, ButtonText } from "./styles";

interface Props {
  onPress: (index: number) => void;
  activeIndex: number;
  titles: string[];
}

export const Tabs = React.memo<Props>(({ onPress, titles, activeIndex }) => {
  return (
    <Container>
      {titles.map((title, index) => {
        const isActive = index === activeIndex;

        return (
          <Button isActive={isActive} onPress={() => onPress(index)}>
            <ButtonText isActive={isActive}>{title}</ButtonText>
          </Button>
        );
      })}
    </Container>
  );
});
