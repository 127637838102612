import * as React from "react";

import { Text } from "./styles";
import { messages } from "./intl";

interface Props {
  text: string;
  limit: number;
}

export const CharacterCount = React.memo<Props>(({ text, limit }) => {
  const message =
    limit === 1 ? messages.charactersSingular : messages.charactersPlural;

  return (
    <Text>{{ ...message, values: { value: text?.length ?? 0, limit } }}</Text>
  );
});
