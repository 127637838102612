import { createAction } from "@reduxjs/toolkit";
import type { LoadInitialProgressAction } from "./types";

export const startPlan = createAction("startPlan");

export const resumePlan = createAction("resumePlan");

export const navigateToSessionItem = createAction("navigateToSessionItem");

export const navigateToNextSession = createAction("navigateToNextSession");

export const navigateToPrevSession = createAction("navigateToPrevSession");

// progress state related actions
export const loadInitialProgress = createAction<
  LoadInitialProgressAction["payload"]
>("loadInitialProgress");
