import * as React from "react";
import { FlatList } from "react-native";
import { format, isToday, isYesterday, isThisWeek } from "date-fns";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { PostAuthParamList } from "~/navigation/post-auth";
import { GroupListItem } from "~/components/list-item";
import { EmptyGroup } from "~/components/empty-group";
import { MessageType } from "~/state/chat/types";
import { GroupMessage } from "~/state/groups/types";

import { handleError } from "~/utils/logger";

import { messages } from "../intl";

const getTimeFormat = (timestamp: number) => {
  try {
    if (isToday(timestamp)) {
      return format(timestamp, "hh:mm");
    }
    if (isYesterday(timestamp)) {
      return messages.yesterday;
    }
    if (isThisWeek(timestamp)) {
      return format(timestamp, "EEE");
    }
    return format(timestamp, "LLL d");
  } catch (e) {
    handleError(e);
    return "";
  }
};

export type ChatProps = NativeStackScreenProps<PostAuthParamList, "group.chat">;

const getUserMessage = (
  text: string,
  type: MessageType,
  senderName: string,
  isDeleted: boolean,
  isCurrentUser: boolean
) => {
  if (type === MessageType.NoteSession) {
    return isCurrentUser
      ? messages.youSessionNote
      : { ...messages.userSessionNote, values: { senderName } };
  }
  if (type === MessageType.NoteBible) {
    return isCurrentUser
      ? messages.youBibleNote
      : { ...messages.userBibleNote, values: { senderName } };
  }
  if (type === MessageType.NotePersonal) {
    return isCurrentUser
      ? messages.youPersonalNote
      : { ...messages.userPersonalNote, values: { senderName } };
  }
  if (isDeleted) {
    return isCurrentUser
      ? messages.youDeleted
      : { ...messages.userDeleted, values: { senderName } };
  }
  if (!text) {
    return messages.noMessages;
  }
  return isCurrentUser
    ? { ...messages.userText, values: { text } }
    : { ...messages.text, values: { senderName, text } };
};

interface Props {
  data: GroupMessage[];
  handleOpenChat: (groupId: string) => void;
  currentGroupId?: string;
}

export const MessagesList = ({
  data,
  currentGroupId,
  handleOpenChat,
}: Props) => {
  const hasData = data?.length;

  return hasData ? (
    <FlatList
      data={data}
      renderItem={({ item, index }) => {
        const {
          senderName,
          text,
          type,
          groupId,
          groupName,
          groupUri,
          timestamp,
          isCurrentUser,
          isRead,
          isDeleted,
        } = item;
        const description = getUserMessage(
          text,
          type,
          senderName,
          isDeleted,
          isCurrentUser
        );
        const time = getTimeFormat(timestamp);
        const isActive = currentGroupId === groupId;
        const isLast = index === data.length - 1;
        return (
          <GroupListItem
            key={groupId}
            groupId={groupId}
            title={groupName}
            description={description}
            uri={groupUri}
            onPress={() => handleOpenChat(groupId)}
            rightText={time}
            numberOfLines={2}
            isHighlighted={!isRead}
            hasAvatar
            isActive={isActive}
            isLast={isLast}
          />
        );
      }}
    />
  ) : (
    <EmptyGroup />
  );
};
