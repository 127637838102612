import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

import { NotificationData } from "./types";
import { isSameDay } from "date-fns";

const getState = (state: RootState) => state.inAppNotifications;

export const getCurrentInAppNotification: (
  state: RootState
) => NotificationData | undefined = createSelector(
  getState,
  (state) => state.data
);

export const getNotificationsBlacklist: (state: RootState) => string[] =
  createSelector(getState, (state) => state?.blacklist || []);

export const getShouldShowNotification: (state: RootState) => boolean =
  createSelector(getState, ({ data, isVisible, blacklist }) => {
    if (!!data && isVisible && !blacklist.includes(data.id)) {
      if (data.date && !isSameDay(data?.date?.toDate(), new Date())) {
        return false;
      }
      return true;
    }
    return false;
  });
