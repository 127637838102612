import * as React from "react";
import { LessonsContent } from "~/state/flamelink";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { Prayer } from "../../../prayer";
import { RichText } from "../../../rich-text";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface BibleTimeContinuedProps {
  bibleTimeContinued: LessonsContent["bibleTimeContinued"];
}

export const BibleTimeContinued: React.FC<BibleTimeContinuedProps> = ({
  bibleTimeContinued,
}) => {
  const hasContent = React.useMemo(
    () =>
      bibleTimeContinued?.leaderThought ||
      bibleTimeContinued?.introduction ||
      bibleTimeContinued?.bibleLessonMedia?.length ||
      bibleTimeContinued?.conclusion ||
      bibleTimeContinued?.conclusionMedia?.length ||
      bibleTimeContinued?.epilogue ||
      bibleTimeContinued?.prayer,
    [bibleTimeContinued]
  );

  return hasContent ? (
    <>
      {bibleTimeContinued?.sectionTitle && (
        <SectionTitle>{bibleTimeContinued.sectionTitle}</SectionTitle>
      )}

      {bibleTimeContinued?.leaderThought && (
        <Thought thought={bibleTimeContinued?.leaderThought} />
      )}

      {bibleTimeContinued?.introduction && (
        <RichText>{bibleTimeContinued.introduction}</RichText>
      )}

      {Array.isArray(bibleTimeContinued?.bibleLessonMedia) &&
        bibleTimeContinued?.bibleLessonMedia.map((fileId, index) => (
          <Media key={`bibleLessonMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTimeContinued?.conclusion && (
        <RichText>{bibleTimeContinued.conclusion}</RichText>
      )}

      {Array.isArray(bibleTimeContinued?.conclusionMedia) &&
        bibleTimeContinued?.conclusionMedia.map((fileId, index) => (
          <Media key={`conclusionMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTimeContinued?.epilogue && (
        <RichText>{bibleTimeContinued.epilogue}</RichText>
      )}

      {bibleTimeContinued?.prayer && (
        <Prayer prayer={bibleTimeContinued.prayer} />
      )}

      <ResponseTime bibleTimeContinued={bibleTimeContinued} />

      <FaithVerse bibleTimeContinued={bibleTimeContinued} />

      <Closing bibleTimeContinued={bibleTimeContinued} />

      <ContentIcon
        content={[
          bibleTimeContinued?.introduction || "",
          bibleTimeContinued?.conclusion || "",
        ]}
      />
    </>
  ) : null;
};

const ResponseTime: React.FC<BibleTimeContinuedProps> = ({
  bibleTimeContinued,
}) => {
  return (
    <>
      {bibleTimeContinued?.responseTime?.introduction && (
        <RichText>{bibleTimeContinued.responseTime.introduction}</RichText>
      )}

      {bibleTimeContinued?.responseTime?.prayer && (
        <Prayer prayer={bibleTimeContinued.responseTime.prayer} />
      )}

      {bibleTimeContinued?.responseTime?.conclusion && (
        <RichText>{bibleTimeContinued.responseTime.conclusion}</RichText>
      )}

      <ContentIcon
        content={[
          bibleTimeContinued?.responseTime?.introduction || "",
          bibleTimeContinued?.responseTime?.conclusion || "",
        ]}
      />
    </>
  );
};

const FaithVerse: React.FC<BibleTimeContinuedProps> = ({
  bibleTimeContinued,
}) => {
  return (
    <>
      {bibleTimeContinued?.faithVerse?.introduction && (
        <RichText>{bibleTimeContinued.faithVerse.introduction}</RichText>
      )}

      {Array.isArray(bibleTimeContinued?.faithVerse?.faithVerseMedia) &&
        bibleTimeContinued?.faithVerse?.faithVerseMedia.map((fileId, index) => (
          <Media key={`faithVerseMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTimeContinued?.faithVerse?.content && (
        <RichText>{bibleTimeContinued.faithVerse.content}</RichText>
      )}

      {bibleTimeContinued?.faithVerse?.prayer && (
        <Prayer prayer={bibleTimeContinued?.faithVerse?.prayer} />
      )}
    </>
  );
};

const Closing: React.FC<BibleTimeContinuedProps> = ({ bibleTimeContinued }) => {
  return (
    <>
      {bibleTimeContinued?.closing?.introduction && (
        <RichText>{bibleTimeContinued.closing.introduction}</RichText>
      )}

      {Array.isArray(bibleTimeContinued?.closing?.faithFactMedia) &&
        bibleTimeContinued?.closing?.faithFactMedia.map((fileId, index) => (
          <Media key={`closing-faithFactMedia-${index}`} fileId={fileId} />
        ))}

      {bibleTimeContinued?.closing?.content && (
        <RichText>{bibleTimeContinued.closing.content}</RichText>
      )}

      {Array.isArray(bibleTimeContinued?.closing?.faithFactSlideMedia) &&
        bibleTimeContinued?.closing?.faithFactSlideMedia.map(
          (fileId, index) => (
            <Media
              key={`closing-faithFactSlideMedia-${index}`}
              fileId={fileId}
            />
          )
        )}

      {bibleTimeContinued?.closing?.conclusion && (
        <RichText>{bibleTimeContinued.closing.conclusion}</RichText>
      )}
    </>
  );
};
