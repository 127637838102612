import * as React from "react";
import { useRoute } from "@react-navigation/native";
import {
  getLessonData,
  getPersonalDevotionData,
  getSessionData,
  loadLessonsBySessionId,
  loadPersonalDevotion,
  loadSession,
} from "~/state/flamelink";
import { getPlanById, getPlanLogo } from "~/state/flamelink/selectors";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import type { SessionRouteProp } from "~/screens/session/types";
import { formatMessage } from "~/utils/translation";
import {
  SessionHomeContent,
  SessionDay,
  SessionLogo,
  SessionSeparator,
  SessionIndex,
  PlanTitle,
} from "./styles";
import { messages } from "../../intl";
import { useLandingContentStyles } from "./hooks";

interface LandingContentProps {
  currentDay: number;
}

export const LandingContent: React.FC<LandingContentProps> = ({
  currentDay,
}) => {
  const {
    params: {
      sessionId,
      lessonId,
      childSessionId,
      volumeIndex,
      sessionIndex,
      planId,
    },
  } = useRoute<SessionRouteProp>();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => getSessionData(state, sessionId));
  const dayId = data?.days[currentDay === 0 ? 0 : currentDay - 1];
  const dayData = useAppSelector((state) =>
    getPersonalDevotionData(state, dayId ?? "")
  );
  const lessonData = useAppSelector((state) =>
    getLessonData(state, lessonId ?? "")
  );
  const childSessionData = useAppSelector((state) =>
    getSessionData(state, childSessionId ?? "")
  );
  const {
    animatedLogoStyles,
    animatedContainerStyles,
    animatedSessionDayStyles,
    animatedSeparatorStyles,
  } = useLandingContentStyles();

  const planData = useAppSelector((state) => getPlanById(state, planId));
  const planLogo = useAppSelector((state) => getPlanLogo(state, planId));

  const contentIndexText = React.useMemo(() => {
    return planData?.volumesHidden
      ? formatMessage(messages.sessionIndex, { sessionIndex: sessionIndex + 1 })
      : formatMessage(messages.contentIndex, {
          volumeIndex: volumeIndex + 1,
          sessionIndex: sessionIndex + 1,
        });
  }, [sessionIndex, volumeIndex, planData?.volumesHidden]);

  const sessionDayText = React.useMemo(() => {
    if (lessonData) {
      return data?.title.split(":")[1] ?? "";
    }

    if (childSessionData) {
      return childSessionData?.title.split(":")[0] ?? "";
    }

    if (dayData && currentDay !== 0) {
      return dayData?.title.split(":")[0] ?? "";
    }

    return data?.title.split(":")[1] ?? "";
  }, [childSessionData, currentDay, data?.title, dayData, lessonData]);

  React.useEffect(() => {
    if (sessionId) {
      dispatch(loadSession(sessionId));
    }

    if (lessonId) {
      // we load all lessons at once to have them available between navigation inside the session bottom sheet
      dispatch(loadLessonsBySessionId(sessionId));
    }

    if (dayId) {
      dispatch(loadPersonalDevotion(dayId));
    }
  }, [dispatch, dayId, lessonId, sessionId]);

  if (!data) return null;

  return (
    <SessionHomeContent style={animatedContainerStyles}>
      {planLogo && (
        <SessionLogo style={animatedLogoStyles} source={{ uri: planLogo }} />
      )}

      <PlanTitle>{planData?.title ?? ""}</PlanTitle>

      <SessionSeparator style={animatedSeparatorStyles} />

      <SessionIndex>{contentIndexText}</SessionIndex>

      <SessionSeparator style={animatedSeparatorStyles} />

      <SessionDay style={animatedSessionDayStyles}>{sessionDayText}</SessionDay>
    </SessionHomeContent>
  );
};
