import * as React from "react";
import {
  BackgroundBlurView,
  BackgroundLinearGradient,
  BackgroundImage,
  BackgroundStaticView,
} from "./styles";
import { useRAMInfo } from "~/utils/ram-info";
import { StyledComponent } from "styled-components";

interface BackgroundGradientProps {
  source: {
    uri: string;
  };
  colors?: string[];
  imageWidth?: string;
}

export const BackgroundGradient: React.FC<BackgroundGradientProps> = ({
  source,
  colors,
  imageWidth,
}) => {
  const { isMemoryLow } = useRAMInfo();

  const BackgroundView: StyledComponent<
    React.ComponentType<any>,
    Object
  > = isMemoryLow ? BackgroundStaticView : BackgroundBlurView;

  return (
    <>
      <BackgroundImage source={source} imageWidth={imageWidth} />

      <BackgroundLinearGradient colors={colors} />

      <BackgroundView />
    </>
  );
};
