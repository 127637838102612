import * as React from "react";

import { useModal } from "~/state/modal/hook.web";
import { GroupModalComponent } from "~/screens/group-modal";
import { GroupJoinComponent } from "~/screens/group-join";

import { EmptyGroupComponent } from "./content";

interface Props {
  onSuccess: (groupId: string) => void;
}

export const EmptyGroup = React.memo(({ onSuccess }: Props) => {
  const { showModal, hideModal } = useModal();

  const onJoinSuccess = React.useCallback(
    (groupId: string) => {
      onSuccess(groupId);
      hideModal();
    },
    [hideModal, onSuccess]
  );

  const handleCreate = React.useCallback(() => {
    showModal(
      <GroupModalComponent onGoBack={hideModal} onSuccess={onSuccess} />
    );
  }, [showModal, hideModal, onSuccess]);

  const handleJoin = React.useCallback(() => {
    showModal(
      <GroupJoinComponent onSuccess={onJoinSuccess} onError={hideModal} />
    );
  }, [showModal, hideModal, onJoinSuccess]);

  return (
    <EmptyGroupComponent handleCreate={handleCreate} handleJoin={handleJoin} />
  );
});
