import React, { useEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Button } from "react-native-paper";

import { SettingsScreen } from "~/components/settings";
import { Alerts } from "~/components/alerts";
import { useAppDispatch } from "~/state/hooks";
import {
  deleteAccount,
  preLogout,
  triggerResetAPICache,
} from "~/state/user/actions";
import { useAlerts } from "~/state/alerts";
import { formatMessage } from "~/utils/translation";
import { EmptyGroupImage } from "~/components/empty-group/group-image";
import { colors } from "~/styles/theme";
import { asyncLogEvent, events } from "~/utils/analytics";
import { Loader } from "~/components/loader";
import { SettingsStackParamList } from "~/navigation/settings-stack";
import * as routes from "~/constants/routes";

import {
  InfoContainer,
  InfoTitle,
  LoaderWrapper,
  SubContainer,
} from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

type DeleteAccountProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.deleteAccount
>;

export const DeleteAccount = () => {
  const navigation = useNavigation<DeleteAccountProps["navigation"]>();
  const { params } = useRoute<DeleteAccountProps["route"]>();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = React.useState(true);
  const [hasRequested, setHasRequested] = React.useState(false);
  const { pushAlert } = useAlerts();

  const onDonePress = React.useCallback(() => {
    if (isWeb) {
      navigation.navigate(routes.home);
    } else {
      navigation.getParent()?.goBack();
    }

    if (params?.deleteOnlyData) {
      dispatch(triggerResetAPICache());

      return;
    }

    setTimeout(() => dispatch(preLogout()), 1000);
  }, [dispatch, navigation, params?.deleteOnlyData]);

  useEffect(() => {
    if (!hasRequested) {
      dispatch(
        deleteAccount({
          deleteOnlyData: params?.deleteOnlyData,
          onSuccess: () => {
            asyncLogEvent(events.DELETE_ACCOUNT);
            setIsDeleting(false);
          },
          onError: () => {
            pushAlert({
              message: messages.error,
              color: colors.red600,
              duration: 3000,
            });
            setIsDeleting(false);
          },
        })
      );

      setHasRequested(true);
    }
  }, [dispatch, hasRequested, navigation, params?.deleteOnlyData, pushAlert]);

  const getMessage = React.useMemo(() => {
    if (isDeleting) {
      return params?.deleteOnlyData
        ? messages.deletingData
        : messages.deletingAccount;
    }

    return params?.deleteOnlyData
      ? messages.dataDeleted
      : messages.accountDeleted;
  }, [isDeleting, params?.deleteOnlyData]);

  return (
    <SettingsScreen screenName="delete-account">
      <SubContainer>
        <InfoContainer>
          <EmptyGroupImage />

          <InfoTitle>{getMessage}</InfoTitle>

          {isDeleting ? (
            <LoaderWrapper>
              <Loader size={20} />
            </LoaderWrapper>
          ) : (
            <Button onPress={onDonePress}>
              {formatMessage(messages.infoButtonText)}
            </Button>
          )}
        </InfoContainer>
      </SubContainer>

      <Alerts />
    </SettingsScreen>
  );
};
