import React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { ProgressIcon } from "~/screens/plan/components/progress-icon";
import { IconSizes } from "~/components/icon";
import { useAppSelector } from "~/state/hooks";
import { ProgressState, getPlansProgressCount } from "~/state/content-progress";

import { LibraryProps } from "../..";
import { messages } from "./intl";
import {
  Divider,
  ForwardIcon,
  ProgressCounter,
  ProgressCounterContainer,
  ProgressRow,
  ProgressText,
  ProgressTextContainer,
} from "./styles";

export function LibraryProgress() {
  const data = useAppSelector(getPlansProgressCount);
  const navigation = useNavigation<LibraryProps["navigation"]>();

  const onPress = React.useCallback(
    (type: ProgressState) =>
      navigation.navigate(routes.libraryProgress, {
        type,
      }),
    [navigation]
  );

  return (
    <>
      <ProgressRow onPress={() => onPress(ProgressState.InProgress)} isFirst>
        <ProgressTextContainer>
          <ProgressIcon
            state={ProgressState.InProgress}
            isSession={false}
            iconSize={IconSizes.SMedium}
          />

          <ProgressText>{messages.inProgress}</ProgressText>
        </ProgressTextContainer>

        <ProgressCounterContainer>
          <ProgressCounter>{`${
            data?.plansInProgress?.length ?? 0
          }`}</ProgressCounter>

          <ForwardIcon />
        </ProgressCounterContainer>
      </ProgressRow>

      <Divider />

      <ProgressRow onPress={() => onPress(ProgressState.Completed)} isLast>
        <ProgressTextContainer>
          <ProgressIcon
            state={ProgressState.Completed}
            isSession={false}
            iconSize={IconSizes.SMedium}
          />

          <ProgressText>{messages.completed}</ProgressText>
        </ProgressTextContainer>

        <ProgressCounterContainer>
          <ProgressCounter>{`${
            data?.plansCompleted?.length ?? 0
          }`}</ProgressCounter>

          <ForwardIcon />
        </ProgressCounterContainer>
      </ProgressRow>
    </>
  );
}
