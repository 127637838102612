import * as React from "react";
import { formatMessage } from "~/utils/translation";
import { EmptyGroupImage } from "~/components/empty-group/group-image";
import { messages } from "./intl";
import { NoDownloadsContainer, NoDownloadsTitle } from "./styles";

export const NoDownloadsFound: React.FC = () => {
  return (
    <NoDownloadsContainer>
      <EmptyGroupImage />

      <NoDownloadsTitle>{formatMessage(messages.title)}</NoDownloadsTitle>
    </NoDownloadsContainer>
  );
};
