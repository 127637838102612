import { createAction } from "@reduxjs/toolkit";

import {
  GetPersonalNotePayload,
  AddPersonalNotePayload,
  EditPersonalNotePayload,
  DeleteNotePayload,
} from "./types";

export const getPersonalNotes =
  createAction<GetPersonalNotePayload>("getPersonalNotes");

export const addPersonalNote =
  createAction<AddPersonalNotePayload>("addPersonalNote");

export const editPersonalNote =
  createAction<EditPersonalNotePayload>("editPersonalNote");

export const deletePersonalNote =
  createAction<DeleteNotePayload>("deletePersonalNote");
