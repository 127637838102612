import styled from "styled-components/native";

import { Text2, Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  padding-top: ${spacers.ss4};
  background-color: ${colors.white};
  flex: 1;
`;

export const TopContainer = styled.View`
  padding-horizontal: ${spacers.ss8};
`;

export const Item = styled.TouchableOpacity<{ isLast: boolean }>`
  padding-left: ${spacers.ss8};
  padding-right: ${spacers.ss8};
  padding-vertical: ${spacers.ss6};
  border-bottom-width: 1px;
  border-bottom-color: ${({ isLast }) =>
    isLast ? colors.transparent : colors.gray200};
  flex-direction: row;
  align-items: center;
`;

export const Details = styled.View``;

export const MethodBox = styled.View`
  margin-horizontal: ${spacers.ss8};
`;

export const Selector = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-color: ${colors.gray200};
  border-width: 1px;
  margin-right: ${spacers.ss5};
  align-items: center;
  justify-content: center;
`;

export const SelectorDot = styled.View`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${colors.emerald600};
`;

export const ItemTitle = styled(Text3)``;

export const ExpiryDate = styled(Text2)`
  color: ${colors.gray500};
`;
