import styled from "styled-components/native";

import { Text2, Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View``;

export const TextBox = styled.View`
  padding-top: ${spacers.ss8};
  padding-bottom: ${spacers.ss9};
  padding-horizontal: ${spacers.ss8};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray200};
`;

export const Title = styled(Text3)`
  font-weight: 600;
  text-align: center;
  margin-bottom: ${spacers.ss4};
`;

export const Description = styled(Text3)`
  text-align: center;
`;

export const ButtonBox = styled.View`
  flex-direction: row;
`;

export const Button = styled.TouchableOpacity<{ isLast: boolean }>`
  padding-horizontal: ${spacers.ss6};
  border-right-width: ${({ isLast }) => (isLast ? "0px" : "1px")};
  border-right-color: ${colors.gray200};
  flex: 1;
  align-items: center;
  padding-vertical: ${spacers.ss6};
`;

export const ButtonText = styled(Text2)<{ isDanger: boolean }>`
  text-transform: uppercase;
  color: ${({ isDanger }) => (isDanger ? colors.red600 : colors.black)};
`;
