import { Dimensions, Platform, PlatformIOSStatic } from "react-native";

export const isWeb = Platform.OS === "web";
export const isAndroid = Platform.OS === "android";
export const isIOS = Platform.OS === "ios";
export const isPad = (Platform as PlatformIOSStatic).isPad;

// windowHeight is different on Android vs iOS
// https://stackoverflow.com/questions/46126521/android-navigation-bar-height-react-native/60561393#60561393
export const windowHeight = isAndroid
  ? Dimensions.get("screen").height
  : Dimensions.get("window").height;
export const windowWidth = Dimensions.get("window").width;

export const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i.test(
    navigator.userAgent
  );
