import * as React from "react";
import { ShareOptions, TouchableOpacity } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import * as Clipboard from "expo-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { WEB_APP_URL } from "~/constants";
import { useModal } from "~/state/modal/hook.web";
import { colors } from "~/styles/theme";

import { messages } from "./intl";

import {
  Container,
  Title,
  ShareContainer,
  IconContainer,
  IconBox,
  Text,
  ShareSection,
  ShareLinkTitle,
  ShareLinkBox,
  ShareLink,
  ShareLinkMeta,
} from "./styles";

type ShareContent = {
  message?: string;
  url?: string;
  hasLink?: boolean;
};

interface Props {
  content: ShareContent;
  options?: ShareOptions;
}

interface ItemProps {
  text: string;
  children: React.ReactNode;
}

const ShareItem = ({ text, children }: ItemProps) => (
  <IconContainer>
    <IconBox>{children}</IconBox>
    <Text>{text}</Text>
  </IconContainer>
);

const ShareComponent = ({ content, options }: Props) => {
  const url = content?.url || WEB_APP_URL;
  const baseProps = {
    url,
    children: null,
  };
  const title = "";
  const message = content?.message || "";

  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = () => {
    Clipboard.setStringAsync(url);
    setIsCopied(true);
  };

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  return (
    <Container>
      <Title>{messages.title}</Title>

      <ShareContainer>
        <EmailShareButton {...baseProps} subject={title} body={message}>
          <ShareItem text="Email">
            <EmailIcon />
          </ShareItem>
        </EmailShareButton>
        <FacebookShareButton {...baseProps} quote={message}>
          <ShareItem text="Facebook">
            <FacebookIcon />
          </ShareItem>
        </FacebookShareButton>
        <TwitterShareButton {...baseProps} title={message}>
          <ShareItem text="Twitter">
            <TwitterIcon />
          </ShareItem>
        </TwitterShareButton>
        <WhatsappShareButton {...baseProps} title={message}>
          <ShareItem text="WhatsApp">
            <WhatsappIcon />
          </ShareItem>
        </WhatsappShareButton>
        <TelegramShareButton {...baseProps} title={message}>
          <ShareItem text="Telegram">
            <TelegramIcon />
          </ShareItem>
        </TelegramShareButton>
      </ShareContainer>

      {content?.hasLink ? (
        <ShareSection>
          <ShareLinkTitle>Share the link:</ShareLinkTitle>
          <ShareLinkBox>
            <ShareLink>{url}</ShareLink>
            <TouchableOpacity onPress={handleCopy}>
              <Icon name="content-copy" color={colors.gray800} />
            </TouchableOpacity>
          </ShareLinkBox>
          {isCopied ? (
            <ShareLinkMeta>{messages.linkMessage}</ShareLinkMeta>
          ) : null}
        </ShareSection>
      ) : null}
    </Container>
  );
};

export const useShare = () => {
  const { showModal } = useModal();

  const share = (content: ShareContent, options?: ShareOptions) => {
    showModal(<ShareComponent content={content} options={options} />);
  };

  return {
    share,
  };
};

export const Share = {
  share: () => {},
};
