import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import NetInfo from "@react-native-community/netinfo";

import * as routes from "~/constants/routes";
import { Button } from "~/components/button";
import Offline from "<assets>/resources/offline.png";

import {
  ButtonBox,
  Container,
  Content,
  Title,
  Description,
  EmptyImage,
} from "./styles";
import { messages } from "./intl";
import { handleError } from "~/utils/logger";
import { ButtonTypes } from "~/components/button/types";

export const ResourceOffline = React.memo(() => {
  const navigation = useNavigation();

  const onGoToDownloads = React.useCallback(() => {
    // @ts-ignore
    navigation.navigate(routes.settingsModal, {
      screen: routes.downloads,
    });
  }, [navigation]);

  const onRefresh = () => {
    NetInfo.refresh().catch((e) => {
      handleError(e);
    });
  };

  return (
    <>
      <Container>
        <EmptyImage source={Offline} />

        <Content>
          <Title>{messages.title}</Title>
          <Description>{messages.description}</Description>
        </Content>
      </Container>

      <ButtonBox>
        <Button onPress={onGoToDownloads} text={messages.buttonDownloads} />
        <Button
          onPress={onRefresh}
          text={messages.buttonTryAgain}
          type={ButtonTypes.Secondary}
        />
      </ButtonBox>
    </>
  );
});
