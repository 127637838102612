import * as React from "react";
import { Text } from "react-native";

import { genericMessages } from "~/constants/intl";
import { Alert } from "~/components/alert";

import { Container, Button } from "./styles";
import { handleError } from "~/utils/logger";
import { formatMessage } from "~/utils/translation";

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    handleError({ error, errorInfo });
  }

  onPress() {
    // Let's keep it here for debugging purpose
    Alert.alert("Error", JSON.stringify(this.state?.error));
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Button onPress={this.onPress}>
            <Text>{formatMessage(genericMessages.error)}</Text>
          </Button>
        </Container>
      );
    }

    return this.props.children;
  }
}
