import * as React from "react";

import { AlertsContext } from "./context";

export const useAlerts = () => {
  const { alerts, pushAlert, removeAlert } = React.useContext(AlertsContext);

  return {
    alerts,
    pushAlert,
    removeAlert,
  };
};
