import * as React from "react";
import { TouchableOpacity, GestureResponderEvent } from "react-native";
import * as Clipboard from "expo-clipboard";
import Toast from "react-native-root-toast";

import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { deleteMessage } from "~/state/chat/actions";
import { getIsGroupLeaderByGroupId } from "~/state/groups/selectors";
import { MessageData, MessageType } from "~/state/chat/types";
import { useActionSheet } from "~/components/action-sheet";
import { isWeb } from "~/utils/platform";

import { ChatWrapper } from "../wrapper";
import { TextMessage } from "../text-message";
import { DeletedMessage } from "../deleted-message";
import { NoteBible, NoteSession, NotePersonal } from "../note";
import { messages } from "./intl";

export type ViewData = {
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  isReversed: boolean;
  data?: MessageData;
};

interface Props {
  isReversed: boolean;
  isFirst: boolean;
  text: string;
  timestamp: number;
  name: string;
  imageUri: string;
  isDeleted?: boolean;
  messageId: string;
  groupId: string;
  onPress: (data: ViewData) => void;
  onDismiss: () => void;
  type: MessageType;
  data?: MessageData;
}

export const getContent = (
  type: MessageType,
  text: string,
  isReversed?: boolean,
  data?: MessageData
) => {
  if (type === MessageType.Text) {
    return <TextMessage isReversed={!!isReversed} text={text} />;
  }
  if (type === MessageType.NoteBible) {
    return <NoteBible data={data} text={text} />;
  }
  if (type === MessageType.NoteSession) {
    return <NoteSession data={data} text={text} />;
  }
  if (type === MessageType.NotePersonal) {
    return <NotePersonal data={data} isReversed={!!isReversed} />;
  }

  return null;
};

export const ChatMessage = React.memo<Props>(
  ({
    isReversed,
    isFirst,
    text,
    name,
    imageUri,
    timestamp,
    messageId,
    groupId,
    isDeleted,
    data,
    onPress,
    onDismiss,
    type,
  }) => {
    const [isDisabled, setIsDisabled] = React.useState(false);
    const ref = React.useRef<TouchableOpacity>(null);

    const isGroupLeader = useAppSelector((state) =>
      getIsGroupLeaderByGroupId(state, groupId)
    );

    const { showActionSheetWithOptions } = useActionSheet();
    const dispatch = useAppDispatch();

    const onCopyMessage = React.useCallback(() => {
      Clipboard.setStringAsync(text);
      Toast.show(formatMessage(messages.copied), {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
      });
    }, [text]);

    const onDeleteMessage = React.useCallback(() => {
      setIsDisabled(true);
      dispatch(
        deleteMessage({
          messageId,
          groupId,
          onSuccess: () => setIsDisabled(false),
          onError: () => setIsDisabled(false),
        })
      );
    }, [dispatch, messageId, groupId]);

    const canDeleteMessage = !isReversed || isGroupLeader;

    const optionsArray = canDeleteMessage
      ? [messages.copy, messages.delete, messages.cancel]
      : [messages.copy, messages.cancel];
    const options = optionsArray.map((message) => formatMessage(message));

    const openActionSheet = React.useCallback(
      (event: GestureResponderEvent) => {
        showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex: canDeleteMessage ? 2 : 1,
            destructiveButtonIndex: canDeleteMessage ? 1 : 3,
          },
          (selectedIndex?: number) => {
            switch (selectedIndex) {
              case 0: {
                onCopyMessage();
                onDismiss();
                return;
              }
              case 1: {
                if (!canDeleteMessage) {
                  onDismiss();
                  return;
                }
                onDeleteMessage();
                onDismiss();
                return;
              }
            }
            onDismiss();
          },
          // @ts-ignore
          event
        );
      },
      [
        options,
        showActionSheetWithOptions,
        onCopyMessage,
        onDeleteMessage,
        onDismiss,
      ]
    );

    const handleViewPress = (event: GestureResponderEvent) => {
      if (isWeb) {
        openActionSheet(event);
        return;
      }
      ref?.current?.measureInWindow(
        (x: number, y: number, width: number, height: number) => {
          onPress({ x, y, width, height, text, isReversed });
          openActionSheet(event);
        }
      );
    };

    const buttonProps = isWeb
      ? {
          onPress: handleViewPress,
        }
      : {
          onLongPress: handleViewPress,
        };

    return (
      <ChatWrapper
        isReversed={isReversed}
        isFirst={isFirst}
        name={name}
        imageUri={imageUri}
        timestamp={timestamp}
      >
        {!isDeleted ? (
          <TouchableOpacity {...buttonProps} ref={ref}>
            {getContent(type, text, isReversed, data)}
          </TouchableOpacity>
        ) : (
          <DeletedMessage />
        )}
      </ChatWrapper>
    );
  }
);
