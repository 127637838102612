export const messages: {[key: string]: any} = {
  title: {
    id: "version-modal.title",
    defaultMessage: "VERSION",
  },
  recent: {
    id: "version-modal.recent",
    defaultMessage: "Recent",
  },
  eng: {
    id: "version-modal.eng",
    defaultMessage: "English",
  },
  spa: {
    id: "version-modal.spa",
    defaultMessage: "Spanish",
  },
  other: {
    id: "version-modal.other",
    defaultMessage: "Other Versions",
  },
};
