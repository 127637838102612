import React from "react";
import { TouchableOpacity, View } from "react-native";
import { isBefore } from "date-fns";

import { useAppSelector } from "~/state/hooks";
import {
  getInAppNotifications,
  LanguageKey,
} from "~/state/in-app-notifications-setup";
import { InAppNotification } from "~/state/in-app-notifications-setup/types";
import { StatusTag } from "~/components/status-tag";
import { Status } from "~/components/status-tag/types";
import {
  Container,
  HeaderBox,
  SectionTitle,
  ContentBox,
  List,
  ListItem,
  Title,
  Meta,
  AddIcon,
  EmptyBox,
  EmptyText,
  SectionSubTitle,
} from "~/components/cms/styles";
import { getFormattedTime } from "~/utils/time";
import { getCurrentLocale } from "~/utils/translation";

import { messages } from "../intl";
import { messages as sectionMessages } from "./intl";

interface Props {
  onCreate: () => void;
  onSelectItem: (id: string) => void;
}

const getStatus = (item: InAppNotification) => {
  const now = new Date();
  const isExpired = isBefore(new Date(item.expiration), now);
  const isScheduled = isBefore(new Date(item.schedule), now);

  if (isExpired) {
    return Status.Expired;
  }
  if (isScheduled && !isExpired && !!item.isActive) {
    return Status.Active;
  }
  return Status.Inactive;
};

export const AnnouncementList = React.memo<Props>(
  ({ onCreate, onSelectItem }) => {
    const data = useAppSelector(getInAppNotifications);
    const language = getCurrentLocale() as LanguageKey;

    return (
      <Container>
        <HeaderBox>
          <View>
            <SectionTitle>{messages.title}</SectionTitle>
            <SectionSubTitle>{messages.subtitle}</SectionSubTitle>
          </View>

          <TouchableOpacity>
            <AddIcon onPress={onCreate} />
          </TouchableOpacity>
        </HeaderBox>

        {data.length ? (
          <List
            renderItem={({
              item,
              index,
            }: {
              item: InAppNotification;
              index: number;
            }) => {
              const scheduled = getFormattedTime(item.schedule);
              const expiration = getFormattedTime(item.expiration);
              const scheduleText = {
                ...sectionMessages.scheduleTimespan,
                values: { from: scheduled, to: expiration },
              };
              return (
                <ListItem
                  isLast={index === data.length - 1}
                  onPress={() => onSelectItem(item.id)}
                >
                  <ContentBox>
                    <Title>{item.titleText?.[language]}</Title>
                    <Meta>{scheduleText}</Meta>
                  </ContentBox>
                  <StatusTag status={getStatus(item)} />
                </ListItem>
              );
            }}
            data={data}
          />
        ) : (
          <EmptyBox>
            <EmptyText>{sectionMessages.empty}</EmptyText>
          </EmptyBox>
        )}
      </Container>
    );
  }
);
