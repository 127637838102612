import React from "react";

import { Container, Button, Dot } from "./styles";

interface Props {
  pages: number;
  currentPage: number;
  isValid: boolean;
  setPage: (page: number) => void;
}

const Pager = ({ pages, currentPage, setPage, isValid }: Props) => {
  return (
    <Container>
      {new Array(pages).fill(undefined).map((_, index) => {
        const isActive = index === currentPage;
        if ((isValid && currentPage + 1 === index) || index < currentPage) {
          return (
            <Button key={index} onPress={() => setPage(index)}>
              <Dot isActive={isActive} />
            </Button>
          );
        }

        return <Dot key={`dot-${index}`} isActive={isActive} />;
      })}
    </Container>
  );
};

export default Pager;
