import React from "react";
import { useNavigation } from "@react-navigation/native";

import { formatMessage } from "~/utils/translation";
import { genericMessages } from "~/constants/intl";
import { useAlert } from "~/components/alert";
import { BoxedTextInput } from "~/components/text-input";
import { Loader } from "~/components/loader";
import { useActionSheet } from "~/components/action-sheet";

import {
  getPaymentMethodString,
  supportedCurrencyMap,
} from "~/state/payments/utils";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getUserEmail } from "~/state/user/selectors";
import {
  deleteSubscription,
  getPaymentMethodsAction,
  getSubscriptions,
  updateSubscription,
} from "~/state/payments/actions";
import {
  getSubscription,
  subscriptionFrequencies,
  getPaymentMethods,
  getSubscriptionMethodId,
} from "~/state/payments";
import { Subscription } from "~/state/payments/types";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { AccountHeader } from "../../../header";
import { Title, Section, SectionTitle } from "../../styles";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";

import {
  Container,
  ManageBox,
  Amount,
  CancelButton,
  CancelText,
  EditButton,
  EditText,
  EmptyText,
} from "./styles";
import { messages } from "./intl";
import { CustomAmount } from "../../../custom-amount";

interface Props {
  amount: number;
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

interface ContentProps {
  email: string;
  setEmail: (value: string) => void;
  frequency: number;
  setFrequency: (value: number) => void;
  chargeOn: number;
  setChargeOn: (value: number) => void;
  amount: number;
  setAmount: (value: number) => void;
  onDelete: () => void;
  onPaymentMethod: () => void;
  subscription: Subscription | null;
  isLoadingMethods: boolean;
}

const chargeMessages = [
  messages.charge1,
  messages.charge15,
  messages.charge30,
  genericMessages.cancel,
];
const chargeOptions = chargeMessages.map((label) => formatMessage(label));

const frequencyMessages = [
  messages.frequencyMonthly,
  messages.frequencyQuarterly,
  messages.frequencyYearly,
  genericMessages.cancel,
];
const frequencyOptions = frequencyMessages.map((label) => formatMessage(label));

export const GivingManageContent = React.memo<ContentProps>(
  ({
    email,
    setEmail,
    frequency,
    setFrequency,
    chargeOn,
    setChargeOn,
    amount,
    setAmount,
    onDelete,
    subscription,
    isLoadingMethods,
    onPaymentMethod,
  }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isEditingAmount, setIsEditingAmount] = React.useState(false);

    const paymentMethods = useAppSelector(getPaymentMethods);
    const defaultPaymentMethodId = useAppSelector(getSubscriptionMethodId);

    const { showActionSheetWithOptions } = useActionSheet();
    const dispatch = useAppDispatch();

    const handleCharge = (event?: React.PointerEvent) => {
      const cancelButtonIndex = 3;
      showActionSheetWithOptions(
        {
          options: chargeOptions,
          cancelButtonIndex,
        },
        (selectedIndex?: number) => {
          if (
            typeof selectedIndex === "number" &&
            selectedIndex !== cancelButtonIndex
          ) {
            setChargeOn(selectedIndex);
          }
        },
        // @ts-ignore - app doesn't require the event
        event
      );
    };

    const handleFrequency = (event?: React.PointerEvent) => {
      const cancelButtonIndex = 3;
      showActionSheetWithOptions(
        {
          options: frequencyOptions,
          cancelButtonIndex,
        },
        (selectedIndex?: number) => {
          if (
            typeof selectedIndex === "number" &&
            selectedIndex !== cancelButtonIndex
          ) {
            setFrequency(selectedIndex);
          }
        },
        // @ts-ignore - app doesn't require the event
        event
      );
    };

    const handleAmountEdit = (value: string) => {
      setAmount(Number(value));
      setIsEditingAmount(false);
    };

    const handleEmailEdit = () => {
      setIsEditing(true);
    };

    React.useEffect(() => {
      dispatch(
        getSubscriptions({
          onSuccess: () => setIsLoading(false),
          onError: () => setIsLoading(false),
        })
      );
    }, [dispatch]);

    const amountText = `${supportedCurrencyMap.get(
      subscription?.currency || ""
    )}${amount}`;

    if (isLoading) {
      return <Loader size={16} fullScreen />;
    }

    if (!subscription) {
      return <EmptyText>{messages.empty}</EmptyText>;
    }

    const emailProps = !isEditing
      ? {
          onOverlayPress: handleEmailEdit,
          showEdit: false,
        }
      : {};

    const paymentMethodString = Array.isArray(paymentMethods)
      ? getPaymentMethodString(
          paymentMethods.find((item) => item.id === defaultPaymentMethodId)
        )
      : "";

    return (
      <>
        <ManageBox>
          <Amount>
            {{ ...messages.amount, values: { amount: amountText } }}
          </Amount>

          <EditButton onPress={() => setIsEditingAmount(true)}>
            <EditText>{messages.amountEdit}</EditText>
          </EditButton>

          {paymentMethodString ? (
            <Section>
              <SectionTitle>{messages.method}</SectionTitle>
              <BoxedTextInput
                value={paymentMethodString}
                onChangeText={() => {}}
                onOverlayPress={onPaymentMethod}
                showEdit
              />
            </Section>
          ) : null}

          <Section>
            <SectionTitle>{messages.charge}</SectionTitle>
            <BoxedTextInput
              value={formatMessage(chargeMessages[chargeOn])}
              onChangeText={() => {}}
              onOverlayPress={handleCharge}
              showEdit
            />
          </Section>
          <Section>
            <SectionTitle>{messages.frequency}</SectionTitle>
            <BoxedTextInput
              value={formatMessage(frequencyMessages[frequency])}
              onChangeText={() => {}}
              onOverlayPress={handleFrequency}
              showEdit
            />
          </Section>
          <Section isLast>
            <SectionTitle>{messages.email}</SectionTitle>
            <BoxedTextInput
              value={email}
              onChangeText={setEmail}
              onBlur={() => setIsEditing(false)}
              autoCorrect={false}
              autoComplete="email"
              {...emailProps}
            />
          </Section>
        </ManageBox>

        <CancelButton onPress={onDelete}>
          <CancelText>{messages.cancel}</CancelText>
        </CancelButton>

        {isEditingAmount ? (
          <CustomAmount value={`${amount}`} setValue={handleAmountEdit} />
        ) : null}
      </>
    );
  }
);

export const GivingManage = React.memo<Props>(({ setScreen }) => {
  const userEmail = useAppSelector(getUserEmail);
  const subscription = useAppSelector(getSubscription);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const defaultAmount = subscription?.amount || 0;
  const defaultChargeOn = Math.ceil((subscription?.chargeOn || 1) / 15);
  const defaultFrequency = subscription?.frequency || 0;

  const [email, setEmail] = React.useState(userEmail);
  const [amount, setAmount] = React.useState(defaultAmount);
  const [frequency, setFrequency] = React.useState(defaultFrequency);
  const [chargeOn, setChargeOn] = React.useState(defaultChargeOn);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoadingMethods, setIsLoadingMethods] = React.useState(false);

  const { pushAlert } = useAlerts();
  const { showAlert } = useAlert();

  const hasChanged =
    email !== userEmail ||
    amount !== defaultAmount ||
    frequency !== defaultFrequency ||
    chargeOn !== defaultChargeOn;

  const dismissModals = React.useCallback(() => {
    navigation.goBack();
    navigation.goBack();
  }, [navigation]);

  const onPaymentMethod = () => setScreen(Screens.PaymentMethod);

  const onSuccess = React.useCallback(() => {
    dismissModals();
    pushAlert({
      message: messages.updateSuccess,
      color: colors.emerald600,
    });
    setIsSaving(false);
  }, [dismissModals, pushAlert]);

  const onError = React.useCallback(() => {
    dismissModals();
    pushAlert({
      message: messages.updateError,
      color: colors.red500,
    });
    setIsSaving(false);
  }, [dismissModals, pushAlert]);

  const onSave = React.useCallback(() => {
    if (!hasChanged || isSaving) {
      return;
    }
    setIsSaving(true);
    dispatch(
      updateSubscription({
        email,
        amount: Number(amount),
        frequency: subscriptionFrequencies[frequency],
        chargeOn: Math.max(chargeOn * 15, 1),
        onSuccess,
        onError,
      })
    );
  }, [
    hasChanged,
    amount,
    dispatch,
    email,
    frequency,
    chargeOn,
    onError,
    onSuccess,
    isSaving,
  ]);

  const onDelete = React.useCallback(() => {
    showAlert(
      formatMessage(messages.cancel),
      formatMessage(messages.cancelText),
      [
        {
          text: formatMessage(genericMessages.cancel),
          style: "cancel",
        },
        {
          text: formatMessage(genericMessages.yesSure),
          onPress: () => {
            dispatch(deleteSubscription({ onSuccess, onError }));
          },
        },
      ]
    );
  }, [showAlert, dispatch, onError, onSuccess]);

  React.useEffect(() => {
    dispatch(
      getPaymentMethodsAction({
        onSuccess: () => setIsLoadingMethods(false),
        onError: () => setIsLoadingMethods(false),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (subscription?.amount) {
      setAmount(subscription.amount);
    }
  }, [subscription?.amount]);

  return (
    <Container>
      <AccountHeader
        onBackPress={() => setScreen(Screens.Intro)}
        onSave={onSave}
        isSaveDisabled={!hasChanged}
        isLoading={isSaving}
      />

      <Title>{generalMessages.manage}</Title>

      <GivingManageContent
        email={email}
        setEmail={setEmail}
        amount={amount || defaultAmount}
        setAmount={setAmount}
        frequency={frequency}
        setFrequency={setFrequency}
        chargeOn={chargeOn}
        setChargeOn={setChargeOn}
        subscription={subscription}
        onDelete={onDelete}
        isLoadingMethods={isLoadingMethods}
        onPaymentMethod={onPaymentMethod}
      />
    </Container>
  );
});
