import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgGroups = (props) => (
  <Svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.007 15.84a.6.6 0 0 0 .145 1A10.067 10.067 0 0 1 22.067 26a.733.733 0 0 1-1.467 0 8.6 8.6 0 0 0-17.2 0 .733.733 0 0 1-1.467 0 10.067 10.067 0 0 1 5.915-9.16.6.6 0 0 0 .145-1 5.96 5.96 0 0 1-2.06-4.507 6.067 6.067 0 1 1 12.134 0 5.96 5.96 0 0 1-2.06 4.507Zm8.531-.479a.6.6 0 0 0 .13.908 11.4 11.4 0 0 1 5.359 9.728V26a.734.734 0 0 1-1.467.002 9.934 9.934 0 0 0-5.662-9.044l-.135-.073-.497-.331-.003-.002a.733.733 0 0 1 .037-1.246 4.6 4.6 0 0 0 2.3-3.971v-.002a4.6 4.6 0 0 0-4.6-4.6.733.733 0 1 1 0-1.466 6.067 6.067 0 0 1 4.538 10.095Zm-15.094-.203a4.6 4.6 0 1 0 5.112-7.649 4.6 4.6 0 0 0-5.112 7.649Z"
      fill="currentColor"
    />
  </Svg>
);

export default SvgGroups;
