export const messages = {
  title: {
    id: "plan.add-resource.title",
    defaultMessage: "Continue with a group",
  },
  titleStart: {
    id: "plan.add-resource.title-start",
    defaultMessage: "Start with a group",
  },
  newGroup: {
    id: "plan.add-resource.new-group",
    defaultMessage: "Start it with a new group",
  },
  success: {
    id: "plan.add-resource.success",
    defaultMessage: "Resource has been successfully added to the group",
  },
};
