import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { Text1, Text2, Text3 } from "~/components/text";
import { spacers, colors, lineHeights, fontSizes } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const Container = styled.View`
  background-color: ${colors.white};
  margin-bottom: ${spacers.ss3};
  border-radius: 8px;
`;

export const Header = styled.View`
  padding-vertical: ${spacers.ss4};
  padding-horizontal: ${spacers.ss6};
  align-items: center;
  flex-direction: row;
`;

export const HeaderText = styled(Text3)`
  color: ${colors.gray800};
  font-family: SFProDisplayMedium;
  margin-right: ${spacers.ss3};
`;

export const IconButton = styled.TouchableOpacity``;

export const NoteText = styled(Text3)`
  color: ${colors.gray700};
  padding-vertical: ${spacers.ss4};
  padding-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss3};
`;

export const ShortNoteText = styled(Text3)`
  color: ${colors.gray700};
  padding-bottom: ${spacers.ss4};
  padding-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss3};
`;

export const HighlightedBox = styled.View`
  background-color: ${colors.gray100};
  padding-vertical: ${spacers.ss6};
  padding-horizontal: ${spacers.ss6};
  margin-horizontal: ${spacers.ss6};
  border-radius: 6px;
`;

export const VerseNumber = styled(Text1)`
  font-family: LoraSuperscript;
  font-size: 9px;
  color: ${colors.amber600};
`;

export const VerseText = styled(Text2)`
  line-height: 19.5px;
  color: ${colors.gray800};
`;

export const QuestionKey = styled(Text3)`
  color: ${colors.gray700};
  font-family: SFProDisplayMedium;
`;

export const QuestionText = styled(Text3)`
  color: ${colors.gray700};
`;

export const questionTextStyles: StyleSheet.NamedStyles<any> = {
  body: {
    margin: 0,
    padding: 0,
  },
  paragraph: {
    color: colors.gray700,
    fontSize: 16,
    marginTop: 1,
    marginBottom: 0,
  },
};
