import styled from "styled-components/native";

import { Text1 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  background-color: ${colors.gray100};
  padding: ${spacers.ss4};
  margin-vertical: ${spacers.ss4};
  border-radius: 6px;
  align-self: center;
`;

export const Text = styled(Text1)`
  font-family: SFProDisplayMedium;
  color: ${colors.gray500};
`;
