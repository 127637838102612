import * as React from "react";
import { messages as sessionMessages } from "~/screens/session/intl";
import { LessonsContent } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";
import { Checklist } from "../../../checklist";
import { SectionTitle } from "../../../common";
import { Media } from "../../../media";
import { Prayer } from "../../../prayer";
import { RichText } from "../../../rich-text";
import { Thought } from "../../../thought";
import { ContentIcon } from "../../../content-icon";

interface BibleLessonProps {
  bibleLesson: LessonsContent["bibleLesson"];
}

export const BibleLesson: React.FC<BibleLessonProps> = ({ bibleLesson }) => {
  const hasContent = React.useMemo(
    () =>
      bibleLesson?.introduction ||
      bibleLesson?.youWillNeed ||
      bibleLesson?.gettingReady ||
      bibleLesson?.prayer ||
      bibleLesson?.bibleLessonVideoIntro ||
      bibleLesson?.bibleLessonIntroVideoMedia?.length ||
      bibleLesson?.bibleLessonStoryIntro ||
      bibleLesson?.bibleLessonStoryVideoMedia?.length ||
      bibleLesson?.bibleLessonStoryConclusion,
    [bibleLesson]
  );

  return hasContent ? (
    <>
      {bibleLesson?.sectionTitle && (
        <SectionTitle>{bibleLesson.sectionTitle}</SectionTitle>
      )}

      {bibleLesson?.introduction && (
        <RichText>{bibleLesson.introduction}</RichText>
      )}

      {bibleLesson?.youWillNeed && (
        <Checklist
          title={formatMessage(sessionMessages.youWillNeed)}
          content={bibleLesson.youWillNeed}
          checklistId="bibleLesson-youWillNeed"
        />
      )}

      {bibleLesson?.gettingReady && (
        <Checklist
          title={formatMessage(sessionMessages.gettingReady)}
          content={bibleLesson.gettingReady}
          checklistId="bibleLesson-gettingReady"
        />
      )}

      {bibleLesson?.prayer && <Prayer prayer={bibleLesson.prayer} />}

      {bibleLesson?.bibleLessonVideoIntro && (
        <RichText>{bibleLesson.bibleLessonVideoIntro}</RichText>
      )}

      {Array.isArray(bibleLesson?.bibleLessonIntroVideoMedia) &&
        bibleLesson?.bibleLessonIntroVideoMedia.map((fileId, index) => (
          <Media key={`bibleLessonIntroVideoMedia-${index}`} fileId={fileId} />
        ))}

      <BibleStory bibleLesson={bibleLesson} />

      <ContentIcon content={[bibleLesson?.introduction || ""]} />
    </>
  ) : null;
};

const BibleStory: React.FC<BibleLessonProps> = ({ bibleLesson }) => {
  const hasContent = React.useMemo(
    () =>
      bibleLesson?.bibleLessonStoryIntro ||
      bibleLesson?.bibleStoryRepeater?.length ||
      bibleLesson?.bibleLessonStoryVideoMedia?.length ||
      bibleLesson?.bibleLessonStoryConclusion,
    [bibleLesson]
  );

  return hasContent ? (
    <>
      {bibleLesson?.bibleLessonStoryIntro && (
        <RichText>{bibleLesson.bibleLessonStoryIntro}</RichText>
      )}

      {Array.isArray(bibleLesson?.bibleStoryRepeater) &&
        bibleLesson?.bibleStoryRepeater.map(
          ({ introduction, thought, media, conclusion }, i) => (
            <React.Fragment key={`bibleStoryRepeater-${i}`}>
              {introduction && <RichText>{introduction}</RichText>}

              {thought && <Thought thought={thought} />}

              {Array.isArray(media) &&
                media.map((fileId, index) => (
                  <Media
                    key={`bibleStoryRepeater-media-${index}`}
                    fileId={fileId}
                  />
                ))}

              {conclusion && <RichText>{conclusion}</RichText>}
            </React.Fragment>
          )
        )}

      {Array.isArray(bibleLesson?.bibleLessonStoryVideoMedia) &&
        bibleLesson?.bibleLessonStoryVideoMedia.map((fileId, index) => (
          <Media key={`bibleLessonIntroVideoMedia-${index}`} fileId={fileId} />
        ))}

      {bibleLesson?.bibleLessonStoryConclusion && (
        <RichText>{bibleLesson.bibleLessonStoryConclusion}</RichText>
      )}
    </>
  ) : null;
};
