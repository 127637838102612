import { MessageData, MessageType } from "~/state/chat/types";

export const formatShareMessage = (
  content: string,
  type: MessageType,
  data?: MessageData
) => {
  if (type === MessageType.NoteSession) {
    if (!data?.question || !data?.title) {
      return content;
    }
    return `${data.title} \n Q: ${data.question} \n${content} \n\n`;
  }
  if (type === MessageType.NoteBible) {
    if (!data?.title) {
      return content;
    }
    return `${data.title} \n${content} \n\n`;
  }

  if (type === MessageType.NotePersonal) {
    if (!data?.title) {
      return content;
    }
    return `${data.title} \n${content} \n\n`;
  }
  return content;
};
