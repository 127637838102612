import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

interface Chip {
  label: string;
  value: string;
}

export const CHIPS: Chip[] = [
  { label: formatMessage(messages.login), value: "Login" },
  { label: formatMessage(messages.signUp), value: "Sign Up" },
  { label: formatMessage(messages.content), value: "Content" },
  { label: formatMessage(messages.progress), value: "Progress" },
  { label: formatMessage(messages.bible), value: "Bible" },
  { label: formatMessage(messages.notes), value: "Notes" },
  { label: formatMessage(messages.missingFeatures), value: "Missing Features" },
  { label: formatMessage(messages.howItLooks), value: "How It Looks" },
  { label: formatMessage(messages.speed), value: "Speed" },
  { label: formatMessage(messages.bugs), value: "Bugs" },
  { label: formatMessage(messages.crashes), value: "Crashes" },
  { label: formatMessage(messages.other), value: "Other" },
];

export const STARS: (1 | 2 | 3 | 4 | 5)[] = [1, 2, 3, 4, 5];
