export const messages = {
  contentIndex: {
    id: "content.index",
    defaultMessage: "Vol. {{volumeIndex}}: Session {{sessionIndex}}",
  },
  sessionIndex: {
    id: "session.index",
    defaultMessage: "Session {{sessionIndex}}",
  },
  sessionDay: {
    id: "session.day",
    defaultMessage: "Day {{currentDay}}",
  },
  sessionGeneric: {
    id: "session.generic",
    defaultMessage: "Session",
  },
  backButton: {
    id: "session.content.back-button.text",
    defaultMessage: "Back",
  },
  continueButton: {
    id: "session.content.continue-button.text",
    defaultMessage: "Continue",
  },
  completeDayButton: {
    id: "session.content.complete-day-button.text",
    defaultMessage: "Mark day {{day}} complete",
  },
  completeGenericButton: {
    id: "session.content.complete-generic-button.text",
    defaultMessage: "Mark as complete",
  },
  finishButton: {
    id: "session.content.finish-button.text",
    defaultMessage: "Finish",
  },
  nextLessonButton: {
    id: "session.content.next-lesson-button.text",
    defaultMessage: "Next Lesson",
  },
  volumeOverview: {
    id: "session.content.volume-overview",
    defaultMessage: "Volume Overview",
  },
  scripture: {
    id: "session.content.scripture",
    defaultMessage: "Read {{scripture}}",
  },
  question: {
    id: "session.content.question",
    defaultMessage: "Question",
  },
  viewAnswer: {
    id: "session.content.view-answer",
    defaultMessage: "View Answer",
  },
  answerQuestion: {
    id: "session.content.answer-question",
    defaultMessage: "Answer Question",
  },
  answerInputPlaceholder: {
    id: "session.content.answer-input-placeholder",
    defaultMessage: "Type your answer here...",
  },
  answerCancelButton: {
    id: "session.content.answer-cancel-button",
    defaultMessage: "Cancel",
  },
  answerDoneButton: {
    id: "session.content.answer-done-button",
    defaultMessage: "Done",
  },
  youWillNeed: {
    id: "session.content.you-will-need",
    defaultMessage: "You Will Need:",
  },
  gettingReady: {
    id: "session.content.getting-ready",
    defaultMessage: "Getting Ready:",
  },
  faithVerseFun: {
    id: "session.content.faith-verse-fun",
    defaultMessage: "Faith Verse Fun",
  },
  snackTime: {
    id: "session.content.preservice-activities.snack-time",
    defaultMessage: "Snack Time",
  },
  bibleStory: {
    id: "session.content.bible-story",
    defaultMessage: "Bible Story",
  },
};
